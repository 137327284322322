var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "results-container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "text-center lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.showFail
        ? _c("div", [
            _c("h2", { staticClass: "copy-heading" }, [_vm._v("Bad news 😞")]),
            _c("p", { staticClass: "copy-text" }, [
              _vm._v(
                "Unfortunately, you did not pass enough of the required tests to apply for the " +
                  _vm._s(_vm.job.name) +
                  " role"
              ),
            ]),
            _c("br"),
            _c("p", { staticClass: "copy-text" }, [
              _vm._v("Here are your results:"),
            ]),
            _c("br"),
          ])
        : _vm._e(),
      _vm.acquiredSkillsCount >= 3
        ? [
            _c("h2", { staticClass: "copy-heading" }, [
              _vm._v("Congratulations 🎉"),
            ]),
            _c("p", { staticClass: "copy-text" }, [
              _vm._v("You can apply for the " + _vm._s(_vm.job.name) + " role"),
            ]),
            _c("br"),
            _c("p", { staticClass: "copy-text" }, [
              _vm._v("Here are your results:"),
            ]),
            _c("br"),
          ]
        : _vm._e(),
      _c(
        "md-table",
        { staticClass: "results-table" },
        [
          _c(
            "md-table-row",
            [
              _c("md-table-head", [_vm._v("Skill")]),
              _c("md-table-head", [_vm._v("Result")]),
            ],
            1
          ),
          _vm._l(_vm.writtenSkillTests, function (skill, index) {
            return _c(
              "md-table-row",
              { key: index },
              [
                _c("md-table-cell", [_vm._v(_vm._s(skill.id))]),
                _c(
                  "md-table-cell",
                  { class: _vm.resultClass(skill.testResult.pass) },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatResult(skill.testResult.pass)) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.acquiredSkillsCount >= 3 && !_vm.showApplyButton
        ? _c(
            "md-button",
            {
              staticClass: "md-success apply-button",
              on: { click: _vm.navigateToJob },
            },
            [_vm._v(" Proceed to Application ")]
          )
        : _vm._e(),
      _vm.acquiredSkillsCount >= 3 && _vm.showApplyButton
        ? _c(
            "md-button",
            {
              staticClass: "md-success apply-button",
              on: { click: _vm.apply },
            },
            [_vm._v(" Submit Application ")]
          )
        : _vm._e(),
      _vm.error
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c(
                  "h3",
                  {
                    staticStyle: {
                      color: "red !important",
                      "font-weight": "bold !important",
                    },
                  },
                  [_vm._v("Oops, we encountered an issue")]
                ),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    "There has been an error in updating your application status"
                  ),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: { click: _vm.closeErrorModal },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.successModal
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.successModal = false
                },
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "modal-title black" }, [
                  _vm._v("Congratulations!"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "Your application has been sent. We will let you know the outcome of your application."
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.closeSuccessModal },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.successModal
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.successModal = false
                },
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "modal-title black" }, [
                  _vm._v("Congratulations!"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "Your application has been sent. We will let you know the outcome of your application."
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.closeSuccessModal },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }