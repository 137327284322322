var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "error_container md-layout-item ml-auto mt-4 md-small-size-100",
    },
    [
      _vm.iconName ? _c("md-icon", [_vm._v(_vm._s(this.iconName))]) : _vm._e(),
      _c(
        "md-autocomplete",
        {
          class: _vm.fieldClass,
          staticStyle: { "padding-top": "2rem" },
          attrs: {
            value: _vm.value,
            type: "text",
            name: _vm.name,
            required: "",
            "md-options": _vm.options,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event)
            },
            change: function ($event) {
              return _vm.$emit("change", $event)
            },
          },
        },
        [_c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])]
      ),
      _vm.error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v(_vm._s(_vm.label) + " " + _vm._s(_vm.error)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }