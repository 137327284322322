var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bursary-container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "logo-position lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.bursary && _vm.employer
        ? _c(
            "div",
            { staticClass: "bursary-content-container" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-header", [
                    _c("h1", { staticClass: "info-text centre" }, [
                      _vm._v(_vm._s(_vm.bursary.title)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "w-full flex items-center justify-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("md-icon", [_vm._v("business ")]),
                            _c("p", { staticClass: "margin-left-small" }, [
                              _vm._v(_vm._s(_vm.employer.companyName)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "row margin-left-large" },
                          [
                            _c("md-icon", [_vm._v("calendar_month ")]),
                            _c("p", { staticClass: "margin-left-small" }, [
                              _vm._v(_vm._s(_vm.bursary.applicationDeadline)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.bursaryApplication &&
                    _vm.bursaryApplication.applicationStatus ===
                      _vm.BursaryStatuses.APPLIED
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "green",
                                "text-align": "center",
                                "font-size": "2rem",
                              },
                            },
                            [_vm._v("🎉🎉🎉")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "green",
                                "text-align": "center",
                                "font-size": "2rem",
                              },
                            },
                            [
                              _vm._v(
                                "You have already applied for this bursary"
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "md-card-content",
                    [
                      _c(
                        "collapse",
                        {
                          attrs: {
                            collapse:
                              _vm.bursary.workPlacement === "Yes"
                                ? [
                                    "Bursary Description",
                                    "Eligibility Requirements",
                                    "Funding Details",
                                    "Work Placement Information",
                                  ]
                                : [
                                    "Bursary Description",
                                    "Eligibility Requirements",
                                    "Funding Details",
                                  ],
                            icon: "keyboard_arrow_down",
                            "color-collapse": "success",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "md-collapse-pane-1" },
                            [
                              _c(
                                "md-card",
                                { staticClass: "bg-success" },
                                [
                                  _c("md-card-content", [
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("business ")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Company Name")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "margin-left-medium margin-top-small",
                                      },
                                      [_vm._v(_vm._s(_vm.employer.companyName))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("school ")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Bursary Name")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "margin-left-medium margin-top-small",
                                      },
                                      [_vm._v(_vm._s(_vm.bursary.title))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("description ")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Bursary Description")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary.description
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("checklist")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Eligibility Requirements")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary.bursaryRequirements
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("folder")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Required Documents")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "margin-left-small margin-top-small",
                                      },
                                      _vm._l(
                                        _vm.hasDocuments(),
                                        function (document, index) {
                                          return _c("li", { key: index }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatDocumentName(document)
                                              )
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _vm.bursary &&
                                    _vm.bursary.additionalDocuments.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c(
                                              "md-icon",
                                              { staticClass: "bursary-icon" },
                                              [_vm._v("folder")]
                                            ),
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "margin-left-small",
                                              },
                                              [_vm._v("Additional Documents")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "margin-left-small margin-top-small",
                                      },
                                      _vm._l(
                                        _vm.bursary.additionalDocuments,
                                        function (document, index) {
                                          return _c("li", { key: index }, [
                                            _vm._v(
                                              _vm._s(document.name || document)
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _c("br"),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("info")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [
                                            _vm._v(
                                              "Document Submission Instructions"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary
                                            .documentSubmissionInstructions
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("school")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Funding Cover")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticStyle: { "margin-top": "-1rem" },
                                      },
                                      _vm._l(
                                        _vm.bursary.fundingCover,
                                        function (value, key) {
                                          return _c("li", { key: key }, [
                                            _vm._v(
                                              _vm._s(_vm.formatKey(key)) +
                                                ": " +
                                                _vm._s(value ? "✅" : "❌")
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("description")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Additional Cover Details")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary.additionalCoverDetails
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("place")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Work Placement Information")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary.workPlacementInformation
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.bursary
                                                .additionalSubmissions &&
                                              _vm.bursary.additionalSubmissions
                                                .videoSubmission === "Yes",
                                            expression:
                                              "bursary.additionalSubmissions && bursary.additionalSubmissions.videoSubmission === 'Yes'",
                                          },
                                        ],
                                        staticClass: "row",
                                      },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("videocam")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Video Submission Details")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.bursary.additionalSubmissions &&
                                            _vm.bursary.additionalSubmissions
                                              .videoSubmission === "Yes",
                                          expression:
                                            "bursary.additionalSubmissions && bursary.additionalSubmissions.videoSubmission === 'Yes'",
                                        },
                                      ],
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary.additionalSubmissions
                                            .videoSubmissionDetails
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.bursary
                                                .additionalSubmissions &&
                                              _vm.bursary.additionalSubmissions
                                                .writtenSubmission === "Yes",
                                            expression:
                                              "bursary.additionalSubmissions && bursary.additionalSubmissions.writtenSubmission === 'Yes'",
                                          },
                                        ],
                                        staticClass: "row",
                                      },
                                      [
                                        _c(
                                          "md-icon",
                                          { staticClass: "bursary-icon" },
                                          [_vm._v("assignment")]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "margin-left-small" },
                                          [_vm._v("Written Submission Details")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.bursary.additionalSubmissions &&
                                            _vm.bursary.additionalSubmissions
                                              .writtenSubmission === "Yes",
                                          expression:
                                            "bursary.additionalSubmissions && bursary.additionalSubmissions.writtenSubmission === 'Yes'",
                                        },
                                      ],
                                      staticClass:
                                        "margin-left-medium margin-top-small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.bursary.additionalSubmissions
                                            .writtenSubmissionDetails
                                        ),
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  !_vm.bursaryApplication ||
                  _vm.bursaryApplication.applicationStatus ===
                    _vm.BursaryStatuses.IN_PROGRESS
                    ? _c(
                        "div",
                        { staticClass: "apply-button-container" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "jb-standard",
                              on: { click: _vm.applyForBursary },
                            },
                            [_vm._v("Apply")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.error
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Apologies 😔"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeErrorModal },
                        },
                        [_vm._v("Ok")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              86230402
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }