import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// namespaced compatibility
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig); //namespaced compatibility
const firebaseApp = initializeApp(firebaseConfig);

export default app.firestore();
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
