var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v("Have one last final look at the job you are about to post"),
    ]),
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-content",
              [
                _c(
                  "collapse",
                  {
                    attrs: {
                      collapse: [
                        "Job Description",
                        "Job Information",
                        "Budget",
                      ],
                      icon: "keyboard_arrow_down",
                      "color-collapse": "success",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "md-collapse-pane-1" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", { staticClass: "fas fa-list-ul" }),
                                  _vm._v(" Job Description"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Job Name"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.name)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Job Description"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.description)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Job Category"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.category)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Skills Required"),
                              ]),
                              _vm.skills
                                ? _c(
                                    "ul",
                                    _vm._l(_vm.skills, function (skill) {
                                      return _c(
                                        "li",
                                        {
                                          key: skill,
                                          staticClass: "card-description",
                                        },
                                        [_vm._v(_vm._s(skill))]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "md-collapse-pane-2" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-clipboard-list",
                                  }),
                                  _vm._v(" Job Information"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Location"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.location)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Estimated Duration"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.deadline)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "md-collapse-pane-3" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", { staticClass: "fas fa-wallet" }),
                                  _vm._v(" Budget"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Total Budget"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _c("b", [_vm._v("R" + _vm._s(_vm.total()))]),
                                _vm._v(" = "),
                                _c("b", [_vm._v("R" + _vm._s(_vm.rate()))]),
                                _vm._v(" + "),
                                _c("b", [_vm._v("R" + _vm._s(_vm.fee()))]),
                                _vm._v(" + "),
                                _c("b", [
                                  _vm._v(
                                    "R" + _vm._s(_vm.price.facilitationFee)
                                  ),
                                ]),
                              ]),
                              _c("hr"),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Cost Breakdown"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(" Student Rate: "),
                                _c("b", [_vm._v("R" + _vm._s(_vm.rate()))]),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(
                                  " Jobox Service Fee (" +
                                    _vm._s(_vm.percentage()) +
                                    "%): "
                                ),
                                _c("b", [_vm._v("R" + _vm._s(_vm.fee()))]),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(" Jobox Facilitation Cost: "),
                                _c("b", [
                                  _vm._v(
                                    "R" +
                                      _vm._s(_vm.price.facilitationFee) +
                                      ".00"
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }