var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "text-center lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v("📝 Vetting Tests"),
      ]),
      _c(
        "a",
        {
          staticStyle: { "text-align": "center" },
          attrs: {
            href: "https://intercom.help/jobox-64fe20407ade/en/articles/9064938-introducing-jobox-s-new-vetting-process",
            target: "_blank",
          },
        },
        [_vm._v("Click here for a full overview of our vetting process")]
      ),
      _c(
        "md-table",
        { staticClass: "tests-table" },
        [
          _c(
            "md-table-row",
            [
              _c("md-table-head", { staticClass: "test" }, [
                _vm._v("🎯 Skills"),
              ]),
              _c("md-table-head", { staticStyle: { "text-align": "right" } }),
            ],
            1
          ),
          _vm._l(_vm.writtenSkillTests, function (ref) {
            var key = ref[0]
            var value = ref[1]
            return _c(
              "md-table-row",
              { key: "written-" + key },
              [
                value.testStatus !== _vm.ApplicationStatus.IN_PROGRESS
                  ? [
                      _c("md-table-cell", [_vm._v(_vm._s(value.skill))]),
                      _c("md-table-cell", { staticClass: "tests-table-cell" }, [
                        _c("div", { staticClass: "status-container" }, [
                          _c("div", { staticClass: "status-box" }, [
                            _c("p", { staticClass: "status-text md-success" }, [
                              _vm._v(_vm._s(value.testStatus)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          _vm._l(_vm.skillTestsToWrite, function (skill, index) {
            return _c(
              "md-table-row",
              { key: "toWrite-" + (skill.id || index) },
              [
                _c("md-table-cell", [_vm._v(_vm._s(skill.skillName))]),
                _c(
                  "md-table-cell",
                  { staticClass: "tests-table-cell" },
                  [
                    _vm.testStatuses[skill.skillName] !== "Disqualified"
                      ? [
                          _c(
                            "md-button",
                            {
                              class: [_vm.buttonClass(skill)],
                              staticStyle: { width: "fit-content" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewTest(
                                    skill.formId,
                                    skill.skillName
                                  )
                                },
                              },
                            },
                            [
                              _vm.testStatuses[skill.skillName]
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.testStatuses[skill.skillName]
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("Loading...")]),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "span",
                            { staticClass: "status-text disqualified" },
                            [_vm._v("Disqualified")]
                          ),
                        ],
                  ],
                  2
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.error
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! 🤚"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeErrorModal },
                        },
                        [_vm._v(" Ok ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3042895156
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }