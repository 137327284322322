var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v("Specify the location of work"),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50 md-small-size-100" },
        [
          _c("icon-checkbox", {
            attrs: { icon: "fas fa-laptop", title: "Remote" },
            on: { input: _vm.remoteSelection },
            model: {
              value: _vm.remote,
              callback: function ($$v) {
                _vm.remote = $$v
              },
              expression: "remote",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50 md-small-size-100" },
        [
          _c("icon-checkbox", {
            attrs: { icon: "fas fa-building", title: "On-site" },
            on: { input: _vm.onsiteSelection },
            model: {
              value: _vm.onsite,
              callback: function ($$v) {
                _vm.onsite = $$v
              },
              expression: "onsite",
            },
          }),
        ],
        1
      ),
      _vm.onsite
        ? _c(
            "div",
            { staticClass: "md-layout-item mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("location") && _vm.touched.location,
                    },
                    { "md-error": _vm.errors.has("location") },
                  ],
                },
                [
                  _c("label", [_vm._v("Location")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.location,
                        expression: "modelValidations.location",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "location",
                      type: "text",
                      name: "location",
                      required: "",
                    },
                    on: { change: _vm.addLocation },
                    model: {
                      value: _vm.location,
                      callback: function ($$v) {
                        _vm.location = $$v
                      },
                      expression: "location",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("location"),
                              expression: "errors.has('location')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("location") &&
                                _vm.touched.location,
                              expression:
                                "!errors.has('location') && touched.location",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "md-layout-item mt-4 md-small-size-100" },
        [
          _c(
            "md-field",
            {
              class: [
                {
                  "md-valid":
                    !_vm.errors.has("deadline") && _vm.touched.deadline,
                },
                { "md-error": _vm.errors.has("deadline") },
              ],
            },
            [
              _c("label", [_vm._v("Duration")]),
              _c(
                "md-select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.modelValidations.deadline,
                      expression: "modelValidations.deadline",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    "data-vv-name": "deadline",
                    type: "text",
                    name: "deadline",
                    required: "",
                  },
                  on: { input: _vm.addDeadline },
                  model: {
                    value: _vm.deadline,
                    callback: function ($$v) {
                      _vm.deadline = $$v
                    },
                    expression: "deadline",
                  },
                },
                _vm._l(_vm.deadlines, function (deadline, index) {
                  return _c(
                    "md-option",
                    { key: index, attrs: { value: deadline } },
                    [_vm._v(_vm._s(deadline))]
                  )
                }),
                1
              ),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("deadline"),
                          expression: "errors.has('deadline')",
                        },
                      ],
                      staticClass: "error",
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.errors.has("deadline") && _vm.touched.deadline,
                          expression:
                            "!errors.has('deadline') && touched.deadline",
                        },
                      ],
                      staticClass: "success",
                    },
                    [_vm._v("done")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }