var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "star-rating" },
    _vm._l(_vm.ratings, function (rating) {
      return _c(
        "span",
        {
          key: rating,
          class: {
            "is-selected": _vm.modelValue >= rating && _vm.modelValue != null,
            "is-disabled": _vm.disabled,
          },
          on: {
            click: function ($event) {
              return _vm.set(rating)
            },
            mouseover: function ($event) {
              return _vm.star_over(rating)
            },
            mouseleave: _vm.star_out,
          },
        },
        [
          _c("input", {
            staticClass: "star-rating star-rating__checkbox",
            attrs: { type: "hidden", name: _vm.name, disabled: _vm.disabled },
            domProps: { value: rating },
          }),
          _vm._v(" ★ "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }