import { render, staticRenderFns } from "./ViewTalentPool.vue?vue&type=template&id=61fb9085&scoped=true&"
import script from "./ViewTalentPool.vue?vue&type=script&lang=js&"
export * from "./ViewTalentPool.vue?vue&type=script&lang=js&"
import style0 from "./ViewTalentPool.vue?vue&type=style&index=0&id=61fb9085&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fb9085",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/jobox-platform/jobox-platform/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61fb9085')) {
      api.createRecord('61fb9085', component.options)
    } else {
      api.reload('61fb9085', component.options)
    }
    module.hot.accept("./ViewTalentPool.vue?vue&type=template&id=61fb9085&scoped=true&", function () {
      api.rerender('61fb9085', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/client/talentPool/ViewTalentPool.vue"
export default component.exports