<template>
	<modal v-if="isFilterClicked" :class="isFilterClicked ? 'table-filter open' : 'table-filter'">
		<template slot="header">
			<div class="close-btn-container">
				<h3 class="filter-title">Filter Students</h3>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="closeFilter">
					<md-icon>clear</md-icon>
				</md-button>
			</div>
		</template>

		<template slot="body">
			<md-field>
				<label for="institutions">Institutions</label>
				<md-select
					v-model="institutions"
					hint="Select institutions to filter by"
					label="Select Institutions"
					multiple
					persistent-hint
					@input="emitFilters"
					name="institutions"
				>
					<md-option v-for="(value, index) in uniqueInstitutions" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label for="qualificationTypes">QualificationTypes</label>
				<md-select
					v-model="qualificationTypes"
					hint="Select qualification types to filter by"
					label="Select Qualification Types"
					multiple
					persistent-hint
					@input="emitFilters"
				>
					<md-option v-for="(value, index) in uniqueQualificationTypes" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Year Of Study</label>
				<md-select v-model="yearOfStudy" hint="Select year of study to filter by" label="Select Year of Study" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueYearOfStudy" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Degrees</label>
				<md-select v-model="degrees" hint="Select degrees to filter by" label="Select Degrees" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueDegrees" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Interested Departments</label>
				<md-select
					v-model="interestedDepartments"
					hint="Select interested departments to filter by"
					label="Select Interested Departments"
					multiple
					persistent-hint
					@input="emitFilters"
				>
					<md-option v-for="(value, index) in uniqueInterestedDepartments" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Preferred Employers</label>
				<md-select
					v-model="preferredEmployers"
					hint="Select preferred employers to filter by"
					label="Select Preferred Employers"
					multiple
					persistent-hint
					@input="emitFilters"
				>
					<md-option v-for="(value, index) in uniquePreferredEmployers" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Programmes</label>
				<md-select v-model="programmes" hint="Select programmes to filter by" label="Select Programmes" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueProgrammes" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Races</label>
				<md-select v-model="races" hint="Select races to filter by" label="Select Races" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueRaces" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Gender</label>
				<md-select v-model="genders" hint="Select gender to filter by" label="Select Gender" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueGenders" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Driver's License</label>
				<md-select v-model="driversLicense" hint="Select drivers license to filter by" label="Select Drivers License" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueDriversLicense" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Vehicle</label>
				<md-select v-model="vehicle" hint="Select Vehicle to filter by" label="Select Vehicle" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueVehicles" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Disability</label>
				<md-select v-model="disability" hint="Select disability to filter by" label="Select Disability" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueDisability" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Citizenships</label>
				<md-select v-model="citizenships" hint="Select citizenships to filter by" label="Select Citizenships" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueCitizenships" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Locations</label>
				<md-select v-model="locations" hint="Select locations to filter by" label="Select Locations" multiple persistent-hint @input="emitFilters">
					<md-option v-for="(value, index) in uniqueLocations" :key="index" :value="value">
						{{ value }}
					</md-option>
				</md-select>
			</md-field>

			<md-button @click="clearFilters" class="md-danger">Clear Filters</md-button>
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';

export default {
	components: { Modal },
	props: {
		isFilterClicked: Boolean,
		uniqueInstitutions: { type: Array, required: true },
		uniqueQualificationTypes: { type: Array, required: true },
		uniqueYearOfStudy: { type: Array, required: true },
		uniqueDegrees: { type: Array, required: true },
		uniqueInterestedDepartments: { type: Array, required: true },
		uniquePreferredEmployers: { type: Array, required: true },
		uniqueProgrammes: { type: Array, required: true },
		uniqueRaces: { type: Array, required: true },
		uniqueGenders: { type: Array, required: true },
		uniqueDriversLicense: { type: Array, required: true },
		uniqueVehicles: { type: Array, required: true },
		uniqueDisability: { type: Array, required: true },
		uniqueCitizenships: { type: Array, required: true },
		uniqueLocations: { type: Array, required: true },
	},
	data() {
		return {
			institutions: [],
			qualificationTypes: [],
			yearOfStudy: [],
			degrees: [],
			interestedDepartments: [],
			preferredEmployers: [],
			programmes: [],
			races: [],
			genders: [],
			driversLicense: [],
			vehicle: [],
			disability: [],
			citizenships: [],
			locations: [],
		};
	},
	methods: {
		closeFilter() {
			this.$emit('update:isClosed', false);
		},
		clearFilters() {
			this.$emit('clearFilters');
		},
		emitFilters() {
			this.$emit('filterChanged', {
				institutions: this.institutions,
				qualificationTypes: this.qualificationTypes,
				yearOfStudy: this.yearOfStudy,
				degrees: this.degrees,
				interestedDepartments: this.interestedDepartments,
				preferredEmployers: this.preferredEmployers,
				programmes: this.programmes,
				races: this.races,
				genders: this.genders,
				driversLicense: this.driversLicense,
				vehicles: this.vehicle,
				disability: this.disability,
				citizenships: this.citizenships,
				locations: this.locations,
			});
		},
	},
};
</script>

<style scoped>
.md-select {
	margin-bottom: 0.5rem;
}
.close-btn-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.filter-title {
	flex-grow: 1;
	text-align: center;
	margin: 0;
}

.close-btn {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 14px;
	color: #333;
}

.close-btn .close-icon {
	width: 16px;
	height: 16px;

	background-size: contain;
	margin-right: 8px;
}
</style>
