<template>
	<div>
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<div v-if="bursaryApplication && bursaryApplication.submittedDocuments">
			<md-card style="width: 1400px; margin: auto">
				<md-card-header>
					<div class="md-title text-center">{{ bursaryTitle }}</div>
					<div class="status">
						<p style="font-size: 15px">
							<md-icon style="font-size: 1.4rem !important; margin-right: 8px">assignment</md-icon>
							Status: {{ bursaryApplication.applicationStatus }}
						</p>
					</div>
				</md-card-header>
				<md-card-header>
					<div class="md-title">Submitted Documents</div>
				</md-card-header>
				<md-card-content>
					<md-table>
						<md-table-row>
							<md-table-head>Document</md-table-head>
							<md-table-head>Verification Status</md-table-head>
							<md-table-head>Resubmit Required</md-table-head>
							<md-table-head></md-table-head>
							<md-table-head></md-table-head>
						</md-table-row>

						<md-table-row v-for="(doc, docType) in bursaryApplication.submittedDocuments" :key="docType">
							<md-table-cell>{{ formatKey(docType) }}</md-table-cell>
							<md-table-cell>
								{{ doc.isVerified && !doc.shouldResubmit ? 'Verified' : 'Not Verified' }}
							</md-table-cell>
							<md-table-cell>{{ doc.shouldResubmit ? 'Yes' : 'No' }}</md-table-cell>

							<md-table-cell>
								<md-button @click="viewDoc(doc.url)" class="resubmission-btn" style="width: fit-content !important; background-color: #558fe6 !important">
									View
								</md-button>
							</md-table-cell>
							<md-table-cell>
								<input type="file" :ref="`fileInput_${docType}`" style="display: none" @change="handleFileChange($event, docType)" accept="application/pdf" />
								<md-button class="md-success" style="width: fit-content !important" @click="triggerFileUpload(docType)" :disabled="!doc.shouldResubmit">
									Resubmit
								</md-button>
							</md-table-cell>
							<md-table-cell>
								<span :style="{ color: doc.isVerified && !doc.shouldResubmit ? 'green' : 'red', fontSize: '1rem' }">
									{{ doc.isVerified && !doc.shouldResubmit ? '✅' : '❌' }}
								</span>
							</md-table-cell>
						</md-table-row>
					</md-table>

					<md-card v-if="hasComments" class="feedback-card">
						<md-card-header>
							<div class="md-title">
								<md-icon class="feedback-icon">comment</md-icon>
								Submission Feedback
							</div>
						</md-card-header>

						<md-card-content>
							<div v-for="(doc, docType) in bursaryApplication.submittedDocuments" :key="docType" class="feedback-doc-type">
								<div v-if="doc.comments && doc.comments.comment">
									<h3 class="doc-type-title">
										<md-icon class="doc-type-icon">description</md-icon>
										{{ formatKey(docType) }}
									</h3>

									<div class="comment-item">
										<span class="comment-timestamp">
											{{ formatTimestamp(doc.comments.date) }}
										</span>
										<span class="comment-text">{{ doc.comments.comment }}</span>
									</div>
								</div>
							</div>
						</md-card-content>
					</md-card>
				</md-card-content>
			</md-card>
		</div>

		<Modal v-if="showModal" @close="closeModal">
			<template #header>
				<div class="md-title">Resubmit Document</div>
			</template>
			<template #body>
				<p v-if="uploadSuccess" style="color: green; margin-top: -1rem">🎉🎉 Document uploaded successfully! 🎉🎉</p>
				<p v-if="!uploadSuccess" style="color: red; margin-top: -1rem">Error uploading document. Please try again.</p>
				<md-button v-if="uploadSuccess" class="md-button md-primary" @click="viewUploadedDocument" style="margin-left: 10px"> View Document </md-button>
			</template>
			<template #footer>
				<md-button class="md-button md-success" @click="closeModal">Okay</md-button>
			</template>
		</Modal>
	</div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, updateDoc, getDocs, query, where, limit } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Modal from '@/components/Modal.vue';

export default {
	components: {
		Modal,
	},
	computed: {
		hasComments() {
			if (!this.bursaryApplication || !this.bursaryApplication.submittedDocuments) return false;
			return Object.values(this.bursaryApplication.submittedDocuments).some(doc => doc.comments && doc.comments.comment);
		},
	},

	data() {
		return {
			uid: getAuth().currentUser.uid,
			alias: null,
			bursaryApplication: null,
			bursaryTitle: '',
			selectedDocType: null,
			showModal: false,
			uploadSuccess: false,
			downloadURL: null,
			loading: false,
		};
	},
	methods: {
		formatTimestamp(timestamp) {
			if (!timestamp) return '';
			if (typeof timestamp === 'string') {
				return new Date(timestamp).toLocaleString();
			}
			const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
			return date.toLocaleString();
		},
		formatKey(key) {
			return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
		},
		viewDoc(url) {
			window.open(url, '_blank');
		},
		async fetchAlias() {
			try {
				const db = getFirestore();
				const usersRef = collection(db, 'users');
				const querySnapshot = await getDocs(query(usersRef, where('userId', '==', this.uid), limit(1)));

				if (!querySnapshot.empty) {
					this.alias = querySnapshot.docs[0].data().alias;
					await this.fetchBursaryApplication();
				} else {
					console.log('No matching documents found');
				}
			} catch (error) {
				console.error('Error fetching alias:', error);
			}
		},
		async fetchBursaryApplication() {
			if (!this.alias) return;

			try {
				const db = getFirestore();
				const docId = `${this.alias}-${this.$route.params.id}`;
				const bursaryAppRef = doc(db, 'bursary-applications', docId);
				const bursaryDoc = await getDoc(bursaryAppRef);

				if (bursaryDoc.exists()) {
					this.bursaryApplication = bursaryDoc.data();
					await this.fetchBursaryTitle(this.bursaryApplication.bursaryID);
				} else {
					console.log('No matching bursary application found');
				}
			} catch (error) {
				console.error('Error fetching bursary application:', error);
			}
		},
		async fetchBursaryTitle(bursaryID) {
			try {
				const db = getFirestore();
				const bursaryRef = doc(db, 'bursaries', bursaryID);
				const bursaryDoc = await getDoc(bursaryRef);

				if (bursaryDoc.exists()) {
					this.bursaryTitle = bursaryDoc.data().title;
				} else {
					console.log('No matching bursary found');
				}
			} catch (error) {
				console.error('Error fetching bursary title:', error);
			}
		},
		triggerFileUpload(docType) {
			this.selectedDocType = docType;
			this.$refs[`fileInput_${docType}`][0].click();
		},
		handleFileChange(event, docType) {
			const file = event.target.files[0];
			if (file) {
				this.uploadDocument(file, docType);
			}
		},
		async uploadDocument(file, docType) {
			try {
				this.loading = true;
				this.downloadURL = await this.uploadToFirebaseStorage(docType, file);
				await this.updateBursaryDocument(docType, this.downloadURL);
				this.uploadSuccess = true;
				this.loading = false;
			} catch (error) {
				this.uploadSuccess = false;
				this.loading = false;
			}
			this.showModal = true;
		},
		uploadToFirebaseStorage(docType, file) {
			const storage = getStorage();
			const storageRef = ref(storage, `/users/students/${this.alias}/bursaryDocuments/${this.$route.params.id}/${docType}`);
			const metadata = {
				contentType: file.type,
				customMetadata: { userAlias: this.alias, applicationId: this.$route.params.id, certified: docType === 'id', docType },
			};

			const uploadTask = uploadBytesResumable(storageRef, file, metadata);

			return new Promise((resolve, reject) => {
				uploadTask.on('state_changed', null, reject, async () => {
					const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
					resolve(downloadURL);
				});
			});
		},
		async updateBursaryDocument(docType, downloadURL) {
			const db = getFirestore();
			const docId = `${this.alias}-${this.$route.params.id}`;
			const bursaryAppRef = doc(db, 'bursary-applications', docId);

			try {
				await updateDoc(bursaryAppRef, {
					[`submittedDocuments.${docType}.url`]: downloadURL,
					[`submittedDocuments.${docType}.shouldResubmit`]: false,
					[`submittedDocuments.${docType}.isVerified`]: false,
				});
				this.downloadURL = downloadURL;
			} catch (error) {
				console.error('Error updating document URL:', error);
			}
		},
		viewUploadedDocument() {
			if (this.downloadURL) {
				window.open(this.downloadURL, '_blank');
			}
		},
		closeModal() {
			this.showModal = false;
			this.uploadSuccess = false;
		},
	},
	async created() {
		await this.fetchAlias();
	},
};
</script>

<style>
.resubmission-btn {
	width: fit-content !important;
	background-color: #558fe6 !important;
}

.resubmission-btn:hover {
	background-color: #4384e6 !important;
}
.status {
	display: flex;
	align-items: center;
	justify-content: center;
}
.background {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.background .logo-position {
	position: absolute;
	top: 40%;
	left: 47%;
	transform: translate(-50%, -50%);
}
.feedback-card {
	margin-top: 1rem;
}

.feedback-icon {
	font-size: 1.5rem !important;
	margin-right: 8px;
}

.feedback-doc-type {
	margin-bottom: 1.5rem;
}

.doc-type-title {
	margin: 0 0 0.5rem;
	font-size: 1.1rem;
	font-weight: 600;
}

.comment-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.comment-item {
	background-color: #f5f5f5;
	border-radius: 4px;
	padding: 8px 12px;
	margin: 6px 0;
	display: flex;
	flex-direction: column;
}

.comment-text {
	font-size: 0.95rem;
	color: #333;
}

.comment-timestamp {
	font-size: 0.85rem;
	color: #777;
	margin-bottom: 0.5rem;
}

.doc-type-icon {
	font-size: 1.25rem !important;
	margin-right: 6px;
	vertical-align: middle;
}
</style>
