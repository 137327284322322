var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.approved
    ? _c(
        "div",
        { staticClass: "content" },
        [
          _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
            : _vm._e(),
          _c("hr"),
          _vm.isOfferDeclined
            ? _c("div", [
                _c("h2", { staticClass: "centre" }, [_vm._v("Response Sent!")]),
              ])
            : _c("div", [
                _c("h2", { staticClass: "centre" }, [
                  _vm._v("Application Sent!"),
                ]),
              ]),
          _c("hr"),
          _vm.isOfferDeclined
            ? _c("div", [
                _c("p", { staticClass: "centre" }, [
                  _vm._v(
                    "We will let you know on the outcome of your response"
                  ),
                ]),
              ])
            : _c("div", [
                _c("p", { staticClass: "centre" }, [
                  _vm._v(
                    "We will let you know on the outcome of your application"
                  ),
                ]),
              ]),
          _c("hr"),
          _c("client-status-profile", {
            attrs: {
              companyName: this.client.companyName,
              clientName: this.client.clientName,
              clientAlias: _vm.client.clientAlias,
              name: this.client.name,
              picture: this.picture,
              cardUserImage: _vm.cardUserImage,
            },
          }),
          _c(
            "div",
            { staticClass: "centre" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-danger",
                  on: {
                    click: function ($event) {
                      _vm.cancelModal = true
                    },
                  },
                },
                [_vm._v(" Withdraw Application ")]
              ),
            ],
            1
          ),
          _vm.cancelModal
            ? _c(
                "modal",
                { on: { close: _vm.cancelModalHide } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Withdraw Application"),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.cancelModalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("p", { staticClass: "black" }, [
                      _vm._v(
                        "You are now choosing to withdraw your application for this job. Would you like to proceed?"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-danger",
                            on: { click: _vm.cancelModalHide },
                          },
                          [_vm._v("No")]
                        ),
                        _vm._v("     "),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: {
                              click: function ($event) {
                                return _vm.studentCancelSelect(_vm.applicant)
                              },
                            },
                          },
                          [_vm._v("Yes")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "content" },
        [
          _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(1)])
            : _vm._e(),
          _c("hr"),
          _c("h2", { staticClass: "centre" }, [
            _vm._v("Application Successful!"),
          ]),
          _c("hr"),
          _c("p", { staticClass: "centre" }, [
            _vm._v("Congratulations you have been selected for the job"),
          ]),
          _c("hr"),
          _c("client-status-profile", {
            attrs: {
              companyName: this.client.companyName,
              clientName: this.client.clientName,
              clientAlias: _vm.client.clientAlias,
              name: this.client.name,
              picture: this.picture,
              cardUserImage: _vm.cardUserImage,
              applicationStatus: _vm.applicationStatus,
            },
          }),
          _c(
            "div",
            { staticClass: "centre" },
            [
              _vm._m(2),
              _c(
                "md-button",
                {
                  staticClass: "md-danger",
                  on: { click: _vm.openReasonsModal },
                },
                [_vm._v(" Decline Job ")]
              ),
              _vm._v("        "),
              _c(
                "md-button",
                { staticClass: "md-success", on: { click: _vm.acceptOffer } },
                [_vm._v(" Accept Job ")]
              ),
            ],
            1
          ),
          _vm.declineModal
            ? _c(
                "modal",
                { on: { close: _vm.declineModalHide } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! ✋"),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.declineModalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("p", { staticClass: "black" }, [
                      _vm._v(
                        "Are you sure you want to turn down the job offer?"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-danger",
                            on: { click: _vm.declineModalHide },
                          },
                          [_vm._v("No")]
                        ),
                        _vm._v("     "),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.sendStudentResponse },
                          },
                          [_vm._v("Yes")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.confirmSelection
            ? _c(
                "modal",
                { on: { close: _vm.closeConfirmSelectionModal } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! ✋"),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.closeConfirmSelectionModal },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("p", { staticClass: "black" }, [
                      _vm._v(
                        "Please make a selection for a reason for declining the offer"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.closeConfirmSelectionModal },
                          },
                          [_vm._v("OK")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.closeReasonModal
            ? _c(
                "modal",
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v(
                          "Please specify the reasons for rejecting the offer"
                        ),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.hideReasonsModal },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "body" },
                    [
                      _c("md-table", {
                        attrs: { "md-card": "" },
                        on: { "md-selected": _vm.onSelect },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "md-table-row",
                              fn: function (ref) {
                                var item = ref.item
                                return _c(
                                  "md-table-row",
                                  { attrs: { "md-selectable": "multiple" } },
                                  [
                                    _c("md-table-cell", [
                                      _vm._v(_vm._s(item.reason)),
                                    ]),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          44175680
                        ),
                        model: {
                          value: _vm.rejectionReasons,
                          callback: function ($$v) {
                            _vm.rejectionReasons = $$v
                          },
                          expression: "rejectionReasons",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "centre",
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-danger",
                            on: { click: _vm.hideReasonsModal },
                          },
                          [_vm._v(" Cancel")]
                        ),
                        _vm._v("        "),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.confirmDeclineModal },
                          },
                          [_vm._v(" Confirm")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.sendResponseModal
            ? _c(
                "modal",
                [
                  _c("template", { slot: "body" }, [
                    _c("p", [
                      _vm._v(
                        "Thank you for your reponse. We will be in touch with the employer's response"
                      ),
                    ]),
                  ]),
                  _c(
                    "template",
                    { slot: "footer" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-button md-success",
                          on: { click: _vm.closeResponseModal },
                        },
                        [_vm._v(" Okay")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("I would like to:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }