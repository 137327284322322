var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("h5", { staticClass: "info-text" }, [
        _vm._v("Let's start with the basic information"),
      ]),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("notifications"),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-40 md-small-size-100" },
            [
              _c("div", { staticClass: "picture-container" }, [
                _c("div", { staticClass: "picture" }, [
                  !_vm.image
                    ? _c("div", [
                        _c("img", {
                          staticClass: "picture-src",
                          attrs: { src: _vm.avatar, title: "" },
                        }),
                      ])
                    : _c("div", [_c("img", { attrs: { src: _vm.image } })]),
                  _c("input", {
                    attrs: {
                      type: "file",
                      title: "Profile Picture",
                      accept: "image/*",
                    },
                    on: { change: _vm.previewImage },
                  }),
                ]),
                _c("h6", { staticClass: "description" }, [
                  _vm._v("Profile Picture"),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-60 mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("companyName") &&
                        _vm.touched.companyName,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("companyName") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fas fa-building" })]),
                  _c("label", [_vm._v("Company Name")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.companyName,
                        expression: "modelValidations.companyName",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "companyName",
                      type: "text",
                      name: "companyName",
                      required: "",
                    },
                    on: { change: _vm.addCompanyName },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("companyName"),
                              expression: "errors.has('companyName')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("companyName") &&
                                _vm.touched.companyName,
                              expression:
                                "!errors.has('companyName') && touched.companyName",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.errors.has("companyName")
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("Please add a company name"),
                  ])
                : _vm._e(),
              _c("br"),
              _c("br"),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("companyWebsite") &&
                        _vm.touched.companyWebsite,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("companyWebsite") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fas fa-globe" })]),
                  _c("label", [_vm._v("Company Website")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.companyWebsite,
                        expression: "modelValidations.companyWebsite",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "companyWebsite",
                      type: "text",
                      name: "companyWebsite",
                    },
                    on: { change: _vm.addCompanyWebsite },
                    model: {
                      value: _vm.companyWebsite,
                      callback: function ($$v) {
                        _vm.companyWebsite = $$v
                      },
                      expression: "companyWebsite",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("companyWebsite"),
                              expression: "errors.has('companyWebsite')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("companyWebsite") &&
                                _vm.touched.companyWebsite,
                              expression:
                                "!errors.has('companyWebsite') && touched.companyWebsite",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.errors.has("companyWebsite")
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("Please add a company website"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("companyCategory") &&
                        _vm.touched.companyCategory,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("companyCategory") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "far fa-building" })]),
                  _c("label", [_vm._v("Company Category")]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.companyCategory,
                          expression: "modelValidations.companyCategory",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "companyCategory",
                        name: "select",
                        required: "",
                      },
                      on: { input: _vm.addCompanyCategory },
                      model: {
                        value: _vm.companyCategory,
                        callback: function ($$v) {
                          _vm.companyCategory = $$v
                        },
                        expression: "companyCategory",
                      },
                    },
                    _vm._l(
                      _vm.companyCategorys,
                      function (companyCategory, index) {
                        return _c(
                          "md-option",
                          { key: index, attrs: { value: companyCategory } },
                          [_vm._v(_vm._s(companyCategory))]
                        )
                      }
                    ),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("companyCategory"),
                              expression: "errors.has('companyCategory')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("companyCategory") &&
                                _vm.touched.companyCategory,
                              expression:
                                "!errors.has('companyCategory') && touched.companyCategory",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.errors.has("companyCategory")
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("Please add a company category"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("companySize") &&
                        _vm.touched.companySize,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("companySize") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fas fa-sitemap" })]),
                  _c("label", [_vm._v("Company Size")]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.companySize,
                          expression: "modelValidations.companySize",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "companySize",
                        name: "select",
                        required: "",
                      },
                      on: { input: _vm.addCompanySize },
                      model: {
                        value: _vm.companySize,
                        callback: function ($$v) {
                          _vm.companySize = $$v
                        },
                        expression: "companySize",
                      },
                    },
                    _vm._l(_vm.sizeTypes, function (sizeType, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: sizeType } },
                        [_vm._v(_vm._s(sizeType))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("companySize"),
                              expression: "errors.has('companySize')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("companySize") &&
                                _vm.touched.companySize,
                              expression:
                                "!errors.has('companySize') && touched.companySize",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.errors.has("companySize")
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("Please add a company size"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-autocomplete",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.modelValidations.industry,
                      expression: "modelValidations.industry",
                    },
                  ],
                  staticClass: "industry",
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("industry") && _vm.touched.industry,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("industry") },
                  ],
                  attrs: {
                    "md-options": _vm.industries,
                    "data-vv-name": "industry",
                    name: "industry",
                    required: "",
                  },
                  on: { input: _vm.addIndustry },
                  model: {
                    value: _vm.industry,
                    callback: function ($$v) {
                      _vm.industry = $$v
                    },
                    expression: "industry",
                  },
                },
                [
                  _c("label", { staticStyle: { "margin-left": "35px" } }, [
                    _vm._v("Industry"),
                  ]),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("industry"),
                              expression: "errors.has('industry')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("industry") &&
                                _vm.touched.industry,
                              expression:
                                "!errors.has('industry') && touched.industry",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.errors.has("industry")
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("Please add an industry"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "md-field",
            {
              class: [
                {
                  "md-valid": !_vm.errors.has("aboutMe") && _vm.touched.aboutMe,
                },
                { "md-error": _vm.errors.has("aboutMe") },
              ],
            },
            [
              _c("label", [_vm._v("About Us")]),
              _c("md-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.aboutMe,
                    expression: "modelValidations.aboutMe",
                  },
                ],
                attrs: {
                  "data-vv-name": "aboutMe",
                  type: "text",
                  name: "aboutMe",
                  required: "",
                },
                on: { change: _vm.addAboutMe },
                model: {
                  value: _vm.aboutMe,
                  callback: function ($$v) {
                    _vm.aboutMe = $$v
                  },
                  expression: "aboutMe",
                },
              }),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("aboutMe"),
                          expression: "errors.has('aboutMe')",
                        },
                      ],
                      staticClass: "error",
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.errors.has("aboutMe") && _vm.touched.aboutMe,
                          expression:
                            "!errors.has('aboutMe') && touched.aboutMe",
                        },
                      ],
                      staticClass: "success",
                    },
                    [_vm._v("done")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [_vm._v(_vm._s(_vm.body))]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      _c("div", { staticClass: "loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }