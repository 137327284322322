var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
        [
          _c(
            "simple-wizard",
            {
              attrs: {
                image: _vm.image,
                firstName: _vm.firstName,
                lastName: _vm.lastName,
                companyName: _vm.companyName,
                companyWebsite: _vm.companyWebsite,
                phoneNumber: _vm.phoneNumber,
                vat: _vm.vat,
                companySize: _vm.companySize,
                industry: _vm.industry,
                aboutMe: _vm.aboutMe,
                addressLine1: _vm.addressLine1,
                addressLine2: _vm.addressLine2,
                city: _vm.city,
                province: _vm.province,
                postalCode: _vm.postalCode,
                companyCategory: _vm.companyCategory,
                country: _vm.country,
              },
              on: { feedback: _vm.addFeedback },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "btn-next md-success",
                      on: { click: _vm.back },
                    },
                    [_vm._v("Save Changes")]
                  ),
                  _c("p"),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Edit Your Profile"),
                  ]),
                  _c("h5", { staticClass: "category" }, [
                    _vm._v("This information will let us know more about you."),
                  ]),
                ],
                1
              ),
              _c(
                "wizard-tab",
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [_vm._v("About")]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-user" }),
                    ]),
                  ]),
                  _c("first-step", {
                    ref: "step1",
                    on: {
                      image: _vm.addImage,
                      firstName: _vm.addFirstName,
                      lastName: _vm.addLastName,
                      companyName: _vm.addCompanyName,
                      companyWebsite: _vm.addCompanyWebsite,
                      phoneNumber: _vm.addPhoneNumber,
                      vat: _vm.addVat,
                      companySize: _vm.addCompanySize,
                      industry: _vm.addIndustry,
                      companyCategory: _vm.addCompanyCategory,
                      aboutMe: _vm.addAboutMe,
                    },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [_vm._v("Location")]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-location-arrow" }),
                    ]),
                  ]),
                  _c("second-step", {
                    ref: "step2",
                    on: {
                      addressLine1: _vm.addAddressLine1,
                      addressLine2: _vm.addAddressLine2,
                      city: _vm.addCity,
                      province: _vm.addProvince,
                      postalCode: _vm.addPostalCode,
                      country: _vm.addCountry,
                    },
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Oops!"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(_vm._s(_vm.feedback)),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }