import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=141717ac&scoped=true&"
import script from "./Profile.vue?vue&type=script&lang=js&"
export * from "./Profile.vue?vue&type=script&lang=js&"
import style0 from "./Profile.vue?vue&type=style&index=0&id=141717ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141717ac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/jobox-platform/jobox-platform/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('141717ac')) {
      api.createRecord('141717ac', component.options)
    } else {
      api.reload('141717ac', component.options)
    }
    module.hot.accept("./Profile.vue?vue&type=template&id=141717ac&scoped=true&", function () {
      api.rerender('141717ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/student/profile/Profile.vue"
export default component.exports