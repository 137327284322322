<template>
	<modal v-if="inviteModalVisible" @close="inviteModalHide" class="modal">
		<template slot="header">
			<md-button
				style="position: absolute; top: 24px; left: 10px; font-size: 30px !important"
				class="md-simple md-just-icon md-round modal-default-button"
				@click="openInterviewHelp"
			>
				<md-icon>help</md-icon>
			</md-button>

			<h2 v-if="!interviewSentSuccess" class="modal-title black">Schedule Interviews 📆</h2>
			<md-button class="md-simple md-just-icon md-round modal-default-button" @click="inviteModalHide">
				<md-icon>clear</md-icon>
			</md-button>
		</template>

		<template slot="body">
			<md-card v-if="step === constants.ASK_IF_LINK" class="modalCard">
				<div>Do you have a scheduling link?</div>
				(ie Calendly)
				<div style="margin-top: 1rem">
					<md-button @click="onAskIfLinkYes" class="btn-next md-success button">Yes</md-button>
					<div class="spacer"></div>
					<md-button class="btn-next md-danger button" @click="onNoLink">No</md-button>
				</div>
			</md-card>
			<md-card v-if="step === constants.ASK_FOR_LINK" class="modalCard">
				<div>Please enter your scheduling link</div>
				<div style="text-align: center; margin: 0px 50px 0px 50px">
					<md-field class="md-alignment-center-center">
						<label>Url</label>
						<md-input style="width: 90% !important" v-model="calendarLink" placeholder="https://yourcalendalink.here"></md-input>
					</md-field>
				</div>
				<div style="margin-top: 1rem">
					<md-button class="btn-next md-success button" @click="onEnterLink">Confirm</md-button>
					<div class="spacer"></div>
					<md-button class="btn-next md-danger button" @click="inviteModalHide">Cancel</md-button>
				</div>
			</md-card>
			<md-card v-if="step === constants.CONFIRM" class="modalCard">
				<div>Are you sure you want to interview</div>
				<div>{{ names }}</div>
				<div>
					<md-button class="button md-success" @click="onConfirm">Yes</md-button>
					<div class="spacer"></div>
					<md-button class="button md-danger" @click="inviteModalHide">Cancel</md-button>
				</div>
			</md-card>
			<div v-if="step === constants.ASK_IF_JOBOX">
				<modal>
					<template slot="body">
						<p>
							If you would like Jobox to schedule interviews for you, please contact Chelsea at
							<a href="mailto:chelsea@joinjobox.com">chelsea@joinjobox.com</a>
						</p>
					</template>

					<template slot="footer">
						<md-button class="md-button md-success" @click="inviteModalHide"> Okay</md-button>
					</template>
				</modal>
			</div>
			<md-card v-if="interviewSentSuccess">
				<h3>Success!🎉</h3>
				<p>Interview Invites have been successfully sent</p>
			</md-card>
			<md-card v-if="step === constants.SPINNER" class="modalCard">
				<div>
					<p>Processing</p>
					<div class="loader">
						<div class="lds-circle">
							<div style="position: relative">
								<img src="@/assets/img/logo.png" />
								<div class="loading"></div>
							</div>
						</div>
					</div>
				</div>
			</md-card>
		</template>

		<template slot="footer">
			<!-- action button here -->
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';
import firebase from 'firebase/compat/app';
import 'firebase/functions';
import db from '@/firebase/init';
import { helperArticles } from '@/constants/helper-articles.const.js';
import MixpanelFunctions from '@/mixpanel/mixpanel';
import { posthog } from '@/posthog/postHog.js';
const constants = {
	SPINNER: 'spinner',
	ASK_IF_LINK: 'askIfLink',
	ASK_FOR_LINK: 'askForLink',
	CONFIRM: 'confirm',
	ASK_IF_JOBOX: 'askIfJobox',
	INVITE_SUCCESS: 'inviteSuccess',
};

async function Sleep() {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve();
		}, 2000);
	});
}
async function SendInterviewInvites(selected, employerName, employerEmail, jobName, schedulingLink) {
	const sendInvites = firebase.functions().httpsCallable('sendInterviewInvites');
	try {
		const recipients = selected.map(item => ({ name: item.applicant, email: item.applicantEmail }));
		const params = {
			recipients,
			employerName,
			employerEmail,
			jobName,
			schedulingLink,
		};
		const response = await sendInvites(params);

		return response;
	} catch (e) {
		console.log({ e });
	}
}

async function SendInterviewInvitesRequestToSlack(selected, employerName, employerEmail, jobName, requestedHelp = true) {
	const sendRequest = firebase.functions().httpsCallable(requestedHelp ? 'askJoboxToSendInterviewInvites' : 'notifyJoboxUserWantsNoHelpToSendInterviewInvites');
	try {
		const students = selected.map(item => ({ name: item.applicant, email: item.applicantEmail }));
		const params = {
			students,
			employerName,
			employerEmail,
			jobName,
		};
		const response = await sendRequest(params);
		return response;
	} catch (e) {
		console.log({ e });
	}
}

const initialData = () => ({
	inviteModalVisible: false,
	hasLink: null,
	enteredLink: false,
	confirmed: null,
	calendarLink: null,
	joboxTeam: null,
	constants,
	interviewSentSuccess: false,
	joboxTeamSentSuccess: null,
	loading: false,
});

export default {
	components: { Modal },
	props: {
		selected: {
			type: Array,
			default: () => [],
		},
		employerName: { type: String },
		employerEmail: { type: String },
		jobName: { type: String },
		jobId: { String },
		jobType: { String },
		companySize: { type: String },
		industry: { type: String },
		employerCompany: { type: String },
		getShowModalAction: {
			type: Function,
			default: () => {},
		},
		visible: { type: Boolean },
	},
	data: () => initialData(),
	computed: {
		step() {
			if (this.loading) return constants.SPINNER;
			if (!this.selected.length) return constants.ASK_IF_LINK;
			if (this.joboxTeam === true) return constants.INVITE_SUCCESS;
			if (this.hasLink === false && this.joboxTeam === null) return constants.ASK_IF_JOBOX;
			if (this.hasLink === true && this.enteredLink === true && this.confirmed === true) return constants.INVITE_SUCCESS;
			if (this.hasLink === true && this.enteredLink === true) return constants.CONFIRM;
			if (this.hasLink === true) return constants.ASK_FOR_LINK;
			return constants.ASK_IF_LINK;
		},
		names() {
			return this.selected.map(item => item.applicant).join(', ');
		},
	},

	methods: {
		openInterviewHelp() {
			window.open(helperArticles.INTERVIEW_HELP, '_blank');
		},
		resetData() {
			const data = initialData();
			Object.keys(data).forEach(k => (this[k] = data[k]));
		},
		inviteModalHide() {
			localStorage.removeItem('appointmentSlots');
			this.inviteModalVisible = false;
			this.resetData();
			this.$emit('refresh');
		},
		inviteModalShow() {
			this.inviteModalVisible = true;
		},
		onAskIfLinkYes() {
			this.hasLink = true;
		},
		onEnterLink() {
			if (this.calendarLink && this.calendarLink.length) {
				this.enteredLink = true;
			}
		},
		async onJoboxTeam() {
			this.joboxTeam = true;
		},
		onNoHelp() {
			var interviewDetails = {
				companyIndustry: this.industry,
				companyName: this.employerCompany,
				companySize: this.companySize,
			};
			MixpanelFunctions.noInterviewScheduled(interviewDetails);
			SendInterviewInvitesRequestToSlack(this.selected, this.employerName, this.employerEmail, this.jobName, false);
			this.updateApplicationStatus();
			this.inviteModalHide();
		},
		async onNoLink() {
			this.hasLink = false;
		},
		async onConfirm() {
			this.confirmed = true;
			this.loading = true;
			await SendInterviewInvites(this.selected, this.employerName, this.employerEmail, this.jobName, this.calendarLink);
			await this.updateApplicationStatus();
			this.selected.forEach(element => {
				var interviewDetails = {
					university: element.firstDegree.campus,
					faculty: element.firstDegree.faculty,
					yearOfStudy: element.firstDegree.year,
					gender: element.profile.gender,
					ethnicity: element.profile.race,
					emailAddress: element.profile.email,
					citizen: element.profile.citizenship,
					jobId: this.jobId,
					jobType: this.jobType,
					jobName: this.jobName,
					companyIndustry: this.industry,
					companyName: this.employerCompany,
					companySize: this.companySize,
				};
				MixpanelFunctions.inviteForInterviewLink(interviewDetails);
			});
			this.loading = false;
			this.interviewSentSuccess = true;
		},
		async updateApplicationStatus() {
			this.selected.forEach(async item => {
				const applicant = await db.collection('applications').doc(item.id);
				applicant.update({
					applicationStatus: 'Interview Request Sent',
					interviewRequestSent: true,
				});
				posthog.capture('interview_candidate', {
					employerName: this.employerCompany,
					jobName: this.jobName,
					jobId: this.jobId,
				});
			});
		},
	},
	created() {
		if (this.selected.length) {
			this.inviteModalVisible = this.visible;
		}
	},
};
</script>
<style scoped>
.button {
	display: inline;
	max-width: 40%;
}

.modal-header .md-button .md-button-content i {
	font-size: 30px !important;
}

.modal >>> .modal-container {
	max-width: 800px !important;
}
.spacer {
	margin: 30px;
	display: inline;
	height: 1em;
}
.modalCard {
	padding: 1rem;
}

.loader {
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
}
</style>
