var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
    _vm.loading
      ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
      : _vm._e(),
    _vm.activeJobs
      ? _c(
          "div",
          { staticClass: "md-layout" },
          [
            _c("client-active-jobs-data-table", {
              staticClass: "desktop-table",
              attrs: { data: _vm.jobs },
            }),
            _c("client-active-jobs-mobile-data-table", {
              staticClass: "mobile-table",
              attrs: { data: _vm.jobs },
            }),
          ],
          1
        )
      : _vm.activeJobs === false
      ? _c("div", [
          _c(
            "h1",
            { staticClass: "black", staticStyle: { "text-align": "center" } },
            [_vm._v("You have no active jobs")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }