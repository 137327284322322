var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
        [
          _c(
            "simple-wizard",
            {
              attrs: {
                image: _vm.image,
                firstName: _vm.firstName,
                lastName: _vm.lastName,
                dob: _vm.dob,
                gender: _vm.gender,
                race: _vm.race,
                location: _vm.location,
                relocation: _vm.relocation,
                citizenship: _vm.citizenship,
                id: _vm.id,
                passport: _vm.passport,
                identification: _vm.identification,
                disability: _vm.disability,
                disabilityDescription: _vm.disabilityDescription,
                license: _vm.license,
                vehicle: _vm.vehicle,
                bio: _vm.bio,
                programme: _vm.programme,
                studying: _vm.studying,
                institution: _vm.institution,
                campus: _vm.campus,
                studentNo: _vm.studentNo,
                faculty: _vm.faculty,
                degree: _vm.degree,
                major: _vm.major,
                year: _vm.year,
                graduateStatus: _vm.graduateStatus,
                accountName: _vm.accountName,
                accountNumber: _vm.accountNumber,
                accountType: _vm.accountType,
                bankName: _vm.bankName,
                branchCode: _vm.branchCode,
                email: _vm.email,
                linkedIn: _vm.linkedIn,
                facebook: _vm.facebook,
                twitter: _vm.twitter,
                instagram: _vm.instagram,
                github: _vm.github,
                certificate1: _vm.certificate1,
                certificate2: _vm.certificate2,
                certificate3: _vm.certificate3,
                portfolio: _vm.portfolio,
                personalWebsite: _vm.personalWebsite,
                cv: _vm.cv,
                industryCategory: _vm.industryCategory,
                qualificationsValid: _vm.qualificationsValid,
                choices: _vm.choices,
                department: _vm.department,
                extraCirricularActivities: _vm.extraCirricularActivities,
              },
              on: {
                emailVerified: _vm.addEmailVerified,
                feedback: _vm.addFeedback,
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "btn-next md-success",
                      on: { click: _vm.back },
                    },
                    [_vm._v("Save Changes")]
                  ),
                  _c("p"),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Edit Your Profile"),
                  ]),
                  _c("h5", { staticClass: "category" }, [
                    _vm._v("This information will let us know more about you."),
                  ]),
                ],
                1
              ),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": function () {
                      return _vm.validateStep("step1")
                    },
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [_vm._v("About")]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-user" }),
                    ]),
                  ]),
                  _c("first-step", {
                    ref: "step1",
                    on: {
                      "on-validated": _vm.onStepValidated,
                      image: _vm.addImage,
                      firstName: _vm.addFirstName,
                      lastName: _vm.addLastName,
                      dob: _vm.addDob,
                      gender: _vm.addGender,
                      race: _vm.addRace,
                      location: _vm.addLocation,
                      relocation: _vm.addRelocation,
                      license: _vm.addLicense,
                      vehicle: _vm.addVehicle,
                      id: _vm.addId,
                      bio: _vm.addBio,
                      citizenship: _vm.addCitizenship,
                      passport: _vm.addPassport,
                      programme: _vm.addProgramme,
                      identification: _vm.addIdentification,
                    },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": function () {
                      return _vm.validateStep("step2")
                    },
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [_vm._v("Studies")]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-university" }),
                    ]),
                  ]),
                  _c("second-step", {
                    ref: "step2",
                    on: {
                      "on-validated": _vm.onStepValidated,
                      institution: _vm.addInstitution,
                      studentNo: _vm.addStudentNo,
                      qualificationsValid: _vm.addQualificationsValid,
                      faculty: _vm.addFaculty,
                      degree: _vm.addDegree,
                      year: _vm.addYear,
                      graduateStatus: _vm.addGraduateStatus,
                      campus: _vm.addCampus,
                    },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [
                      _vm._v("Experience"),
                    ]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-briefcase" }),
                    ]),
                  ]),
                  _c("third-step", { ref: "step3" }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": function () {
                      return _vm.validateStep("step4")
                    },
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [
                      _vm._v("Prefered Employer"),
                    ]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-globe-africa" }),
                    ]),
                  ]),
                  _c("fourth-step", {
                    ref: "step4",
                    on: {
                      "on-validated": _vm.onStepValidated,
                      choices: _vm.addChoices,
                      department: _vm.addDepartment,
                    },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": function () {
                      return _vm.validateStep("step5")
                    },
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [
                      _vm._v("Portfolio"),
                    ]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-piggy-bank" }),
                    ]),
                  ]),
                  _c("fifth-step", {
                    ref: "step5",
                    on: {
                      "on-validated": _vm.onStepValidated,
                      linkedIn: _vm.addLinkedIn,
                      facebook: _vm.addFacebook,
                      twitter: _vm.addTwitter,
                      instagram: _vm.addInstagram,
                      github: _vm.addGithub,
                      certificate1: _vm.addCertificate1,
                      certificate2: _vm.addCertificate2,
                      certificate3: _vm.addCertificate3,
                      portfolio: _vm.addPortfolio,
                      personalWebsite: _vm.addPersonalWebsite,
                      id: _vm.addId,
                      cv: _vm.addCv,
                      industryCategory: _vm.addIndustryCategory,
                      extraCirricularActivities: _vm.addExtraCirricularActivity,
                    },
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.feedbackHeader)),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(_vm._s(_vm.feedback)),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }