var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h6", { staticClass: "centre" }, [_vm._v("Client Information")]),
    _c("br"),
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item md-small-size-100 profile" }, [
        _c("div", { staticClass: "md-card-avatar clientImg" }, [
          _vm.picture
            ? _c("img", { staticClass: "img", attrs: { src: _vm.picture } })
            : _c("img", {
                staticClass: "img",
                attrs: { src: _vm.cardUserImage },
              }),
        ]),
        _c("br"),
        _c(
          "h4",
          [
            _c(
              "router-link",
              {
                staticClass: "card-title",
                attrs: {
                  to: {
                    name: "view-client-profile",
                    params: { id: _vm.clientAlias },
                  },
                },
              },
              [_c("a", [_vm._v(_vm._s(_vm.companyName))])]
            ),
          ],
          1
        ),
        _c("p", { staticClass: "card-description" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
        _vm._m(0),
        this.applicationStatus === "active"
          ? _c(
              "div",
              [
                _c("script", {
                  tag: "div",
                  attrs: {
                    src: "https://platform.linkedin.com/in.js",
                    type: "text/javascript",
                  },
                }),
                _c("script", {
                  tag: "div",
                  attrs: {
                    type: "IN/Share",
                    "data-url": "https://www.joinjobox.com",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v(" Email us at: "),
      _c("b", [
        _c(
          "a",
          {
            staticStyle: { color: "blue" },
            attrs: { href: "mailto:contact@joinjobox.com" },
          },
          [_vm._v("contact@joinjobox.com")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }