import Badge from './Badge';

// Inputs
export * from './Inputs';
import DropDown from './Dropdown.vue';

// Modals
import Modal from './Modal.vue';
export * from './Modals';

// Tables
import GlobalSalesTable from './Tables/GlobalSalesTable.vue';

import PricingCard from './Cards/PricingCard.vue';
import SignupCard from './Cards/SignupCard.vue';
import LockCard from './Cards/LockCard.vue';
import LoginCard from './Cards/LoginCard.vue';
import StatsCard from './Cards/StatsCard.vue';
import ChartCard from './Cards/ChartCard.vue';
import TestimonialCard from './Cards/TestimonialCard.vue';
import GlobalSalesCard from './Cards/GlobalSalesCard.vue';
import NavTabsCard from './Cards/NavTabsCard.vue';
import ProductCard from './Cards/ProductCard.vue';
import RotatingCard from './Cards/RotatingCard.vue';

import TimeLine from './Timeline/TimeLine.vue';
import TimeLineItem from './Timeline/TimeLineItem.vue';
import Tabs from './Tabs.vue';
import Collapse from './Collapse.vue';
import Slider from './Slider.vue';
import SimpleWizard from './Wizard/Wizard.vue';
import WizardTab from './Wizard/WizardTab.vue';
import AsyncWorldMap from './WorldMap/AsyncWorldMap.vue';

import Pagination from './Pagination.vue';

import SidebarPlugin from './SidebarPlugin';

import AnimatedNumber from './AnimatedNumber';

export {
	Badge,
	Modal,
	Pagination,
	GlobalSalesTable,
	LockCard,
	PricingCard,
	SignupCard,
	TestimonialCard,
	StatsCard,
	LoginCard,
	ChartCard,
	GlobalSalesCard,
	NavTabsCard,
	ProductCard,
	RotatingCard,
	DropDown,
	SidebarPlugin,
	TimeLine,
	TimeLineItem,
	Tabs,
	Slider,
	SimpleWizard,
	WizardTab,
	AnimatedNumber,
	Collapse,
	AsyncWorldMap,
};
