var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c(
      "div",
      { staticClass: "copy-content" },
      [
        _c("h1", { staticClass: "copy-content-heading" }, [
          _vm._v("You've Already Completed This Test! ✅"),
        ]),
        _c("h3", { staticClass: "copy-content-text" }, [
          _vm._v(
            "It looks like you've already taken the " +
              _vm._s(_vm.skillName) +
              " vetting test for the " +
              _vm._s(_vm.jobName) +
              " role."
          ),
        ]),
        _c("br"),
        _c(
          "md-button",
          {
            staticClass: "md-success copy-content-text",
            on: { click: _vm.navigateToVettingTests },
          },
          [
            _c("md-icon", [_vm._v("west")]),
            _vm._v(" Go Back To Remaining Tests"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }