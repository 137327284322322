var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carousel-container" }, [
    _c(
      "div",
      { staticClass: "close-btn" },
      [
        _c(
          "md-button",
          { staticClass: "md-danger", on: { click: _vm.closeViewer } },
          [_vm._v("Close")]
        ),
      ],
      1
    ),
    _vm.documentKeys.length > 0
      ? _c("div", { staticClass: "document-name" }, [
          _c("h2", [
            _vm._v(_vm._s(_vm.formatKey(_vm.documentKeys[_vm.currentIndex]))),
          ]),
        ])
      : _vm._e(),
    _vm.documentKeys.length > 0
      ? _c("div", { staticClass: "pdf-display" }, [
          _c("embed", {
            staticClass: "pdf-embed",
            attrs: {
              src: _vm.documents[_vm.documentKeys[_vm.currentIndex]].url,
            },
          }),
        ])
      : _vm._e(),
    _vm.documents.writtenSubmissionLink
      ? _c("div", [
          _c("embed", {
            staticClass: "pdf-embed",
            attrs: { src: _vm.documents.writtenSubmissionLink },
          }),
        ])
      : _c("p", [_vm._v("No documents available for this applicant.")]),
    _vm.documentKeys.length > 0
      ? _c(
          "div",
          { staticClass: "arrow left", on: { click: _vm.previousPdf } },
          [
            _c("md-icon", { staticStyle: { color: "white !important" } }, [
              _vm._v("arrow_back"),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.documentKeys.length > 0
      ? _c(
          "div",
          { staticClass: "arrow right", on: { click: _vm.nextPdf } },
          [
            _c("md-icon", { staticStyle: { color: "white !important" } }, [
              _vm._v("arrow_forward"),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.documentKeys.length > 0
      ? _c(
          "div",
          { staticClass: "indicators" },
          _vm._l(_vm.documentKeys, function (doc, index) {
            return _c(
              "span",
              {
                key: index,
                class: { active: _vm.currentIndex === index },
                on: {
                  click: function ($event) {
                    return _vm.goToPdf(index)
                  },
                },
              },
              [_vm._v(" ● ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }