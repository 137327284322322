import { render, staticRenderFns } from "./SendApplicantOffer.vue?vue&type=template&id=c16e7704&scoped=true&"
import script from "./SendApplicantOffer.vue?vue&type=script&lang=js&"
export * from "./SendApplicantOffer.vue?vue&type=script&lang=js&"
import style0 from "./SendApplicantOffer.vue?vue&type=style&index=0&id=c16e7704&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c16e7704",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/jobox-platform/jobox-platform/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c16e7704')) {
      api.createRecord('c16e7704', component.options)
    } else {
      api.reload('c16e7704', component.options)
    }
    module.hot.accept("./SendApplicantOffer.vue?vue&type=template&id=c16e7704&scoped=true&", function () {
      api.rerender('c16e7704', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/client/jobs/applications/applicants/SendApplicantOffer.vue"
export default component.exports