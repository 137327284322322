var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", {
            staticClass: "background",
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
            },
          })
        : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("hr"),
      _vm.available
        ? _c(
            "div",
            { staticClass: "md-layout" },
            [
              _c(
                "md-card",
                { staticClass: "padding", attrs: { id: "container" } },
                [
                  _c(
                    "md-table",
                    {
                      attrs: { "md-card": "", id: "content" },
                      on: { "md-selected": _vm.onSelect },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "md-table-row",
                            fn: function (ref) {
                              var item = ref.item
                              return _c(
                                "md-table-row",
                                { attrs: { "md-selectable": "multiple" } },
                                [
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Name",
                                        "md-sort-by": "profile.name",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.profile.name))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Surname",
                                        "md-sort-by": "profile.surname",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.profile.surname))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Year of Study" } },
                                    [_vm._v(_vm._s(item.firstDegree.year))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Degree" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.firstDegree.degree)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Status" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: _vm.getChipClass(
                                            item.applicationStatus
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.applicationStatus) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          staticClass: "md-success",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onViewProfile(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" View Profile ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isVideoSubmissionQuesition
                                    ? _c("md-table-cell", [
                                        _c(
                                          "div",
                                          { staticClass: "btn-submissions" },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass: "btn-next md-info",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewVideoSubmission(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("View Video Submission")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.isWrittenSubmissionQuesition
                                    ? _c("md-table-cell", [
                                        _c(
                                          "div",
                                          { staticClass: "btn-submissions" },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "btn-next md-info button",
                                                staticStyle: {
                                                  "margin-left": "1rem",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewWrittenSubmission(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "View Written Submission"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("md-table-cell", [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.setFavourite(item)
                                          },
                                        },
                                      },
                                      [
                                        item.favourite
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "md-icon",
                                                  {
                                                    staticStyle: {
                                                      color: "#e2c800",
                                                    },
                                                  },
                                                  [_vm._v("star")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c("md-icon", [
                                                  _vm._v("star_border"),
                                                ]),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1329620582
                      ),
                      model: {
                        value: _vm.queriedData,
                        callback: function ($$v) {
                          _vm.queriedData = $$v
                        },
                        expression: "queriedData",
                      },
                    },
                    [
                      _c("md-table-toolbar", [
                        _c("div", { staticClass: "button-section" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "md-field",
                                [
                                  _c("md-input", {
                                    staticClass: "mb-3",
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      type: "search",
                                      clearable: "",
                                      placeholder: "Search",
                                    },
                                    model: {
                                      value: _vm.searchData,
                                      callback: function ($$v) {
                                        _vm.searchData = $$v
                                      },
                                      expression: "searchData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "btn-submissions btn-center" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-info button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.filterDetails()
                                    },
                                  },
                                },
                                [_vm._v("Filter")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "btn-submissions btn-center" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-success",
                                  on: { click: _vm.onInvite },
                                },
                                [_vm._v("Schedule an interview")]
                              ),
                            ],
                            1
                          ),
                          _vm.appointments.length
                            ? _c(
                                "div",
                                { staticClass: "btn-submissions btn-center" },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass: "btn-next md-info button",
                                      staticStyle: { "max-width": "155px" },
                                      on: { click: _vm.showAppointmentsModal },
                                    },
                                    [_vm._v(" Edit Interview Slots ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.profileModalVisible
        ? _c(
            "modal",
            { on: { close: _vm.profileModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.profileModalHide },
                    },
                    [
                      _c(
                        "md-icon",
                        {
                          staticStyle: {
                            "font-size": "2rem !important",
                            color: "black !important",
                          },
                        },
                        [_vm._v("clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("CandidateProfile", {
                    attrs: {
                      userProfile: this.getUserProfile,
                      showFavoriteButton: true,
                      jobId: this.$route.params.id,
                    },
                    on: {
                      setProfileFavourite: _vm.setProfileFavourite,
                      profileViewWrittenSubmission:
                        _vm.profileViewWrittenSubmission,
                      profileViewVideoSubmission:
                        _vm.profileViewVideoSubmission,
                    },
                  }),
                ],
                1
              ),
              _c("template", { slot: "footer" }),
            ],
            2
          )
        : _vm._e(),
      _vm.noSubmission
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _vm._v(" " + _vm._s(this.submissionErrorMessage)),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-danger",
                      on: {
                        click: function ($event) {
                          return _vm.closeWrittenSubmissionModal()
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      this.isWrittenSubmission || this.isVideoSubmission
        ? _c("view-submission", {
            attrs: {
              writtenSubmission: this.isWrittenSubmission,
              isVideoSubmission: this.isVideoSubmission,
              currentProfile: this.currentProfile,
              name: _vm.currentProfile.profile.name,
              surname: _vm.currentProfile.profile.surname,
            },
            on: {
              closeViewSubmissionModal: _vm.closeViewSubmissionModal,
              viewProfile: _vm.viewProfile,
            },
          })
        : _vm._e(),
      _vm.interviewModal
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Woah there ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.interviewModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("h4", [
                  _vm._v("Please select an applicant using the checkboxes."),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      this.visible
        ? _c("invite-modal", {
            attrs: {
              selected: _vm.selected,
              employerEmail: _vm.employerEmail,
              employerName: _vm.employerName,
              employerCompany: _vm.employerCompany,
              jobName: _vm.jobName,
              jobId: _vm.jobId,
              jobType: _vm.jobType,
              industry: _vm.industry,
              companySize: _vm.companySize,
              visible: _vm.visible,
            },
            on: { refresh: this.refresh },
          })
        : _vm._e(),
      !_vm.available && !_vm.approved
        ? _c("div", [
            _c("br"),
            _c("br"),
            _c("h2", { staticClass: "black centre" }, [
              _vm._v(_vm._s(_vm.feedback)),
            ]),
          ])
        : _vm._e(),
      _vm.showFilter
        ? _c(
            "div",
            [
              _c("application-filter-selection", {
                attrs: {
                  userGender: this.gender,
                  userFavourite:
                    this.favourite === true
                      ? "yes"
                      : this.favourite === false
                      ? "no"
                      : null,
                  userQualification: this.qualification,
                  userAgeRange: this.ageRange,
                  userEthnicity: this.ethnicity,
                  universities: this.institutions,
                  applicant: _vm.applicants,
                  selectedTown: _vm.selectedLocation,
                  selectedStudentRelocation: _vm.selectedStudentRelocation,
                },
                on: {
                  closeModal: function (x) {
                    return (_vm.showFilter = x)
                  },
                  setFilterFavourite: _vm.setFilterFavourite,
                  setQualification: _vm.setQualification,
                  setGender: _vm.setGender,
                  setUniversities: _vm.setUniversities,
                  setExtracirricularActivities:
                    _vm.setExtracirricularActivities,
                  setApplicationStatus: _vm.setApplicationStatus,
                  setEthnicity: _vm.setEthnicity,
                  setAge: _vm.setAge,
                  setLocation: _vm.setLocation,
                  setStudentRelocation: _vm.setStudentRelocation,
                  resetFilters: _vm.resetFilters,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.viewAppointments
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("🗓 Your Appointments"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.hideAppointmentsModal },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "text-align": "left" } },
                    _vm._l(_vm.appointments, function (appointment, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c("p", [_vm._v(_vm._s(appointment.name))]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAppointment(appointment)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "md-icon",
                                    {
                                      staticStyle: {
                                        color: "orange",
                                        cursor: "pointer",
                                      },
                                    },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAppointment(appointment)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "md-icon",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "margin-left": "1rem",
                                        cursor: "pointer",
                                      },
                                    },
                                    [_vm._v("delete")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: { click: _vm.hideAppointmentsModal },
                    },
                    [_vm._v("Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.isEditAppointmentClicked
        ? _c("edit-appointment", {
            attrs: { appointment: _vm.selectedAppointment },
            on: { hideAppointmentsModal: _vm.hideEditAppointmentsModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }