var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "black" }, [
          _c("h2", [_vm._v("You are almost there")]),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "black" }, [
          _c("p", [
            _vm._v(
              " Thank you for signing up. You should see a verification link in your email (it might be in your spam folder). Verify your email to complete your registration. "
            ),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            [
              _c(
                "md-button",
                {
                  staticClass: "md-button jb-standard",
                  on: { click: _vm.resendVerification },
                },
                [_vm._v(" Resend Verification ")]
              ),
            ],
            1
          ),
          _vm.modal
            ? _c(
                "modal",
                { on: { close: _vm.modalHide } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v(_vm._s(_vm.feedbackHeader)),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.modalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("p", { staticClass: "black" }, [
                      _vm._v(_vm._s(_vm.feedback)),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.modalHide },
                          },
                          [_vm._v("Got it")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }