var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.sendOffer.apply(null, arguments)
        },
      },
    },
    [
      _vm.visible
        ? _c(
            "modal",
            { on: { close: _vm.hideModal } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Offer Details"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.hideModal },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "div",
                    { staticClass: "start_date_container" },
                    [
                      _c("date-picker", {
                        attrs: {
                          name: "startDate",
                          modelValidation: {},
                          error: _vm.getErrorMessage("startDate"),
                        },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                      _vm.getErrorMessage("startDate")
                        ? _c("p", [
                            _vm._v(
                              "Start Date " +
                                _vm._s(_vm.getErrorMessage("startDate"))
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("text-field", {
                    attrs: {
                      name: "salary",
                      type: "number",
                      label: "Salary",
                      iconName: "payments",
                      error: _vm.getErrorMessage("salary"),
                    },
                    model: {
                      value: _vm.salary,
                      callback: function ($$v) {
                        _vm.salary = $$v
                      },
                      expression: "salary",
                    },
                  }),
                  _c("text-field", {
                    attrs: {
                      name: "duration",
                      type: "number",
                      label: "Contract Duration (months)",
                      iconName: "schedule",
                      error: _vm.getErrorMessage("duration"),
                    },
                    model: {
                      value: _vm.duration,
                      callback: function ($$v) {
                        _vm.duration = $$v
                      },
                      expression: "duration",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    { staticClass: "md-primary", attrs: { type: "submit" } },
                    [_vm._v("Send")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c("confirm-modal", {
        attrs: {
          title: "Confirmation",
          message:
            "Are you sure you want to send an offer to " +
            (_vm.profile ? _vm.profile.applicant : "") +
            "?",
          visible: _vm.showConfirmModal,
        },
        on: {
          onYesClick: _vm.onOfferConfirm,
          onNoClick: function ($event) {
            _vm.showConfirmModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }