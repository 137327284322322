var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("img", {
        staticStyle: { cursor: "pointer", "margin-right": "4rem" },
        attrs: {
          src: require("@/assets/img/logo.png"),
          height: "125px",
          width: "125px",
        },
      }),
      _c("h1", [_vm._v("Great! 👍")]),
      _c("h3", [
        _vm._v(
          "Your account is now connected. Please log back in to continue scheduling interviews"
        ),
      ]),
      _c(
        "md-button",
        {
          staticClass: "md-success",
          staticStyle: { "margin-top": "4rem", "margin-left": "-4rem" },
          on: { click: _vm.login },
        },
        [_vm._v("Login")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }