var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "application-container" },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "md-card",
        { staticClass: "padding" },
        [
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              staticStyle: {
                position: "absolute",
                top: "3px",
                left: "10px",
                "font-size": "30px !important",
              },
              on: { click: _vm.openJobsHelp },
            },
            [_c("md-icon", [_vm._v("help")])],
            1
          ),
          _c("h1", { staticClass: "info-text centre" }, [
            _vm._v(_vm._s(_vm.job.name)),
          ]),
          _c(
            "div",
            { staticClass: "w-full flex items-center justify-center" },
            [
              _c("div", { staticClass: "job-summary-container" }, [
                _vm._v("🏢 " + _vm._s(_vm.job.companyName)),
              ]),
              _c("div", { staticClass: "job-summary-container" }, [
                _vm._v("📍 " + _vm._s(_vm.job.location)),
              ]),
              _c("div", { staticClass: "job-summary-container" }, [
                _vm._v("🕜 " + _vm._s(_vm.job.type)),
              ]),
              _c("div", { staticClass: "job-summary-container" }, [
                _vm._v("💵 " + _vm._s(_vm.formatBudget(_vm.job))),
              ]),
              _c("div", { staticClass: "job-summary-container" }, [
                _vm._v(
                  "📅 " + _vm._s(_vm.job.date || _vm.job.applicationDeadline)
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "md-layout" },
            [
              _c(
                "div",
                { staticClass: "applicationButton" },
                [
                  (_vm.application &&
                    _vm.application.applicationStatus &&
                    _vm.application.applicationStatus ===
                      _vm.ApplicationStatus.WITHDRAWN) ||
                  !_vm.application ||
                  (_vm.application &&
                    _vm.application.applicationStatus &&
                    _vm.application.applicationStatus ===
                      _vm.ApplicationStatus.IN_PROGRESS)
                    ? _c(
                        "md-button",
                        {
                          staticClass: "jb-standard",
                          on: { click: _vm.apply },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.auth ? "Apply for job" : "Login to Apply"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.application &&
                  _vm.application.applicationStatus &&
                  _vm.application.applicationStatus ===
                    _vm.ApplicationStatus.APPLIED
                    ? _c(
                        "md-button",
                        {
                          staticClass: "md-danger",
                          on: {
                            click: function ($event) {
                              _vm.withdrawModal = true
                            },
                          },
                        },
                        [_vm._v(" Withdraw Application ")]
                      )
                    : _vm._e(),
                  _vm.auth &&
                  _vm.job &&
                  _vm.job.additionalSubmissions &&
                  _vm.job.additionalSubmissions.writtenSubmission === "Yes" &&
                  _vm.isVetted
                    ? _c(
                        "md-button",
                        {
                          staticStyle: {
                            "background-color": "#5c99ee !important",
                            margin: "0 2rem !important",
                          },
                          on: { click: _vm.openWrittenSubmissionsModal },
                        },
                        [_vm._v(" Upload Written Submission ")]
                      )
                    : _vm._e(),
                  _vm.auth &&
                  _vm.job &&
                  _vm.job.additionalSubmissions &&
                  _vm.job.additionalSubmissions.videoSubmission === "Yes" &&
                  _vm.isVetted
                    ? _c(
                        "md-button",
                        {
                          staticStyle: {
                            "background-color": "#4e77ed !important",
                          },
                          on: { click: _vm.openVideoSubmissionsModal },
                        },
                        [_vm._v(" Upload Video Submission ")]
                      )
                    : _vm._e(),
                  _vm._v("   "),
                  this.previouslyApplied
                    ? _c("div", [
                        _c("p", { staticClass: "error" }, [
                          _vm._v(
                            "You previously applied for this job and withdrew your application"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.application &&
                  _vm.application.applicationStatus &&
                  _vm.application.applicationStatus ===
                    _vm.ApplicationStatus.DISQUALIFIED
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass: "error",
                            staticStyle: { "font-size": "20px" },
                          },
                          [
                            _vm._v(
                              "You are do not qualify to apply for this job"
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("p", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.feedback)),
              ]),
              _c(
                "md-card",
                [
                  _c(
                    "md-card-content",
                    [
                      _c(
                        "collapse",
                        {
                          attrs: {
                            collapse: _vm.collapseItems,
                            icon: "keyboard_arrow_down",
                            "color-collapse": "success",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "md-collapse-pane-1" },
                            [
                              _c(
                                "md-card",
                                { staticClass: "bg-success" },
                                [
                                  _c("md-card-content", [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "card-category card-category-social",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-list-ul",
                                        }),
                                        _vm._v(" Job Description"),
                                      ]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Job Name"),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [_vm._v(_vm._s(_vm.job.name))]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Company Name"),
                                    ]),
                                    _c(
                                      "h4",
                                      {
                                        staticStyle: {
                                          "margin-top": "-1px",
                                          "font-size": "15px",
                                        },
                                      },
                                      [
                                        _vm.client.website
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.client.website.startsWith(
                                                    "http"
                                                  )
                                                    ? _vm.client.website
                                                    : "//" + _vm.client.website,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.job.companyName)
                                                ),
                                              ]
                                            )
                                          : _vm.auth
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass: "card-title",
                                                attrs: {
                                                  to: {
                                                    name: "view-client-profile",
                                                    params: {
                                                      id: _vm.job.clientAlias,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c("a", [
                                                  _vm._v(
                                                    _vm._s(_vm.job.companyName)
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c(
                                              "p",
                                              {
                                                staticClass: "card-description",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.job.companyName)
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Description"),
                                    ]),
                                    _c("p", {
                                      staticClass: "card-description",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.job.description),
                                      },
                                    }),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Job Category"),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.jobSkills.category || "Other"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Skills Required"),
                                    ]),
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.jobSkills.skills,
                                        function (skill, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "card-description",
                                            },
                                            [_vm._v(_vm._s(skill.skillName))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { slot: "md-collapse-pane-2" },
                            [
                              _c(
                                "md-card",
                                { staticClass: "bg-success" },
                                [
                                  _c("md-card-content", [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "card-category card-category-social",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-dungeon",
                                        }),
                                        _vm._v(" Job Requirements"),
                                      ]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Minimum Level of Education"),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [_vm._v(_vm._s(_vm.job.education))]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Minimum Work Experience"),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [_vm._v(_vm._s(_vm.job.experience))]
                                    ),
                                    _vm.job && _vm.job.citizenship
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "card-title" },
                                              [_vm._v("Required Citizenship")]
                                            ),
                                            _vm._l(
                                              _vm.job.citizenship,
                                              function (citizenship, index) {
                                                return _c(
                                                  "p",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "card-description",
                                                  },
                                                  [_vm._v(_vm._s(citizenship))]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { slot: "md-collapse-pane-3" },
                            [
                              _c(
                                "md-card",
                                { staticClass: "bg-success" },
                                [
                                  _c("md-card-content", [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "card-category card-category-social centre",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-clipboard-list",
                                        }),
                                        _vm._v(" Job Information"),
                                      ]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Location"),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [_vm._v(_vm._s(_vm.job.location))]
                                    ),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v("Application Deadline"),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [_vm._v(_vm._s(_vm.job.date))]
                                    ),
                                    _vm.job.type === "Once-off Project/Task"
                                      ? _c(
                                          "h4",
                                          { staticClass: "card-title" },
                                          [_vm._v("Estimated Duration")]
                                        )
                                      : _c(
                                          "h4",
                                          { staticClass: "card-title" },
                                          [_vm._v("Working Days")]
                                        ),
                                    _vm.job.type === "Once-off Project/Task"
                                      ? _c(
                                          "p",
                                          { staticClass: "card-description" },
                                          [_vm._v(_vm._s(_vm.job.duration))]
                                        )
                                      : _c(
                                          "ul",
                                          _vm._l(
                                            _vm.job.daysOfTheWeek,
                                            function (days) {
                                              return _c(
                                                "li",
                                                {
                                                  key: days,
                                                  staticClass:
                                                    "card-description",
                                                },
                                                [_vm._v(_vm._s(days))]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                    _vm.job.type === "Internship" ||
                                    _vm.job.type === "Part-time"
                                      ? _c(
                                          "h4",
                                          { staticClass: "card-title" },
                                          [_vm._v("Working Hours Per Week")]
                                        )
                                      : _vm._e(),
                                    _vm.job.type === "Internship" ||
                                    _vm.job.type === "Part-time"
                                      ? _c(
                                          "p",
                                          { staticClass: "card-description" },
                                          [_vm._v(_vm._s(_vm.job.workingHours))]
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { slot: "md-collapse-pane-4" },
                            [
                              _c(
                                "md-card",
                                { staticClass: "bg-success" },
                                [
                                  _c("md-card-content", [
                                    _vm.job.type === "Once-off Project/Task"
                                      ? _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "card-category card-category-social centre",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-wallet",
                                            }),
                                            _vm._v(" Budget"),
                                          ]
                                        )
                                      : _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "card-category card-category-social centre",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-wallet",
                                            }),
                                            _vm._v(" Salary"),
                                          ]
                                        ),
                                    _vm.job.type === "Once-off Project/Task"
                                      ? _c(
                                          "h4",
                                          { staticClass: "card-title" },
                                          [_vm._v("Budget")]
                                        )
                                      : _c(
                                          "h4",
                                          { staticClass: "card-title" },
                                          [_vm._v("Salary")]
                                        ),
                                    _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formatBudget(_vm.job))
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          this.isSubmission
                            ? _c(
                                "template",
                                { slot: "md-collapse-pane-5" },
                                [
                                  _c(
                                    "md-card",
                                    { staticClass: "bg-success" },
                                    [
                                      _c("md-card-content", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "card-category card-category-social centre",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-file",
                                            }),
                                            _vm._v(" Additional Submission"),
                                          ]
                                        ),
                                        _c(
                                          "h4",
                                          { staticClass: "card-title" },
                                          [_vm._v("Additional Submission")]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "card-description" },
                                          [
                                            _vm._v(
                                              " The job requires additional submissions once you pass your vetting tests or have the required skills for the job. View the additional submission information on this link: "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.job
                                                    .additionalSubmissions
                                                    .submissionLink,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.job
                                                      .additionalSubmissions
                                                      .submissionLink
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.auth,
                      expression: "auth",
                    },
                  ],
                  staticClass: "jb-standard",
                  on: { click: _vm.back },
                },
                [_vm._v(" Back ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isVetted && _vm.modal
        ? _c("modal", {
            on: { close: _vm.modalHide },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v(_vm._s(_vm.header)),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          staticStyle: {
                            position: "absolute",
                            top: "3px",
                            left: "10px",
                            "font-size": "30px !important",
                          },
                          on: { click: _vm.openVettingHelp },
                        },
                        [_c("md-icon", [_vm._v("help")])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _c("p", [
                          _vm._v(
                            "You need to be assessed for the following skills:"
                          ),
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.skillsToTest, function (skill, index) {
                            return _c("li", { key: index }, [
                              _vm._v(_vm._s(skill.skillName || skill)),
                            ])
                          }),
                          0
                        ),
                        _c("p", [
                          _vm._v(
                            "Would you like to take the required tests to apply for this job?"
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "centre" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "apply" } } },
                            [
                              _vm.sentTest
                                ? _c(
                                    "md-button",
                                    { staticClass: "md-button md-success" },
                                    [_vm._v("Got it")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          !_vm.sentTest
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-button md-danger",
                                  on: { click: _vm.modalHide },
                                },
                                [_vm._v("No")]
                              )
                            : _vm._e(),
                          _vm._v("    "),
                          !_vm.sentTest
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-button md-success",
                                  on: { click: _vm.takeVettingTests },
                                },
                                [_vm._v("Yes")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1116093593
            ),
          })
        : _vm._e(),
      _vm.withdrawModal
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.withdrawModal = false
                },
              },
            },
            [
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v("Are you sure you want to withdraw your application?"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-danger",
                        on: {
                          click: function ($event) {
                            _vm.withdrawModal = false
                          },
                        },
                      },
                      [_vm._v("No")]
                    ),
                    _vm._v("    "),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: {
                          click: function ($event) {
                            return _vm.withdrawApplication(_vm.application)
                          },
                        },
                      },
                      [_vm._v("Yes")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.successModal
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.successModal = false
                },
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "modal-title black" }, [
                  _vm._v("Congratulations!"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "Your application has been sent. We will let you know the outcome of your application."
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    !_vm.sentTest
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.closeModal },
                          },
                          [_vm._v("Got it")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.writtenSubmissionModal
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c(
                  "div",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-simple md-just-icon md-round modal-default-button",
                        staticStyle: {
                          position: "absolute",
                          top: "3px",
                          left: "10px",
                          "font-size": "30px !important",
                        },
                        on: { click: _vm.openWrittenSubmissionHelp },
                      },
                      [_c("md-icon", [_vm._v("help")])],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-simple md-just-icon md-round modal-default-button",
                            staticStyle: {
                              position: "absolute",
                              top: "3px",
                              right: "10px",
                              "font-size": "30px !important",
                            },
                            on: { click: _vm.closeWrittenSubmissionsModal },
                          },
                          [_c("md-icon", [_vm._v("clear")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("div", { staticClass: "submission-modal" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "modal-title black",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v("Written Submission")]
                    ),
                    _c("br"),
                    _c("p", [
                      _vm._v(" Please submit the written piece "),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("as a PDF"),
                      ]),
                      _vm.job.additionalSubmissions.submissionLink !== ""
                        ? _c("span", [_vm._v(" about the following:")])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "submission-text" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.job.additionalSubmissions.submissionLink,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.job.additionalSubmissions.submissionLink)
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "md-field",
                    [
                      _c("md-file", {
                        staticClass: "custom-placeholder",
                        attrs: {
                          accept: "application/pdf",
                          placeholder: "Upload PDF",
                        },
                        on: { change: _vm.previewPdf },
                        model: {
                          value: _vm.writtenSubmission,
                          callback: function ($$v) {
                            _vm.writtenSubmission = $$v
                          },
                          expression: "writtenSubmission",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.feedback
                    ? _c("p", { staticStyle: { color: "green !important" } }, [
                        _vm._v(_vm._s(_vm.feedback)),
                      ])
                    : _vm._e(),
                  _vm.message
                    ? _c("p", { staticStyle: { color: "red !important" } }, [
                        _vm._v(_vm._s(_vm.message)),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                    },
                    [
                      _vm.feedback
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-button md-success",
                              on: {
                                click:
                                  _vm.confirmFeedbackOnWrittenSubmissionModal,
                              },
                            },
                            [_vm._v("Submit")]
                          )
                        : _vm._e(),
                      _vm._v("    "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.videoSubmissionModal
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      staticStyle: {
                        position: "absolute",
                        top: "3px",
                        left: "10px",
                        "font-size": "30px !important",
                      },
                      on: { click: _vm.openVideoSubmissionHelp },
                    },
                    [_c("md-icon", [_vm._v("help")])],
                    1
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.cancelVideoOptionSubmission },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("div", { staticClass: "submission-modal" }, [
                  _c(
                    "h4",
                    {
                      staticClass: "modal-title black",
                      staticStyle: { "font-weight": "bold" },
                    },
                    [_vm._v("Video Submission")]
                  ),
                  _c("br"),
                  _c("p", [
                    _vm._v("Please submit a video "),
                    _vm.job.additionalSubmissions.submissionLink !== ""
                      ? _c("span", [_vm._v(" on the following:")])
                      : _vm._e(),
                  ]),
                  _c("p", { staticClass: "submission-text" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.job.additionalSubmissions.submissionLink,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.job.additionalSubmissions.submissionLink)
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "submission-modal" },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "submission-btn" },
                        [
                          _c(
                            "button",
                            { ref: "myButton", staticClass: "centered-button" },
                            [_vm._v("Record Submission")]
                          ),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-button md-success",
                              on: { click: _vm.submitVideoSubmissionModal },
                            },
                            [_vm._v(" Upload Submission Link")]
                          ),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.submitVideoModal
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.cancelVideoSubmission },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("div", { staticClass: "submission-modal" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "modal-title black",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v("Video Submission")]
                    ),
                    _c("br"),
                    _c("p", [
                      _vm._v(" Please submit the video as a "),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(" Google Drive link or a Loom link"),
                      ]),
                      _vm.job.additionalSubmissions.submissionLink !== ""
                        ? _c("span", [_vm._v(" about the following:")])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "submission-text" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.job.additionalSubmissions.submissionLink,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.job.additionalSubmissions.submissionLink)
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "md-field",
                    [
                      _c("md-input", {
                        staticStyle: { "text-align": "center" },
                        attrs: { placeholder: "Upload Submission Link" },
                        model: {
                          value: _vm.videoSubmission,
                          callback: function ($$v) {
                            _vm.videoSubmission = $$v
                          },
                          expression: "videoSubmission",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.feedback
                    ? _c("p", { staticStyle: { color: "green !important" } }, [
                        _vm._v(_vm._s(_vm.feedback)),
                      ])
                    : _vm._e(),
                  _vm.videoSubmissionMessage
                    ? _c("p", { staticStyle: { color: "red !important" } }, [
                        _vm._v(_vm._s(_vm.videoSubmissionMessage)),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _vm.feedback
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-button md-success",
                              on: { click: _vm.closeWrittenSubmissionsModal },
                            },
                            [_vm._v("Okay")]
                          )
                        : _vm._e(),
                      _vm._v("    "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: { click: _vm.submitVideoSubmissionUrl },
                      },
                      [_vm._v("Submit")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.confirmVideoSubmission
        ? _c(
            "modal",
            [
              _c("template", { slot: "body" }, [
                _c("p", [_vm._v("Video has been Successfully Submitted")]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: { click: _vm.closeVideoConfirmationModal },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.applyAfterSubmission
        ? _c(
            "modal",
            [
              _c("template", { slot: "body" }, [
                _c("h3", [_vm._v("Please Submit Your Application!")]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    { staticClass: "md-success", on: { click: _vm.apply } },
                    [_vm._v(" Submit Application ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.loomBrowserSupportError
        ? _c(
            "modal",
            [
              _c("template", { slot: "body" }, [
                _c("h4", [
                  _vm._v(
                    "Oops! Your browser isn't supported, please try a different web browser and contact Jobox for support"
                  ),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: {
                        click: function () {
                          this$1.loomBrowserSupportError = false
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.loomTokenError
        ? _c(
            "modal",
            [
              _c("template", { slot: "body" }, [
                _c("h4", [
                  _vm._v(
                    "Oops! Something went wrong with video recording, please contact Jobox for support"
                  ),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: {
                        click: function () {
                          this$1.loomTokenError = false
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.applicationBlock
        ? _c("modal", {
            attrs: {
              role: "dialog",
              "aria-labelledby": "modal-title",
              "aria-describedby": "modal-message",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "h4",
                        {
                          staticClass: "modal-title black",
                          attrs: { id: "modal-title" },
                        },
                        [_vm._v("Whoa there! ⛔️")]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        {
                          staticClass: "black",
                          attrs: { id: "modal-message" },
                        },
                        [_vm._v(_vm._s(_vm.applicationBlockMsg))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.redirectToStudentProfile },
                        },
                        [_vm._v(" Got It ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3305950231
            ),
          })
        : _vm._e(),
      _c("confirm-modal", {
        attrs: {
          title: "Update Profile",
          message:
            "To increase your chances of success, make sure your profile is always up to date. Would you like to update it now?",
          visible: _vm.profileUpdateConfirm,
          noLabel: "No, Submit my application",
          noPrimary: "",
        },
        on: {
          onYesClick: function ($event) {
            return _vm.confirmApplication(true)
          },
          onNoClick: function ($event) {
            return _vm.confirmApplication(false)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }