var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("br"),
      _c(
        "md-field",
        { class: _vm.v$.title.$error && "md-error" },
        [
          _c("md-icon", [_vm._v(" book ")]),
          _c("label", [_vm._v("Name of Bursary")]),
          _c("md-input", {
            attrs: { required: "" },
            on: { input: _vm.addTitle },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
        ],
        1
      ),
      _vm.v$.title.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v("Please enter the name of the bursary"),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { margin: "3rem 0 0 0 !important" } },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { class: _vm.v$.description.$error && "error" }, [
                _vm._v("description"),
              ]),
              _c(
                "label",
                {
                  class: _vm.v$.description.$error && "error_message",
                  staticStyle: { "margin-left": "0.5rem" },
                },
                [_vm._v(" Bursary Description ")]
              ),
            ],
            1
          ),
          _vm.v$.description.$error
            ? _c("p", { staticClass: "error_message" }, [
                _vm._v("Please enter a bursary description"),
              ])
            : _vm._e(),
          _c("vue-editor", {
            staticStyle: {
              margin: "1rem 0 3rem 0 !important",
              width: "100% !important",
            },
            on: { "text-change": _vm.addDescription },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
        ],
        1
      ),
      _c(
        "md-field",
        { class: _vm.v$.workPlacement.$error && "md-error" },
        [
          _c(
            "md-icon",
            {
              class: _vm.v$.workPlacement.$error && "error",
              staticStyle: { "margin-right": "1rem" },
            },
            [_vm._v("business")]
          ),
          _c("label", { attrs: { for: "workPlacement" } }, [
            _vm._v("Does this bursary involve work placement?"),
          ]),
          _c(
            "md-select",
            {
              attrs: {
                required: "",
                name: "workPlacement",
                id: "workPlacement",
              },
              on: { input: _vm.addWorkPlacement },
              model: {
                value: _vm.workPlacement,
                callback: function ($$v) {
                  _vm.workPlacement = $$v
                },
                expression: "workPlacement",
              },
            },
            [
              _c("md-option", { attrs: { value: "Yes" } }, [_vm._v("Yes")]),
              _c("md-option", { attrs: { value: "No" } }, [_vm._v("No")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.v$.workPlacement.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v(
              "Please select an option whether this bursary involves work placement"
            ),
          ])
        : _vm._e(),
      _c("br"),
      _c("br"),
      _vm.workPlacement === "Yes"
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "md-icon",
                    {
                      class: _vm.v$.workPlacementInfo.$error && "error",
                      staticStyle: { "margin-right": "1rem" },
                    },
                    [_vm._v("business")]
                  ),
                  _c(
                    "label",
                    { style: _vm.v$.workPlacementInfo.$error && "color:red" },
                    [_vm._v("Work Placement Information")]
                  ),
                ],
                1
              ),
              _vm.v$.workPlacementInfo.$error
                ? _c("p", { staticClass: "error_message" }, [
                    _vm._v("Please add work place information"),
                  ])
                : _vm._e(),
              _c("vue-editor", {
                staticStyle: { margin: "1rem 0 3rem 0 !important" },
                on: { "text-change": _vm.addWorkPlacementInfo },
                model: {
                  value: _vm.workPlacementInfo,
                  callback: function ($$v) {
                    _vm.workPlacementInfo = $$v
                  },
                  expression: "workPlacementInfo",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c("label", { style: _vm.v$.deadline.$error && "color:red" }, [
        _vm._v("Application Deadline"),
      ]),
      _c("md-datepicker", {
        class: _vm.v$.deadline.$error && "md-invalid",
        attrs: { required: "" },
        on: { input: _vm.addDeadline },
        model: {
          value: _vm.deadline,
          callback: function ($$v) {
            _vm.deadline = $$v
          },
          expression: "deadline",
        },
      }),
      _vm.v$.deadline.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v("Please add a deadline"),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "justify-end" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-raised md-primary",
              on: { click: _vm.nextStep },
            },
            [_vm._v("Next")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }