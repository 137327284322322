var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
    _vm.loading
      ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
      : _vm._e(),
    _c("div", { staticClass: "md-layout-item md-small-size-100" }, [
      _c("hr"),
      _c("h2", { staticClass: "centre" }, [_vm._v("Currently Investigating")]),
      _c("hr"),
      _c("p", { staticClass: "centre" }, [
        _vm._v(
          "You have expressed that you are dissatisfied with the job. We will contact you shortly."
        ),
      ]),
      _c("hr"),
      _c("h6", { staticClass: "centre" }, [_vm._v("Student Information")]),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item md-small-size-100" },
          [
            _c(
              "md-card",
              { staticClass: "md-card-profile" },
              [
                _c("div", { staticClass: "md-card-avatar" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.cardUserImage },
                  }),
                ]),
                _c(
                  "md-card-content",
                  [
                    _c("h6", { staticClass: "category text-gray" }, [
                      _vm._v(_vm._s(_vm.applicant.degree[0].degree)),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "card-title",
                        attrs: {
                          to: {
                            name: "view-student-profile",
                            params: { id: _vm.applicant.alias },
                          },
                        },
                      },
                      [_c("a", [_vm._v(_vm._s(_vm.applicant.applicant))])]
                    ),
                    _c("p", { staticClass: "card-description" }, [
                      _vm._v(_vm._s(_vm.applicant.bio)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._m(1),
            _vm._m(2),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Call us at: "),
      _c("b", [_vm._v("087 149 4394")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v(" Email us at: "),
      _c("b", [
        _c(
          "a",
          {
            staticStyle: { color: "blue" },
            attrs: { href: "mailto:contact@jobox.co.za" },
          },
          [_vm._v("contact@jobox.co.za")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }