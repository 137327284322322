<template>
	<div class="star-rating">
		<span
			v-for="rating in ratings"
			:key="rating"
			:class="{ 'is-selected': modelValue >= rating && modelValue != null, 'is-disabled': disabled }"
			@click="set(rating)"
			@mouseover="star_over(rating)"
			@mouseleave="star_out"
		>
			<input class="star-rating star-rating__checkbox" type="hidden" :value="rating" :name="name" :disabled="disabled" />
			★
		</span>
	</div>
</template>
<script>
export default {
	props: {
		name: String,
		value: {
			type: [String, Number],
			default: 0,
		},
		id: String,
		disabled: Boolean,
		required: Boolean,
	},
	data: function () {
		return {
			modelValue: this.value,
			temp_value: null,
			ratings: [1, 2, 3, 4, 5],
		};
	},
	computed: {
		getModalValue() {
			return this.value;
		},
	},
	methods: {
		/*
		 * Behaviour of the stars on mouseover.
		 */
		star_over(index) {
			var self = this;

			if (!this.disabled) {
				this.temp_value = this.modelValue;
				return (this.modelValue = index);
			}
		},

		/*
		 * Behaviour of the stars on mouseout.
		 */
		star_out() {
			var self = this;

			if (!this.disabled) {
				return (this.modelValue = this.temp_value);
			}
		},

		/*
		 * Set the rating.
		 */
		set(value) {
			var self = this;
			if (!this.disabled) {
				this.temp_value = this.modelValue;
				this.$emit('setRatingValue', value);
				return (this.modelValue = value);
			}
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(newValue) {
				this.modelValue = newValue;
			},
		},
		modelValue(newValue) {
			this.$emit('update:value', newValue);
		},
	},
};
</script>
<style scoped>
.visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

.star-rating {
	display: inline-block;
	padding: 3px;
	vertical-align: middle;
	font-size: 2em;
	color: grey;
	transition: color 0.2s ease-out;
}
.__star {
	display: inline-block;
	padding: 3px;
	vertical-align: middle;
	line-height: 1;
	font-size: 1.5em;
	color: #ababab;
	transition: color 0.2s ease-out;
}

.is-selected {
	color: #ffd700;
}

.is-disabled:hover {
	cursor: default;
}
</style>
