var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "md-icon",
            {
              class: _vm.v$.requirements.$error && "error",
              staticStyle: { "margin-right": "1rem" },
            },
            [_vm._v("description")]
          ),
          _c("label", { style: _vm.v$.requirements.$error && "color:red" }, [
            _vm._v(" Bursary Requirements "),
          ]),
        ],
        1
      ),
      _vm.v$.requirements.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v(
              "Requirements are required and should be at least 10 characters long."
            ),
          ])
        : _vm._e(),
      _c("vue-editor", {
        staticStyle: { margin: "1rem 0 3rem 0 !important" },
        on: { "text-change": _vm.addRequirements },
        model: {
          value: _vm.requirements,
          callback: function ($$v) {
            _vm.requirements = $$v
          },
          expression: "requirements",
        },
      }),
      _c(
        "div",
        { staticStyle: { display: "flex", "flex-direction": "column" } },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "md-icon",
                {
                  class: _vm.v$.documentRequirements.$error && "error",
                  staticStyle: { "margin-right": "1rem" },
                },
                [_vm._v("folder")]
              ),
              _c(
                "label",
                { style: _vm.v$.documentRequirements.$error && "color:red" },
                [_vm._v(" Documentation Requirements ")]
              ),
            ],
            1
          ),
          _vm.v$.documentRequirements.$error
            ? _c("p", { staticClass: "error_message" }, [
                _vm._v("At least one document is required"),
              ])
            : _vm._e(),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addCv },
              model: {
                value: _vm.cv,
                callback: function ($$v) {
                  _vm.cv = $$v
                },
                expression: "cv",
              },
            },
            [_vm._v("CV")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addId },
              model: {
                value: _vm.id,
                callback: function ($$v) {
                  _vm.id = $$v
                },
                expression: "id",
              },
            },
            [_vm._v("ID")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addProofOfIncome },
              model: {
                value: _vm.proofOfIncome,
                callback: function ($$v) {
                  _vm.proofOfIncome = $$v
                },
                expression: "proofOfIncome",
              },
            },
            [_vm._v("Proof of Income")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addMatricResults },
              model: {
                value: _vm.matricResults,
                callback: function ($$v) {
                  _vm.matricResults = $$v
                },
                expression: "matricResults",
              },
            },
            [_vm._v("Matric Results")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addAcademicTranscript },
              model: {
                value: _vm.academicTranscript,
                callback: function ($$v) {
                  _vm.academicTranscript = $$v
                },
                expression: "academicTranscript",
              },
            },
            [_vm._v("Academic Transcript")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addTuitionQuotation },
              model: {
                value: _vm.tuitionQuotation,
                callback: function ($$v) {
                  _vm.tuitionQuotation = $$v
                },
                expression: "tuitionQuotation",
              },
            },
            [_vm._v("Tuition Quotation")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addOther },
              model: {
                value: _vm.other,
                callback: function ($$v) {
                  _vm.other = $$v
                },
                expression: "other",
              },
            },
            [_vm._v("Other")]
          ),
          _vm.other && _vm.additionalDocuments.length === 0
            ? _c("p", { staticClass: "error_message" }, [
                _vm._v("Please add at least one additional document"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.additionalDocuments.length > 0
        ? _c("p", [_vm._v("Additional Documents")])
        : _vm._e(),
      _vm._l(_vm.additionalDocuments, function (doc, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "flex-direction": "column",
              "margin-top": "10px",
              width: "30%",
            },
          },
          [
            _c(
              "md-field",
              [
                _c("md-input", {
                  on: {
                    change: function ($event) {
                      return _vm.updateDocument(index, doc.name)
                    },
                  },
                  model: {
                    value: doc.name,
                    callback: function ($$v) {
                      _vm.$set(doc, "name", $$v)
                    },
                    expression: "doc.name",
                  },
                }),
                _c(
                  "md-button",
                  {
                    staticClass: "md-icon-button md-raised md-primary",
                    on: {
                      click: function ($event) {
                        return _vm.deleteDocument(index)
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("delete")])],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.other
        ? _c(
            "div",
            [
              _c("TextField", {
                staticStyle: { width: "30%" },
                attrs: { label: "Additional Document" },
                model: {
                  value: _vm.newDocument,
                  callback: function ($$v) {
                    _vm.newDocument = $$v
                  },
                  expression: "newDocument",
                },
              }),
              _c(
                "md-button",
                {
                  staticClass: "md-raised md-primary",
                  on: { click: _vm.addDocument },
                },
                [_c("md-icon", [_vm._v("add")]), _vm._v(" Add")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "md-icon",
            {
              class: _vm.v$.documentInstructions.$error && "error",
              staticStyle: { "margin-right": "1rem" },
            },
            [_vm._v("description")]
          ),
          _c(
            "label",
            { style: _vm.v$.documentInstructions.$error && "color:red" },
            [_vm._v(" Document Submission Instructions ")]
          ),
        ],
        1
      ),
      _vm.v$.documentInstructions.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v("Document submission instructions are required."),
          ])
        : _vm._e(),
      _c("vue-editor", {
        staticStyle: { margin: "1rem 0 3rem 0 !important" },
        on: { "text-change": _vm.addDocumentInstructions },
        model: {
          value: _vm.documentInstructions,
          callback: function ($$v) {
            _vm.documentInstructions = $$v
          },
          expression: "documentInstructions",
        },
      }),
      _c(
        "div",
        { staticClass: "justify-between" },
        [
          _c(
            "md-button",
            { staticClass: "md-raised", on: { click: _vm.previousStep } },
            [_c("md-icon", [_vm._v("arrow_back")]), _vm._v(" Previous")],
            1
          ),
          _c(
            "md-button",
            {
              staticClass: "md-raised md-primary",
              on: { click: _vm.nextStep },
            },
            [_vm._v(" Next "), _c("md-icon", [_vm._v("arrow_forward")])],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }