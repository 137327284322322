var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.collapse, function (item, index) {
      var _obj
      return _c(
        "div",
        {
          key: item.field,
          class: [
            "md-collapse",
            _vm.activeCollapse(index + 1),
            ((_obj = {}),
            (_obj[_vm.getColorCollapse(_vm.colorCollapse)] = true),
            _obj),
          ],
        },
        [
          item.isShow
            ? _c(
                "div",
                {
                  staticClass: "md-collapse-label",
                  on: {
                    click: function ($event) {
                      return _vm.toggle(index + 1)
                    },
                  },
                },
                [
                  _c(
                    "h5",
                    { staticClass: "md-collapse-title" },
                    [
                      _vm._v(" " + _vm._s(item.field) + " "),
                      _c("md-icon", [_vm._v(_vm._s(_vm.icon))]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.getActiveCollapse(index + 1),
                    expression: "getActiveCollapse(index + 1)",
                  },
                ],
                staticClass: "md-collapse-content",
              },
              [_vm._t(_vm.getCollapseContent(index + 1))],
              2
            ),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }