var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("img", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          src: require("@/assets/img/logo.png"),
          height: "125px",
          width: "125px",
        },
      }),
      _c("h1", [_vm._v("Woah There!")]),
      _c("h3", [
        _vm._v(
          "Your account is still unverified. Please close this tab and try again."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }