var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.client.studentRatingComplete
    ? _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
            : _vm._e(),
          _c("hr"),
          _c("h2", { staticClass: "centre" }, [
            _vm._v("Please Rate & Review the Client"),
          ]),
          _c("hr"),
          _c("p", [
            _vm._v(
              "Thank you so much for being awesome and adding value to your client."
            ),
          ]),
          _c("p", [
            _vm._v(
              "In order to ensure that we keep on providing you with better clients, we ask that you please rate and review the client that you worked with."
            ),
          ]),
          _c("p", [
            _vm._v(
              "This not only helps the clients improve but also assists us in knowing which clients we continue to work with."
            ),
          ]),
          _c("p", [_vm._v("Your feedback will be highly appreciated!")]),
          _c("strong", [
            _vm._v(
              "Could you please rate the client based on the work you did with them? "
            ),
          ]),
          _c(
            "i",
            { staticClass: "fas fa-question-circle" },
            [
              _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
                _vm._v(
                  "This is similar to an Uber rating where you rate how it was working with the client"
                ),
              ]),
            ],
            1
          ),
          _c("star-rating", {
            attrs: {
              "show-rating": true,
              glow: 10,
              "round-start-rating": false,
            },
            model: {
              value: _vm.rating,
              callback: function ($$v) {
                _vm.rating = $$v
              },
              expression: "rating",
            },
          }),
          _c(
            "md-field",
            {
              class: [
                { "md-valid": !_vm.errors.has("review") && _vm.touched.review },
                { "md-error": _vm.errors.has("review") },
              ],
            },
            [
              _c("label", [_vm._v("Do you have any additional comments?")]),
              _c("md-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.review,
                    expression: "modelValidations.review",
                  },
                ],
                attrs: {
                  "data-vv-name": "review",
                  type: "text",
                  name: "review",
                },
                model: {
                  value: _vm.review,
                  callback: function ($$v) {
                    _vm.review = $$v
                  },
                  expression: "review",
                },
              }),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("review"),
                          expression: "errors.has('review')",
                        },
                      ],
                      staticClass: "error",
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.errors.has("review") && _vm.touched.review,
                          expression: "!errors.has('review') && touched.review",
                        },
                      ],
                      staticClass: "success",
                    },
                    [_vm._v("done")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("div", { staticClass: "centre" }, [
            _c(
              "button",
              {
                staticClass: "md-button md-success md-theme-default",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [_vm._m(1)]
            ),
          ]),
          _vm.modal
            ? _c(
                "modal",
                { on: { close: _vm.modalHide } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! ✋"),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.modalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("p", { staticClass: "black" }, [
                      _vm._v(_vm._s(_vm.feedback)),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.modalHide },
                          },
                          [_vm._v("Got it")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _c("div", [
        _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
        _vm.loading
          ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(2)])
          : _vm._e(),
        _c("hr"),
        _c("h2", { staticClass: "centre" }, [
          _vm._v("Waiting for the Client to Give You a Rating"),
        ]),
        _c("hr"),
        _c("p", { staticClass: "centre" }, [
          _vm._v("Waiting for the client to give you a rating."),
        ]),
        _c("hr"),
        _c("h6", { staticClass: "centre" }, [_vm._v("Client Information")]),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-small-size-100" },
            [
              _c(
                "md-card",
                { staticClass: "md-card-profile" },
                [
                  _c("div", { staticClass: "md-card-avatar" }, [
                    _vm.picture
                      ? _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.picture },
                        })
                      : _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.cardUserImage },
                        }),
                  ]),
                  _c("br"),
                  _c("md-card-content", [
                    _c("h6", { staticClass: "category text-gray" }, [
                      _vm._v(_vm._s(_vm.client.companyName)),
                    ]),
                    _c(
                      "h4",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "card-title",
                            attrs: {
                              to: {
                                name: "view-client-profile",
                                params: { id: _vm.client.clientAlias },
                              },
                            },
                          },
                          [_c("a", [_vm._v(_vm._s(_vm.client.clientName))])]
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "card-description" }, [
                      _vm._v(_vm._s(_vm.client.description)),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._m(3),
              _vm._m(4),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-ripple" }, [
      _c("div", { staticClass: "md-button-content" }, [_vm._v("Submit")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Call us at: "),
      _c("b", [_vm._v("087 149 4394")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v(" Email us at: "),
      _c("b", [
        _c(
          "a",
          {
            staticStyle: { color: "blue" },
            attrs: { href: "mailto:contact@jobox.co.za" },
          },
          [_vm._v("contact@jobox.co.za")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }