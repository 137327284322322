import { render, staticRenderFns } from "./Cancel.vue?vue&type=template&id=44480d96&scoped=true&"
import script from "./Cancel.vue?vue&type=script&lang=js&"
export * from "./Cancel.vue?vue&type=script&lang=js&"
import style0 from "./Cancel.vue?vue&type=style&index=0&id=44480d96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44480d96",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/jobox-platform/jobox-platform/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('44480d96')) {
      api.createRecord('44480d96', component.options)
    } else {
      api.reload('44480d96', component.options)
    }
    module.hot.accept("./Cancel.vue?vue&type=template&id=44480d96&scoped=true&", function () {
      api.rerender('44480d96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/client/jobs/cancel/Cancel.vue"
export default component.exports