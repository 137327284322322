var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "bursary-filter-overlay" }, [
        _c(
          "div",
          { staticClass: "bursary-filter" },
          [
            _c(
              "div",
              { staticClass: "close-btn-container" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-danger",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ],
              1
            ),
            _c("h3", [_vm._v("Filter Bursary Applicants")]),
            _vm._l(_vm.filterOptions, function (options, key) {
              return _c(
                "md-field",
                { key: key, staticClass: "filter-field" },
                [
                  _c(
                    "md-select",
                    {
                      attrs: {
                        placeholder: key.charAt(0).toUpperCase() + key.slice(1),
                      },
                      model: {
                        value: _vm.filters[key],
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, key, $$v)
                        },
                        expression: "filters[key]",
                      },
                    },
                    _vm._l(options, function (option) {
                      return _c(
                        "md-option",
                        { key: option, attrs: { value: option } },
                        [_vm._v(_vm._s(option))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            }),
            _c(
              "md-switch",
              {
                attrs: { "true-value": true, "false-value": false },
                model: {
                  value: _vm.filters.favourite,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "favourite", $$v)
                  },
                  expression: "filters.favourite",
                },
              },
              [_vm._v("Show Only Favourited")]
            ),
            _c(
              "div",
              { staticClass: "btn-container" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "jobox-std",
                    staticStyle: { "background-color": "#5081cb !important" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("apply")
                      },
                    },
                  },
                  [_vm._v("Apply")]
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-success",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("reset")
                      },
                    },
                  },
                  [_vm._v("Reset Filters")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }