var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("hr"),
      _c("h2", { staticClass: "centre" }, [
        _vm._v(
          "Please Confirm That the Job Was Completed To Your Satisfaction"
        ),
      ]),
      _c("hr"),
      _c("h6", { staticClass: "centre" }, [_vm._v("Student Information")]),
      _c("br"),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item md-small-size-100" },
          [
            _c(
              "md-card",
              { staticClass: "md-card-profile" },
              [
                _c("div", { staticClass: "md-card-avatar" }, [
                  _vm.profile
                    ? _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.profile },
                      })
                    : _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.cardUserImage },
                      }),
                ]),
                _c("br"),
                _c(
                  "md-card-content",
                  [
                    _c("h6", { staticClass: "category text-gray" }, [
                      _vm._v(_vm._s(_vm.applicant.degree[0].degree)),
                    ]),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-info md-sm",
                        on: {
                          click: function ($event) {
                            return _vm.viewProfile(_vm.applicant.alias)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.applicant.applicant))]
                    ),
                    _c("p", { staticClass: "card-description" }, [
                      _vm._v(_vm._s(_vm.applicant.bio)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "centre" },
        [
          _c("p", [
            _vm._v(
              "The student has completed the job, satisfied and ready to pay?"
            ),
          ]),
          _vm._v("     "),
          _c("p", [
            _vm._v(
              "Are you satisfied with the student's completion of the job?"
            ),
          ]),
          _c(
            "md-button",
            {
              staticClass: "md-danger",
              on: {
                click: function ($event) {
                  return _vm.dissatisfied(_vm.client)
                },
              },
            },
            [_vm._v(" I am not satisfied ")]
          ),
          _vm._v("     "),
          _c(
            "md-button",
            {
              staticClass: "md-success",
              on: {
                click: function ($event) {
                  return _vm.complete({ id: _vm.client.id, paid: _vm.paid })
                },
              },
            },
            [_vm._v(" I am satisfied ")]
          ),
        ],
        1
      ),
      _vm.cancelModal
        ? _c(
            "modal",
            { on: { close: _vm.cancelModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Whoa there! ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.cancelModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "Are you sure that you want to report that you are dissatisfied with the student’s completion of the job?"
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-danger",
                        on: { click: _vm.cancelModalHide },
                      },
                      [_vm._v("No")]
                    ),
                    _vm._v("     "),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: {
                          click: function ($event) {
                            return _vm.dissatisfied(_vm.client)
                          },
                        },
                      },
                      [_vm._v("Yes")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.profileModal
        ? _c(
            "modal",
            { on: { close: _vm.profileModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("div", { staticClass: "md-card-avatar avatar" }, [
                    _c("img", {
                      staticClass: "img radius",
                      attrs: { src: _vm.cardUserImage },
                    }),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.profileModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("h2", { staticClass: "modal-title black" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.student.name + " " + _vm.student.surname)
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "black left" }, [
                  _vm._v(" " + _vm._s(_vm.student.bio) + " "),
                ]),
                _c("b", { staticClass: "large-font" }, [
                  _vm._v("Qualifications"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("p", { staticClass: "black left" }, [
                  _vm.student.studying === "Yes"
                    ? _c("span", [
                        _vm._v(
                          "The candidate is currently studying the following:"
                        ),
                      ])
                    : _vm._e(),
                  _vm.student.studying === "No"
                    ? _c("span", [
                        _vm._v(
                          "The candidate most recently completed the following:"
                        ),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("br"),
                  _c("b", [_vm._v("Institution:")]),
                  _vm._v(" " + _vm._s(_vm.student.degree[0].institution) + " "),
                  _c("br"),
                  _c("b", [_vm._v("Degree:")]),
                  _vm._v(" " + _vm._s(_vm.student.degree[0].degree) + " "),
                  _c("br"),
                  _c("b", [_vm._v("Graduate Status:")]),
                  _vm._v(
                    " " + _vm._s(_vm.student.degree[0].graduateStatus) + " "
                  ),
                  _c("br"),
                  _c("b", [_vm._v("Year of Study:")]),
                  _vm._v(" " + _vm._s(_vm.student.degree[0].year) + " "),
                  _c("br"),
                ]),
                _c("b", { staticClass: "large-font" }, [
                  _vm._v("Contact Information"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("p", { staticClass: "black left" }, [
                  _c("b", [_vm._v("Email Address:")]),
                  _vm._v(" " + _vm._s(_vm.student.email || "**********") + " "),
                  _c("br"),
                  _c("b", [_vm._v("Phone Number:")]),
                  _vm._v(" " + _vm._s(_vm.student.phone || "**********") + " "),
                  _vm.student.personalWebsite
                    ? _c("span", [
                        _c("br"),
                        _c("b", [_vm._v("Website: ")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.student.personalWebsite,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.student.personalWebsite))]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("b", { staticClass: "large-font" }, [
                  _vm._v("Certificates"),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm.student.certificate1 ||
                _vm.student.certificate2 ||
                _vm.student.certificate3
                  ? _c(
                      "p",
                      { staticClass: "black" },
                      [
                        _vm.student.certificate1
                          ? _c(
                              "md-button",
                              {
                                staticClass: "md-round md-info md-sm",
                                on: { click: _vm.certificate1 },
                              },
                              [_vm._v("# 1")]
                            )
                          : _vm._e(),
                        _vm._v("     "),
                        _vm.student.certificate2
                          ? _c(
                              "md-button",
                              {
                                staticClass: "md-round md-info md-sm",
                                on: { click: _vm.certificate2 },
                              },
                              [_vm._v("# 2")]
                            )
                          : _vm._e(),
                        _vm._v("     "),
                        _vm.student.certificate3
                          ? _c(
                              "md-button",
                              {
                                staticClass: "md-round md-info md-sm",
                                on: { click: _vm.certificate3 },
                              },
                              [_vm._v("# 3")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.student.certificate1 &&
                !_vm.student.certificate2 &&
                !_vm.student.certificate3
                  ? _c("p", { staticClass: "red left" }, [
                      _vm._v("The candidate has not uploaded any certificates"),
                    ])
                  : _vm._e(),
                _c("b", { staticClass: "large-font" }, [_vm._v("Resume")]),
                _vm._v(" "),
                _c("br"),
                _c(
                  "p",
                  { staticClass: "black" },
                  [
                    _vm.student.cv && _vm.student.cv !== ""
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-round md-info md-sm",
                            on: { click: _vm.cv },
                          },
                          [_vm._v("CV")]
                        )
                      : _vm._e(),
                    _vm._v("     "),
                    _vm.student.portfolio && _vm.student.portfolio !== ""
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-round md-info md-sm",
                            on: { click: _vm.portfolio },
                          },
                          [_vm._v("Portfolio")]
                        )
                      : _vm._e(),
                    _vm._v("     "),
                  ],
                  1
                ),
                _c("b", { staticClass: "large-font" }, [
                  _vm._v("Social Media Handles"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c(
                  "p",
                  { staticClass: "black" },
                  [
                    _vm.student.linkedIn && _vm.student.linkedIn !== ""
                      ? _c("md-icon", [
                          _c("i", {
                            staticClass: "fab fa-linkedin",
                            staticStyle: {
                              color: "#0e76a8",
                              cursor: "pointer",
                            },
                            on: { click: _vm.linkedin },
                          }),
                        ])
                      : _vm._e(),
                    _vm.student.linkedIn && _vm.student.linkedIn !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.linkedIn && _vm.student.linkedIn !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.linkedIn && _vm.student.linkedIn !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.linkedIn && _vm.student.linkedIn !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.github && _vm.student.github !== ""
                      ? _c("md-icon", [
                          _c("i", {
                            staticClass: "fab fa-github",
                            staticStyle: { color: "#000", cursor: "pointer" },
                            on: { click: _vm.github },
                          }),
                        ])
                      : _vm._e(),
                    _vm.student.github && _vm.student.github !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.github && _vm.student.github !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.github && _vm.student.github !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.github && _vm.student.github !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.facebook && _vm.student.facebook !== ""
                      ? _c("md-icon", [
                          _c("i", {
                            staticClass: "fab fa-facebook",
                            staticStyle: {
                              color: "#3b5998",
                              cursor: "pointer",
                            },
                            on: { click: _vm.facebook },
                          }),
                        ])
                      : _vm._e(),
                    _vm.student.facebook && _vm.student.facebook !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.facebook && _vm.student.facebook !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.facebook && _vm.student.facebook !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.facebook && _vm.student.facebook !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.twitter && _vm.student.twitter !== ""
                      ? _c("md-icon", [
                          _c("i", {
                            staticClass: "fab fa-twitter",
                            staticStyle: {
                              color: "#00acee",
                              cursor: "pointer",
                            },
                            on: { click: _vm.twitter },
                          }),
                        ])
                      : _vm._e(),
                    _vm.student.twitter && _vm.student.twitter !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.twitter && _vm.student.twitter !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.twitter && _vm.student.twitter !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.twitter && _vm.student.twitter !== ""
                      ? _c("span", [_vm._v(" ")])
                      : _vm._e(),
                    _vm.student.instagram && _vm.student.instagram !== ""
                      ? _c("md-icon", [
                          _c("i", {
                            staticClass: "fab fa-instagram",
                            staticStyle: {
                              color: "#d6249f",
                              cursor: "pointer",
                            },
                            on: { click: _vm.instagram },
                          }),
                        ])
                      : _vm._e(),
                    !_vm.socialMedia
                      ? _c("span", { staticClass: "red left" }, [
                          _vm._v(
                            "The candidate has no social media handles present"
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.profileModalHide },
                      },
                      [_vm._v("Done")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }