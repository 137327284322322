var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-container" },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "md-card",
        {
          staticClass: "md-card-wizard active",
          attrs: { "data-color": "green" },
        },
        [
          _c(
            "md-card-header",
            [
              _vm._t("header", function () {
                return [
                  _c("h3", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("h3", { staticClass: "description" }, [
                    _vm._v(_vm._s(_vm.subTitle)),
                  ]),
                ]
              }),
            ],
            2
          ),
          _c("div", { staticClass: "wizard-navigation" }, [
            _c(
              "ul",
              { staticClass: "nav nav-pills", attrs: { role: "tablist" } },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "li",
                  {
                    key: tab.title,
                    ref: "tab-" + index,
                    refInFor: true,
                    staticClass: "nav-item wizard-tab-link",
                    style: _vm.linkWidth,
                    attrs: {
                      role: "tab",
                      tabindex: tab.checked ? 0 : "",
                      id: "step-" + tab.tabId,
                      "aria-controls": tab.tabId,
                      "aria-disabled": tab.active,
                      "aria-selected": tab.active,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: [{ active: tab.active }],
                        attrs: { "data-toggle": "tab" },
                        on: {
                          click: function ($event) {
                            return _vm.navigateToTab(index)
                          },
                        },
                      },
                      [_c("tab-item-content", { attrs: { tab: tab } })],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
            _vm.activeTab
              ? _c(
                  "div",
                  {
                    staticClass: "moving-tab",
                    class: { "error-link": _vm.activeTab.hasError },
                    staticStyle: {
                      transition:
                        "transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
                      width: "100%",
                    },
                    style: _vm.movingTabStyles,
                  },
                  [
                    _c("tab-item-content", {
                      attrs: { tab: _vm.activeTab, "moving-tab": true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("md-card-content", [
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _vm._t("default", null, {
                  activeIndex: _vm.activeTabIndex,
                  activeTab: _vm.activeTab,
                }),
              ],
              2
            ),
          ]),
          _c(
            "md-card-actions",
            { attrs: { "md-alignment": "space-between" } },
            [
              _vm._t(
                "footer",
                function () {
                  return [
                    _c(
                      "div",
                      [
                        _vm.activeTabIndex > 0
                          ? _c(
                              "md-button",
                              {
                                staticClass: "btn-previous",
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.prevTab.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pc-view" }, [
                                  _vm._v(_vm._s(_vm.prevButtonText)),
                                ]),
                                _c("div", { staticClass: "mobi-view" }, [
                                  _c("i", { staticClass: "fas fa-arrow-left" }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.activeTabIndex < _vm.tabCount - 1
                          ? _c(
                              "md-button",
                              {
                                staticClass: "btn-next jb-standard",
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.nextTab()
                                    _vm.updateStudentAccount(_vm.activeTabIndex)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pc-view" }, [
                                  _vm._v(_vm._s(_vm.nextButtonText)),
                                ]),
                                _c("div", { staticClass: "mobi-view" }, [
                                  _c("i", {
                                    staticClass: "fas fa-arrow-right",
                                  }),
                                ]),
                              ]
                            )
                          : _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-button md-success md-theme-default",
                                attrs: { slot: "footer" },
                                on: { click: _vm.createAccount },
                                slot: "footer",
                              },
                              [
                                _c("div", { staticClass: "pc-view" }, [
                                  _vm._v(_vm._s(_vm.finishButtonText)),
                                ]),
                                _c("div", { staticClass: "mobi-view" }, [
                                  _c("i", { staticClass: "fa fa-check" }),
                                ]),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]
                },
                { nextTab: _vm.nextTab, prevTab: _vm.prevTab }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }