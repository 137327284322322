var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav nav-mobile-menu" }, [
    _c("li", [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle",
          attrs: { "data-toggle": "dropdown" },
          on: { click: _vm.logout },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("reply_all")]),
          _c("p", [_vm._v("Logout")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }