var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-filter-container" },
    [
      _c("h1", { staticClass: "data-table-heading" }, [_vm._v("Bursaries")]),
      _vm._l(_vm.tableData, function (item, index) {
        return _c(
          "md-card",
          { key: index, staticClass: "bursary-card" },
          [
            _c("md-card-header", [
              _c("div", { staticClass: "md-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      item[
                        _vm.columns.find(function (column) {
                          return column.field === "title"
                        }).field
                      ]
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "md-card-content",
              _vm._l(
                _vm.columns.filter(function (col) {
                  return col.field !== "title"
                }),
                function (column, colIndex) {
                  return _c(
                    "div",
                    { key: colIndex, staticClass: "bursary-row" },
                    [
                      column.field !== "action"
                        ? [
                            _c("div", { staticClass: "row" }, [
                              _c("span", { staticClass: "bursary-label" }, [
                                _vm._v(_vm._s(column.label) + ":"),
                              ]),
                              _c("span", { staticClass: "bursary-content" }, [
                                _vm._v(_vm._s(item[column.field])),
                              ]),
                            ]),
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "view-btn" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: _vm.getRoute(column.route, item),
                                    },
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      { staticClass: "jb-standard" },
                                      [_vm._v("View")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  )
                }
              ),
              0
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }