var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.confirmChoices.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "department-selection-container" }, [
              _c("h5", { staticClass: "info-text" }, [
                _vm._v("What departments would you like to work in?"),
              ]),
              _c(
                "h5",
                {
                  staticClass: "info-text",
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "12px",
                    "margin-top": "-25px",
                  },
                },
                [
                  _vm._v(
                    "Select 1-3 departments that you would like to work in"
                  ),
                ]
              ),
              _vm.v$.department.$error
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v("Select your preferred departments"),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "selected-departments" },
                _vm._l(_vm.department, function (dept, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "department-tag" },
                    [_vm._v(" " + _vm._s(dept.department) + " ")]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: { click: _vm.openDepartmentSelection },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.department.length > 0
                              ? "Edit"
                              : "+ Add Departments"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm.openDepartmentModal
        ? _c("department-selection", {
            attrs: {
              companyDepartments: _vm.companyDepartments,
              openDepartmentModal: _vm.openDepartmentModal,
            },
            on: {
              cancelDepartmentSelection: _vm.cancelDepartmentSelection,
              departmentSelection: _vm.departmentSelection,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }