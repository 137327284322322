var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout-item md-small-size-100" },
    [
      _c(
        "div",
        { staticClass: "favorite-button", on: { click: _vm.setFavourite } },
        [
          (
            _vm.userProfile.favourite
              ? _vm.userProfile.favourite
              : _vm.userProfile.isTalentPoolFavourite
          )
            ? _c(
                "md-icon",
                {
                  staticClass: "star-icon",
                  class: {
                    "yellow-star": _vm.userProfile.favourite
                      ? _vm.userProfile.favourite
                      : _vm.userProfile.isTalentPoolFavourite,
                  },
                },
                [_vm._v("star")]
              )
            : _c("md-icon", { staticClass: "star-icon" }, [
                _vm._v("star_border"),
              ]),
          _c("md-tooltip", { staticClass: "icon-tooltip" }, [
            _vm._v(_vm._s(_vm.favouriteButtonText)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "comment-icon-container",
          on: { click: _vm.showCommentsModal },
        },
        [
          _c(
            "md-icon",
            {
              staticClass: "comment-icon",
              staticStyle: {
                "font-size": "30px !important",
                cursor: "pointer !important",
              },
            },
            [_vm._v("chat")]
          ),
          _c("md-tooltip", { staticClass: "icon-tooltip" }, [
            _vm._v("Comments"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.isTalentPool === ""
            ? _c("applicant-comments-modal", {
                attrs: {
                  commentsButtonClicked: _vm.commentsModal,
                  userProfile: _vm.userProfile,
                  jobId: this.jobId,
                },
                on: { closeCommentsModal: _vm.closeCommentsModal },
              })
            : _vm._e(),
          _vm.isTalentPool !== ""
            ? _c("talent-pool-comments-modal", {
                attrs: {
                  commentsButtonClicked: _vm.talentCommentsModal,
                  userProfile: _vm.userProfile,
                  currentTalentPool: _vm.currentTalentPool,
                  isTalentPool: _vm.isTalentPool,
                },
                on: {
                  closeTalentPoolCommentsModal:
                    _vm.closeTalentPoolCommentsModal,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "md-card",
        { staticClass: "md-card-profile" },
        [
          _c("div", { staticClass: "image_container" }, [
            _vm.profile.profile
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.profile.profile },
                })
              : _vm._e(),
            !_vm.profile.profile
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.cardUserImage },
                })
              : _vm._e(),
          ]),
          _c(
            "md-card-content",
            { staticStyle: { height: "auto" } },
            [
              _c("h1", { staticClass: "md-headline" }, [
                _vm._v(_vm._s(_vm.profile.name + " " + _vm.profile.surname)),
              ]),
              _vm.profile.bio
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-style": "italic",
                          "font-size": "12px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.profile.bio))]
                    ),
                  ])
                : _vm._e(),
              _vm.profile &&
              _vm.profile.qualifications &&
              _vm.profile.qualifications.length
                ? _c(
                    "div",
                    { staticClass: "heading-row" },
                    [
                      _c("md-icon", { staticClass: "profile" }, [
                        _vm._v(" school "),
                      ]),
                      _c("h1", { staticClass: "md-headline" }, [
                        _vm._v("Qualifications"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(
                _vm.profile.qualifications,
                function (qualification, index) {
                  return _c("div", { key: index, staticClass: "md-card" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("p", { staticClass: "label" }, [
                        _vm._v("Institution"),
                      ]),
                      _c("p", [_vm._v(_vm._s(qualification.institution))]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("p", { staticClass: "label" }, [_vm._v("Degree")]),
                      _c("p", [_vm._v(_vm._s(qualification.degree))]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("p", { staticClass: "label" }, [
                        _vm._v("Year of Study"),
                      ]),
                      _c("p", [_vm._v(_vm._s(qualification.year))]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("p", { staticClass: "label" }, [
                        _vm._v("Graduate Status"),
                      ]),
                      _c("p", [_vm._v(_vm._s(qualification.graduateStatus))]),
                    ]),
                    qualification.academicTranscript &&
                    qualification.academicTranscript.url
                      ? _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.viewAcademicTranscript(
                                      qualification.academicTranscript
                                    )
                                  },
                                },
                              },
                              [_vm._v("View Academic Transcript")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }
              ),
              _vm.profile &&
              _vm.profile.experiences &&
              _vm.profile.experiences.length
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "heading-row" },
                        [
                          _c("md-icon", { staticClass: "profile" }, [
                            _vm._v(" work "),
                          ]),
                          _c("h1", { staticClass: "md-headline" }, [
                            _vm._v("Experience"),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(_vm.profile.experiences, function (experience) {
                        return _c(
                          "div",
                          {
                            key: experience.experienceNum,
                            staticClass: "md-card",
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Employer"),
                              ]),
                              _c("p", [_vm._v(_vm._s(experience.employer))]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Description"),
                              ]),
                              _c("p", [_vm._v(_vm._s(experience.description))]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Job Title"),
                              ]),
                              _c("p", [_vm._v(_vm._s(experience.jobTitle))]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Start Date"),
                              ]),
                              _c("p", [_vm._v(_vm._s(experience.startDate))]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.profile &&
              _vm.profile.extraCirricularActivities &&
              _vm.profile.extraCirricularActivities.length
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "heading-row" },
                      [
                        _c("md-icon", { staticClass: "profile" }, [
                          _vm._v(" sports "),
                        ]),
                        _c("h1", { staticClass: "md-headline" }, [
                          _vm._v("Extracurricular Activities"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "profile-row" },
                      _vm._l(
                        _vm.profile.extraCirricularActivities,
                        function (activity, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "extra-cirricular-card",
                            },
                            [_c("ul", [_c("li", [_vm._v(_vm._s(activity))])])]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "heading-row" },
                [
                  _c("md-icon", { staticClass: "profile" }, [
                    _vm._v(" note_alt "),
                  ]),
                  _c("h1", { staticClass: "md-headline" }, [
                    _vm._v("Contact Information"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "row" }, [
                _c("p", { staticClass: "label" }, [_vm._v("Email Address")]),
                _c("p", [_vm._v(_vm._s(_vm.profile.email))]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("p", { staticClass: "label" }, [_vm._v("Phone Number")]),
                _c("p", [_vm._v(_vm._s(_vm.profile.phone))]),
              ]),
              _vm.profile.personalWebsite
                ? _c("div", { staticClass: "row" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("Website")]),
                    _c("p", [_vm._v(_vm._s(_vm.profile.personalWebsite))]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "heading-row" },
                [
                  _c("md-icon", { staticClass: "profile" }, [
                    _vm._v("location_on"),
                  ]),
                  _c("h1", { staticClass: "md-headline" }, [
                    _vm._v("Location Information"),
                  ]),
                ],
                1
              ),
              _vm.profile.location
                ? _c("div", { staticClass: "row" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("Province")]),
                    _c("p", [_vm._v(_vm._s(_vm.profile.location.province))]),
                  ])
                : _vm._e(),
              _vm.profile.location
                ? _c("div", { staticClass: "row" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("City/Town")]),
                    _c("p", [_vm._v(_vm._s(_vm.profile.location.town))]),
                  ])
                : _vm._e(),
              _vm.profile.relocation
                ? _c("div", { staticClass: "row" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("Relocation")]),
                    _c("p", [_vm._v(_vm._s(_vm.profile.relocation))]),
                  ])
                : _vm._e(),
              _vm.profile.certificate1 ||
              _vm.profile.certificate2 ||
              _vm.profile.certificate3
                ? _c(
                    "div",
                    { staticClass: "heading-row" },
                    [
                      _c("md-icon", { staticClass: "profile" }, [
                        _vm._v("workspace_premium"),
                      ]),
                      _vm.profile.certificate1 ||
                      _vm.profile.certificate2 ||
                      _vm.profile.certificate3
                        ? _c("h1", { staticClass: "md-headline" }, [
                            _vm._v("Certificates"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.profile.certificate1
                ? _c("div", [
                    _c("div", { staticClass: "attachment_button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.profile.certificate1.url,
                            target: "blank",
                          },
                        },
                        [_vm._v("#1")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.profile.certificate2
                ? _c("div", [
                    _c("div", { staticClass: "attachment_button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.profile.certificate2.url,
                            target: "blank",
                          },
                        },
                        [_vm._v("#2")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.profile.certificate3
                ? _c("div", [
                    _c("div", { staticClass: "attachment_button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.profile.certificate3.url,
                            target: "blank",
                          },
                        },
                        [_vm._v("#3")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "heading-row" },
                [
                  _c("md-icon", { staticClass: "profile" }, [
                    _vm._v("account_box"),
                  ]),
                  _c("h1", { staticClass: "md-headline" }, [_vm._v("Resume")]),
                ],
                1
              ),
              _c("div", [
                _vm.profile.cv
                  ? _c("div", { staticClass: "attachment_button" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.profile.cv.url, target: "blank" },
                        },
                        [_vm._v("CV")]
                      ),
                    ])
                  : _vm._e(),
                _vm.profile.portfolio
                  ? _c("div", { staticClass: "attachment_button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.profile.portfolio.url,
                            target: "blank",
                          },
                        },
                        [_vm._v("Portfolio")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.profile.github || _vm.profile.linkedIn
                ? _c(
                    "div",
                    { staticClass: "heading-row" },
                    [
                      _c("md-icon", { staticClass: "profile" }, [
                        _vm._v("tag"),
                      ]),
                      _vm.profile.github || _vm.profile.linkedIn
                        ? _c("h1", { staticClass: "md-headline" }, [
                            _vm._v("Social Media Handles"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkProfile
                ? _c("p", [
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.profile.linkedIn, target: "blank" },
                      },
                      [
                        _vm.checkLinkedIn
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-linkedin",
                                staticStyle: {
                                  color: "#0e76a8",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.checkLinkedIn },
                              }),
                            ])
                          : _vm._e(),
                        _vm.checkLinkedIn
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.checkLinkedIn
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.checkLinkedIn
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.checkLinkedIn
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      { attrs: { href: _vm.profile.github, target: "blank" } },
                      [
                        _vm.checkGitHub
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-github",
                                staticStyle: {
                                  color: "#000",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.checkGitHub },
                              }),
                            ])
                          : _vm._e(),
                        _vm.checkGitHub ? _c("span", [_vm._v(" ")]) : _vm._e(),
                        _vm.checkGitHub ? _c("span", [_vm._v(" ")]) : _vm._e(),
                        _vm.checkGitHub ? _c("span", [_vm._v(" ")]) : _vm._e(),
                        _vm.checkGitHub ? _c("span", [_vm._v(" ")]) : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "btn-submissions-row" }, [
        this.userProfile.writtenSubmissionUrl
          ? _c(
              "div",
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-info",
                    on: { click: _vm.viewWrittenSubmission },
                  },
                  [_vm._v(" view Written Submission ")]
                ),
              ],
              1
            )
          : _vm._e(),
        this.userProfile.videoSubmissionUrl
          ? _c(
              "div",
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-info",
                    on: { click: _vm.viewVideoSubmission },
                  },
                  [_vm._v(" View Video Submission ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.isAcademicTranscript
        ? _c("view-academic-transcript", {
            attrs: {
              currentProfile: this.userProfile,
              academicTranscript: this.academicTranscript,
            },
            on: { closeViewTranscriptModal: _vm.closeViewTranscriptModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }