<template>
	<div class="md-layout-item md-small-size-100">
		<div class="favorite-button" @click="setFavourite">
			<md-icon
				v-if="userProfile.favourite ? userProfile.favourite : userProfile.isTalentPoolFavourite"
				class="star-icon"
				:class="{ 'yellow-star': userProfile.favourite ? userProfile.favourite : userProfile.isTalentPoolFavourite }"
				>star</md-icon
			>
			<md-icon v-else class="star-icon">star_border</md-icon>

			<md-tooltip class="icon-tooltip">{{ favouriteButtonText }}</md-tooltip>
		</div>
		<div class="comment-icon-container" @click="showCommentsModal">
			<md-icon style="font-size: 30px !important; cursor: pointer !important" class="comment-icon">chat</md-icon>
			<md-tooltip class="icon-tooltip">Comments</md-tooltip>
		</div>
		<div>
			<applicant-comments-modal
				v-if="isTalentPool === ''"
				:commentsButtonClicked="commentsModal"
				@closeCommentsModal="closeCommentsModal"
				:userProfile="userProfile"
				:jobId="this.jobId"
			/>
			<talent-pool-comments-modal
				v-if="isTalentPool !== ''"
				:commentsButtonClicked="talentCommentsModal"
				@closeTalentPoolCommentsModal="closeTalentPoolCommentsModal"
				:userProfile="userProfile"
				:currentTalentPool="currentTalentPool"
				:isTalentPool="isTalentPool"
			/>
		</div>

		<md-card class="md-card-profile">
			<div class="image_container">
				<img v-if="profile.profile" class="img" :src="profile.profile" />
				<img v-if="!profile.profile" class="img" :src="cardUserImage" />
			</div>
			<md-card-content style="height: auto">
				<h1 class="md-headline">{{ profile.name + ' ' + profile.surname }}</h1>
				<div class="row" v-if="profile.bio">
					<p style="font-style: italic; font-size: 12px">{{ profile.bio }}</p>
				</div>
				<div class="heading-row" v-if="profile && profile.qualifications && profile.qualifications.length">
					<md-icon class="profile"> school </md-icon>
					<h1 class="md-headline">Qualifications</h1>
				</div>

				<div class="md-card" v-for="(qualification, index) in profile.qualifications" :key="index">
					<div class="row">
						<p class="label">Institution</p>
						<p>{{ qualification.institution }}</p>
					</div>
					<div class="row">
						<p class="label">Degree</p>
						<p>{{ qualification.degree }}</p>
					</div>
					<div class="row">
						<p class="label">Year of Study</p>
						<p>{{ qualification.year }}</p>
					</div>
					<div class="row">
						<p class="label">Graduate Status</p>
						<p>{{ qualification.graduateStatus }}</p>
					</div>
					<div class="row" v-if="qualification.academicTranscript && qualification.academicTranscript.url">
						<md-button class="md-primary" @click="viewAcademicTranscript(qualification.academicTranscript)">View Academic Transcript</md-button>
					</div>
				</div>

				<div v-if="profile && profile.experiences && profile.experiences.length">
					<div class="heading-row">
						<md-icon class="profile"> work </md-icon>
						<h1 class="md-headline">Experience</h1>
					</div>
					<div class="md-card" v-for="experience in profile.experiences" :key="experience.experienceNum">
						<div class="row">
							<p class="label">Employer</p>
							<p>{{ experience.employer }}</p>
						</div>
						<div class="row">
							<p class="label">Description</p>
							<p>{{ experience.description }}</p>
						</div>
						<div class="row">
							<p class="label">Job Title</p>
							<p>{{ experience.jobTitle }}</p>
						</div>
						<div class="row">
							<p class="label">Start Date</p>
							<p>{{ experience.startDate }}</p>
						</div>
					</div>
				</div>

				<div v-if="profile && profile.extraCirricularActivities && profile.extraCirricularActivities.length">
					<div class="heading-row">
						<md-icon class="profile"> sports </md-icon>
						<h1 class="md-headline">Extracurricular Activities</h1>
					</div>

					<div class="profile-row">
						<div v-for="(activity, index) in profile.extraCirricularActivities" :key="index" class="extra-cirricular-card">
							<ul>
								<li>{{ activity }}</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="heading-row">
					<md-icon class="profile"> note_alt </md-icon>
					<h1 class="md-headline">Contact Information</h1>
				</div>

				<div class="row">
					<p class="label">Email Address</p>
					<p>{{ profile.email }}</p>
				</div>
				<div class="row">
					<p class="label">Phone Number</p>
					<p>{{ profile.phone }}</p>
				</div>
				<div class="row" v-if="profile.personalWebsite">
					<p class="label">Website</p>
					<p>{{ profile.personalWebsite }}</p>
				</div>
				<!-- Location and Relocation Information -->
				<div class="heading-row">
					<md-icon class="profile">location_on</md-icon>
					<h1 class="md-headline">Location Information</h1>
				</div>

				<div class="row" v-if="profile.location">
					<p class="label">Province</p>
					<p>{{ profile.location.province }}</p>
				</div>
				<div class="row" v-if="profile.location">
					<p class="label">City/Town</p>
					<p>{{ profile.location.town }}</p>
				</div>

				<div class="row" v-if="profile.relocation">
					<p class="label">Relocation</p>
					<p>{{ profile.relocation }}</p>
				</div>
				<div class="heading-row" v-if="profile.certificate1 || profile.certificate2 || profile.certificate3">
					<md-icon class="profile">workspace_premium</md-icon>
					<h1 class="md-headline" v-if="profile.certificate1 || profile.certificate2 || profile.certificate3">Certificates</h1>
				</div>
				<div v-if="profile.certificate1">
					<div class="attachment_button">
						<a :href="profile.certificate1.url" target="blank">#1</a>
					</div>
				</div>
				<div v-if="profile.certificate2">
					<div class="attachment_button">
						<a :href="profile.certificate2.url" target="blank">#2</a>
					</div>
				</div>
				<div v-if="profile.certificate3">
					<div class="attachment_button">
						<a :href="profile.certificate3.url" target="blank">#3</a>
					</div>
				</div>

				<div class="heading-row">
					<md-icon class="profile">account_box</md-icon>
					<h1 class="md-headline">Resume</h1>
				</div>
				<div>
					<div class="attachment_button" v-if="profile.cv">
						<a :href="profile.cv.url" target="blank">CV</a>
					</div>
					<div class="attachment_button" v-if="profile.portfolio">
						<a :href="profile.portfolio.url" target="blank">Portfolio</a>
					</div>
				</div>
				<div class="heading-row" v-if="profile.github || profile.linkedIn">
					<md-icon class="profile">tag</md-icon>
					<h1 class="md-headline" v-if="profile.github || profile.linkedIn">Social Media Handles</h1>
				</div>

				<p v-if="checkProfile">
					<a :href="profile.linkedIn" target="blank">
						<md-icon v-if="checkLinkedIn">
							<i class="fab fa-linkedin" style="color: #0e76a8; cursor: pointer" @click="checkLinkedIn"></i>
						</md-icon>
						<span v-if="checkLinkedIn">&nbsp;</span><span v-if="checkLinkedIn">&nbsp;</span><span v-if="checkLinkedIn">&nbsp;</span
						><span v-if="checkLinkedIn">&nbsp;</span>
					</a>
					<a :href="profile.github" target="blank">
						<md-icon v-if="checkGitHub"><i class="fab fa-github" style="color: #000; cursor: pointer" @click="checkGitHub"></i></md-icon>
						<span v-if="checkGitHub">&nbsp;</span><span v-if="checkGitHub">&nbsp;</span><span v-if="checkGitHub">&nbsp;</span><span v-if="checkGitHub">&nbsp;</span>
					</a>
				</p>
			</md-card-content>
		</md-card>
		<div class="btn-submissions-row">
			<div v-if="this.userProfile.writtenSubmissionUrl">
				<md-button class="md-info" @click="viewWrittenSubmission"> view Written Submission </md-button>
			</div>
			<div v-if="this.userProfile.videoSubmissionUrl">
				<md-button class="md-info" @click="viewVideoSubmission"> View Video Submission </md-button>
			</div>
		</div>

		<view-academic-transcript
			v-if="isAcademicTranscript"
			@closeViewTranscriptModal="closeViewTranscriptModal"
			:currentProfile="this.userProfile"
			:academicTranscript="this.academicTranscript"
		></view-academic-transcript>
	</div>
</template>
<script>
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import ApplicantCommentsModal from '@/components/Modals/ApplicantCommentsModal.vue';
import TalentPoolCommentsModal from '@/components/Modals/TalentPoolCommentsModal.vue';
import ViewAcademicTranscript from '@/views/dashboard/client/jobs/applications/applicants/ViewAcademicTranscript.vue';
import { posthog } from '@/posthog/postHog.js';

export default {
	components: {
		ApplicantCommentsModal,
		ViewAcademicTranscript,
		TalentPoolCommentsModal,
	},
	props: {
		currentTalentPool: {
			type: Object,
			default: null,
		},
		userProfile: {
			type: Object,
		},
		favourite: {
			type: Boolean,
			default: false,
		},
		showFavoriteButton: {
			type: Boolean,
			default: false,
		},
		jobId: {
			type: String,
		},
		isTalentPool: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			cardUserImage: '/img/dashboard/client/card-1.jpg',
			profile: {},
			favouriteButtonText: '',
			user: this.userProfile.alias,
			commentsModal: false,
			talentCommentsModal: false,
			applicationId: '',
			isAcademicTranscript: false,
			academicTranscript: null,
			jobData: null,
		};
	},
	methods: {
		async fetchJobData() {
			const jobSnapshot = await db.collection('jobs').where('jobId', '==', this.jobId).get();
			if (!jobSnapshot.empty) {
				this.jobData = jobSnapshot.docs[0].data();
			} else {
				throw new Error('Job not found.');
			}
		},
		closeViewTranscriptModal() {
			this.isAcademicTranscript = false;
		},
		viewAcademicTranscript(value) {
			if (value) {
				this.academicTranscript = value;
				this.isAcademicTranscript = true;
			}
		},
		viewVideoSubmission() {
			this.$emit('profileViewVideoSubmission', this.userProfile);
		},
		viewWrittenSubmission() {
			this.$emit('profileViewWrittenSubmission', this.userProfile);
		},
		checkBtnFavourite() {
			return this.userProfile.favourite ? this.userProfile.favourite : this.userProfile.isTalentPoolFavourite;
		},
		showCommentsModal() {
			if (this.isTalentPool === '') {
				this.commentsModal = true;
			} else {
				this.talentCommentsModal = true;
			}
		},
		closeCommentsModal(payload) {
			this.commentsModal = payload;
		},
		closeTalentPoolCommentsModal(payload) {
			this.talentCommentsModal = payload;
		},
		async loadProfile(alias) {
			const studentDocRef = db.collection('students').doc(alias);
			const usersDocRef = db.collection('users').doc(alias);

			const [studentDoc, usersDoc, qualificationsSnapShot, experiencesSnapShot] = await Promise.all([
				studentDocRef.get(),
				usersDocRef.get(),
				studentDocRef.collection('qualifications').get(),
				studentDocRef.collection('experiences').get(),
			]);

			const student = {
				...studentDoc.data(),
				name: usersDoc.data().name,
				surname: usersDoc.data().surname,
				email: usersDoc.data().email,
				phone: usersDoc.data().phone,
				qualifications: qualificationsSnapShot.docs.map(doc => doc.data()),
				experiences: experiencesSnapShot.docs.map(doc => doc.data()),
			};
			return student;
		},
		async setFavourite() {
			this.$emit('setProfileFavourite', this.userProfile);
		},
		checkFavourite() {
			const favourite = this.userProfile.favourite ? this.userProfile.favourite : this.userProfile.isTalentPoolFavourite;
			if (favourite) {
				this.favouriteButtonText = 'Unfavourite Candidate';
			} else {
				this.favouriteButtonText = 'Favourite Candidate';
			}
		},
	},
	computed: {
		checkProfile() {
			return this.checkLinkedIn || this.checkGitHub;
		},
		checkLinkedIn() {
			return this.profile.linkedIn && this.profile.linkedIn !== '';
		},
		checkGitHub() {
			return this.profile.github && this.profile.github !== '';
		},
	},
	async created() {
		try {
			this.profile = await this.loadProfile(this.userProfile.alias);

			if (this.jobId !== undefined) {
				await this.fetchJobData();
				posthog.capture('view_candidate_profile', {
					employerName: this.jobData.clientName,
					jobName: this.jobData.name,
					jobId: this.jobData.jobId,
				});
			}
			this.checkFavourite();
		} catch (error) {
			console.error('Error:', error);
		}
	},
};
</script>
<style scoped>
.btn-submissions .md-button {
	width: 200px !important;
}
.btn-favourite {
	background-color: green;
}
.btn-unfavourite {
	background-color: red;
}
.image_container {
	width: 100%;
	height: 80px;
}
.image_container > img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	border-radius: 50vw;
}
.row {
	width: 100%;
	display: flex;
	text-align: left;
}

.row .label {
	margin-right: 10px;
	font-weight: bold;
}

.row.attachment {
	align-items: center;
	justify-content: center;
	gap: 100px;
}

.md-card {
	padding: 1rem;
}

.attachment_button {
	width: 100px;
	background-color: #61b9d3;
	border-radius: 50vw;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
}

a {
	color: white !important;
}

.heading-row {
	display: flex;
	align-items: center;
	margin: 3rem 0 1rem 0;
}

.md-icon.profile {
	margin: 0 !important;
	font-size: 40px !important;
	margin-right: 2rem !important;
	color: rgb(255, 139, 1) !important;
}

ul {
	margin-left: 2rem;
	text-align: left;
}
ul > li {
	font-size: 14px;
	text-align: left;
}

.btn-submissions-row {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.comment-icon-container {
	position: absolute;
	top: 30px;
	left: 100px;
	width: fit-content;
}
.star-icon {
	font-size: 35px !important;
}

.yellow-star {
	color: yellow !important;
}

.yellow-star,
.star-icon:hover {
	color: rgb(214, 214, 13) !important;
	cursor: pointer;
	transition: none;
}

.favorite-button {
	position: absolute;
	top: 30px;
	left: 50px;
	width: fit-content;
}
</style>
