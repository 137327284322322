var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "text-center lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "md-table",
        { attrs: { "md-card": "" } },
        [
          _c(
            "md-table-toolbar",
            [
              _c(
                "div",
                { staticClass: "w-full flex items-center justify-between" },
                [
                  _c("h1", { staticClass: "md-title" }, [
                    _vm._v("Bursary Applicants"),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          staticStyle: { "margin-right": "1rem" },
                          on: { click: _vm.selectForBursaryBulk },
                        },
                        [_vm._v(" Select Applicants ")]
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass: "filter-btn",
                          on: { click: _vm.openFilterMenu },
                        },
                        [_c("md-icon", [_vm._v("filter_alt")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "md-field",
                { staticClass: "filter-field" },
                [
                  _c("label", [_vm._v("Search Applicants")]),
                  _c("md-input", {
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "md-table-row",
            [
              _c(
                "md-table-head",
                [
                  _c("md-checkbox", {
                    on: { change: _vm.toggleSelectAll },
                    model: {
                      value: _vm.selectAll,
                      callback: function ($$v) {
                        _vm.selectAll = $$v
                      },
                      expression: "selectAll",
                    },
                  }),
                ],
                1
              ),
              _c("md-table-head", [_vm._v("Name")]),
              _c("md-table-head", [_vm._v("Surname")]),
              _c("md-table-head", [_vm._v("Year of Study")]),
              _c("md-table-head", [_vm._v("Degree To Be Funded")]),
              _c("md-table-head", [_vm._v("Status")]),
              _c("md-table-head"),
              _c("md-table-head"),
              _c("md-table-head"),
            ],
            1
          ),
          _vm._l(_vm.filteredApplicants, function (applicant, index) {
            return _c(
              "md-table-row",
              { key: index },
              [
                _c(
                  "md-table-cell",
                  [
                    _c("md-checkbox", {
                      attrs: { value: applicant.id },
                      model: {
                        value: _vm.selectedBursaryApplicants,
                        callback: function ($$v) {
                          _vm.selectedBursaryApplicants = $$v
                        },
                        expression: "selectedBursaryApplicants",
                      },
                    }),
                  ],
                  1
                ),
                _c("md-table-cell", [_vm._v(_vm._s(applicant.name))]),
                _c("md-table-cell", [_vm._v(_vm._s(applicant.surname))]),
                _c("md-table-cell", [
                  _vm._v(_vm._s(applicant.selectedDegree.yearOfStudy)),
                ]),
                _c("md-table-cell", [
                  _vm._v(_vm._s(applicant.selectedDegree.degree)),
                ]),
                _c("md-table-cell", [_vm._v(_vm._s(applicant.status))]),
                _c(
                  "md-table-cell",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.openUserProfile(applicant)
                          },
                        },
                      },
                      [_vm._v("View Profile")]
                    ),
                  ],
                  1
                ),
                _c(
                  "md-table-cell",
                  { staticStyle: { "text-align": "left" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "select-bursary-btn filter-btn",
                        on: {
                          click: function ($event) {
                            return _vm.selectForBursary(applicant)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              applicant.status === "Selected"
                                ? "Unselect"
                                : "Select for Bursary"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("md-table-cell", [
                  _c(
                    "div",
                    {
                      staticClass: "favourite-candidate",
                      on: {
                        click: function ($event) {
                          return _vm.toggleFavourite(applicant)
                        },
                      },
                    },
                    [
                      _c(
                        "md-icon",
                        {
                          style: {
                            color: applicant.favourite ? "orange" : "lightgrey",
                          },
                        },
                        [_vm._v("star")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "md-table-cell",
                  { staticStyle: { "text-align": "right !important" } },
                  [
                    _c(
                      "md-menu",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-icon-button",
                            attrs: { "md-menu-trigger": "" },
                          },
                          [_c("md-icon", [_vm._v("more_vert")])],
                          1
                        ),
                        _c(
                          "md-menu-content",
                          [
                            _c(
                              "md-menu-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDocuments(applicant)
                                  },
                                },
                              },
                              [_vm._v("View Required Documents")]
                            ),
                            applicant.additionalSubmissions
                              ? _c(
                                  "md-menu-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewVideoSubmissions(
                                          applicant
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("View Video Submissions")]
                                )
                              : _vm._e(),
                            applicant.additionalSubmissions
                              ? _c(
                                  "md-menu-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewWrittenSubmission(
                                          applicant
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("View Written Submissions")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("BursaryApplicantFilter", {
        attrs: {
          visible: _vm.filterMenuVisible,
          filters: _vm.filters,
          uniqueValues: _vm.uniqueValues,
        },
        on: {
          close: _vm.closeFilterMenu,
          apply: _vm.applyFilters,
          reset: _vm.resetFilters,
        },
      }),
      _vm.profileModalVisible
        ? _c(
            "modal",
            { on: { close: _vm.profileModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.profileModalHide },
                    },
                    [
                      _c(
                        "md-icon",
                        {
                          staticStyle: {
                            "font-size": "2rem !important",
                            color: "black !important",
                          },
                        },
                        [_vm._v("clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("CandidateProfile", {
                    attrs: {
                      userProfile: _vm.selectedApplicant,
                      showFavoriteButton: true,
                      jobId: this.$route.params.id,
                    },
                  }),
                ],
                1
              ),
              _c("template", { slot: "footer" }),
            ],
            2
          )
        : _vm._e(),
      _vm.isViewDocumentsOpened
        ? _c(
            "div",
            [
              _c("PdfViewer", {
                attrs: { documents: _vm.selectedApplicant.submittedDocuments },
                on: { closeDocumentViewer: _vm.closeDocumentViewer },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isViewVideoOpened
        ? _c(
            "div",
            [
              _c("VideoViewer", {
                attrs: { videoLink: _vm.videoLink },
                on: { closeViedoViewer: _vm.closeViedoViewer },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isViewWrittenSubmissionOpened
        ? _c(
            "div",
            [
              _c("PdfViewer", {
                attrs: { documents: _vm.writtenSubmissionLink },
                on: { closeDocumentViewer: _vm.closeDocumentViewer },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.error
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! 🤚"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeErrorModal },
                        },
                        [_vm._v(" Ok ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3042895156
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }