var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v("Let's start with the basic information about the job"),
    ]),
    _c(
      "div",
      { staticClass: "padding" },
      [
        _c(
          "md-field",
          {
            class: [
              { "md-valid": !_vm.errors.has("name") && _vm.touched.name },
              { "md-error": _vm.errors.has("name") },
            ],
          },
          [
            _c("label", [_vm._v("Name")]),
            _c("md-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.name,
                  expression: "modelValidations.name",
                },
              ],
              attrs: {
                "data-vv-name": "name",
                type: "text",
                name: "name",
                required: "",
              },
              on: { change: _vm.addName },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("name"),
                        expression: "errors.has('name')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.errors.has("name") && _vm.touched.name,
                        expression: "!errors.has('name') && touched.name",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "md-field",
          {
            class: [
              {
                "md-valid":
                  !_vm.errors.has("description") && _vm.touched.description,
              },
              { "md-error": _vm.errors.has("description") },
            ],
          },
          [
            _c("label", [_vm._v("Description")]),
            _c("md-textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.description,
                  expression: "modelValidations.description",
                },
              ],
              attrs: {
                "data-vv-name": "description",
                type: "text",
                name: "description",
                required: "",
              },
              on: { change: _vm.addDescription },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("description"),
                        expression: "errors.has('description')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("description") &&
                          _vm.touched.description,
                        expression:
                          "!errors.has('description') && touched.description",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              {
                "md-valid": !_vm.errors.has("category") && _vm.touched.category,
              },
              { "md-error": _vm.errors.has("category") },
            ],
          },
          [
            _c("label", [_vm._v("Task/Project")]),
            _c(
              "md-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.category,
                    expression: "modelValidations.category",
                  },
                ],
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  "data-vv-name": "category",
                  type: "text",
                  name: "category",
                  required: "",
                },
                on: { input: _vm.addCategory },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              },
              _vm._l(_vm.skillCategories, function (category, index) {
                return _c(
                  "md-option",
                  { key: index, attrs: { value: category } },
                  [_vm._v(_vm._s(category))]
                )
              }),
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("category"),
                        expression: "errors.has('category')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("category") && _vm.touched.category,
                        expression:
                          "!errors.has('category') && touched.category",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              { "md-valid": !_vm.errors.has("skills") && _vm.touched.skills },
              { "md-error": _vm.errors.has("skills") },
            ],
          },
          [
            _c("label", [_vm._v("Required Skills *")]),
            _c(
              "md-chips",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.skills,
                    expression: "modelValidations.skills",
                  },
                ],
                attrs: {
                  "data-vv-name": "skills",
                  type: "text",
                  name: "skills",
                  required: "",
                },
                on: { input: _vm.addSkills },
                model: {
                  value: _vm.skills,
                  callback: function ($$v) {
                    _vm.skills = $$v
                  },
                  expression: "skills",
                },
              },
              [
                _c("div", { staticClass: "md-helper-text" }, [
                  _vm._v("Press enter to add a skill"),
                ]),
              ]
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("skills"),
                        expression: "errors.has('skills')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.errors.has("skills") && _vm.touched.skills,
                        expression: "!errors.has('skills') && touched.skills",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }