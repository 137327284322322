var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "logo-position lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "md-card",
        { staticStyle: { width: "80%", margin: "auto" } },
        [
          _c("md-card-header", [
            _c("h3", { staticClass: "title" }, [
              _vm._v("What Degree Do You Want To Be Funded?"),
            ]),
          ]),
          _c("md-card-content", [
            Object.keys(_vm.selectedDegree).length > 0
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "w-full flex items-center justify-center" },
                    [
                      _c(
                        "md-card",
                        {
                          staticClass: "education-card",
                          staticStyle: { width: "70%" },
                        },
                        [
                          _c("md-card-content", [
                            _c(
                              "div",
                              { staticClass: "education-card-content" },
                              [
                                _c("div", [
                                  _c("h3", { staticClass: "title-degree" }, [
                                    _vm._v(_vm._s(_vm.selectedDegree.degree)),
                                  ]),
                                  _c("p", { staticClass: "sub-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.selectedDegree.university)
                                    ),
                                  ]),
                                  _c("p", { staticClass: "sub-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.selectedDegree.yearOfStudy)
                                    ),
                                  ]),
                                  _c("p", { staticClass: "sub-text" }, [
                                    _vm._v(_vm._s(_vm.selectedDegree.faculty)),
                                  ]),
                                  _c("p", { staticClass: "sub-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedDegree.qualificationType
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "action-items-container" },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-icon-button edit",
                                        on: { click: _vm.editDegree },
                                      },
                                      [
                                        _c("md-icon", [_vm._v("edit")]),
                                        _c(
                                          "md-tooltip",
                                          {
                                            attrs: { "md-direction": "bottom" },
                                          },
                                          [_vm._v("View")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-icon-button delete",
                                        on: { click: _vm.deleteDegree },
                                      },
                                      [
                                        _c("md-icon", [_vm._v("delete")]),
                                        _c(
                                          "md-tooltip",
                                          {
                                            attrs: { "md-direction": "bottom" },
                                          },
                                          [_vm._v("Delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "w-full flex items-center justify-center" },
              [
                _c(
                  "md-button",
                  { staticClass: "jb-standard", on: { click: _vm.addStudies } },
                  [_vm._v("Add Degree")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.showAddStudies
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "font-size": "2rem" } }, [
                  _vm._v("🎓 Add Degree"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c(
                          "md-icon",
                          { staticStyle: { "margin-right": "10px" } },
                          [_vm._v("school")]
                        ),
                        _c("label", { attrs: { for: "isStudying" } }, [
                          _vm._v("Are you currently studying this degree?"),
                        ]),
                        _c(
                          "md-select",
                          {
                            attrs: {
                              name: "isStudying",
                              id: "isStudying",
                              placeholder:
                                "Are you currently studying this degree?",
                            },
                            model: {
                              value: _vm.isStudying,
                              callback: function ($$v) {
                                _vm.isStudying = $$v
                              },
                              expression: "isStudying",
                            },
                          },
                          [
                            _c("md-option", { attrs: { value: "Yes" } }, [
                              _vm._v("Yes"),
                            ]),
                            _c("md-option", { attrs: { value: "No" } }, [
                              _vm._v("No"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isStudying
                      ? _c(
                          "md-field",
                          [
                            _c(
                              "md-icon",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v("school")]
                            ),
                            _c("label", { attrs: { for: "university" } }, [
                              _vm._v("University"),
                            ]),
                            _c(
                              "md-select",
                              {
                                attrs: {
                                  name: "university",
                                  id: "university",
                                  placeholder: "University",
                                },
                                model: {
                                  value: _vm.university,
                                  callback: function ($$v) {
                                    _vm.university = $$v
                                  },
                                  expression: "university",
                                },
                              },
                              _vm._l(
                                _vm.universityNames,
                                function (university, index) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: index,
                                      attrs: { value: university },
                                    },
                                    [_vm._v(_vm._s(university))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.university
                      ? _c(
                          "md-field",
                          [
                            _c(
                              "md-icon",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v("school")]
                            ),
                            _c("label", { attrs: { for: "faculty" } }, [
                              _vm._v("Faculty"),
                            ]),
                            _c(
                              "md-select",
                              {
                                attrs: {
                                  name: "faculty",
                                  id: "faculty",
                                  placeholder: "Faculty",
                                },
                                model: {
                                  value: _vm.faculty,
                                  callback: function ($$v) {
                                    _vm.faculty = $$v
                                  },
                                  expression: "faculty",
                                },
                              },
                              _vm._l(
                                _vm.filteredFaculties,
                                function (faculty, index) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: index,
                                      attrs: { value: faculty.name },
                                    },
                                    [_vm._v(_vm._s(faculty.name))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.faculty
                      ? _c(
                          "md-field",
                          [
                            _c(
                              "md-icon",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v("school")]
                            ),
                            _c(
                              "label",
                              { attrs: { for: "qualificationType" } },
                              [_vm._v("Qualification Type")]
                            ),
                            _c(
                              "md-select",
                              {
                                attrs: {
                                  name: "qualificationType",
                                  id: "qualificationType",
                                  placeholder: "Qualification Type",
                                },
                                model: {
                                  value: _vm.qualificationType,
                                  callback: function ($$v) {
                                    _vm.qualificationType = $$v
                                  },
                                  expression: "qualificationType",
                                },
                              },
                              _vm._l(
                                _vm.filteredQualifications,
                                function (qualification, index) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: index,
                                      attrs: { value: qualification.name },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(qualification.name) +
                                          " (" +
                                          _vm._s(qualification.qualification) +
                                          ") "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.qualificationType
                      ? _c(
                          "md-field",
                          [
                            _c(
                              "md-icon",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v("school")]
                            ),
                            _c("label", { attrs: { for: "degree" } }, [
                              _vm._v("Degree"),
                            ]),
                            _c(
                              "md-select",
                              {
                                attrs: {
                                  name: "degree",
                                  id: "degree",
                                  placeholder: "Degree",
                                },
                                model: {
                                  value: _vm.degree,
                                  callback: function ($$v) {
                                    _vm.degree = $$v
                                  },
                                  expression: "degree",
                                },
                              },
                              _vm._l(
                                _vm.filteredDegrees,
                                function (degree, index) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: index,
                                      attrs: { value: degree.name },
                                    },
                                    [_vm._v(_vm._s(degree.name))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.degree
                      ? _c(
                          "md-field",
                          [
                            _c(
                              "md-icon",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v("school")]
                            ),
                            _c("label", { attrs: { for: "yearOfStudy" } }, [
                              _vm._v("Year of Study to be Funded"),
                            ]),
                            _c(
                              "md-select",
                              {
                                attrs: {
                                  name: "yearOfStudy",
                                  id: "yearOfStudy",
                                  placeholder: "Year of Study to be Funded",
                                },
                                model: {
                                  value: _vm.yearOfStudy,
                                  callback: function ($$v) {
                                    _vm.yearOfStudy = $$v
                                  },
                                  expression: "yearOfStudy",
                                },
                              },
                              _vm._l(_vm.years, function (year, index) {
                                return _c(
                                  "md-option",
                                  { key: index, attrs: { value: year } },
                                  [_vm._v(_vm._s(year))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-button md-success",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(" Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }