var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("hr"),
      _vm.available
        ? _c(
            "div",
            { staticClass: "md-layout" },
            [
              _c(
                "md-card",
                { staticClass: "padding" },
                [
                  _c(
                    "md-table",
                    {
                      attrs: { "md-card": "" },
                      on: { "md-selected": _vm.onSelect },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "md-table-row",
                            fn: function (ref) {
                              var item = ref.item
                              return _c(
                                "md-table-row",
                                { attrs: { "md-selectable": "multiple" } },
                                [
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "First Name",
                                        "md-sort-by": "profile.name",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.profile.name))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Surname",
                                        "md-sort-by": "profile.surname",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.profile.surname))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Year of Study" } },
                                    [_vm._v(_vm._s(item.selectDegree.year))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Degree" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.selectDegree.degree)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Status" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: _vm.getChipClass(
                                            item.applicationStatus
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.applicationStatus) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          staticClass: "view_profile",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onViewProfile(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" View Profile ")]
                                      ),
                                      _c(
                                        "md-button",
                                        {
                                          staticClass:
                                            "btn-next md-info button",
                                          staticStyle: {
                                            "margin-left": "1rem",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSendOffer(item)
                                            },
                                          },
                                        },
                                        [_vm._v("Send Offer")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("md-table-cell", [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.setFavourite(item)
                                          },
                                        },
                                      },
                                      [
                                        item.favourite
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "md-icon",
                                                  {
                                                    staticStyle: {
                                                      color: "#e2c800",
                                                    },
                                                  },
                                                  [_vm._v("star")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.favourite
                                          ? _c(
                                              "div",
                                              [
                                                _c("md-icon", [
                                                  _vm._v("star_border"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1911734863
                      ),
                      model: {
                        value: _vm.queriedData,
                        callback: function ($$v) {
                          _vm.queriedData = $$v
                        },
                        expression: "queriedData",
                      },
                    },
                    [
                      _c("md-table-toolbar", [
                        _c("h1", { staticClass: "md-title" }, [
                          _vm._v("Applicants"),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "md-field",
                              [
                                _c("md-input", {
                                  staticClass: "mb-3",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    type: "search",
                                    clearable: "",
                                    placeholder: "Search",
                                  },
                                  model: {
                                    value: _vm.searchData,
                                    callback: function ($$v) {
                                      _vm.searchData = $$v
                                    },
                                    expression: "searchData",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-info button",
                                on: {
                                  click: function ($event) {
                                    return _vm.filterDetails()
                                  },
                                },
                              },
                              [_vm._v("Filter")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.profileModalVisible
        ? _c(
            "modal",
            { on: { close: _vm.profileModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.profileModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("CandidateProfile", {
                    attrs: { alias: _vm.currentProfile.alias },
                  }),
                ],
                1
              ),
              _c("template", { slot: "footer" }),
            ],
            2
          )
        : _vm._e(),
      _c("send-offer-modal", {
        attrs: {
          visible: _vm.showSendOfferModal,
          employerCompany: _vm.employerCompany,
          industry: _vm.industry,
          companySize: _vm.companySize,
          jobName: _vm.jobName,
          jobId: _vm.jobId,
          jobType: _vm.jobType,
          profile: _vm.currentProfile,
        },
        on: {
          "close-send-offer": _vm.hideSendOfferModal,
          onOfferSent: _vm.afterOfferSent,
        },
      }),
      _c("invite-modal", {
        attrs: {
          getShowModalAction: this.getShowModalAction,
          selected: _vm.selected,
          employerEmail: _vm.employerEmail,
          employerName: _vm.employerName,
          employerCompany: _vm.employerCompany,
          jobName: _vm.jobName,
          jobId: _vm.jobId,
          jobType: _vm.jobType,
          industry: _vm.industry,
          companySize: _vm.companySize,
        },
        on: { refresh: this.refresh },
      }),
      !_vm.available && !_vm.approved
        ? _c("div", [
            _c("br"),
            _c("br"),
            _c("h2", { staticClass: "black centre" }, [
              _vm._v(_vm._s(_vm.feedback)),
            ]),
          ])
        : _vm._e(),
      _vm.showFilter
        ? _c(
            "div",
            [
              _c("application-filter-selection", {
                attrs: {
                  userGender: this.gender,
                  userFavourite:
                    this.favourite === true
                      ? "yes"
                      : this.favourite === false
                      ? "no"
                      : null,
                  userQualification: this.qualification,
                  userAgeRange: this.ageRange,
                  userEthnicity: this.ethnicity,
                  universities: this.institutions,
                },
                on: {
                  closeModal: function (x) {
                    return (_vm.showFilter = x)
                  },
                  setFilterFavourite: _vm.setFilterFavourite,
                  setQualification: _vm.setQualification,
                  setGender: _vm.setGender,
                  setUniversities: _vm.setUniversities,
                  setExtracirricularActivities:
                    _vm.setExtracirricularActivities,
                  setApplicationStatus: _vm.setApplicationStatus,
                  setEthnicity: _vm.setEthnicity,
                  setAge: _vm.setAge,
                  resetFilters: _vm.resetFilters,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }