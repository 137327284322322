import { render, staticRenderFns } from "./ClientAccount.vue?vue&type=template&id=30598bc0&scoped=true&"
import script from "./ClientAccount.vue?vue&type=script&lang=js&"
export * from "./ClientAccount.vue?vue&type=script&lang=js&"
import style0 from "./ClientAccount.vue?vue&type=style&index=0&id=30598bc0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30598bc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/jobox-platform/jobox-platform/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30598bc0')) {
      api.createRecord('30598bc0', component.options)
    } else {
      api.reload('30598bc0', component.options)
    }
    module.hot.accept("./ClientAccount.vue?vue&type=template&id=30598bc0&scoped=true&", function () {
      api.rerender('30598bc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/register/client/ClientAccount.vue"
export default component.exports