var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "md-layout text-center",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.login({ email: _vm.email, password: _vm.password })
        },
      },
    },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100",
        },
        [
          _c(
            "login-card",
            { attrs: { "header-color": "green" } },
            [
              _c(
                "h3",
                {
                  staticClass: "title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v("Login")]
              ),
              _c(
                "md-field",
                {
                  staticClass: "md-form-group",
                  class: [
                    {
                      "md-valid": !_vm.errors.has("email") && _vm.touched.email,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("email") },
                  ],
                  attrs: { slot: "inputs" },
                  slot: "inputs",
                },
                [
                  _c("md-icon", [_vm._v("email")]),
                  _c("label", [_vm._v("Email")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.email,
                        expression: "modelValidations.email",
                      },
                    ],
                    attrs: {
                      type: "email",
                      "data-vv-name": "email",
                      name: "email",
                      required: "",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("email"),
                              expression: "errors.has('email')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("email") && _vm.touched.email,
                              expression:
                                "!errors.has('email') && touched.email",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  staticClass: "md-form-group",
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("password") && _vm.touched.password,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("password") },
                  ],
                  attrs: { slot: "inputs" },
                  slot: "inputs",
                },
                [
                  _c("md-icon", [_vm._v("lock_outline")]),
                  _c("label", [_vm._v("Password")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.password,
                        expression: "modelValidations.password",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "password",
                      name: "password",
                      type: "password",
                      required: "",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("password"),
                              expression: "errors.has('password')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("password") &&
                                _vm.touched.password,
                              expression:
                                "!errors.has('password') && touched.password",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "md-button jb-standard",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("div", { staticClass: "md-ripple" }, [
                    _c("div", { staticClass: "md-button-content" }, [
                      _vm._v("Let's go"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "router-link",
                {
                  staticStyle: { color: "blue" },
                  attrs: { slot: "hyperlink", to: "/forgot-password" },
                  slot: "hyperlink",
                },
                [_vm._v(" Forgot Password? ")]
              ),
              _c(
                "router-link",
                {
                  staticStyle: {
                    color: "#0000ff",
                    display: "block",
                    "margin-top": "10px",
                    "font-size": "12px",
                  },
                  attrs: { slot: "hyperlink", to: "/register" },
                  slot: "hyperlink",
                },
                [_vm._v("Don't have an account? Register here")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [_vm._v(_vm._s(_vm.body))]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v(_vm._s(_vm.footer))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      _c("div", { staticClass: "loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }