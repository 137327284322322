<template>
	<apexchart :width="width" :height="height" type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		width: {
			type: [String, Number],
		},
		height: {
			type: [String, Number],
		},
		options: {
			type: Object,
			required: true,
		},
		series: {
			type: Array,
			required: true,
		},
	},
};
</script>
