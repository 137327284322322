var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SelectField", {
        attrs: {
          label: "Would you like a video submission?",
          options: ["Yes", "No"],
          error: _vm.getErrorMessage("videoSubmission"),
        },
        on: { input: _vm.addVideoSubmission },
        model: {
          value: _vm.videoSubmission,
          callback: function ($$v) {
            _vm.videoSubmission = $$v
          },
          expression: "videoSubmission",
        },
      }),
      _vm.videoSubmission === "Yes"
        ? _c(
            "div",
            [
              _c(
                "label",
                {
                  class:
                    _vm.getErrorMessage("videoSubmissionDetails") &&
                    "error_message",
                },
                [_vm._v("Video Submission Details")]
              ),
              _c("vue-editor", {
                staticStyle: { margin: "1rem 0 3rem 0 !important" },
                on: { "text-change": _vm.addVideoSubmissionDetails },
                model: {
                  value: _vm.videoSubmissionDetails,
                  callback: function ($$v) {
                    _vm.videoSubmissionDetails = $$v
                  },
                  expression: "videoSubmissionDetails",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("SelectField", {
        attrs: {
          label: "Would you like a written submission?",
          options: ["Yes", "No"],
          error: _vm.getErrorMessage("writtenSubmission"),
        },
        on: { input: _vm.addWrittenSubmission },
        model: {
          value: _vm.writtenSubmission,
          callback: function ($$v) {
            _vm.writtenSubmission = $$v
          },
          expression: "writtenSubmission",
        },
      }),
      _vm.writtenSubmission === "Yes"
        ? _c(
            "div",
            [
              _c(
                "label",
                {
                  class:
                    _vm.getErrorMessage("writtenSubmissionDetails") &&
                    "error_message",
                },
                [_vm._v("Written Submission Details")]
              ),
              _c("vue-editor", {
                staticStyle: { margin: "1rem 0 3rem 0 !important" },
                on: { "text-change": _vm.addWrittenSubmissionDetails },
                model: {
                  value: _vm.writtenSubmissionDetails,
                  callback: function ($$v) {
                    _vm.writtenSubmissionDetails = $$v
                  },
                  expression: "writtenSubmissionDetails",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "justify-between" },
        [
          _c(
            "md-button",
            { staticClass: "md-raised", on: { click: _vm.previousStep } },
            [_c("md-icon", [_vm._v("arrow_back")]), _vm._v("Previous")],
            1
          ),
          _c(
            "md-button",
            {
              staticClass: "md-raised md-primary",
              on: { click: _vm.nextStep },
            },
            [_vm._v("Next"), _c("md-icon", [_vm._v("arrow_forward")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }