var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.confirmChoices.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "h1",
              {
                staticClass: "md-headline",
                staticStyle: { "font-size": "14px", "text-align": "center" },
              },
              [
                _vm._v(
                  "Select the three companies you would love to work for in the future"
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "company-input" },
              [
                _c(
                  "md-field",
                  { staticStyle: { "margin-right": "30px" } },
                  [
                    _c("label", [_vm._v("What is your first choice company?")]),
                    _c("md-input", {
                      attrs: { type: "search" },
                      on: { click: _vm.openFirstCompanySelection },
                      model: {
                        value: _vm.firstChoice,
                        callback: function ($$v) {
                          _vm.firstChoice = $$v
                        },
                        expression: "firstChoice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-success",
                    on: { click: _vm.openFirstCompanySelection },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(this.firstChoice != null ? "Edit" : "Add")
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "company-input" },
              [
                _c(
                  "md-field",
                  { staticStyle: { "margin-right": "30px" } },
                  [
                    _c("label", [
                      _vm._v("What is your second choice company?"),
                    ]),
                    _c("md-input", {
                      attrs: { type: "search" },
                      on: { click: _vm.openSecondCompanySelection },
                      model: {
                        value: _vm.secondChoice,
                        callback: function ($$v) {
                          _vm.secondChoice = $$v
                        },
                        expression: "secondChoice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-success",
                    on: { click: _vm.openSecondCompanySelection },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(this.secondChoice != null ? "Edit" : "Add")
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "company-input" },
              [
                _c(
                  "md-field",
                  { staticStyle: { "margin-right": "30px" } },
                  [
                    _c("label", [_vm._v("What is your third choice company?")]),
                    _c("md-input", {
                      attrs: { type: "search" },
                      on: { click: _vm.openThirdCompanySelection },
                      model: {
                        value: _vm.thirdChoice,
                        callback: function ($$v) {
                          _vm.thirdChoice = $$v
                        },
                        expression: "thirdChoice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-success",
                    on: { click: _vm.openThirdCompanySelection },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(this.thirdChoice != null ? "Edit" : "Add")
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "department-input" },
              [
                _c(
                  "md-field",
                  { staticStyle: { "margin-right": "30px" } },
                  [
                    _c("label", [
                      _vm._v(
                        " Lastly, what departments do you want to work in? "
                      ),
                      _c("br"),
                      _vm._v(
                        " Select a minimum of 1 department and a maximum of 3. "
                      ),
                    ]),
                    _vm.v$.department.$error
                      ? _c(
                          "div",
                          {
                            staticClass: "error-message",
                            staticStyle: { "margin-top": "60px", width: "70%" },
                          },
                          [_vm._v("Select your preferred departments")]
                        )
                      : _vm._e(),
                    _c("md-table", {
                      staticClass: "department-tble",
                      attrs: { "md-card": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "md-table-row",
                          fn: function (ref) {
                            var item = ref.item
                            return _c(
                              "md-table-row",
                              {},
                              [
                                _c("md-table-cell", [
                                  _vm._v(_vm._s(item.department)),
                                ]),
                              ],
                              1
                            )
                          },
                        },
                      ]),
                      model: {
                        value: _vm.department,
                        callback: function ($$v) {
                          _vm.department = $$v
                        },
                        expression: "department",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "department-input-btn" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: { click: _vm.openDepartmentSelection },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.department.length > 0 ? "Edit" : "Add"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.openFirstCompanyModal
        ? _c("preffered-companies-seletion", {
            attrs: {
              modalHeading: "Select your first company",
              openCompanyModal: _vm.openFirstCompanyModal,
            },
            on: {
              cancelSelection: _vm.cancelFirstSelection,
              companySelection: _vm.firstCompanySelection,
            },
          })
        : _vm._e(),
      _vm.openSecondCompanyModal
        ? _c("preffered-companies-seletion", {
            attrs: {
              modalHeading: "Select your second company",
              openCompanyModal: _vm.openSecondCompanyModal,
            },
            on: {
              cancelSelection: _vm.cancelSecondSelection,
              companySelection: _vm.secondCompanySelection,
            },
          })
        : _vm._e(),
      _vm.openThirdCompanyModal
        ? _c("preffered-companies-seletion", {
            attrs: {
              modalHeading: "Select your third company",
              openCompanyModal: _vm.openThirdCompanyModal,
            },
            on: {
              cancelSelection: _vm.cancelThirdSelection,
              companySelection: _vm.thirdCompanySelection,
            },
          })
        : _vm._e(),
      _vm.openDepartmentModal
        ? _c("department-selection", {
            attrs: {
              companyDepartments: _vm.companyDepartments,
              openDepartmentModal: _vm.openDepartmentModal,
            },
            on: {
              cancelDepartmentSelection: _vm.cancelDepartmentSelection,
              departmentSelection: _vm.departmentSelection,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }