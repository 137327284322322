var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-toolbar",
    {
      staticClass: "md-transparent",
      class: {
        "md-toolbar-absolute md-white md-fixed-top":
          _vm.$route.meta.navbarAbsolute,
      },
      attrs: { "md-elevation": "0", id: "top" },
    },
    [
      _c("div", { staticClass: "md-toolbar-row" }, [
        _c(
          "div",
          { staticClass: "md-toolbar-section-end" },
          [
            _c(
              "md-button",
              {
                staticClass:
                  "md-just-icon md-round md-simple md-toolbar-toggle",
                class: { toggled: _vm.$sidebar.showSidebar },
                on: { click: _vm.toggleSidebar },
              },
              [
                _c("span", { staticClass: "icon-bar" }),
                _c("span", { staticClass: "icon-bar" }),
                _c("span", { staticClass: "icon-bar" }),
              ]
            ),
            _c(
              "div",
              { staticClass: "md-collapse" },
              [
                _c("md-list", [
                  _c("li", { staticClass: "md-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "md-list-item-router md-list-item-container md-button-clean dropdown",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "md-list-item-content" },
                          [
                            _c(
                              "drop-down",
                              { attrs: { direction: "down" } },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-button md-just-icon md-simple",
                                    attrs: {
                                      slot: "title",
                                      "data-toggle": "dropdown",
                                    },
                                    slot: "title",
                                  },
                                  [_c("md-icon", [_vm._v("person")])],
                                  1
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "dropdown-menu dropdown-menu-right",
                                  },
                                  [
                                    _c("li", [
                                      _c("a", { on: { click: _vm.logout } }, [
                                        _vm._v("Logout"),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }