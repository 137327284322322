var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("questions-taker-vue", {
        on: { bypassRoutingCheck: _vm.bypassRoutingCheck },
      }),
      _vm.showModal
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! 🤚"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "button-container",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-button md-danger",
                              on: { click: _vm.leavePage },
                            },
                            [_vm._v(" Leave")]
                          ),
                          _vm._v("        "),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-button md-success",
                              on: { click: _vm.stayOnPage },
                            },
                            [_vm._v(" Stay")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2838557493
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }