var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
    _vm.loading
      ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
      : _vm._e(),
    _vm.jobs.length > 0
      ? _c(
          "div",
          [
            _c("AppliedJobsTable", {
              attrs: {
                data: _vm.jobs,
                columns: [
                  { label: "Company Name", key: "companyName" },
                  { label: "Job Name", key: "name" },
                  { label: "Status", key: "status" },
                ],
              },
            }),
          ],
          1
        )
      : _c("div", [
          _c("h1", { staticClass: "black centre" }, [
            _vm._v("You have no open applications"),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }