export function createChartOptions({ chartType, labels = [], categories = [], colors = [], horizontal = false, legend = null, dataLabelsEnabled = true, plotOptions = null }) {
	const options = {
		chart: {
			type: chartType,
			toolbar: {
				show: true,
				tools: {
					download: true,
				},
				export: {
					png: { filename: `${chartType}-chart` },
					svg: { filename: `${chartType}-chart` },
				},
			},
		},
		dataLabels: { enabled: dataLabelsEnabled },
	};

	// Donut charts use labels
	if (chartType === 'donut') {
		options.labels = labels;
	}
	// Bar charts use categories on the x-axis
	else if (chartType === 'bar') {
		options.xaxis = { categories };
		// If horizontal bars are needed
		if (horizontal) {
			options.plotOptions = { bar: { horizontal: true, dataLabels: { position: 'center' } } };
		} else if (plotOptions) {
			options.plotOptions = plotOptions;
		}
		// Optionally include legend settings
		if (legend) {
			options.legend = legend;
		}
	}

	if (colors.length > 0) {
		options.colors = colors;
	}

	return options;
}
