var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appointment-container" },
    [
      _vm.loading
        ? _c("div", {
            staticClass: "background",
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
            },
          })
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "text-center lds-circle",
              staticStyle: { position: "absolute", top: "50%", left: "50%" },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "appointment-content" },
        [
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: { click: _vm.hideAppointmentsModal },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "4rem" } },
            [
              _c("h3", [_vm._v(_vm._s(_vm.appointment.name))]),
              _c("weekday-selector", {
                attrs: {
                  allDaysOfWeek: [
                    "Sun",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                  ],
                  apiData: _vm.appointment,
                },
                on: { selectedDayOfTheWeek: _vm.selectedDayOfTheWeek },
              }),
              _c(
                "md-button",
                { staticClass: "md-success", on: { click: _vm.checkData } },
                [_vm._v(" Update Appointment ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.success
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.success = false
                },
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "h2",
                  {
                    staticClass: "modal-title",
                    staticStyle: { color: "green" },
                  },
                  [_vm._v("Success!")]
                ),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "Your appointment slot has been successfully updated."
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.hideModal },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }