var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "modal",
        { on: { close: _vm.hideModal } },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _c("h4", { staticClass: "modal-title black" }, [
                _vm._v(_vm._s(this.title)),
              ]),
              _c(
                "md-button",
                {
                  staticClass:
                    "md-simple md-just-icon md-round modal-default-button",
                  on: { click: _vm.hideModal },
                },
                [_c("md-icon", [_vm._v("clear")])],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "body" }, [
            _c("p", { staticClass: "black" }, [_vm._v(_vm._s(this.message))]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "md-button",
                  {
                    class: [
                      { "md-danger": !_vm.noPrimary },
                      { "md-info": _vm.noPrimary },
                    ],
                    on: { click: _vm.onNoClick },
                  },
                  [_vm._v(_vm._s(this.noLabel || "No"))]
                ),
                _vm._v("     "),
                _c(
                  "md-button",
                  { staticClass: "md-success", on: { click: _vm.onYesClick } },
                  [_vm._v(_vm._s(this.yesLabel || "Yes"))]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }