var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item mt-4 md-size-100" },
        [
          _c(
            "md-field",
            {
              class: [
                { "md-valid": !_vm.errors.has("budget") && _vm.touched.budget },
                { "md-form-group": true },
                { "md-error": _vm.errors.has("budget") },
              ],
            },
            [
              _c("md-icon", [_vm._v("face")]),
              _c("label", [_vm._v("Budget")]),
              _c("md-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.budget,
                    expression: "modelValidations.budget",
                  },
                ],
                attrs: {
                  "data-vv-name": "budget",
                  type: "number",
                  min: "10",
                  step: ".01",
                  name: "budget",
                  required: "",
                },
                on: { change: _vm.addBudget },
                model: {
                  value: _vm.budget,
                  callback: function ($$v) {
                    _vm.budget = $$v
                  },
                  expression: "budget",
                },
              }),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("budget"),
                          expression: "errors.has('budget')",
                        },
                      ],
                      staticClass: "error",
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.errors.has("budget") && _vm.touched.budget,
                          expression: "!errors.has('budget') && touched.budget",
                        },
                      ],
                      staticClass: "success",
                    },
                    [_vm._v("done")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", [
        _vm._v(" Jobox service fee (" + _vm._s(_vm.percentage()) + "%): "),
        _c("b", [_vm._v("R" + _vm._s(_vm.fee()))]),
        _c("br"),
        _vm._v(" Payment facilitation fee: "),
        _c("b", [_vm._v("R" + _vm._s(_vm.price.facilitationFee) + ".00")]),
        _vm._v(" "),
        _c("br"),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v("Total: "),
          _c("b", [_c("u", [_vm._v("R" + _vm._s(_vm.total()))])]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "info-text" }, [
      _vm._v(" Let us know what your budget is. Minimum amount is "),
      _c("b", [_c("u", [_vm._v("R400.00")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }