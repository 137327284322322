var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bursary && _vm.bursary.additionalSubmissions
    ? _c(
        "div",
        { staticClass: "video-submission-content-container" },
        [
          _vm.loading
            ? _c("div", { staticClass: "background" }, [
                _vm.loading
                  ? _c("div", { staticClass: "logo-position lds-circle" }, [
                      _vm._m(0),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "written-submission-container" }, [
            _c("div", { staticClass: "submission-instructions" }, [
              _c("h3", { staticStyle: { "text-align": "left" } }, [
                _vm._v("Written Submission Instructions"),
              ]),
              _vm.bursary.additionalSubmissions.writtenSubmission === "No" ||
              _vm.bursary.additionalSubmissions.writtenSubmission === ""
                ? _c("p", { staticClass: "text-center" }, [
                    _vm._v(" There are no written submissions required. "),
                  ])
                : _vm._e(),
              _vm.bursary.additionalSubmissions.writtenSubmission === "Yes"
                ? _c("p", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(
                      "Instructions: Please submit your written response as a PDF file."
                    ),
                  ])
                : _vm._e(),
              _c("br"),
              _vm.bursary.additionalSubmissions.writtenSubmission === "Yes"
                ? _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Question"),
                  ])
                : _vm._e(),
              _vm.bursary.additionalSubmissions.writtenSubmission === "Yes"
                ? _c("h4", {
                    staticStyle: {
                      "margin-top": "-1rem",
                      "text-align": "left",
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.bursary.additionalSubmissions
                          .writtenSubmissionDetails
                      ),
                    },
                  })
                : _vm._e(),
            ]),
            _vm.bursary.additionalSubmissions.writtenSubmission === "Yes"
              ? _c(
                  "div",
                  { staticClass: "submission-input" },
                  [
                    _c(
                      "md-field",
                      { staticClass: "md-success" },
                      [
                        _c("label", [_vm._v("Written Submission:")]),
                        _c("md-file", {
                          attrs: { accept: "application/pdf" },
                          on: { change: _vm.onFileSelected },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "modal",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSuccessModal,
                  expression: "showSuccessModal",
                },
              ],
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "font-size": "2rem" } }, [
                  _vm._v("Success! 🎊"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", [
                  _vm._v("Written Submission Successfully Submitted! ✅"),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-button md-success",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(" Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm.showErrorModal
            ? _c("modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("h4", { staticClass: "modal-title black" }, [
                            _vm._v("Whoa there! 🤚"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("h4", [
                            _vm._v("Please add a written submission! ⛔️"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-success",
                              on: { click: _vm.closeErrorModal },
                            },
                            [_vm._v(" Ok ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3289023601
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }