var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: [{ "nav-open": _vm.$sidebar.showSidebar }],
    },
    [
      _c("notifications"),
      _c(
        "side-bar",
        {
          attrs: {
            "active-color": _vm.sidebarBackground,
            "background-image": _vm.sidebarBackgroundImage,
            "data-background-color": _vm.sidebarBackgroundColor,
          },
        },
        [
          !_vm.institution ? _c("user-menu") : _vm._e(),
          _c(
            "template",
            { slot: "links" },
            [
              _vm.client
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "My Profile",
                        icon: "person",
                        path: "/client/profile/" + _vm.alias,
                      },
                    },
                  })
                : _vm._e(),
              _vm.client
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Post a Job",
                        icon: "create",
                        path: "/client/jobs/micro/post",
                      },
                    },
                  })
                : _vm._e(),
              _vm.client
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Post a Bursary",
                        icon: "book",
                        path: "/client/bursary/post",
                      },
                    },
                  })
                : _vm._e(),
              _vm.client
                ? _c(
                    "sidebar-item",
                    {
                      attrs: {
                        link: {
                          name: "View Applications",
                          icon: "work_outline",
                        },
                      },
                    },
                    [
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Job Applications",
                            path: "/client/jobs/active",
                          },
                        },
                      }),
                      _vm.client
                        ? _c("sidebar-item", {
                            attrs: {
                              link: {
                                name: "Bursary Applications",
                                icon: "book",
                                path: "/client/bursaries",
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.client
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Users",
                        icon: "people",
                        path: "/client/users",
                      },
                    },
                  })
                : _vm._e(),
              _vm.client
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Talent Pools",
                        icon: "book",
                        path: "/client/talent-pools",
                      },
                    },
                  })
                : _vm._e(),
              _vm.client
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Logout",
                        icon: "reply_all",
                        path: "/login",
                      },
                    },
                  })
                : _vm._e(),
              _vm.student
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "My Profile",
                        icon: "person",
                        path: "/student/profile/" + _vm.alias,
                      },
                    },
                  })
                : _vm._e(),
              _vm.student
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Apply For a Job",
                        icon: "create",
                        path: "/student/apply-for-job",
                      },
                    },
                  })
                : _vm._e(),
              _vm.student
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Apply For a Bursary",
                        icon: "school",
                        path: "/student/apply-for-bursary",
                      },
                    },
                  })
                : _vm._e(),
              _vm.student
                ? _c(
                    "sidebar-item",
                    {
                      attrs: {
                        link: {
                          name: "View Applications",
                          icon: "work_outline",
                        },
                      },
                    },
                    [
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Applied Jobs",
                            path: "/student/jobs/applied",
                          },
                        },
                      }),
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Job Offers",
                            path: "/student/jobs/active",
                          },
                        },
                      }),
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Bursary Applications",
                            path: "/student/bursary/applications",
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.student
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Logout",
                        icon: "reply_all",
                        path: "/login",
                      },
                    },
                  })
                : _vm._e(),
              _vm.institution
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Get Support",
                        icon: "contact_support",
                        path: "/institution/support",
                      },
                    },
                  })
                : _vm._e(),
              _vm.institution
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Give Feedback",
                        icon: "feedback",
                        path: "/institution/feedback",
                      },
                    },
                  })
                : _vm._e(),
              _vm.institution
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Logout",
                        icon: "reply_all",
                        path: "/login",
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _vm.institution ? _c("report-menu") : _vm._e(),
          _c(
            "div",
            {
              class: { content: !_vm.$route.meta.hideContent },
              on: { click: _vm.toggleSidebar },
            },
            [
              _c(
                "zoom-center-transition",
                { attrs: { duration: 200, mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
          !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }