var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item" },
      [
        _c(
          "md-card",
          [
            _c(
              "h1",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function (ref) {
                        var item = ref.item
                        return _c(
                          "md-table-row",
                          {},
                          _vm._l(_vm.columns, function (column) {
                            return _c(
                              "md-table-cell",
                              {
                                key: column.field,
                                attrs: {
                                  "md-label": column.label,
                                  "md-sort-by": column.field,
                                },
                              },
                              [
                                column.field !== "action"
                                  ? [
                                      _vm._v(
                                        " " + _vm._s(item[column.field]) + " "
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRoute(
                                              column.route,
                                              item
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "md-button",
                                            { staticClass: "jb-standard" },
                                            [_vm._v("View")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                              ],
                              2
                            )
                          }),
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableData,
                    callback: function ($$v) {
                      _vm.tableData = $$v
                    },
                    expression: "tableData",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }