var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "md-layout text-center",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.registerUser.apply(null, arguments)
        },
      },
    },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100",
        },
        [
          _c(
            "login-card",
            { attrs: { "header-color": "green" } },
            [
              _c(
                "h3",
                {
                  staticClass: "title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v("Register")]
              ),
              _c(
                "div",
                { attrs: { slot: "inputs" }, slot: "inputs" },
                [
                  _c(
                    "router-link",
                    { staticClass: "login-link", attrs: { to: "/login" } },
                    [_vm._v(" Already have an account? Login")]
                  ),
                ],
                1
              ),
              _c(
                "md-radio",
                {
                  class: { "error-border": _vm.roleError },
                  attrs: { slot: "inputs", value: "student" },
                  slot: "inputs",
                  model: {
                    value: _vm.role,
                    callback: function ($$v) {
                      _vm.role = $$v
                    },
                    expression: "role",
                  },
                },
                [_c("md-icon", [_vm._v("school")]), _vm._v(" Student")],
                1
              ),
              _c(
                "md-radio",
                {
                  class: { "error-border": _vm.roleError },
                  attrs: { slot: "inputs", value: "client" },
                  slot: "inputs",
                  model: {
                    value: _vm.role,
                    callback: function ($$v) {
                      _vm.role = $$v
                    },
                    expression: "role",
                  },
                },
                [_c("md-icon", [_vm._v("work")]), _vm._v(" Employer")],
                1
              ),
              _c("text-field", {
                attrs: {
                  slot: "inputs",
                  name: "firstName",
                  label: "First Name",
                  iconName: "face",
                  error: _vm.getErrorMessage("firstName"),
                },
                slot: "inputs",
                model: {
                  value: _vm.firstName,
                  callback: function ($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName",
                },
              }),
              _c("text-field", {
                attrs: {
                  slot: "inputs",
                  name: "lastName",
                  label: "Last Name",
                  iconName: "person",
                  error: _vm.getErrorMessage("lastName"),
                },
                slot: "inputs",
                model: {
                  value: _vm.lastName,
                  callback: function ($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName",
                },
              }),
              _c("text-field", {
                attrs: {
                  slot: "inputs",
                  name: "phoneNumber",
                  type: "input",
                  label: "Phone Number",
                  iconName: "phone",
                  error: _vm.getErrorMessage("phoneNumber"),
                },
                slot: "inputs",
                model: {
                  value: _vm.phoneNumber,
                  callback: function ($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber",
                },
              }),
              _c("text-field", {
                attrs: {
                  slot: "inputs",
                  name: "email",
                  type: "input",
                  label: _vm.role === "student" ? "Student Email" : "Email",
                  iconName: "email",
                  error: _vm.getErrorMessage("email"),
                },
                slot: "inputs",
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c("text-field", {
                attrs: {
                  slot: "inputs",
                  name: "password",
                  type: "password",
                  label: "Password",
                  iconName: "lock_outline",
                  error: _vm.getErrorMessage("password"),
                },
                slot: "inputs",
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _vm.role
                ? _c("select-field", {
                    attrs: {
                      slot: "inputs",
                      label: "How did you hear about us?",
                      options:
                        _vm.role === "student"
                          ? _vm.studentOptions
                          : _vm.employerOptions,
                      iconName: "hearing",
                      error: _vm.getErrorMessage("howDidYouHear"),
                      required: false,
                    },
                    on: { input: _vm.handleHowDidYouHearChange },
                    slot: "inputs",
                    model: {
                      value: _vm.howDidYouHear,
                      callback: function ($$v) {
                        _vm.howDidYouHear = $$v
                      },
                      expression: "howDidYouHear",
                    },
                  })
                : _vm._e(),
              _vm.role && _vm.howDidYouHear === "Other"
                ? _c("text-field", {
                    attrs: {
                      slot: "inputs",
                      name: "otherOption",
                      label: "Other (please specify)",
                      iconName: "edit",
                      error: _vm.getErrorMessage("otherOption"),
                    },
                    slot: "inputs",
                    model: {
                      value: _vm.otherOption,
                      callback: function ($$v) {
                        _vm.otherOption = $$v
                      },
                      expression: "otherOption",
                    },
                  })
                : _vm._e(),
              _c(
                "md-checkbox",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { slot: "inputs" },
                  on: { change: _vm.checkIfLinkOpened },
                  slot: "inputs",
                  model: {
                    value: _vm.terms,
                    callback: function ($$v) {
                      _vm.terms = $$v
                    },
                    expression: "terms",
                  },
                },
                [
                  _vm._v(" I agree to the "),
                  _c(
                    "a",
                    {
                      staticStyle: { "font-size": "13px", color: "blue" },
                      on: { click: _vm.openTsAndCs },
                    },
                    [_vm._v("terms and conditions")]
                  ),
                  _vm._v(", "),
                  _c(
                    "a",
                    {
                      staticStyle: { "font-size": "13px", color: "blue" },
                      on: { click: _vm.openPrivacyPolicy },
                    },
                    [_vm._v("privacy policy")]
                  ),
                  _vm._v(" and to direct marketing "),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "md-button md-success md-theme-default",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("div", { staticClass: "md-ripple" }, [
                    _c("div", { staticClass: "md-button-content" }, [
                      _vm._v("Register"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isRoleNotSelected
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "modal-title black" }, [
                  _vm._v("Woah there ✋"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("h4", { staticStyle: { color: "black" } }, [
                  _vm._v(
                    "Please indicate if you are registering as a student or employer"
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: { click: _vm.closeOptionModal },
                      },
                      [_vm._v("Got It")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
                  _vm.error
                    ? _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.modalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [_vm._v(_vm._s(_vm.body))]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _vm.success
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.verification },
                          },
                          [_vm._v(_vm._s(_vm.footer))]
                        )
                      : _vm._e(),
                    _vm.error
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.modalHide },
                          },
                          [_vm._v(_vm._s(_vm.footer))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }