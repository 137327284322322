var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", { staticClass: "info-text" }, [
        _vm._v("Tell us about your most recent studies"),
      ]),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("notifications"),
          _vm.qualifications
            ? _c(
                "div",
                { staticClass: "md-layout" },
                _vm._l(_vm.qualifications, function (qualification, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "studies-container" },
                    [
                      _c("div", { staticClass: "study-info-container" }, [
                        _c("p", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(qualification.institution)),
                        ]),
                        _c("p", [_vm._v(_vm._s(qualification.degree))]),
                        _c("p", [_vm._v(_vm._s(qualification.year))]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                new Date(
                                  qualification.startDate
                                ).getFullYear() +
                                  " - " +
                                  (qualification.endDate === "Invalid date"
                                    ? "Present"
                                    : new Date(
                                        qualification.endDate
                                      ).getFullYear())
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "action-items-container" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-icon-button edit",
                              on: {
                                click: function ($event) {
                                  return _vm.viewStudies(
                                    qualification.qualificationNum
                                  )
                                },
                              },
                            },
                            [
                              _c("md-icon", [_vm._v("edit")]),
                              _c(
                                "md-tooltip",
                                { attrs: { "md-direction": "bottom" } },
                                [_vm._v("View")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-icon-button delete",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteStudies(qualification)
                                },
                              },
                            },
                            [
                              _c("md-icon", [_vm._v("delete")]),
                              _c(
                                "md-tooltip",
                                { attrs: { "md-direction": "bottom" } },
                                [_vm._v("Delete")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("div", [
                _c("h6", { staticClass: "info-text" }, [
                  _vm._v("You currently have no studies added"),
                ]),
              ]),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [
          _c(
            "md-button",
            {
              staticClass: "md-plain md-success md-fab-bottom-right",
              on: { click: _vm.addStudies },
            },
            [_c("md-icon", [_vm._v("add")]), _vm._v("Add Studies ")],
            1
          ),
        ],
        1
      ),
      _c("education-modal", {
        attrs: {
          student: _vm.student,
          selectedQualification: _vm.selectedQualification,
          status: _vm.status,
        },
        on: { qualificationAdded: _vm.qualificationAdded },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }