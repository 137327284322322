var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.submittedDocuments
      ? _c("div", { staticClass: "review-container" }, [
          Object.keys(_vm.selectedDegree).length > 0
            ? _c("div", [
                _c("div", { staticClass: "w-full" }, [
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c(
                        "md-card",
                        [
                          _c(
                            "h3",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "md-icon",
                                {
                                  staticStyle: {
                                    "font-size": "2rem !important",
                                    "margin-bottom": "5px !important",
                                    "margin-right": "10px",
                                  },
                                },
                                [_vm._v(" school ")]
                              ),
                              _vm._v(" Degree To Be Funded "),
                            ],
                            1
                          ),
                          _c("md-card-content", [
                            _c("div", [
                              _c("h3", { staticClass: "title-degree" }, [
                                _vm._v(_vm._s(_vm.selectedDegree.degree)),
                              ]),
                              _c("p", { staticClass: "sub-text" }, [
                                _vm._v(_vm._s(_vm.selectedDegree.university)),
                              ]),
                              _c("p", { staticClass: "sub-text" }, [
                                _vm._v(_vm._s(_vm.selectedDegree.yearOfStudy)),
                              ]),
                              _c("p", { staticClass: "sub-text" }, [
                                _vm._v(_vm._s(_vm.selectedDegree.faculty)),
                              ]),
                              _c("p", { staticClass: "sub-text" }, [
                                _vm._v(
                                  _vm._s(_vm.selectedDegree.qualificationType)
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "document-list" },
            [
              _c(
                "md-card",
                [
                  _c(
                    "h3",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "md-icon",
                        {
                          staticStyle: {
                            "font-size": "2rem !important",
                            "margin-bottom": "5px !important",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" assignment ")]
                      ),
                      _vm._v(" Submitted Documents "),
                    ],
                    1
                  ),
                  _c(
                    "md-card-content",
                    _vm._l(
                      _vm.submittedDocuments,
                      function (document, docType) {
                        return _c(
                          "div",
                          { key: docType, staticClass: "document-item" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { display: "flex", width: "80%" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { width: "30px" } },
                                  [
                                    _c("md-icon", { staticClass: "icon" }, [
                                      _vm._v("insert_drive_file"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "document-name" }, [
                                  _vm._v(
                                    _vm._s(_vm.formatDocumentName(docType))
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "20%",
                                  display: "flex",
                                  "justify-content": "flex-end",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "document-link",
                                    attrs: {
                                      href: document.url,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("View Document")]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.videoSubmissionLink
            ? _c(
                "div",
                { staticClass: "section video-submission" },
                [
                  _c(
                    "md-card",
                    [
                      _c("md-card-content", [
                        _c(
                          "h3",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "md-icon",
                              {
                                staticStyle: {
                                  "font-size": "2rem !important",
                                  "margin-bottom": "5px !important",
                                  "margin-right": "10px",
                                },
                              },
                              [_vm._v(" videocam ")]
                            ),
                            _vm._v(" Video Submission "),
                          ],
                          1
                        ),
                        _vm.videoSubmissionLink.includes("loom")
                          ? _c("iframe", {
                              staticStyle: { margin: "auto" },
                              attrs: {
                                src: _vm.videoSubmissionLink + "?autoplay=0",
                                width: "640",
                                height: "360",
                                frameborder: "0",
                                allowfullscreen: "",
                              },
                            })
                          : _c(
                              "a",
                              {
                                staticClass: "document-link",
                                attrs: {
                                  href: _vm.videoSubmissionLink,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("View Video")]
                            ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.writtenSubmissionLink
            ? _c(
                "div",
                { staticClass: "section written-submission" },
                [
                  _c(
                    "md-card",
                    [
                      _c("md-card-content", [
                        _c(
                          "h3",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "md-icon",
                              {
                                staticStyle: {
                                  "font-size": "2rem !important",
                                  "margin-bottom": "5px !important",
                                  "margin-right": "10px",
                                },
                              },
                              [_vm._v(" description ")]
                            ),
                            _vm._v(" Written Submission "),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "document-link",
                            attrs: {
                              href: _vm.writtenSubmissionLink,
                              target: "_blank",
                            },
                          },
                          [_vm._v("View Written Submission")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }