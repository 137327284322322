var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.loading
        ? _c("div", {
            staticClass: "background",
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
            },
          })
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "text-center lds-circle",
              staticStyle: { position: "absolute", top: "50%", left: "50%" },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _c(
        "md-card",
        { staticClass: "padding" },
        [
          _c("div", { staticClass: "status-bar-container" }, [
            _c("div", { staticClass: "step-container" }, [
              _c(
                "div",
                {
                  class:
                    _vm.checkStep() === 2 || _vm.checkStep() === 3
                      ? "step active"
                      : "step",
                },
                [
                  _c(
                    "md-icon",
                    {
                      class:
                        _vm.checkStep() === 2 || _vm.checkStep() === 3
                          ? "md-size-2x active"
                          : "md-size-2x",
                    },
                    [_vm._v("verified_user")]
                  ),
                  _c("h4", [_vm._v("Step 1")]),
                  _c("h4", [_vm._v("Job Verification")]),
                ],
                1
              ),
            ]),
            _c("div", {
              class:
                _vm.checkStep() === 2 || _vm.checkStep() === 3
                  ? "line active"
                  : "line",
            }),
            _c("div", { staticClass: "step-container" }, [
              _c(
                "div",
                { class: _vm.checkStep() === 3 ? "step active" : "step" },
                [
                  _c(
                    "md-icon",
                    {
                      class:
                        _vm.checkStep() === 3
                          ? "md-size-2x active"
                          : "md-size-2x",
                    },
                    [_vm._v("school")]
                  ),
                  _c("h4", [_vm._v("Step 2")]),
                  _c("h4", [_vm._v("Student Applications")]),
                ],
                1
              ),
            ]),
            _c("div", {
              class: _vm.checkStep() === 3 ? "line active" : "line",
            }),
            _c("div", { staticClass: "step-container" }, [
              _c(
                "div",
                { class: _vm.checkStep() === 3 ? "step active" : "step" },
                [
                  _c(
                    "md-icon",
                    {
                      class:
                        _vm.checkStep() === 3
                          ? "md-size-2x active"
                          : "md-size-2x",
                    },
                    [_vm._v("people")]
                  ),
                  _c("h4", [_vm._v("Step 3")]),
                  _c("h4", [_vm._v("Select Applicants")]),
                ],
                1
              ),
            ]),
            _c("div", {
              class: _vm.checkStep() === 3 ? "line active" : "line",
            }),
            _c("div", { staticClass: "step-container" }, [
              _c(
                "div",
                { class: _vm.checkStep() === 3 ? "step active" : "step" },
                [
                  _c(
                    "md-icon",
                    {
                      class:
                        _vm.checkStep() === 3
                          ? "md-size-2x active"
                          : "md-size-2x",
                    },
                    [_vm._v("people")]
                  ),
                  _c("h4", [_vm._v("Step 4")]),
                  _c("h4", [_vm._v("Video Submissions")]),
                ],
                1
              ),
            ]),
          ]),
          _vm.checkStep() === 1
            ? _c("h2", { staticClass: "status-message" }, [
                _vm._v("Please be patient, we'll get back to you in 24 hours"),
              ])
            : _vm._e(),
          _vm.checkStep() === 2
            ? _c("h2", { staticClass: "status-message" }, [
                _vm._v("Please be patient, students will start applying soon"),
              ])
            : _vm._e(),
          _c(
            "div",
            { class: _vm.checkStep() === 3 ? "show" : "hide" },
            [
              _vm.select || _vm.active
                ? _c("TableSelect", {
                    attrs: { registerOnInvite: this.registerOnInvite },
                    on: {
                      selected: _vm.addSelected,
                      applicants: _vm.addApplicants,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "md-card-actions",
            { staticClass: "button-container" },
            [
              _c(
                "div",
                { class: _vm.checkStep() === 3 ? "show" : "hide" },
                [
                  _vm.inviteCallback
                    ? _c(
                        "md-button",
                        {
                          staticClass: "btn-next md-success button",
                          staticStyle: { "max-width": "155px" },
                          on: { click: _vm.onInvite },
                        },
                        [_vm._v("Invite for interview")]
                      )
                    : _vm._e(),
                  _vm._v("     "),
                ],
                1
              ),
              _vm.select || _vm.active
                ? _c(
                    "md-button",
                    {
                      staticClass: "btn-next md-info button",
                      staticStyle: { "max-width": "110px" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v("Edit Job")]
                  )
                : _vm._e(),
              _vm._v("     "),
              _vm.select && !_vm.paid
                ? _c(
                    "md-button",
                    {
                      staticClass: "btn-next md-danger button",
                      staticStyle: { "max-width": "110px" },
                      on: { click: _vm.cancelJob },
                    },
                    [_vm._v("Cancel Job")]
                  )
                : _vm._e(),
              _c(
                "md-button",
                {
                  staticClass: "btn-next md-success button",
                  staticStyle: {
                    "max-width": "155px",
                    "margin-left": "1rem",
                    "background-color": "blue !important",
                  },
                  on: { click: _vm.showAppointmentsModal },
                },
                [_vm._v("Edit Interview Slots")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Woah there ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("notifications"),
                  !_vm.invoice
                    ? _c("p", { staticClass: "black" }, [
                        _vm._v("Your payment is outstanding"),
                      ])
                    : _vm._e(),
                  !_vm.invoice
                    ? _c("p", { staticClass: "black" }, [
                        _vm._v(
                          "Your payment could take up to 5 minutes to reflect depending on your internet speed"
                        ),
                      ])
                    : _vm._e(),
                  _vm.invoice
                    ? _c("iframe", { attrs: { id: "invoice" } })
                    : _vm._e(),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                !_vm.invoice
                  ? _c(
                      "div",
                      { staticClass: "centre" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.makePayment },
                          },
                          [_vm._v("Pay Now")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.invoice
                  ? _c(
                      "div",
                      { staticClass: "centre" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.sendEmail },
                          },
                          [_vm._v("Send")]
                        ),
                        _vm._v("    "),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-info",
                            on: { click: _vm.downloadFile },
                          },
                          [_vm._v("Download")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.interviewModal
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Woah there ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.interviewModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("h4", [
                  _vm._v("Please select an applicant using the checkboxes."),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.viewAppointments
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("🗓 Your Appointments"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.hideAppointmentsModal },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "text-align": "left" } },
                    _vm._l(_vm.appointments, function (appointment, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c("p", [_vm._v(_vm._s(appointment.name))]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAppointment(appointment)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "md-icon",
                                    {
                                      staticStyle: {
                                        color: "orange",
                                        cursor: "pointer",
                                      },
                                    },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAppointment(appointment)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "md-icon",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "margin-left": "1rem",
                                        cursor: "pointer",
                                      },
                                    },
                                    [_vm._v("delete")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success",
                      on: { click: _vm.hideAppointmentsModal },
                    },
                    [_vm._v("Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.isEditAppointmentClicked
        ? _c("edit-appointment", {
            attrs: { appointment: _vm.selectedAppointment },
            on: { hideAppointmentsModal: _vm.hideEditAppointmentsModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }