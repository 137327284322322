var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "full-page",
      class: { "nav-open": _vm.$sidebar.showSidebar },
    },
    [
      _c(
        "md-toolbar",
        {
          staticClass: "md-transparent md-toolbar-absolute",
          attrs: { "md-elevation": "0" },
        },
        [
          _c("div", { staticClass: "md-toolbar-row md-offset" }, [
            _c(
              "div",
              {
                staticClass: "md-toolbar-section-start",
                on: { click: _vm.navigateHome },
              },
              [
                _c("img", {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    src: require("@/assets/img/Jobox-Logo.png"),
                    height: "125px",
                    width: "125px",
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "md-toolbar-section-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-just-icon md-simple md-round md-toolbar-toggle",
                    class: { toggled: _vm.$sidebar.showSidebar },
                    on: { click: _vm.toggleSidebar },
                  },
                  [
                    _c("span", { staticClass: "icon-bar" }),
                    _c("span", { staticClass: "icon-bar" }),
                    _c("span", { staticClass: "icon-bar" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-collapse",
                    class: { "off-canvas-sidebar": _vm.responsive },
                  },
                  [
                    _c(
                      "md-list",
                      [
                        _c(
                          "div",
                          { on: { click: _vm.navigateHome } },
                          [
                            _c(
                              "md-list-item",
                              { on: { click: _vm.linkClick } },
                              [
                                _c("md-icon", [_vm._v("home")]),
                                _vm._v(" Home "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/login" } } },
                          [
                            _c(
                              "md-list-item",
                              { on: { click: _vm.linkClick } },
                              [
                                _c("md-icon", [_vm._v("fingerprint")]),
                                _vm._v(" Login "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/register" } } },
                          [
                            _c(
                              "md-list-item",
                              { on: { click: _vm.linkClick } },
                              [
                                _c("md-icon", [_vm._v("person_add")]),
                                _vm._v(" Register "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper wrapper-full-page",
          on: { click: _vm.toggleSidebarPage },
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header header-filter",
              class: _vm.setPageClass,
              style: _vm.setBgImage,
              attrs: { "filter-color": "black" },
            },
            [
              _c(
                "div",
                { staticClass: "container md-offset" },
                [
                  _c(
                    "zoom-center-transition",
                    {
                      attrs: {
                        duration: _vm.pageTransitionDuration,
                        mode: "out-in",
                      },
                    },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _c("footer", { staticClass: "footer" }, [
                _c("div", { staticClass: "container md-offset" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "copyright text-center" }, [
                    _vm._v("© Jobox " + _vm._s(new Date().getFullYear())),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://joinjobox.com/terms-and-conditions/",
                target: "_blank",
              },
            },
            [_vm._v("Terms and Conditions")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://joinjobox.com/privacy-policy/",
                target: "_blank",
              },
            },
            [_vm._v("Privacy Policy")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://intercom.help/jobox-64fe20407ade/en",
                target: "_blank",
              },
            },
            [_vm._v("FAQ")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }