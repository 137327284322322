export default {
	methods: {
		async checkLinkPermissions(url) {
			try {
				const response = await fetch(`https://gcp-proxy-1083723251750.europe-west1.run.app/check-link?url=${encodeURIComponent(url)}`);
				const data = await response.json();
				return data.accessible;
			} catch (e) {
				console.error('Error checking link:', e);
				return false;
			}
		},
	},
};
