<template>
	<div>
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>

		<div class="submissions-container">
			<div class="submission-instructions">
				<h3>Submission Instructions</h3>
				<p v-html="bursary.documentSubmissionInstructions"></p>
			</div>
			<div class="submission-documents">
				<h3>Upload Documents</h3>
				<!-- Document upload fields -->
				<div v-for="docType in requiredDocuments" :key="docType">
					<md-field :class="{ 'md-valid': documents[docType], 'md-error': !documents[docType] && error }">
						<label>{{ formatKey(docType) }}:</label>
						<md-file accept="application/pdf" @change="uploadDocument(docType, $event)" />
					</md-field>
				</div>

				<div v-if="bursary.documentationRequirements.other && bursary.additionalDocuments.length">
					<h3>Additional Documents</h3>
					<div v-for="(doc, index) in bursary.additionalDocuments" :key="index">
						<md-field :class="{ 'md-valid': additionalDocuments[doc.name], 'md-error': !additionalDocuments[doc.name] && error }">
							<label>{{ doc.name }}:</label>
							<md-file accept="application/pdf" @change="uploadAdditionalDocument(doc.name, $event)" />
						</md-field>
					</div>
				</div>
				<!-- Submit button -->
			</div>
		</div>

		<modal v-show="submissionSuccessful">
			<template slot="header">
				<div style="font-size: 2rem">Success! 🎊</div>
			</template>
			<template slot="body">
				<p>Documents Submitted Successfully! ✅</p>
			</template>

			<template slot="footer">
				<md-button class="md-button md-success" @click="closeModal"> Okay</md-button>
			</template>
		</modal>

		<modal v-if="showErrorModal">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>Please submit all required documents! ⛔️</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '@/firebase/init';
import firebase from 'firebase/compat/app';
import Modal from '@/components/Modal';
import { BursaryStatuses } from '../../../../../../../constants/bursary-statuses.const';
export default {
	components: {
		Modal,
	},
	data() {
		return {
			studentAlias: '',
			bursaryID: this.$route.params.id,
			bursary: {
				documentationRequirements: {},
				additionalDocuments: [],
			},
			documents: {},
			additionalDocuments: {},
			loading: false,
			uploading: false,
			progressStatuses: [],
			submissionSuccessful: false,
			showErrorModal: false,
			uploadedDocuments: {},
			error: false,
		};
	},

	async created() {
		try {
			this.loading = true;
			const currentUser = firebase.auth().currentUser;
			if (!currentUser) {
				return;
			}

			const usersCollection = collection(db, 'users');
			const q = query(usersCollection, where('userId', '==', currentUser.uid));

			const querySnapshot = await getDocs(q);
			if (!querySnapshot.empty) {
				querySnapshot.forEach(docSnap => {
					this.studentAlias = docSnap.id;
				});
			}

			const bursaryRef = doc(db, 'bursaries', this.bursaryID);
			const bursarySnap = await getDoc(bursaryRef);

			if (bursarySnap.exists()) {
				this.bursary = bursarySnap.data();

				Object.keys(this.bursary.documentationRequirements).forEach(docType => {
					this.$set(this.documents, docType, null);
				});

				if (this.bursary.documentationRequirements.other) {
					this.bursary.additionalDocuments.forEach(doc => {
						this.$set(this.additionalDocuments, doc.name, null);
					});
				}
			}
			this.loading = false;
		} catch (error) {
			this.loading = false;
		}
	},

	computed: {
		requiredDocuments() {
			return Object.keys(this.bursary.documentationRequirements).filter(docType => this.bursary.documentationRequirements[docType] && docType !== 'other');
		},
	},
	methods: {
		uploadDocument(type, event) {
			const file = event.target.files[0];
			if (file && file.type === 'application/pdf') {
				this.$set(this.documents, type, file);
			} else {
				alert('Please upload a valid PDF file.');
				this.$set(this.documents, type, null);
			}
		},

		uploadAdditionalDocument(type, event) {
			const file = event.target.files[0];
			if (file && file.type === 'application/pdf') {
				this.$set(this.additionalDocuments, type, file);
			} else {
				alert('Please upload a valid PDF file.');
				this.$set(this.additionalDocuments, type, null);
			}
		},

		uploadToFirebaseStorage(docType, file, isAdditional = false) {
			if (!file) throw new Error(`No file provided for ${docType}`);

			if (!this.studentAlias) throw new Error('Student alias is not defined.');

			const storageRef = ref(storage, `/users/students/${this.studentAlias}/bursaryDocuments/${this.bursaryID}/${isAdditional ? 'additionalDocuments/' : ''}${docType}`);
			const metadata = {
				contentType: 'application/pdf',
				customMetadata: { userAlias: this.studentAlias, applicationId: this.bursaryID, certified: docType === 'id', docType },
			};

			try {
				const uploadTask = uploadBytesResumable(storageRef, file, metadata);

				return new Promise((resolve, reject) => {
					uploadTask.on(
						'state_changed',
						snapshot => {
							const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

							const existingStatusIndex = this.progressStatuses.findIndex(p => p.docType === docType);
							if (existingStatusIndex !== -1) {
								this.$set(this.progressStatuses, existingStatusIndex, { docType, progress });
							} else {
								this.progressStatuses.push({ docType, progress });
							}
						},
						reject,
						async () => {
							const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
							this.$set(this.uploadedDocuments, docType, downloadURL);
							resolve(downloadURL);
						}
					);
				});
			} catch (error) {
				return null;
			}
		},
		validate() {
			const allRequiredUploaded = this.requiredDocuments.every(docType => this.uploadedDocuments[docType] !== undefined);

			const allAdditionalUploaded = this.bursary.documentationRequirements.other
				? this.bursary.additionalDocuments.every(doc => this.uploadedDocuments[doc.name] !== undefined)
				: true;
			if (!allRequiredUploaded || !allAdditionalUploaded) {
				this.error = true;
			} else {
				this.error = false;
			}
			return allRequiredUploaded && allAdditionalUploaded;
		},
		handleSubmitDocuments() {
			this.submitDocuments();
		},
		closeModal() {
			this.submissionSuccessful = false;
			this.modalResolve(true);
		},
		async submitDocuments() {
			this.loading = true;
			const submittedDocuments = {};

			try {
				const allRequiredSelected = this.requiredDocuments.every(docType => this.documents[docType]);
				const allAdditionalSelected = this.bursary.documentationRequirements.other
					? this.bursary.additionalDocuments.every(doc => this.additionalDocuments[doc.name])
					: true;

				if (!allRequiredSelected || !allAdditionalSelected) {
					this.showErrorModal = true;
					this.error = true;
					this.loading = false;
					return false;
				}
				const uploadPromises = [];

				Object.entries(this.documents).forEach(([docType, file]) => {
					if (file) {
						uploadPromises.push(
							this.uploadToFirebaseStorage(docType, file).then(fileURL => {
								if (fileURL) {
									submittedDocuments[docType] = { url: fileURL, isVerified: false, shouldResubmit: false };
								}
							})
						);
					}
				});

				if (this.bursary.documentationRequirements.other) {
					this.bursary.additionalDocuments.forEach(doc => {
						const file = this.additionalDocuments[doc.name];
						if (file) {
							uploadPromises.push(
								this.uploadToFirebaseStorage(doc.name, file, true).then(fileURL => {
									if (fileURL) {
										submittedDocuments[doc.name] = { url: fileURL, isVerified: false, shouldResubmit: false };
									}
								})
							);
						}
					});
				}

				await Promise.all(uploadPromises);

				if (Object.keys(submittedDocuments).length) {
					const bursaryRef = doc(db, 'bursary-applications', `${this.studentAlias}-${this.bursaryID}`);

					await updateDoc(bursaryRef, {
						submittedDocuments,
					});
					this.loading = false;
					this.submissionSuccessful = true;
					return new Promise(resolve => {
						this.modalResolve = resolve;
						this.$emit('test', submittedDocuments);
					});
				}
				this.loading = false;
				this.error = false;
				return true;
			} catch (error) {
				this.loading = false;
				return false;
			}
		},

		closeErrorModal() {
			this.showErrorModal = false;
		},
		formatKey(key) {
			return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
		},
	},
};
</script>

<style scoped>
.submissions-container {
	display: flex;
}

.submission-instructions {
	width: 45%;
}

.submission-documents {
	width: 50%;
	margin-left: 2rem;
}

@media (max-width: 1400px) {
	.submissions-container {
		flex-direction: column;
	}

	.submission-instructions {
		width: 100%;
	}
	.submission-documents {
		width: 100%;
		margin-left: 0;
	}
}
.modal-content-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
.modal {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 400px;
	z-index: 1000;
}

.modal-content {
	text-align: left;
}

.background {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.background .logo-position {
	position: absolute;
	top: 40%;
	left: 47%;
	transform: translate(-50%, -50%);
}
.error {
	color: red;
	font-size: 1rem;
	text-align: center;
	margin-top: 2rem;
}

.error {
	color: red;
	font-size: 1rem;
	text-align: center;
	margin-top: 2rem;
}
</style>
