var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "blue" } },
          [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("i", { staticClass: "fas fa-coins" }),
              ]),
              _c("p", { staticClass: "category" }, [
                _vm._v("Revenue Made Last Month"),
              ]),
              _c(
                "h3",
                { staticClass: "title" },
                [
                  _vm._v("R"),
                  _c("animated-number", { attrs: { value: 2000 } }),
                ],
                1
              ),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [_c("md-icon", [_vm._v("date_range")]), _vm._v(" June")],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "rose" } },
          [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("i", { staticClass: "fas fa-coins" }),
              ]),
              _c("p", { staticClass: "category" }, [
                _vm._v("Revenue Made This Month"),
              ]),
              _c(
                "h3",
                { staticClass: "title" },
                [
                  _vm._v("R"),
                  _c("animated-number", { attrs: { value: 5000 } }),
                ],
                1
              ),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [_c("md-icon", [_vm._v("date_range")]), _vm._v(" July")],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "green" } },
          [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("i", { staticClass: "fas fa-business-time" }),
              ]),
              _c("p", { staticClass: "category" }, [
                _vm._v("Jobs Completed Last Month"),
              ]),
              _c(
                "h3",
                { staticClass: "title" },
                [_c("animated-number", { attrs: { value: 20 } })],
                1
              ),
            ]),
            _c("template", { slot: "footer" }, [
              _c("div", { staticClass: "stats" }, [
                _c(
                  "div",
                  { staticClass: "stats" },
                  [_c("md-icon", [_vm._v("date_range")]), _vm._v(" June")],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "warning" } },
          [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "card-icon" }, [
                _c("i", { staticClass: "fas fa-briefcase" }),
              ]),
              _c("p", { staticClass: "category" }, [
                _vm._v("Jobs Completed This Month"),
              ]),
              _c(
                "h3",
                { staticClass: "title" },
                [_c("animated-number", { attrs: { value: 2 } })],
                1
              ),
            ]),
            _c("template", { slot: "footer" }, [
              _c("div", { staticClass: "stats" }, [
                _c(
                  "div",
                  { staticClass: "stats" },
                  [_c("md-icon", [_vm._v("date_range")]), _vm._v(" July")],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50",
      },
      [
        _c(
          "chart-card",
          {
            attrs: {
              "header-animation": "true",
              "chart-data": _vm.emailsSubscriptionChart.data,
              "chart-options": _vm.emailsSubscriptionChart.options,
              "chart-responsive-options":
                _vm.emailsSubscriptionChart.responsiveOptions,
              "chart-type": "Bar",
              "chart-inside-header": "",
              "background-color": "rose",
            },
          },
          [
            _c(
              "md-icon",
              { attrs: { slot: "fixed-button" }, slot: "fixed-button" },
              [_vm._v("build")]
            ),
            _c(
              "md-button",
              {
                staticClass: "md-simple md-info md-just-icon",
                attrs: { slot: "first-button" },
                slot: "first-button",
              },
              [
                _c("md-icon", [_vm._v("refresh")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Refresh"),
                ]),
              ],
              1
            ),
            _c(
              "md-button",
              {
                staticClass: "md-simple md-just-icon",
                attrs: { slot: "second-button" },
                slot: "second-button",
              },
              [
                _c("md-icon", [_vm._v("edit")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Change Date"),
                ]),
              ],
              1
            ),
            _c("template", { slot: "content" }, [
              _c("h4", { staticClass: "title" }, [_vm._v("Website Views")]),
              _c("p", { staticClass: "category" }, [
                _vm._v("Last Campaign Performance"),
              ]),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [
                  _c("md-icon", [_vm._v("access_time")]),
                  _vm._v(" updated "),
                  _c("animated-number", { attrs: { value: 10 } }),
                  _vm._v(" days ago "),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50",
      },
      [
        _c(
          "chart-card",
          {
            attrs: {
              "chart-data": _vm.dailySalesChart.data,
              "chart-options": _vm.dailySalesChart.options,
              "chart-type": "Line",
              "chart-inside-header": "",
              "background-color": "green",
            },
          },
          [
            _c(
              "md-button",
              {
                staticClass: "md-simple md-info md-just-icon",
                attrs: { slot: "first-button" },
                slot: "first-button",
              },
              [
                _c("md-icon", [_vm._v("refresh")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Refresh"),
                ]),
              ],
              1
            ),
            _c(
              "md-button",
              {
                staticClass: "md-simple md-just-icon",
                attrs: { slot: "second-button" },
                slot: "second-button",
              },
              [
                _c("md-icon", [_vm._v("edit")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Change Date"),
                ]),
              ],
              1
            ),
            _c("template", { slot: "content" }, [
              _c("h4", { staticClass: "title" }, [_vm._v("Daily Sales")]),
              _c("p", { staticClass: "category" }, [
                _c(
                  "span",
                  { staticClass: "text-success" },
                  [
                    _c("i", { staticClass: "fas fa-long-arrow-alt-up" }),
                    _vm._v(" "),
                    _c("animated-number", { attrs: { value: 55 } }),
                    _vm._v("% "),
                  ],
                  1
                ),
                _vm._v(" increase in today sales. "),
              ]),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [
                  _c("md-icon", [_vm._v("access_time")]),
                  _vm._v(" updated "),
                  _c("animated-number", { attrs: { value: 4 } }),
                  _vm._v(" minutes ago "),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "h3",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [_vm._v("Recent Projects")]
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33",
      },
      [
        _c(
          "product-card",
          { attrs: { "header-animation": "true" } },
          [
            _c("img", {
              staticClass: "img",
              attrs: { slot: "imageHeader", src: _vm.product1 },
              slot: "imageHeader",
            }),
            _c(
              "md-icon",
              { attrs: { slot: "fixed-button" }, slot: "fixed-button" },
              [_vm._v("build")]
            ),
            _c(
              "template",
              { slot: "first-button" },
              [
                _c("md-icon", [_vm._v("art_track")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("View"),
                ]),
              ],
              1
            ),
            _c(
              "template",
              { slot: "second-button" },
              [
                _c("md-icon", [_vm._v("edit")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Edit"),
                ]),
              ],
              1
            ),
            _c(
              "template",
              { slot: "third-button" },
              [
                _c("md-icon", [_vm._v("close")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Remove"),
                ]),
              ],
              1
            ),
            _c(
              "h4",
              { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
              [_vm._v("Graphic Designer")]
            ),
            _c(
              "div",
              {
                staticClass: "card-description",
                attrs: { slot: "description" },
                slot: "description",
              },
              [
                _vm._v(
                  ' The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona. '
                ),
              ]
            ),
            _c("template", { slot: "footer" }, [
              _c("div", { staticClass: "price" }, [
                _c("i", { staticClass: "fas fa-money-bill-wave" }),
                _vm._v(" budget "),
                _c("h4", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("R1000"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "price" },
                [
                  _c("br"),
                  _c("br"),
                  _c("i", { staticClass: "fas fa-calendar-week" }),
                  _vm._v(" Deadline "),
                  _c("h4", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("12 July 2025"),
                  ]),
                  _c("md-button", { staticClass: "md-success" }, [
                    _vm._v("View"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "stats" }, [
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _c("md-icon", [_vm._v("place")]),
                    _vm._v(" Location "),
                    _c("h4", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Remote"),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33",
      },
      [
        _c(
          "product-card",
          { attrs: { "header-animation": "true" } },
          [
            _c("img", {
              staticClass: "img",
              attrs: { slot: "imageHeader", src: _vm.product2 },
              slot: "imageHeader",
            }),
            _c(
              "md-icon",
              { attrs: { slot: "fixed-button" }, slot: "fixed-button" },
              [_vm._v("build")]
            ),
            _c(
              "template",
              { slot: "first-button" },
              [
                _c("md-icon", [_vm._v("art_track")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("View"),
                ]),
              ],
              1
            ),
            _c(
              "template",
              { slot: "second-button" },
              [
                _c("md-icon", [_vm._v("edit")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Edit"),
                ]),
              ],
              1
            ),
            _c(
              "template",
              { slot: "third-button" },
              [
                _c("md-icon", [_vm._v("close")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Remove"),
                ]),
              ],
              1
            ),
            _c(
              "h4",
              { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
              [
                _c("a", { attrs: { href: "#pablo" } }, [
                  _vm._v("Office Studio"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "card-description",
                attrs: { slot: "description" },
                slot: "description",
              },
              [
                _vm._v(
                  ' The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK. '
                ),
              ]
            ),
            _c("template", { slot: "footer" }, [
              _c("div", { staticClass: "price" }, [
                _c("i", { staticClass: "fas fa-money-bill-wave" }),
                _vm._v(" budget "),
                _c("h4", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("R1000"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "price" },
                [
                  _c("br"),
                  _c("br"),
                  _c("i", { staticClass: "fas fa-calendar-week" }),
                  _vm._v(" Deadline "),
                  _c("h4", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("12 July 2025"),
                  ]),
                  _c("md-button", { staticClass: "md-success" }, [
                    _vm._v("View"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "stats" }, [
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _c("md-icon", [_vm._v("place")]),
                    _vm._v(" Location "),
                    _c("h4", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Remote"),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33",
      },
      [
        _c(
          "product-card",
          { attrs: { "header-animation": "true" } },
          [
            _c("img", {
              staticClass: "img",
              attrs: { slot: "imageHeader", src: _vm.product3 },
              slot: "imageHeader",
            }),
            _c(
              "md-icon",
              { attrs: { slot: "fixed-button" }, slot: "fixed-button" },
              [_vm._v("build")]
            ),
            _c(
              "template",
              { slot: "first-button" },
              [
                _c("md-icon", [_vm._v("art_track")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("View"),
                ]),
              ],
              1
            ),
            _c(
              "template",
              { slot: "second-button" },
              [
                _c("md-icon", [_vm._v("edit")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Edit"),
                ]),
              ],
              1
            ),
            _c(
              "template",
              { slot: "third-button" },
              [
                _c("md-icon", [_vm._v("close")]),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v("Remove"),
                ]),
              ],
              1
            ),
            _c(
              "h4",
              { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
              [
                _c("a", { attrs: { href: "#pablo" } }, [
                  _vm._v("Beautiful Castle"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "card-description",
                attrs: { slot: "description" },
                slot: "description",
              },
              [
                _vm._v(
                  ' The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan. '
                ),
              ]
            ),
            _c("template", { slot: "footer" }, [
              _c("div", { staticClass: "price" }, [
                _c("i", { staticClass: "fas fa-money-bill-wave" }),
                _vm._v(" budget "),
                _c("h4", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("R1000"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "price" },
                [
                  _c("br"),
                  _c("br"),
                  _c("i", { staticClass: "fas fa-calendar-week" }),
                  _vm._v(" Deadline "),
                  _c("h4", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("12 July 2025"),
                  ]),
                  _c("md-button", { staticClass: "md-success" }, [
                    _vm._v("View"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "stats" }, [
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _c("md-icon", [_vm._v("place")]),
                    _vm._v(" Location "),
                    _c("h4", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Remote"),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }