<template>
	<div>
		<md-card>
			<div class="container talentPool">
				<div class="talentPoolActions">
					<md-field style="max-width: 500px">
						<md-input type="search" class="mb-3" clearable placeholder="Search records" v-model="search"> </md-input>
					</md-field>
					<md-button @click="toggleFilterMenu" class="md-success btn-talent-pool" style="width: 150px"> Filter</md-button>
				</div>
				<md-table v-model="reactiveList" md-card>
					<md-table-toolbar>
						<h1 class="md-title">Students in {{ currentTalentPool.name }} Talent Pool</h1>
					</md-table-toolbar>

					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
						<md-table-cell md-label="Surname" md-sort-by="surname">{{ item.surname }}</md-table-cell>
						<md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>

						<md-table-cell>
							<md-button @click="viewStudentinTalentPool(item)" class="md-success btn-talent-pool" style="width: 100px"> View Student</md-button>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</div>
		</md-card>

		<modal v-if="profileModalVisible" @close="profileModalHide">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<CandidateProfile
					:userProfile="currentProfile"
					@setProfileFavourite="setProfileFavourite"
					:showFavoriteButton="true"
					:isTalentPool="isTalentPool"
					:currentTalentPool="currentTalentPool"
				/>
			</template>
		</modal>

		<StudentFilter
			v-if="isFilterClicked"
			:isFilterClicked="isFilterClicked"
			@update:isClosed="handleFilterClose"
			@filterChanged="handleFilterChange"
			:uniqueInstitutions="uniqueInstitutions"
			:uniqueQualificationTypes="uniqueQualificationTypes"
			:uniqueYearOfStudy="uniqueYearOfStudy"
			:uniqueDegrees="uniqueDegrees"
			:uniqueInterestedDepartments="uniqueInterestedDepartments"
			:uniquePreferredEmployers="uniquePreferredEmployers"
			:uniqueProgrammes="uniqueProgrammes"
			:uniqueRaces="uniqueRaces"
			:uniqueGenders="uniqueGenders"
			:uniqueDriversLicense="uniqueDriversLicense"
			:uniqueVehicles="uniqueVehicles"
			:uniqueDisability="uniqueDisability"
			:uniqueCitizenships="uniqueCitizenships"
			:uniqueLocations="uniqueLocations"
			:students="currentTalentPoolStudentTalentPool || []"
			@clearFilters="clearFilters"
		/>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { Modal } from '@/components';
import CandidateProfile from '@/components/Profile/CandidateProfile.vue';
import StudentFilter from '@/components/Filters/StudentFilter.vue';

export default {
	name: 'TableTemplate',
	components: { Modal, CandidateProfile, StudentFilter },
	setup() {
		return { v$: useVuelidate() };
	},
	props: ['myProp'],
	data: () => ({
		isFilterClicked: false,
		isTalentPool: 'SpecialProgramTalentPool',
		currentTalentPool: null,
		currentTalentPoolStudentTalentPool: null,
		profileModalVisible: false,
		userProfile: null,
		talentPools: [],
		loading: false,
		availableTalentPool: [],
		currentProfile: null,
		search: '',
		students: [],
		selectedInstitutions: [],
		selectedQualificationTypes: [],
		selectedDegrees: [],
		selectedYearOfStudy: [],
		selectedInterestedDepartments: [],
		selectedPreferredEmployers: [],
		selectedProgrammes: [],
		selectedRaces: [],
		selectedGenders: [],
		selectedDriversLicenses: [],
		selectedVehicles: [],
		selectedDisabilities: [],
		selectedCitizenships: [],
		selectedLocations: [],
		reactiveList: [],
	}),
	computed: {
		uniqueInstitutions() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool?.map(s => s.qualifications.filter(q => q.institution).map(q => q.institution)).flat()));
		},
		uniqueQualificationTypes() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.qualifications.filter(q => q.graduateStatus).map(q => q.graduateStatus)).flat()));
		},
		uniqueDegrees() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.qualifications.filter(q => q.degree).map(q => q.degree)).flat()));
		},
		uniqueYearOfStudy() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.qualifications.filter(q => q.year !== null).map(q => q.year.toString())).flat()));
		},
		uniqueInterestedDepartments() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.preferredCompanies.department?.map(d => d.department) ?? []).flat()));
		},
		uniquePreferredEmployers() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.preferredCompanies.choices ?? []).flat()));
		},
		uniqueProgrammes() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.programme)));
		},
		uniqueRaces() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.race)));
		},
		uniqueGenders() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.gender)));
		},
		uniqueDriversLicense() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.license)));
		},
		uniqueVehicles() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.vehicle)));
		},
		uniqueDisability() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.disability)));
		},
		uniqueCitizenships() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.citizenship)));
		},
		uniqueLocations() {
			return Array.from(new Set(this.currentTalentPoolStudentTalentPool.map(s => s.location?.town)));
		},

		filteredStudents() {
			const result = this.currentTalentPoolStudentTalentPool?.filter(student => {
				const matchesSearch =
					!this.search || student.name.toLowerCase().includes(this.search.toLowerCase()) || student.surname.toLowerCase().includes(this.search.toLowerCase());

				const matchesInstitutions = this.selectedInstitutions.length === 0 || this.selectedInstitutions.some(i => student.qualifications.some(q => q.institution === i));

				const matchesQualificationTypes =
					this.selectedQualificationTypes.length === 0 || this.selectedQualificationTypes.some(qt => student.qualifications.some(q => q.graduateStatus === qt));

				const matchesDegrees = this.selectedDegrees.length === 0 || this.selectedDegrees.some(d => student.qualifications.some(q => q.degree === d));

				const matchesYearOfStudy =
					this.selectedYearOfStudy.length === 0 || this.selectedYearOfStudy.some(ys => student.qualifications.some(q => q.year !== null && q.year.toString() === ys));

				const matchesInterestedDepartments =
					this.selectedInterestedDepartments.length === 0 ||
					this.selectedInterestedDepartments.some(id => student.preferredCompanies.department?.some(d => d.department === id) ?? false);

				const matchesPreferredEmployers =
					this.selectedPreferredEmployers.length === 0 || this.selectedPreferredEmployers.some(pe => student.preferredCompanies.choices?.includes(pe) ?? false);

				const matchesProgrammes = this.selectedProgrammes.length === 0 || this.selectedProgrammes.includes(student.programme);

				const matchesRaces = this.selectedRaces.length === 0 || this.selectedRaces.includes(student.race);

				const matchesGenders = this.selectedGenders.length === 0 || this.selectedGenders.includes(student.gender);

				const matchesDriversLicenses = this.selectedDriversLicenses.length === 0 || this.selectedDriversLicenses.includes(student.license);

				const matchesVehicles = this.selectedVehicles.length === 0 || this.selectedVehicles.includes(student.vehicle);

				const matchesDisabilities = this.selectedDisabilities.length === 0 || this.selectedDisabilities.includes(student.disability);

				const matchesCitizenships = this.selectedCitizenships.length === 0 || this.selectedCitizenships.includes(student.citizenship);

				const matchesLocations = this.selectedLocations.length === 0 || this.selectedLocations.includes(student.location?.town);

				return (
					matchesSearch &&
					matchesInstitutions &&
					matchesQualificationTypes &&
					matchesYearOfStudy &&
					matchesLocations &&
					matchesCitizenships &&
					matchesDisabilities &&
					matchesVehicles &&
					matchesProgrammes &&
					matchesDriversLicenses &&
					matchesDegrees &&
					matchesGenders &&
					matchesRaces &&
					matchesInterestedDepartments &&
					matchesPreferredEmployers
				);
			});
			return result;
		},
	},
	watch: {
		filteredStudents(newList) {
			this.reactiveList = newList;
		},
	},
	methods: {
		clearFilters() {
			this.selectedInstitutions = [];
			this.selectedQualificationTypes = [];
			this.selectedYearOfStudy = [];
			this.selectedDegrees = [];
			this.selectedInterestedDepartments = [];
			this.selectedPreferredEmployers = [];
			this.selectedProgrammes = [];
			this.selectedRaces = [];
			this.selectedGenders = [];
			this.selectedDriversLicenses = [];
			this.selectedVehicles = [];
			this.selectedDisabilities = [];
			this.selectedCitizenships = [];
			this.selectedLocations = [];
		},
		handleFilterChange(filters) {
			this.selectedInstitutions = filters.institutions || [];
			this.selectedQualificationTypes = filters.qualificationTypes || [];
			this.selectedYearOfStudy = filters.yearOfStudy || [];
			this.selectedDegrees = filters.degrees || [];
			this.selectedInterestedDepartments = filters.interestedDepartments || [];
			this.selectedPreferredEmployers = filters.preferredEmployers || [];
			this.selectedProgrammes = filters.programmes || [];
			this.selectedRaces = filters.races || [];
			this.selectedGenders = filters.genders || [];
			this.selectedDriversLicenses = filters.driversLicense || [];
			this.selectedVehicles = filters.vehicles || [];
			this.selectedDisabilities = filters.disability || [];
			this.selectedCitizenships = filters.citizenships || [];
			this.selectedLocations = filters.locations || [];
		},
		handleFilterClose() {
			this.isFilterClicked = false;
		},
		toggleFilterMenu() {
			this.isFilterClicked = !this.isFilterClicked;
		},
		async viewStudentinTalentPool(item) {
			this.currentProfile = item;
			this.profileModalVisible = true;
		},
		async getStudentDetails(item) {
			const studentDoc = await db.collection('students').where('userId', '==', item).get();
			if (!studentDoc.empty) {
				const studentData = studentDoc.docs[0].data();
			}
		},
		viewStudentProfile(item) {
			this.profileModalVisible = true;
		},
		profileModalHide() {
			this.profileModalVisible = false;
		},
		setProfileFavourite(item) {
			this.setFavourite(item);
		},
		async setFavourite(item) {
			try {
				this.currentProfile.isTalentPoolFavourite = !this.currentProfile.isTalentPoolFavourite;
				const talentPoolDocRef = db.collection('specialProgramTalentPools').doc(this.currentTalentPool.id);

				const specialProgramTalentPoolStudentsSubcollectionRef = talentPoolDocRef.collection('specialProgramTalentPoolStudents');
				const studentQuerySnapshot = await specialProgramTalentPoolStudentsSubcollectionRef.where('userId', '==', item.userId).get();

				if (!studentQuerySnapshot.empty) {
					const studentDocRef = studentQuerySnapshot.docs[0].ref;

					await studentDocRef.set({ isTalentPoolFavourite: !item.isTalentPoolFavourite }, { merge: true });
					item.isTalentPoolFavourite = !item.isTalentPoolFavourite;
				}
			} catch (error) {
				console.error('Error setting favourite status:', error);
			}
		},
		async loadProfile(alias) {
			const studentDocRef = db.collection('students').doc(alias);
			const usersDocRef = db.collection('users').doc(alias);

			const [studentDoc, usersDoc, qualificationsSnapShot, experiencesSnapShot] = await Promise.all([
				studentDocRef.get(),
				usersDocRef.get(),
				studentDocRef.collection('qualifications').get(),
				studentDocRef.collection('experiences').get(),
			]);

			const student = {
				...studentDoc.data(),
				alias: usersDoc.data().alias,
				name: usersDoc.data().name,
				surname: usersDoc.data().surname,
				email: usersDoc.data().email,
				phone: usersDoc.data().phone,
				qualifications: qualificationsSnapShot.docs.map(doc => doc.data()),
				experiences: experiencesSnapShot.docs.map(doc => doc.data()),
			};
			return student;
		},
		async getStudents(studentList) {
			const profiles = await Promise.all(studentList.map(student => this.loadProfile(student.alias)));
			this.students = profiles;
			return this.students;
		},
		filterTalentPools() {
			this.filteredTalentPools;
		},
	},
	async created() {
		const queryProp = this.$route.query.myProp;

		if (queryProp) {
			try {
				this.currentTalentPool = JSON.parse(decodeURIComponent(queryProp));
			} catch (error) {
				console.error('Error parsing query data:', error);
				this.currentTalentPool = null;
			}
		}
		if (this.currentTalentPool?.students?.length) {
			this.currentTalentPoolStudentTalentPool = await Promise.all(this.currentTalentPool.students.map(student => this.loadProfile(student.alias)));
		}
		this.reactiveList = this.filteredStudents;
	},
};
</script>

<style scoped>
.container {
	margin: 0px 20px;
}
.talentPool {
	display: flex;
	flex-direction: column;
}
.talentPoolActions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
}
.md-table >>> .md-table-head {
	background-color: #ff5722 !important;
}
.md-table >>> .md-table-head-label {
	color: rgb(255, 255, 255) !important;
}
</style>
