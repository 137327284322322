var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.bursaries
    ? _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            height: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
          },
        },
        [
          _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
            : _vm._e(),
          _c("BursariesTable", {
            staticClass: "desktop-table",
            attrs: {
              tableData: _vm.bursaries,
              columns: _vm.columns,
              title: "Open Bursaries",
            },
          }),
          _c("BursariesTableMobile", {
            staticClass: "mobile-table",
            attrs: { tableData: _vm.bursaries, columns: _vm.columns },
          }),
        ],
        1
      )
    : _c("div", [
        _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
        _vm.loading
          ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(1)])
          : _vm._e(),
        _c("h1", { staticClass: "black centre" }, [
          _vm._v("There are currently no bursaries posted."),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }