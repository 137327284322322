var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v("Have one last final look at the job you are about to post"),
    ]),
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-content",
              [
                _c(
                  "collapse",
                  {
                    attrs: {
                      collapse:
                        _vm.jobType === "Once-off Project/Task"
                          ? [
                              "Job Description",
                              "Job Requirements",
                              "Job Information",
                              "Cost Breakdown",
                            ]
                          : [
                              "Job Description",
                              "Job Requirements",
                              "Job Information",
                            ],
                      icon: "keyboard_arrow_down",
                      "color-collapse": "success",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "md-collapse-pane-1" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", { staticClass: "fas fa-list-ul" }),
                                  _vm._v(" Job Description"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Job Name"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.name)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Job Description"),
                              ]),
                              _c("p", {
                                staticClass: "card-description",
                                domProps: {
                                  innerHTML: _vm._s(_vm.description),
                                },
                              }),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Job Title"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.jobCategory)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Skills Required"),
                              ]),
                              _vm.selectedSkills
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.selectedSkills,
                                      function (skill, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "card-description",
                                          },
                                          [_vm._v(_vm._s(skill.skillName))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Salary Range"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.budgetFormat)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "md-collapse-pane-2" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", { staticClass: "fas fa-dungeon" }),
                                  _vm._v(" Job Requirements"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Minimum Level of Education"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.education)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Minimum Work Experience"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.experience)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "md-collapse-pane-3" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-clipboard-list",
                                  }),
                                  _vm._v(" Job Information"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Location"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.location)),
                              ]),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Application Deadline"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(_vm._s(_vm.formattedDate)),
                              ]),
                              _vm.jobType === "Once-off Project/Task"
                                ? _c("h4", { staticClass: "card-title" }, [
                                    _vm._v("Estimated Duration"),
                                  ])
                                : _vm._e(),
                              _vm.jobType === "Once-off Project/Task"
                                ? _c("p", { staticClass: "card-description" }, [
                                    _vm._v(_vm._s(_vm.deadlineData)),
                                  ])
                                : _vm._e(),
                              _vm.jobType !== "Once-off Project/Task"
                                ? _c("h4", { staticClass: "card-title" }, [
                                    _vm._v("Working Days"),
                                  ])
                                : _vm._e(),
                              _vm.jobType !== "Once-off Project/Task"
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.selectedDaysOfTheWeek,
                                      function (day, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "card-description",
                                          },
                                          [_vm._v(_vm._s(day))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.jobType === "Internship" ||
                              _vm.jobType === "Part-time"
                                ? _c("h4", { staticClass: "card-title" }, [
                                    _vm._v("Working Hours Per Week"),
                                  ])
                                : _vm._e(),
                              _vm.jobType === "Internship" ||
                              _vm.jobType === "Part-time"
                                ? _c("p", { staticClass: "card-description" }, [
                                    _vm._v(_vm._s(_vm.selectedHours)),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "md-collapse-pane-4" },
                      [
                        _c(
                          "md-card",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.jobType === "Once-off Project/Task",
                                expression:
                                  "jobType === 'Once-off Project/Task'",
                              },
                            ],
                            staticClass: "bg-success",
                          },
                          [
                            _c("md-card-content", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "card-category card-category-social",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c("i", { staticClass: "fas fa-wallet" }),
                                  _vm._v(" Breakdown"),
                                ]
                              ),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Total Cost"),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _c("b", [_vm._v("R" + _vm._s(_vm.total))]),
                                _vm._v(" = "),
                                _c("b", [_vm._v("R" + _vm._s(_vm.rate))]),
                                _vm._v(" + "),
                                _c("b", [_vm._v("R" + _vm._s(_vm.fee))]),
                                _vm._v(" + "),
                                _c("b", [
                                  _vm._v(
                                    "R" +
                                      _vm._s(_vm.price.facilitationFee) +
                                      ".00"
                                  ),
                                ]),
                              ]),
                              _c("hr"),
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v("Cost Breakdown"),
                              ]),
                              _vm.jobType === "Once-off Project/Task"
                                ? _c("p", { staticClass: "card-description" }, [
                                    _vm._v(" Fixed-Term Rate: "),
                                    _c("b", [_vm._v("R" + _vm._s(_vm.rate))]),
                                  ])
                                : _vm._e(),
                              _vm.jobType !== "Once-off Project/Task"
                                ? _c("p", { staticClass: "card-description" }, [
                                    _vm._v(" Salary: "),
                                    _c("b", [_vm._v("R" + _vm._s(_vm.rate))]),
                                  ])
                                : _vm._e(),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(
                                  " Jobox Service Fee (" +
                                    _vm._s(_vm.percentage) +
                                    "%): "
                                ),
                                _c("b", [_vm._v("R" + _vm._s(_vm.fee))]),
                              ]),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(" Jobox Facilitation Cost: "),
                                _c("b", [
                                  _vm._v(
                                    "R" +
                                      _vm._s(_vm.price.facilitationFee) +
                                      ".00"
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }