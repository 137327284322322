var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v("Let's start with the basic information about the job"),
    ]),
    _c(
      "div",
      { staticClass: "padding" },
      [
        _c(
          "md-field",
          {
            class: [
              { "md-valid": !_vm.errors.has("name") && _vm.touched.name },
              { "md-error": _vm.errors.has("name") },
            ],
          },
          [
            _c("label", [_vm._v("Job Title")]),
            _c("md-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.name,
                  expression: "modelValidations.name",
                },
              ],
              attrs: {
                "data-vv-name": "name",
                type: "text",
                name: "name",
                required: "",
                disabled: "",
              },
              on: { change: _vm.addName },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
            _c(
              "md-tooltip",
              {
                style: { maxWidth: "800px" },
                attrs: { "md-direction": "top" },
              },
              [_vm._v("e.g. Need a designer for a new company logo")]
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("name"),
                        expression: "errors.has('name')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.errors.has("name") && _vm.touched.name,
                        expression: "!errors.has('name') && touched.name",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.errors.has("name")
          ? _c("p", { staticClass: "error_message" }, [
              _vm._v("Please enter a Job Name"),
            ])
          : _vm._e(),
        _c("br"),
        _c("br"),
        _c(
          "div",
          [
            _c(
              "md-field",
              {
                class: [
                  {
                    "md-valid":
                      !_vm.errors.has("industryCategory") &&
                      _vm.touched.industryCategory,
                  },
                  { "md-error": _vm.errors.has("industryCategory") },
                ],
              },
              [
                _c("label", [_vm._v("Job Industry")]),
                _c(
                  "md-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.industryCategory,
                        expression: "modelValidations.industryCategory",
                      },
                    ],
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      "data-vv-name": "industryCategory",
                      type: "text",
                      name: "industryCategory",
                      required: "",
                    },
                    on: { input: _vm.addIndustryCategory },
                    model: {
                      value: _vm.industryCategory,
                      callback: function ($$v) {
                        _vm.industryCategory = $$v
                      },
                      expression: "industryCategory",
                    },
                  },
                  _vm._l(
                    _vm.list.JobCategory,
                    function (industryCategory, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: industryCategory } },
                        [_vm._v(_vm._s(industryCategory))]
                      )
                    }
                  ),
                  1
                ),
                _c(
                  "md-tooltip",
                  {
                    style: { maxWidth: "800px" },
                    attrs: { "md-direction": "top" },
                  },
                  [_vm._v("Select the category of task/project")]
                ),
                _c(
                  "slide-y-down-transition",
                  [
                    _c(
                      "md-icon",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("industryCategory"),
                            expression: "errors.has('industryCategory')",
                          },
                        ],
                        staticClass: "error",
                      },
                      [_vm._v("close")]
                    ),
                  ],
                  1
                ),
                _c(
                  "slide-y-down-transition",
                  [
                    _c(
                      "md-icon",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.errors.has("industryCategory") &&
                              _vm.touched.industryCategory,
                            expression:
                              "!errors.has('industryCategory') && touched.industryCategory",
                          },
                        ],
                        staticClass: "success",
                      },
                      [_vm._v("done")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.errors.has("industryCategory")
              ? _c("p", { staticClass: "error_message" }, [
                  _vm._v("Please select a Job Industry"),
                ])
              : _vm._e(),
            _c("br"),
            _c("br"),
            _vm.industryCategory === "Administration"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(
                        _vm.list.Administration,
                        function (jobCategory, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: jobCategory } },
                            [_vm._v(_vm._s(jobCategory))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Advertising, Design & Media"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(
                        _vm.list.AdvertisingDesignMedia,
                        function (jobCategory, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: jobCategory } },
                            [_vm._v(_vm._s(jobCategory))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Banking & Financial Services"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(
                        _vm.list.BankingFinancialServices,
                        function (jobCategory, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: jobCategory } },
                            [_vm._v(_vm._s(jobCategory))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Education"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(_vm.list.Education, function (jobCategory, index) {
                        return _c(
                          "md-option",
                          { key: index, attrs: { value: jobCategory } },
                          [_vm._v(_vm._s(jobCategory))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Hospitality"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(
                        _vm.list.Hospitality,
                        function (jobCategory, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: jobCategory } },
                            [_vm._v(_vm._s(jobCategory))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Human Resources"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(
                        _vm.list.HumanResources,
                        function (jobCategory, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: jobCategory } },
                            [_vm._v(_vm._s(jobCategory))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Information Technology"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(
                        _vm.list.InformationTechnology,
                        function (jobCategory, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: jobCategory } },
                            [_vm._v(_vm._s(jobCategory))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Legal"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(_vm.list.Legal, function (jobCategory, index) {
                        return _c(
                          "md-option",
                          { key: index, attrs: { value: jobCategory } },
                          [_vm._v(_vm._s(jobCategory))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Retail"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(_vm.list.Retail, function (jobCategory, index) {
                        return _c(
                          "md-option",
                          { key: index, attrs: { value: jobCategory } },
                          [_vm._v(_vm._s(jobCategory))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "Sales"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(_vm.list.Sales, function (jobCategory, index) {
                        return _c(
                          "md-option",
                          { key: index, attrs: { value: jobCategory } },
                          [_vm._v(_vm._s(jobCategory))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory === "YES"
              ? _c(
                  "md-field",
                  {
                    class: [
                      {
                        "md-valid":
                          !_vm.errors.has("jobCategory") &&
                          _vm.touched.jobCategory,
                      },
                      { "md-error": _vm.errors.has("jobCategory") },
                    ],
                  },
                  [
                    _c("label", [_vm._v("Primary Role/Responsibility")]),
                    _c(
                      "md-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.jobCategory,
                            expression: "modelValidations.jobCategory",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-vv-name": "jobCategory",
                          type: "text",
                          name: "jobCategory",
                          required: "",
                        },
                        on: { input: _vm.addJobCategory },
                        model: {
                          value: _vm.jobCategory,
                          callback: function ($$v) {
                            _vm.jobCategory = $$v
                          },
                          expression: "jobCategory",
                        },
                      },
                      _vm._l(_vm.list.YES, function (jobCategory, index) {
                        return _c(
                          "md-option",
                          { key: index, attrs: { value: jobCategory } },
                          [_vm._v(_vm._s(jobCategory))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("jobCategory"),
                                expression: "errors.has('jobCategory')",
                              },
                            ],
                            staticClass: "error",
                          },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "slide-y-down-transition",
                      [
                        _c(
                          "md-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.errors.has("jobCategory") &&
                                  _vm.touched.jobCategory,
                                expression:
                                  "!errors.has('jobCategory') && touched.jobCategory",
                              },
                            ],
                            staticClass: "success",
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.industryCategory && _vm.errors.has("jobCategory")
              ? _c("p", { staticClass: "error_message" }, [
                  _vm._v("Please select a Primary Role/Responsibility"),
                ])
              : _vm._e(),
            _c("br"),
            _c("br"),
          ],
          1
        ),
        _c(
          "label",
          {
            style: {
              color: _vm.descriptionExists
                ? "green"
                : _vm.errors.has("jobType")
                ? "red"
                : "",
            },
          },
          [_vm._v("Job Description")]
        ),
        _vm.errors.has("jobType") && !_vm.descriptionExists
          ? _c("p", { staticClass: "error_message" }, [
              _vm._v("Please add a Job Description"),
            ])
          : _vm._e(),
        _c("vue-editor", {
          on: { "text-change": _vm.addDescription },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _c("br"),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              { "md-valid": !_vm.errors.has("jobType") && _vm.touched.jobType },
              { "md-error": _vm.errors.has("jobType") },
            ],
          },
          [
            _c("label", [_vm._v("Employment Type")]),
            _c(
              "md-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.jobType,
                    expression: "modelValidations.jobType",
                  },
                ],
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  "data-vv-name": "jobType",
                  type: "text",
                  name: "jobType",
                  required: "",
                },
                on: { input: _vm.addJobType },
                model: {
                  value: _vm.jobType,
                  callback: function ($$v) {
                    _vm.jobType = $$v
                  },
                  expression: "jobType",
                },
              },
              _vm._l(_vm.extraList.JobType, function (jobType, index) {
                return _c(
                  "md-option",
                  { key: index, attrs: { value: jobType } },
                  [_vm._v(_vm._s(jobType))]
                )
              }),
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("jobType"),
                        expression: "errors.has('jobType')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("jobType") && _vm.touched.jobType,
                        expression: "!errors.has('jobType') && touched.jobType",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.errors.has("jobType")
          ? _c("p", { staticClass: "error_message" }, [
              _vm._v("Please select an Employment Type"),
            ])
          : _vm._e(),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              {
                "md-valid":
                  !_vm.errors.has("programme") && _vm.touched.programme,
              },
              { "md-error": _vm.errors.has("programme") },
            ],
          },
          [
            _c("label", { attrs: { for: "programme" } }, [
              _vm._v("Which programme is this job for? (Optional)"),
            ]),
            _c(
              "md-select",
              {
                staticClass: "pad",
                attrs: { "data-vv-name": "programme", name: "programme" },
                on: { input: _vm.addProgramme },
                model: {
                  value: _vm.programme,
                  callback: function ($$v) {
                    _vm.programme = $$v
                  },
                  expression: "programme",
                },
              },
              _vm._l(
                _vm.extraList.SpecialProgrammes,
                function (programme, index) {
                  return _c(
                    "md-option",
                    { key: index, attrs: { value: programme } },
                    [_vm._v(_vm._s(programme))]
                  )
                }
              ),
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("programme"),
                        expression: "errors.has('programme')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("programme") && _vm.touched.programme,
                        expression:
                          "!errors.has('programme') && touched.programme",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              {
                "md-valid":
                  !_vm.errors.has("citizenship") &&
                  _vm.touched.citizenship &&
                  _vm.citizenship &&
                  _vm.citizenship.length > 0,
              },
              { "md-error": _vm.errors.has("citizenship") },
            ],
          },
          [
            _c("label", [_vm._v("Citizenship (Optional)")]),
            _c(
              "md-select",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  "data-vv-name": "citizenship",
                  type: "text",
                  name: "citizenship",
                  multiple: "",
                },
                on: { input: _vm.addCitizenship },
                model: {
                  value: _vm.citizenship,
                  callback: function ($$v) {
                    _vm.citizenship = $$v
                  },
                  expression: "citizenship",
                },
              },
              [
                _c("md-option", { attrs: { value: "SA Citizen" } }, [
                  _vm._v("SA Citizen"),
                ]),
                _c(
                  "md-option",
                  {
                    attrs: {
                      value: "Non-SA Citizen with Long Term Work Permit",
                    },
                  },
                  [_vm._v("Non-SA Citizen with Long Term Work Permit")]
                ),
                _c(
                  "md-option",
                  {
                    attrs: { value: "Non-SA Citizen with Student Work Permit" },
                  },
                  [_vm._v("Non-SA Citizen with Student Work Permit")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("citizenship"),
                        expression: "errors.has('citizenship')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("citizenship") &&
                          _vm.touched.citizenship &&
                          _vm.citizenship &&
                          _vm.citizenship.length > 0,
                        expression:
                          "!errors.has('citizenship') && touched.citizenship && citizenship && citizenship.length > 0",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              {
                "md-valid":
                  !_vm.errors.has("education") && _vm.touched.education,
              },
              { "md-error": _vm.errors.has("education") },
            ],
          },
          [
            _c("label", [_vm._v("Minimum Level of Education")]),
            _c(
              "md-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.education,
                    expression: "modelValidations.education",
                  },
                ],
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  "data-vv-name": "education",
                  type: "text",
                  name: "education",
                  required: "",
                },
                on: { input: _vm.addEducation },
                model: {
                  value: _vm.education,
                  callback: function ($$v) {
                    _vm.education = $$v
                  },
                  expression: "education",
                },
              },
              _vm._l(
                _vm.extraList.MinimumEducation,
                function (education, index) {
                  return _c(
                    "md-option",
                    { key: index, attrs: { value: education } },
                    [_vm._v(_vm._s(education))]
                  )
                }
              ),
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("education"),
                        expression: "errors.has('education')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("education") && _vm.touched.education,
                        expression:
                          "!errors.has('education') && touched.education",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.errors.has("education")
          ? _c("p", { staticClass: "error_message" }, [
              _vm._v("Please select a Minimum Level of Education"),
            ])
          : _vm._e(),
        _c("br"),
        _c("br"),
        _c(
          "md-field",
          {
            class: [
              {
                "md-valid":
                  !_vm.errors.has("experience") && _vm.touched.experience,
              },
              { "md-error": _vm.errors.has("experience") },
            ],
          },
          [
            _c("label", [_vm._v("Minimum Work Experience")]),
            _c(
              "md-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.experience,
                    expression: "modelValidations.experience",
                  },
                ],
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  "data-vv-name": "experience",
                  type: "text",
                  name: "experience",
                  required: "",
                },
                on: { input: _vm.addExperience },
                model: {
                  value: _vm.experience,
                  callback: function ($$v) {
                    _vm.experience = $$v
                  },
                  expression: "experience",
                },
              },
              _vm._l(
                _vm.extraList.MinimumExperience,
                function (experience, index) {
                  return _c(
                    "md-option",
                    { key: index, attrs: { value: experience } },
                    [_vm._v(_vm._s(experience))]
                  )
                }
              ),
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("experience"),
                        expression: "errors.has('experience')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.errors.has("experience") &&
                          _vm.touched.experience,
                        expression:
                          "!errors.has('experience') && touched.experience",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.errors.has("experience")
          ? _c("p", { staticClass: "error_message" }, [
              _vm._v("Please select a level of experience"),
            ])
          : _vm._e(),
        _c("br"),
        _c("br"),
        _vm.industryCategory ? _c("span", [_c("br"), _c("br")]) : _vm._e(),
        _c(
          "md-field",
          {
            class: [
              { "md-valid": !_vm.errors.has("skills") && _vm.touched.skills },
              { "md-error": _vm.errors.has("skills") },
            ],
          },
          [
            _c("label", [_vm._v("Required Skills")]),
            _c(
              "md-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.skills,
                    expression: "modelValidations.skills",
                  },
                ],
                attrs: {
                  "data-vv-name": "skills",
                  type: "text",
                  name: "skills",
                  required: "",
                  multiple: "",
                },
                on: { input: _vm.addSkills },
                model: {
                  value: _vm.selectedSkills,
                  callback: function ($$v) {
                    _vm.selectedSkills = $$v
                  },
                  expression: "selectedSkills",
                },
              },
              [
                _c(
                  "md-field",
                  {
                    staticStyle: {
                      width: "90%",
                      padding: "4px",
                      "box-sizing": "border-box",
                      margin: "1rem",
                    },
                  },
                  [
                    _c("label", [_vm._v("Search Skills")]),
                    _c("md-input", {
                      attrs: { "aria-placeholder": "Search skills..." },
                      model: {
                        value: _vm.searchQuery,
                        callback: function ($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery",
                      },
                    }),
                    _c("md-icon", [_vm._v("search")]),
                  ],
                  1
                ),
                _vm._l(_vm.filteredSkills, function (skill) {
                  return _c(
                    "md-option",
                    { key: skill.id, attrs: { value: skill.skillName } },
                    [_vm._v(" " + _vm._s(skill.skillName) + " ")]
                  )
                }),
              ],
              2
            ),
            _c("md-tooltip", [
              _vm._v("Pick the skills you want candidates to be vetted for"),
            ]),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("skills"),
                        expression: "errors.has('skills')",
                      },
                    ],
                    staticClass: "error",
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
            _c(
              "slide-y-down-transition",
              [
                _c(
                  "md-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.errors.has("skills") && _vm.touched.skills,
                        expression: "!errors.has('skills') && touched.skills",
                      },
                    ],
                    staticClass: "success",
                  },
                  [_vm._v("done")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("skills"),
                expression: "errors.has('skills')",
              },
            ],
            staticStyle: { color: "red" },
          },
          [_vm._v(" " + _vm._s(_vm.errors.first("skills")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }