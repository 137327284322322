var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "md-layout md-gutter md-alignment-top-center",
      attrs: { id: "report-container" },
    }),
    _c("div", { staticClass: "md-layout md-gutter md-alignment-top-center" }, [
      _c(
        "ul",
        { staticClass: "pagination" },
        [
          _c(
            "li",
            {
              staticClass: "page-item prev-page",
              class: { disabled: _vm.disableButton == 0 },
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { "aria-label": "Previous" },
                  on: { click: _vm.prevPage },
                },
                [_c("i", { staticClass: "fas fa-angle-double-left" })]
              ),
            ]
          ),
          _vm._l(_vm.reportPages, function (page) {
            return _c(
              "li",
              {
                key: page.Name,
                staticClass: "page-item",
                class: { active: _vm.selectedPage === page },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function ($event) {
                        return _vm.selectPage(page)
                      },
                    },
                  },
                  [_vm._v(_vm._s(page.order + 1))]
                ),
                _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                  _vm._v(_vm._s(page.displayName)),
                ]),
              ],
              1
            )
          }),
          _c(
            "li",
            {
              staticClass: "page-item page-pre next-page",
              class: { disabled: _vm.disableButton === 1 },
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { "aria-label": "Next" },
                  on: { click: _vm.nextPage },
                },
                [_c("i", { staticClass: "fas fa-angle-double-right" })]
              ),
            ]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }