var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "md-layout",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.cancel.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "md-layout-item md-small-size-100" },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("mail_outline")])],
                    1
                  ),
                  _c("h4", { staticClass: "title" }, [_vm._v("Cancelled Job")]),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c("h4", [
                    _vm._v(
                      "We are sad to hear that you have cancelled the job. We will be able to reach out within in 24 hours after completing the form below."
                    ),
                  ]),
                  _c(
                    "md-field",
                    [
                      _c("label", { attrs: { for: "select" } }, [
                        _vm._v("Let us know what your issue is?"),
                      ]),
                      _c(
                        "md-select",
                        {
                          attrs: { name: "select" },
                          model: {
                            value: _vm.reason,
                            callback: function ($$v) {
                              _vm.reason = $$v
                            },
                            expression: "reason",
                          },
                        },
                        [
                          _c("md-option", { attrs: { value: "Reason 1" } }, [
                            _vm._v("Reason 1"),
                          ]),
                          _c("md-option", { attrs: { value: "Reason 2" } }, [
                            _vm._v("Reason 2"),
                          ]),
                          _c("md-option", { attrs: { value: "Reason 3" } }, [
                            _vm._v("Reason 3"),
                          ]),
                          _c("md-option", { attrs: { value: "Reason 4" } }, [
                            _vm._v("Reason 4"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "md-field",
                    [
                      _c("label", [
                        _vm._v(
                          "Please give a full description as to why you cancelled"
                        ),
                      ]),
                      _c("md-textarea", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
                _c(
                  "button",
                  { staticClass: "md-button md-success md-theme-default" },
                  [
                    _c("div", { staticClass: "md-ripple" }, [
                      _c("div", { staticClass: "md-button-content" }, [
                        _vm._v("Submit"),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Oops!"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "Please complete all fields so that we examine the problem before we contact you."
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.successModal
        ? _c(
            "modal",
            { on: { close: _vm.successModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Help is on its way!"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.successModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "We appreciate you taking the time to complete the form. As promised we will reach out to you at our earliest convenience."
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.post },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }