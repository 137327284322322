var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", { staticClass: "info-text" }, [
        _vm._v("Specify the location of work"),
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item md-small-size-100" },
          [
            _c("icon-checkbox", {
              attrs: { icon: "fas fa-laptop", title: "Remote" },
              on: { input: _vm.remoteSelection },
              model: {
                value: _vm.remote,
                callback: function ($$v) {
                  _vm.remote = $$v
                },
                expression: "remote",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-small-size-100" },
          [
            _c("icon-checkbox", {
              attrs: { icon: "fas fa-building", title: "On-site" },
              on: { input: _vm.onsiteSelection },
              model: {
                value: _vm.onsite,
                callback: function ($$v) {
                  _vm.onsite = $$v
                },
                expression: "onsite",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-small-size-100" },
          [
            _c("icon-checkbox", {
              attrs: { icon: "fas fa-home", title: "Hybrid" },
              on: { input: _vm.hybridSelection },
              model: {
                value: _vm.hybrid,
                callback: function ($$v) {
                  _vm.hybrid = $$v
                },
                expression: "hybrid",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.onsite || _vm.hybrid
        ? _c(
            "div",
            { staticClass: "md-layout-item mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.v$.address.required.$invalid &&
                        _vm.v$.address.$dirty,
                    },
                    {
                      "md-error":
                        _vm.v$.address.required.$invalid &&
                        _vm.v$.address.$dirty,
                    },
                  ],
                },
                [
                  _c("md-input", {
                    attrs: {
                      "data-vv-name": "address",
                      "data-vv-scope": "common",
                      type: "text",
                      name: "address",
                      required: "",
                      id: "input",
                    },
                    on: { change: _vm.addAddress },
                    model: {
                      value: _vm.v$.address.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.v$.address, "$model", $$v)
                      },
                      expression: "v$.address.$model",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.v$.address.required.$invalid &&
                                _vm.v$.address.$dirty,
                              expression:
                                "v$.address.required.$invalid && v$.address.$dirty",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.v$.address.required.$invalid &&
                                _vm.v$.address.$dirty,
                              expression:
                                "!v$.address.required.$invalid && v$.address.$dirty",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.v$.address.required.$invalid && _vm.v$.address.$dirty
                ? _c("p", { staticClass: "error" }, [
                    _vm._v("Please specify a location"),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("br"),
          _c("br"),
          _vm.jobType === "Once-off Project/Task"
            ? _c(
                "div",
                { staticClass: "md-layout-item mt-4 md-small-size-100" },
                [
                  _c(
                    "md-field",
                    {
                      class: [
                        { "md-valid": !_vm.v$.deadline.required.$invalid },
                        {
                          "md-error":
                            _vm.v$.deadline.required.$invalid &&
                            _vm.v$.deadline.$dirty,
                        },
                      ],
                    },
                    [
                      _c("label", [_vm._v("Duration")]),
                      _c(
                        "md-select",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            "data-vv-name": "deadline",
                            "data-vv-scope": "project",
                            type: "text",
                            name: "deadline",
                            required: "",
                          },
                          on: { input: _vm.addDeadline },
                          model: {
                            value: _vm.v$.deadline.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.v$.deadline, "$model", $$v)
                            },
                            expression: "v$.deadline.$model",
                          },
                        },
                        _vm._l(_vm.deadlines, function (deadline, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: deadline } },
                            [_vm._v(_vm._s(deadline))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.v$.deadline.required.$invalid &&
                                    _vm.v$.deadline.$dirty,
                                  expression:
                                    "v$.deadline.required.$invalid && v$.deadline.$dirty",
                                },
                              ],
                              staticClass: "error",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.v$.deadline.required.$invalid &&
                                    _vm.v$.deadline.$dirty,
                                  expression:
                                    "!v$.deadline.required.$invalid && v$.deadline.$dirty",
                                },
                              ],
                              staticClass: "success",
                            },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.v$.deadline.required.$invalid && _vm.v$.deadline.$dirty
                    ? _c("p", { staticClass: "error" }, [
                        _vm._v("Please specify a duration"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.jobType !== "Once-off Project/Task"
            ? [
                _c(
                  "div",
                  { staticClass: "md-layout-item mt-4 md-small-size-100" },
                  [
                    _c(
                      "md-field",
                      {
                        class: [
                          {
                            "md-valid":
                              !_vm.v$.daysOfTheWeek.required.$invalid &&
                              _vm.v$.daysOfTheWeek.$dirty,
                          },
                          {
                            "md-error":
                              _vm.v$.daysOfTheWeek.required.$invalid &&
                              _vm.v$.daysOfTheWeek.$dirty,
                          },
                        ],
                      },
                      [
                        _c("label", [_vm._v("Working Days")]),
                        _vm.jobType !== "Once-off Project/Task"
                          ? _c(
                              "md-select",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  "data-vv-name": "daysOfTheWeek",
                                  "data-vv-scope": "nonProject",
                                  type: "text",
                                  name: "daysOfTheWeek",
                                  multiple: "",
                                },
                                on: { input: _vm.addDaysOfTheWeek },
                                model: {
                                  value: _vm.v$.daysOfTheWeek.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.v$.daysOfTheWeek,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "v$.daysOfTheWeek.$model",
                                },
                              },
                              _vm._l(
                                _vm.DaysOfTheWeek,
                                function (daysOfTheWeek, index) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: index,
                                      attrs: { value: daysOfTheWeek },
                                    },
                                    [_vm._v(_vm._s(daysOfTheWeek))]
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _c(
                          "slide-y-down-transition",
                          [
                            _c(
                              "md-icon",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.v$.daysOfTheWeek.required.$invalid &&
                                      _vm.v$.daysOfTheWeek.$dirty,
                                    expression:
                                      "v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty",
                                  },
                                ],
                                staticClass: "error",
                              },
                              [_vm._v("close")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "slide-y-down-transition",
                          [
                            _c(
                              "md-icon",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.v$.daysOfTheWeek.required.$invalid &&
                                      _vm.v$.daysOfTheWeek.$dirty,
                                    expression:
                                      "!v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty",
                                  },
                                ],
                                staticClass: "success",
                              },
                              [_vm._v("done")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.v$.daysOfTheWeek.required.$invalid &&
                    _vm.v$.daysOfTheWeek.$dirty
                      ? _c("p", { staticClass: "error" }, [
                          _vm._v("Please specify the working days"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.jobType === "Internship" || _vm.jobType === "Part-time"
            ? _c(
                "div",
                { staticClass: "md-layout-item mt-4 md-small-size-100" },
                [
                  _c(
                    "md-field",
                    {
                      class: [
                        {
                          "md-valid":
                            !_vm.v$.hours.required.$invalid &&
                            _vm.v$.hours.$dirty,
                        },
                        {
                          "md-error":
                            _vm.v$.hours.required.$invalid &&
                            _vm.v$.hours.$dirty,
                        },
                      ],
                    },
                    [
                      _c("label", [_vm._v("Working Hours")]),
                      _c(
                        "md-select",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            "data-vv-scope": "partTime",
                            "data-vv-name": "hours",
                            type: "text",
                            name: "hours",
                          },
                          on: { input: _vm.addHours },
                          model: {
                            value: _vm.v$.hours.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.v$.hours, "$model", $$v)
                            },
                            expression: "v$.hours.$model",
                          },
                        },
                        _vm._l(_vm.workingHours, function (hours, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: hours } },
                            [_vm._v(_vm._s(hours))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.v$.hours.required.$invalid &&
                                    _vm.v$.hours.$dirty,
                                  expression:
                                    "v$.hours.required.$invalid && v$.hours.$dirty",
                                },
                              ],
                              staticClass: "error",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.v$.hours.required.$invalid &&
                                    _vm.v$.hours.$dirty,
                                  expression:
                                    "!v$.hours.required.$invalid && v$.hours.$dirty",
                                },
                              ],
                              staticClass: "success",
                            },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.v$.hours.required.$invalid && _vm.v$.hours.$dirty
                    ? _c("p", { staticClass: "error" }, [
                        _vm._v("Please specify the working hours"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "md-layout-item mt-4 md-small-size-100" },
            [
              _c(
                "md-datepicker",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.v$.applicationDeadline.required.$invalid &&
                        _vm.v$.applicationDeadline.$dirty,
                    },
                    {
                      "md-error":
                        _vm.v$.applicationDeadline.required.$invalid &&
                        _vm.v$.applicationDeadline.$dirty,
                    },
                  ],
                  attrs: {
                    "data-vv-name": "applicationDeadline",
                    required: "",
                    "md-immediately": "",
                  },
                  on: { input: _vm.addApplicationDeadline },
                  model: {
                    value: _vm.v$.applicationDeadline.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.v$.applicationDeadline, "$model", $$v)
                    },
                    expression: "v$.applicationDeadline.$model",
                  },
                },
                [
                  _c("label", [_vm._v("Application Deadline")]),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.v$.applicationDeadline.required.$invalid,
                              expression:
                                "v$.applicationDeadline.required.$invalid",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.v$.applicationDeadline.required.$invalid &&
                                _vm.v$.applicationDeadline.$dirty,
                              expression:
                                "!v$.applicationDeadline.required.$invalid && v$.applicationDeadline.$dirty",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.v$.applicationDeadline.required.$invalid &&
              _vm.v$.applicationDeadline.$dirty
                ? _c(
                    "p",
                    {
                      staticClass: "error",
                      staticStyle: { "margin-left": "2.3rem" },
                    },
                    [_vm._v(" Please specify an application deadline ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [_vm._v(_vm._s(_vm.body))]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }