var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c("div", { staticClass: "profile-banner" }, [
      _c("div", { staticClass: "profile-banner-content" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "margin-bottom": "2rem",
            },
          },
          [
            _vm.edit
              ? _c(
                  "md-button",
                  {
                    staticClass: "jb-standard",
                    on: { click: _vm.editProfile },
                  },
                  [_vm._v("Edit Profile")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "profile-avatar" }, [
          _vm.profile.profile
            ? _c("img", {
                staticClass: "img",
                attrs: { src: _vm.profile.profile },
              })
            : _vm._e(),
          !_vm.profile.profile
            ? _c("img", {
                staticClass: "img",
                attrs: { src: _vm.cardUserImage },
              })
            : _vm._e(),
        ]),
        _c("div", { staticStyle: { width: "100%" } }, [
          _c("span", { staticClass: "profile-full-name-header" }, [
            _c("h1", { staticClass: "profile-full-name" }, [
              _vm._v(_vm._s(_vm.profile.companyName)),
            ]),
          ]),
          _c("p", { staticClass: "profile-bio" }, [
            _vm._v(_vm._s(_vm.profile.bio)),
          ]),
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("div", { staticClass: "profile-social-media-icons" }, [
              _c("p", [
                _vm.profile.website
                  ? _c("i", {
                      staticClass: "fa fa-globe",
                      staticStyle: { color: "#1da1f2", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.openLink(_vm.profile.website)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.profile.website) + " "),
              ]),
              _c("p", [
                _vm.profile.industry
                  ? _c("i", {
                      staticClass: "fa fa-industry",
                      staticStyle: { color: "#ff4500", cursor: "pointer" },
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.profile.industry) + " "),
              ]),
              _c("p", [
                _vm.profile.companySize
                  ? _c("i", {
                      staticClass: "fa fa-users",
                      staticStyle: { color: "#5dade2", cursor: "pointer" },
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.profile.companySize) + " employees "),
              ]),
              _c("p", [
                _vm.profile.addressLine1
                  ? _c("i", {
                      staticClass: "fa fa-map-marker",
                      staticStyle: { color: "#e74c3c", cursor: "pointer" },
                    })
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.profile.addressLine1 === " null"
                        ? ""
                        : _vm.profile.addressLine1 + ",") +
                        " " +
                        _vm.profile.city +
                        ", " +
                        _vm.profile.country
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }