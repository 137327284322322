var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c(
        "div",
        { staticStyle: { display: "flex", "flex-direction": "column" } },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "md-icon",
                {
                  class: _vm.v$.coverOptions.$error && "error",
                  staticStyle: { "margin-right": "1rem" },
                },
                [_vm._v("school")]
              ),
              _c(
                "label",
                { style: _vm.v$.coverOptions.$error && "color:red" },
                [_vm._v("What does the bursary cover?")]
              ),
            ],
            1
          ),
          _vm.v$.coverOptions.$error
            ? _c("p", { staticClass: "error_message" }, [
                _vm._v("Please select at least one option"),
              ])
            : _vm._e(),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addTuition },
              model: {
                value: _vm.tuition,
                callback: function ($$v) {
                  _vm.tuition = $$v
                },
                expression: "tuition",
              },
            },
            [_vm._v("Tuition")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addAccommodation },
              model: {
                value: _vm.accommodation,
                callback: function ($$v) {
                  _vm.accommodation = $$v
                },
                expression: "accommodation",
              },
            },
            [_vm._v("Accommodation")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addMeals },
              model: {
                value: _vm.meals,
                callback: function ($$v) {
                  _vm.meals = $$v
                },
                expression: "meals",
              },
            },
            [_vm._v("Meals")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addBooks },
              model: {
                value: _vm.books,
                callback: function ($$v) {
                  _vm.books = $$v
                },
                expression: "books",
              },
            },
            [_vm._v("Books")]
          ),
          _c(
            "md-checkbox",
            {
              on: { change: _vm.addStipend },
              model: {
                value: _vm.stipend,
                callback: function ($$v) {
                  _vm.stipend = $$v
                },
                expression: "stipend",
              },
            },
            [_vm._v("Stipend")]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "md-icon",
            {
              class: _vm.v$.additionalCoverDetails.$error && "error",
              staticStyle: { "margin-right": "1rem" },
            },
            [_vm._v("description")]
          ),
          _c(
            "label",
            { style: _vm.v$.additionalCoverDetails.$error && "color:red" },
            [_vm._v("Additional Bursary Cover Details")]
          ),
        ],
        1
      ),
      _vm.v$.additionalCoverDetails.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v("Additional details are required"),
          ])
        : _vm._e(),
      _c("vue-editor", {
        staticStyle: { margin: "1rem 0 3rem 0 !important" },
        on: { "text-change": _vm.addAdditionalCoverDetails },
        model: {
          value: _vm.additionalCoverDetails,
          callback: function ($$v) {
            _vm.additionalCoverDetails = $$v
          },
          expression: "additionalCoverDetails",
        },
      }),
      _c(
        "div",
        { staticClass: "justify-between" },
        [
          _c(
            "md-button",
            { staticClass: "md-raised", on: { click: _vm.previousStep } },
            [_c("md-icon", [_vm._v("arrow_back")]), _vm._v("Previous")],
            1
          ),
          _c(
            "md-button",
            {
              staticClass: "md-raised md-primary",
              on: { click: _vm.nextStep },
            },
            [_vm._v("Next"), _c("md-icon", [_vm._v("arrow_forward")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }