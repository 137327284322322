var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
    _vm.loading
      ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
      : _vm._e(),
    _vm.dissatisfiedJobs
      ? _c(
          "div",
          { staticClass: "md-layout" },
          _vm._l(_vm.jobs, function (job) {
            return _c(
              "div",
              {
                key: job.id,
                staticClass:
                  "card-layout md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33",
              },
              [
                _c(
                  "product-card",
                  { attrs: { "header-animation": "false" } },
                  [
                    !job.profilePicture
                      ? _c("img", {
                          staticClass: "img",
                          attrs: { slot: "imageHeader", src: _vm.product1 },
                          slot: "imageHeader",
                        })
                      : _vm._e(),
                    job.profilePicture
                      ? _c("img", {
                          staticClass: "img",
                          attrs: {
                            slot: "imageHeader",
                            src: job.profilePicture,
                          },
                          slot: "imageHeader",
                        })
                      : _vm._e(),
                    _c(
                      "md-icon",
                      { attrs: { slot: "fixed-button" }, slot: "fixed-button" },
                      [_vm._v("build")]
                    ),
                    _c(
                      "template",
                      { slot: "first-button" },
                      [
                        _c("md-icon", [_vm._v("art_track")]),
                        _c(
                          "md-tooltip",
                          { attrs: { "md-direction": "bottom" } },
                          [_vm._v("View")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "second-button" },
                      [
                        _c("md-icon", [_vm._v("edit")]),
                        _c(
                          "md-tooltip",
                          { attrs: { "md-direction": "bottom" } },
                          [_vm._v("Edit")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "third-button" },
                      [
                        _c("md-icon", [_vm._v("close")]),
                        _c(
                          "md-tooltip",
                          {
                            attrs: { "md-direction": "bottom" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteJob(job.id)
                              },
                            },
                          },
                          [_vm._v("Remove")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "h4",
                      {
                        staticClass: "title",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_vm._v(" " + _vm._s(job.name) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "card-description",
                        attrs: { slot: "description" },
                        slot: "description",
                      },
                      [_vm._v(" " + _vm._s(job.category) + " ")]
                    ),
                    _c("template", { slot: "footer" }, [
                      _c("div", { staticClass: "price" }, [
                        _c("i", { staticClass: "fas fa-money-bill-wave" }),
                        _vm._v(" budget "),
                        _c("h4", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(_vm._s(job.budget)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "price" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "client-micro-status",
                                  params: { id: job.id },
                                },
                              },
                            },
                            [
                              _c("md-button", { staticClass: "md-success" }, [
                                _vm._v("View"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "stats" }, [
                        _c(
                          "div",
                          { staticClass: "price" },
                          [
                            _c("md-icon", [_vm._v("place")]),
                            _vm._v(" Location "),
                            job.location !== "Remote"
                              ? _c(
                                  "h4",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v("on-site")]
                                )
                              : _c(
                                  "h4",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(_vm._s(job.location))]
                                ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm.dissatisfiedJobs === false
      ? _c("div", [
          _c(
            "h1",
            { staticClass: "black", staticStyle: { "text-align": "center" } },
            [_vm._v("You have no dissatisfied jobs")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }