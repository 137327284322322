var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout-item md-size-100 transparant" },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-content",
            [
              _c("h2", [_c("center", [_vm._v("General")])], 1),
              _c(
                "collapse",
                {
                  attrs: {
                    collapse: [
                      "Who is Jobox?",
                      "What is a Microjob",
                      "What is holding payment in escrow",
                      "Do I have to pay a monthly subscription?",
                      "Who is Sage Pay",
                      "How much do you take from every microjob that is done?",
                      "Can I have both a student and a client account?",
                      "How does my rating and reviewing system work?",
                    ],
                    icon: "keyboard_arrow_down",
                    "color-collapse": "success",
                  },
                },
                [
                  _c("template", { slot: "md-collapse-pane-1" }, [
                    _c("p", [
                      _vm._v(
                        " Jobox is an online microjob platform that connects vetted and verified freelancers and clients to get things done more easily. Jobox improves the way you work by using a simple and secure platform to facilitate the entire process of working together, from project conception to final payment "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-2" }, [
                    _c("p", [
                      _vm._v(
                        "A microjob is a temporary, task-type job of all types, this includes both remote and proximate (face-to-face) jobs"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-3" }, [
                    _c("p", [
                      _vm._v(
                        " Once the client makes a payment, it is held in a virtual bank account hosted by Sage Pay. This money is not paid directly to the freelancer so we can still transfer it back to the client if the Microjob is not completed. Once the Microjob is completed, we then release the funds to the freelancer "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-4" }, [
                    _c("p", [
                      _vm._v(
                        "No, Jobox is free to use for both Freelancers and Clients"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-5" }, [
                    _c("p", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://sagepay.co.za/",
                            target: "_blank",
                          },
                        },
                        [_vm._v("Sage Pay")]
                      ),
                      _vm._v(
                        " is our payment facilitator. Sage Pay is a leading payments provider to businesses of all sizes throughout South Africa. We form part of the UK based Sage Group plc, that combined has over 30 years of expertise. Sage has partnered with more than 6 million small and medium-sized businesses across 24 countries, providing quality accounting, payroll and payments services "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-6" }, [
                    _c("p", [
                      _vm._v(
                        "We charge a 10% facilitation fee from every transaction that happens from the completion of a microjob"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-7" }, [
                    _c("p", [
                      _vm._v(
                        " Yes, you can have both a freelancer and a client account using the same email address or if you want, you can create a new account with a different email address "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-8" }, [
                    _c("p", [
                      _vm._v(
                        "At the completion of every microjob, both clients and freelancers are given an opportunity to rate each other"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "The ratings and reviews are stored and are seen by the public"
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c("h2", [_c("center", [_vm._v("Freelancer")])], 1),
              _c(
                "collapse",
                {
                  attrs: {
                    collapse: [
                      "What is a freelancer?",
                      "How do I receive payment for the work I have done?",
                      "Who rates and reviews me?",
                      "What happens if a client was to cancel a Microjob before it’s over?",
                      "How do I delete my account?",
                    ],
                    icon: "keyboard_arrow_down",
                    "color-collapse": "success",
                  },
                },
                [
                  _c("template", { slot: "md-collapse-pane-1" }, [
                    _c("p", [
                      _vm._v(
                        "A freelancer is a self-employed person offering services, usually to multiple clients at a time."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "On Jobox, freelancers are those people that bid for jobs and do the work that is requested by the client"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-2" }, [
                    _c("p", [
                      _vm._v(
                        " Firstly, you have to fill out your banking details on the account page. Once that is done, when the client clicks on the Microjob complete button, the money will be transferred to your account "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-3" }, [
                    _c("p", [
                      _vm._v(
                        "The client rates and reviews you once the Microjob is completed"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-4" }, [
                    _c("p", [
                      _vm._v(
                        "Please contact us at (contact@jobox.za) if this does happen"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-5" }, [
                    _c("p", [
                      _vm._v(
                        "Please contact us at (contact@jobox.za) and we will assist you in this process"
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c("h2", [_c("center", [_vm._v("Client")])], 1),
              _c(
                "collapse",
                {
                  attrs: {
                    collapse: [
                      "What is a client?",
                      "Is the payment procedure safe?",
                      "What kind of Microjobs can get done?",
                      "Who rates and reviews me?",
                      "What happens is if I want to cancel a Microjob before it’s completed?",
                      "How do I delete my account?",
                    ],
                    icon: "keyboard_arrow_down",
                    "color-collapse": "success",
                  },
                },
                [
                  _c("template", { slot: "md-collapse-pane-1" }, [
                    _c("p", [
                      _vm._v(
                        "It is the user which offers Microjobs for Freelancers to bid for or to apply to and buys a service from a Freelancers"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-2" }, [
                    _c("p", [
                      _vm._v(
                        " Yes, we use Sage Pay which is a leading payments provider to businesses of all sizes throughout South Africa. They form part of the UK based Sage Group plc, that combined has over 30 years of expertise "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-3" }, [
                    _c("p", [
                      _vm._v(
                        "Our platform currently only allows for a once of task or project (what we have dubbed a Microjob)"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Any Microjob that has a set fee which can be agreed upon by the freelancer and client before the Microjob begins, our platform can facilitate"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-4" }, [
                    _c("p", [
                      _vm._v(
                        "The freelancer rates and reviews you once the Microjob is completed"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-5" }, [
                    _c("p", [
                      _vm._v(
                        "Please contact us at (contact@jobox.za) before you do cancel the Microjob and we will assist you in this process"
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-6" }, [
                    _c("p", [
                      _vm._v(
                        "Please contact us at (contact@jobox.za) and we will assist you in this process"
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }