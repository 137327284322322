var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "md-layout",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.support({ subject: _vm.subject, message: _vm.message })
        },
      },
    },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "md-layout-item md-small-size-100" },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("mail_outline")])],
                    1
                  ),
                  _c("h4", { staticClass: "title" }, [_vm._v("Get Support")]),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c("h4", [_vm._v("What can we help you with?")]),
                  _c(
                    "md-field",
                    [
                      _c("label", { attrs: { for: "select" } }, [
                        _vm._v("Let us know what your issue is?"),
                      ]),
                      _c(
                        "md-select",
                        {
                          attrs: { name: "select" },
                          model: {
                            value: _vm.subject,
                            callback: function ($$v) {
                              _vm.subject = $$v
                            },
                            expression: "subject",
                          },
                        },
                        _vm._l(_vm.subjects, function (subject, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: subject } },
                            [_vm._v(_vm._s(subject))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Tell us more")]),
                      _c("md-textarea", {
                        attrs: { required: "", type: "text" },
                        model: {
                          value: _vm.message,
                          callback: function ($$v) {
                            _vm.message = $$v
                          },
                          expression: "message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
                _c(
                  "button",
                  { staticClass: "md-button md-success md-theme-default" },
                  [
                    _c("div", { staticClass: "md-ripple" }, [
                      _c("div", { staticClass: "md-button-content" }, [
                        _vm._v("Submit"),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm.success
                    ? _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Support is on its way!"),
                      ])
                    : _vm._e(),
                  _vm.error
                    ? _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! ✋"),
                      ])
                    : _vm._e(),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _vm.success
                  ? _c("p", { staticClass: "black" }, [
                      _vm._v(
                        "We will investigate the matter as soon as possible."
                      ),
                    ])
                  : _vm._e(),
                _vm.error
                  ? _c("p", { staticClass: "black" }, [
                      _vm._v("Please let us know what your issue is."),
                    ])
                  : _vm._e(),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.submit },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }