var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "1500px", margin: "auto" } },
    [
      _c(
        "div",
        {
          staticClass: "dashboard-header",
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "space-between",
          },
        },
        [
          _c("h2", [_vm._v("Bursary Demographics Dashboard")]),
          _c("CsvDownload", {
            attrs: {
              data: _vm.filteredApplicants,
              availableColumns: _vm.dynamicCsvColumns,
            },
          }),
        ],
        1
      ),
      _c(
        "md-card",
        [
          _c("md-card-content", [
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "Race",
                        placeholder: "Filter by Race",
                        options: _vm.allUniqueRaces,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedRace,
                        callback: function ($$v) {
                          _vm.selectedRace = $$v
                        },
                        expression: "selectedRace",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "Gender",
                        placeholder: "Filter by Gender",
                        options: _vm.allUniqueGenders,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedGender,
                        callback: function ($$v) {
                          _vm.selectedGender = $$v
                        },
                        expression: "selectedGender",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "Province",
                        placeholder: "Filter by Province",
                        options: _vm.allUniqueProvinces,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedProvince,
                        callback: function ($$v) {
                          _vm.selectedProvince = $$v
                        },
                        expression: "selectedProvince",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "University",
                        placeholder: "Filter by University",
                        options: _vm.allUniqueUniversities,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedUniversity,
                        callback: function ($$v) {
                          _vm.selectedUniversity = $$v
                        },
                        expression: "selectedUniversity",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "Degree",
                        placeholder: "Filter by Degree",
                        options: _vm.allUniqueDegrees,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedDegree,
                        callback: function ($$v) {
                          _vm.selectedDegree = $$v
                        },
                        expression: "selectedDegree",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "Year",
                        placeholder: "Filter by Year",
                        options: _vm.allUniqueYears,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedYear,
                        callback: function ($$v) {
                          _vm.selectedYear = $$v
                        },
                        expression: "selectedYear",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-input" },
                  [
                    _c("SelectField", {
                      attrs: {
                        label: "Age",
                        placeholder: "Filter by Age",
                        options: _vm.allUniqueAgeGroups,
                      },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.selectedAgeGroup,
                        callback: function ($$v) {
                          _vm.selectedAgeGroup = $$v
                        },
                        expression: "selectedAgeGroup",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-warning",
                    on: { click: _vm.resetFilters },
                  },
                  [_vm._v("Clear Filters")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "stats-card-container" }, [
        _c("div", { staticClass: "stats-card" }, [
          _c("h3", [_vm._v("Total Applicants")]),
          _c("h2", [_vm._v(_vm._s(_vm.filteredApplicants.length))]),
        ]),
        _c("div", { staticClass: "stats-card" }, [
          _c("h3", [_vm._v("Selected Applicants")]),
          _c("h2", [_vm._v(_vm._s(_vm.selectedApplicantsCount))]),
        ]),
      ]),
      _c("div", { staticClass: "applicants-toggle-container" }, [
        _c(
          "div",
          {
            staticClass: "applicant-toggle",
            class: { active: _vm.applicantView === "all" },
            on: {
              click: function ($event) {
                return _vm.setApplicantView("all")
              },
            },
          },
          [_c("p", [_vm._v("All Applicants")])]
        ),
        _c(
          "div",
          {
            staticClass: "applicant-toggle",
            class: { active: _vm.applicantView === "selected" },
            on: {
              click: function ($event) {
                return _vm.setApplicantView("selected")
              },
            },
          },
          [_c("p", [_vm._v("Selected Applicants")])]
        ),
      ]),
      _c("div", { staticClass: "dashboard-container" }, [
        _c("div", { staticClass: "chart-row row-1" }, [
          _c(
            "div",
            { staticClass: "chart-card half" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("people")]),
                  _vm._v(" Race Distribution "),
                ],
                1
              ),
              _c("DonutChart", {
                attrs: {
                  title: "Race Distribution",
                  options: _vm.raceChartOptions,
                  series: _vm.raceSeries,
                  width: "100%",
                  height: "100%",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chart-card half" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("wc")]),
                  _vm._v(" Gender Distribution "),
                ],
                1
              ),
              _c("DonutChart", {
                attrs: {
                  title: "Gender Distribution",
                  options: _vm.genderChartOptions,
                  series: _vm.genderSeries,
                  width: "100%",
                  height: "100%",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "chart-row row-2" }, [
          _c(
            "div",
            { staticClass: "chart-card half bar" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("accessibility")]),
                  _vm._v(" Age Distribution "),
                ],
                1
              ),
              _c("BarChart", {
                attrs: {
                  title: "Age Distribution",
                  options: _vm.ageChartOptions,
                  series: _vm.ageSeries,
                  width: "100%",
                  height: "100%",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chart-card half bar" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("location_on")]),
                  _vm._v(" Province Distribution "),
                ],
                1
              ),
              _c("BarChart", {
                attrs: {
                  title: "Province Distribution",
                  options: _vm.provinceChartOptions,
                  series: _vm.provinceSeries,
                  width: "100%",
                  height: "100%",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "chart-row row-3" }, [
          _c(
            "div",
            { staticClass: "chart-card half fit" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("school")]),
                  _vm._v(" University Distribution "),
                ],
                1
              ),
              _c("BarChart", {
                attrs: {
                  title: "University Distribution",
                  options: _vm.universityChartOptions,
                  series: _vm.universitySeries,
                  width: "100%",
                  height: "500",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chart-card half fit" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("assignment")]),
                  _vm._v(" Degree Distribution "),
                ],
                1
              ),
              _c("BarChart", {
                attrs: {
                  title: "Degree Distribution",
                  options: _vm.degreeChartOptions,
                  series: _vm.degreeSeries,
                  width: "100%",
                  height: "500",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "chart-row row-4" }, [
          _c(
            "div",
            { staticClass: "chart-card full bar" },
            [
              _c(
                "h3",
                [
                  _c("md-icon", [_vm._v("date_range")]),
                  _vm._v(" Year of Study Distribution "),
                ],
                1
              ),
              _c("BarChart", {
                attrs: {
                  title: "Year of Study Distribution",
                  options: _vm.yearOfStudyChartOptions,
                  series: _vm.yearOfStudySeries,
                  width: "100%",
                  height: "100%",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }