var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout-item md-size-100 transparant" },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-content",
            [
              _c(
                "collapse",
                {
                  attrs: {
                    collapse: [
                      "SECTION 1 - OUR PRIVACY OBLIGATIONS",
                      "SECTION 2 - THE TYPES OF PERSONAL INFORMATION WE COLLECT AND HOLD",
                      "SECTION 3 - HOW WE COLLECT PERSONAL INFORMATION",
                      "SECTION 4 - HOW WE USE PERSONAL INFORMATION",
                      "SECTION 5 - WHEN WE DISCLOSE PERSONAL INFORMATION",
                      "SECTION 6 - ACCESSING, CORRECTING, OR DOWNLOADING YOUR PERSONAL INFORMATION",
                      "SECTION 7 - EXERCISING YOUR OTHER RIGHTS",
                      "SECTION 8 - CHANGES TO OUR PRIVACY POLICY",
                    ],
                    icon: "keyboard_arrow_down",
                    "color-collapse": "success",
                  },
                },
                [
                  _c("template", { slot: "md-collapse-pane-1" }, [
                    _c("p", [
                      _vm._v(
                        "The definitions used in the Website Terms and Conditions shall apply mutatis mutandis in this Privacy Policy."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Jobox has created this privacy policy in order to demonstrate Our firm commitment to the right to privacy. The following discloses the processing practices pertaining to information on the Jobox Website. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        ' By using the Website and accepting the Terms and Conditions You also agree to this Privacy Policy. If You do not agree to this Privacy Policy, You may not use the Website. The terms "We," "Us," "Our," or "Jobox" includes Jobox (Pty) Ltd and Our affiliates. '
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Jobox's Privacy Policy applies to Personal Information processed by Jobox. 'Personal information' means any personal details which you provide in any way through the use of this Website, in any form whatsoever, capable of identifying You which includes but is not limited to your names, postal and physical addresses, contact numbers, e-mail address, opinions, preferences, name of your University and any other type of personal information as defined in the Data Protection Laws, each of which forms a separate data tag of information on the Website. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "We will review this Privacy Policy regularly, and We may update the same on the Website, from time to time, at our sole discretion."
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-2" }, [
                    _c("p", [
                      _vm._v(
                        " We collect Personal Information about Our Users in order to provide Our Services, and customer support. Our Services and customer support are provided through many platforms including but not limited to: websites, phone apps, email, and telephone. The specific platform for Services and customer support You interact with may affect the Personal Information We process. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        ' Not all information requested, collected, and processed by Us constitutes "Personal Information", especially if the information is incapable of identifying a specific person, falls under the Data Protection Laws exceptions to processing or is for historic and statistical purposes “Non-Personal Information”. This will include the majority of "User Generated Content" that You provide Us with the intention of sharing with other Users. Such Non-Personal Information is not covered by this Privacy Policy. However, Non-Personal Information may be used in aggregate or be linked with existing Personal Information; when in this form it will be treated as Personal Information. As such, this Privacy Policy will list both types of information for the sake of transparency. '
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " In some situations, Users may provide Us with Personal Information without Us asking for it, or through means not intended for its purpose. Whilst We may take reasonable steps to protect this data, the User will have bypassed Our systems, processes, and controls and thus the Personal Information provided will not be governed by this Privacy Policy. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " In some situations, Users may provide Us with Personal Information over platforms that are outside of Our control; for example through Affiliates, social media platforms or forums. Whilst any Personal Information processed by Us is governed by this Privacy Policy, the platform or forum by which it was communicated will be governed by its own Privacy Policy. "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-3" }, [
                    _c("h4", [
                      _vm._v("Information that You specifically give Us"),
                    ]),
                    _c("p", [
                      _vm._v(
                        " While You use Our Services You may be asked to provide Personal Information. This might happen through Our Website, applications, online chat systems, Fixed Term Contracts, telephone, paper forms, or in-person meetings. We will explain how We will use the Personal Information We are asking for, if outside the scope of this Privacy Policy prior to obtaining your informed consent to process the Personal Information. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "In general, We may request, collect, or process the following information:"
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Account Details - username, password, profile picture."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Contact Details - email address, phone number."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Location Details - physical address, billing address, timezone."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " Identity Details - full name, proof of identity (e.g. drivers licence, passport), proof of address (e.g. utility bill), a photograph of the user. "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Financial Information - credit card details, wire transfer details, payment processor details (e.g. skrill, paypal), tax numbers."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "User Generated Content - user profiles, user reviews, user messages etc."
                        ),
                      ]),
                    ]),
                    _c("h4", [
                      _vm._v("Information that We collect from others"),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Users can provide informed consent, by clicking the pop up consent block in order for Us to connect to specific User accounts on other platforms to process Personal Information. This includes but is not limited to Facebook, LinkedIn, Instagram, and Google. Personal Information processed will be governed by this Privacy Policy. Users can stop Us from processing Personal Information from other platforms by removing Our access on the other platform or by contacting Our support team. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Users have the ability to invite non-Users to Our website by providing contact details such as email addresses. In these situations, the Personal Information will be collected and stored by Us to contact the non-user and obtain the non-user’s informed consent in terms of the Data Protection Laws prior to the continued processing of the Personal Information in order to prevent abuse of the invite systems. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Your payment provider may transmit information about the payment that We may collect or process."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "In some situations, Personal Information of Users may be collected from public sources."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "We may collect or process the following information:"
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v("Basic Details - username, profile picture."),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Contact Details - email address, phone number."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Location Details - Physical Address, billing address, timezone."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Financial Information - payment account details (e.g. PayPal email address and physical address), and wire transfer details."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "List of contacts - email provider address book."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("User-Generated Content - user profile."),
                      ]),
                    ]),
                    _c("h4", [
                      _vm._v("Information We collect as You use Our service"),
                    ]),
                    _c("p", [
                      _vm._v(
                        " We maintain records of the interactions We have with Our Users, including the Services, customer support and feedback provided. This includes cookies that track the interactions Our Users have with Our Website such as when a User has viewed a page or clicked a button. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " In order to deliver certain Services, We may process your GPS coordinates, where available from your device used to sign into your Account. Most modern devices such as smartphones will display a permission request when Our Website requests this Personal Information. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " When We are contacted by You, We may process Personal Information that is intrinsic to the communication. For example, if We are contacted via email, We will process the email address provided. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v("We may process the following information:"),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Metadata - IP address, computer and connection information, referring web page, standard web log information, language settings, timezone, etc."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Device Information - device identifier, device type, device plugins, hardware capabilities, etc."
                        ),
                      ]),
                      _c("li", [_vm._v("Location - GPS position.")]),
                      _c("li", [
                        _vm._v(
                          "Actions - pages viewed, buttons clicked, time spent viewing, search keywords, etc."
                        ),
                      ]),
                    ]),
                    _c("h4", [_vm._v("Links to other sites")]),
                    _c("p", [
                      _vm._v(
                        " On Our Website, You will encounter links to third-party websites. These links may be from Us or may appear as content generated by other Users. These linked sites are not under Our control and thus We are not responsible for any actions by the third parties. Before providing your information via any other website, We advise You to examine the terms and conditions of using that website and its privacy policy. "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-4" }, [
                    _c("p", [
                      _vm._v(
                        " The Personal Information We process is primarily used to provide Users with the Services requested. More specifically, We may use your Personal Information for the following purposes: "
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v("to provide the Service You have requested;"),
                      ]),
                      _c("li", [
                        _vm._v("to provide technical or other support to You;"),
                      ]),
                      _c("li", [
                        _vm._v(
                          "to answer enquiries about Our Services, or to respond to a complaint;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "to promote Our other programs, products or services which may be of interest to You (unless You have opted out from such communications);"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "to allow for debugging, testing and otherwise operate Our platforms;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "to conduct data analysis, research and otherwise build and improve Our platforms;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "to comply with legal and regulatory obligations;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v("if otherwise permitted or required by law; or"),
                      ]),
                      _c("li", [
                        _vm._v(
                          "for other purposes with your consent, unless You withdraw your consent for these purposes."
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v(
                        "The 'lawful processing' grounds on which We will use Personal Information about Our Users include but are not limited to:"
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v("when a User has given consent;")]),
                      _c("li", [
                        _vm._v(
                          "when necessary for the performance of a contract to which the user is party;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "processing is necessary for compliance with Our legal obligations;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "processing is necessary in order to protect the vital interests of Our Users or of another natural person."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "processing is done in pursuing Our legitimate interests, where these interests do not infringe on the rights of Our Users."
                        ),
                      ]),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-5" }, [
                    _c("h4", [_vm._v("Our third-party service providers")]),
                    _c("p", [
                      _vm._v(
                        " The Personal Information of Users may be held or processed on Our behalf outside South Africa, including 'in the cloud', by Our third-party service providers. Our third-party service providers are bound by contract to only use your Personal Information on Our behalf, under Our mandate stipulated the manner the Personal Information may be processed. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v("Our third-party service providers include:"),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Cloud hosting, storage, networking and related providers"
                        ),
                      ]),
                      _c("li", [_vm._v("SMS providers")]),
                      _c("li", [_vm._v("Payment and banking providers")]),
                      _c("li", [_vm._v("Marketing and analytics providers")]),
                      _c("li", [_vm._v("Security providers")]),
                    ]),
                    _c("h4", [_vm._v("Third-party applications")]),
                    _c("p", [
                      _vm._v(
                        " Through the Jobox API it is possible for Users to grant third-party applications access to their Jobox account. Depending on the permissions that are granted, these applications may be able to access some Personal Information or do actions on the Users' behalf. These third-party applications are not controlled by Us and will be governed by their own privacy policy. Users are able to remove third-party applications from accessing the Personal Information through the settings function in the Users Account. "
                      ),
                    ]),
                    _c("h4", [_vm._v("Other disclosures and transfers")]),
                    _c("p", [
                      _vm._v(
                        "We may also disclose your Personal Information to third parties for the following purposes:"
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "if necessary, to provide the service or product You have requested;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "We receive court orders, subpoenas or other requests for information by law enforcement;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v("if otherwise permitted or required by law; or"),
                      ]),
                      _c("li", [
                        _vm._v("for other purposes with your consent."),
                      ]),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-6" }, [
                    _c("p", [
                      _vm._v(
                        " You have the right to request access to the Personal Information Jobox holds about You. Unless an exception applies, We are required to provide the Personal Information We hold about You, within a reasonable time period, and without unreasonable expense or at no charge. Certain Personal Information can be accessed by logging into your Account. If You wish to access Personal Information that is not accessible through the Website or intend to download all Personal Information We hold on You in a portable data format, please contact Us. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " You have the right to request the correction and/or deletion of the Personal Information We hold about You. All your Personal Information can be updated through the User settings function in the Account. If You require assistance please contact Our customer support. "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-7" }, [
                    _c("p", [
                      _vm._v(
                        " You have a number of other rights in relation to the Personal Information, however, there may be restrictions on how You may exercise these rights. This is largely due to the nature of the Services We provide. Certain Personal Information is processed in order to facilitate Fixed Terms Contracts and the Job between Users, facilitate the payments, and provide protection for the legitimate Users in Our marketplace and You have the right to: "
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "seek human review of automated decision-making or profiling"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "opt-out of direct marketing, and profiling for marketing"
                        ),
                      ]),
                      _c("li", [_vm._v("deletion and erasure")]),
                      _c("li", [
                        _vm._v("temporary restriction of the processing."),
                      ]),
                    ]),
                    _c("p", [
                      _c("b", [
                        _vm._v(
                          "Human review of automated decision making/profiling"
                        ),
                      ]),
                      _vm._v(
                        " - In the case of Our selection algorithms, it is not possible to exercise this right as this selection is a fundamental part of the marketplace that Users participate in, opting out would mean not being able to participate in the marketplace. Decisions affecting marketplace security are already reviewed by humans. "
                      ),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Direct marketing and profiling")]),
                      _vm._v(
                        " - Users can control what emails they receive through their settings page."
                      ),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Deletion and Erasure")]),
                      _vm._v(
                        " - Certain Personal Information and user-generated content cannot be deleted as the Personal information is used to support contracts between Users, document financial transactions, and are used in protecting other legitimate Users of the marketplace. In the case of non-personal data that can be linked with personal data, it will either be erased or otherwise anonymised from the personal data. "
                      ),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Temporary restriction to processing")]),
                      _vm._v(
                        " - under certain circumstances You may exercise this right, in particular, if You believe that the personal data We have is not accurate, or You believe that We do not have legitimate grounds for processing your information. In either case, You may exercise this right by contacting Our privacy officer. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Unless stated above, Users may exercise any of the above rights by contacting Us."
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "md-collapse-pane-8" }, [
                    _c("p", [
                      _vm._v(
                        " We may change this Privacy Policy from time to time. Any updated versions of this Privacy Policy will be posted on Our Website. You are responsible for the continuous review of this Privacy Policy. "
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }