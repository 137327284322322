var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("h2", { staticClass: "centre" }, [
        _vm._v("Congratulations on being hired"),
      ]),
      _c("p", { staticClass: "centre" }, [
        _vm._v("The employer will be in touch with you with the next steps"),
      ]),
      _c("client-status-profile", {
        attrs: {
          companyName: this.client.companyName,
          clientName: this.client.clientName,
          clientAlias: _vm.client.clientAlias,
          name: this.client.name,
          picture: this.picture,
          cardUserImage: _vm.cardUserImage,
          applicationStatus: _vm.status,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }