var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bursary
    ? _c("div", { staticClass: "md-layout", attrs: { id: "top" } }, [
        _c(
          "div",
          {
            staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto",
          },
          [
            _c(
              "simple-wizard",
              { attrs: { bursary: _vm.bursary } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.bursary.title)),
                    ]),
                    _vm.client
                      ? _c(
                          "div",
                          { staticClass: "flex items-center justify-center" },
                          [
                            _c(
                              "h4",
                              { staticStyle: { "margin-right": "2rem" } },
                              [_vm._v("🏢 " + _vm._s(_vm.client.companyName))]
                            ),
                            _c("h4", [
                              _vm._v(
                                "📅 " + _vm._s(_vm.bursary.applicationDeadline)
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-simple md-just-icon md-round modal-default-button",
                        staticStyle: {
                          position: "absolute",
                          top: "3px",
                          left: "10px",
                          "font-size": "30px !important",
                        },
                      },
                      [_c("md-icon", [_vm._v("help")])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": function () {
                        return _vm.validateStep("step1")
                      },
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "pc-view" }, [
                        _vm._v("Degree to be Funded"),
                      ]),
                      _c("div", { staticClass: "mobi-view" }, [
                        _c("i", { staticClass: "fas fa-location-arrow" }),
                      ]),
                    ]),
                    _c("funded-degree", {
                      ref: "step1",
                      on: {
                        "on-validated": _vm.onStepValidated,
                        selectedDegree: _vm.addSelectedDegree,
                      },
                    }),
                  ],
                  2
                ),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": function () {
                        return _vm.validateStep("step2")
                      },
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "pc-view" }, [
                        _vm._v("Submit Required Documents"),
                      ]),
                      _c("div", { staticClass: "mobi-view" }, [
                        _c("i", { staticClass: "fas fa-location-arrow" }),
                      ]),
                    ]),
                    _c("submit-documents", {
                      ref: "step2",
                      on: {
                        "on-validated": _vm.onStepValidated,
                        test: _vm.addTest,
                      },
                    }),
                  ],
                  2
                ),
                _vm.showVideoSubmission
                  ? _c(
                      "wizard-tab",
                      {
                        attrs: {
                          "before-change": function () {
                            return _vm.validateStep("step3")
                          },
                        },
                      },
                      [
                        _c("template", { slot: "label" }, [
                          _c("div", { staticClass: "pc-view" }, [
                            _vm._v("Video Submission"),
                          ]),
                          _c("div", { staticClass: "mobi-view" }, [
                            _c("i", { staticClass: "fas fa-location-arrow" }),
                          ]),
                        ]),
                        _c("video-submission", {
                          ref: "step3",
                          on: {
                            "on-validated": _vm.onStepValidated,
                            videoSubmission: _vm.addVideoSubmission,
                          },
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.showWrittenSubmission
                  ? _c(
                      "wizard-tab",
                      {
                        attrs: {
                          "before-change": function () {
                            return _vm.validateStep("step4")
                          },
                        },
                      },
                      [
                        _c("template", { slot: "label" }, [
                          _c("div", { staticClass: "pc-view" }, [
                            _vm._v("Written Submission"),
                          ]),
                          _c("div", { staticClass: "mobi-view" }, [
                            _c("i", { staticClass: "fas fa-location-arrow" }),
                          ]),
                        ]),
                        _c("written-submission", {
                          ref: "step4",
                          on: {
                            "on-validated": _vm.onStepValidated,
                            writtenSubmission: _vm.addWrittenSubmission,
                          },
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": function () {
                        return _vm.validateStep("step5")
                      },
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "pc-view" }, [
                        _vm._v("Review Application"),
                      ]),
                      _c("div", { staticClass: "mobi-view" }, [
                        _c("i", { staticClass: "fas fa-location-arrow" }),
                      ]),
                    ]),
                    _c("review-application", {
                      ref: "step5",
                      attrs: { documents: _vm.documents },
                      on: { "on-validated": _vm.onStepValidated },
                    }),
                  ],
                  2
                ),
              ],
              2
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }