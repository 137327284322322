var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      style: _vm.sidebarStyle,
      attrs: {
        "data-color": _vm.activeColor,
        "data-image": _vm.backgroundImage,
        "data-background-color": _vm.backgroundColor,
      },
    },
    [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _vm.profile.user == "client"
            ? _c(
                "router-link",
                {
                  staticClass: "simple-text logo-mini",
                  attrs: {
                    to: {
                      name: "client-profile",
                      params: { id: _vm.profile.alias },
                    },
                  },
                },
                [
                  _c("div", { staticClass: "logo-img" }, [
                    _c("img", { attrs: { src: _vm.logo } }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.profile.user == "student"
            ? _c(
                "router-link",
                {
                  staticClass: "simple-text logo-mini",
                  attrs: {
                    to: {
                      name: "student-profile",
                      params: { id: _vm.profile.alias },
                    },
                  },
                },
                [
                  _c("div", { staticClass: "logo-img" }, [
                    _c("img", { attrs: { src: _vm.logo } }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.profile.user == "client"
            ? _c(
                "router-link",
                {
                  staticClass: "simple-text logo-normal",
                  attrs: {
                    to: {
                      name: "client-profile",
                      params: { id: _vm.profile.alias },
                    },
                  },
                },
                [[_vm._v(_vm._s(_vm.title))]],
                2
              )
            : _vm._e(),
          _vm.profile.user == "student"
            ? _c(
                "router-link",
                {
                  staticClass: "simple-text logo-normal",
                  attrs: {
                    to: {
                      name: "student-profile",
                      params: { id: _vm.profile.alias },
                    },
                  },
                },
                [[_vm._v(_vm._s(_vm.title))]],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "navbar-minimize" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-round md-just-icon md-transparent",
                  attrs: { id: "minimizeSidebar" },
                  on: { click: _vm.minimizeSidebar },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons text_align-center visible-on-sidebar-regular",
                    },
                    [_vm._v("more_vert")]
                  ),
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons design_bullet-list-67 visible-on-sidebar-mini",
                    },
                    [_vm._v("view_list")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _vm._t("default"),
          _c(
            "md-list",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }