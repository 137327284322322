var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        [
          _c("md-card", [
            _c(
              "div",
              { staticClass: "container talentPool" },
              [
                _c(
                  "div",
                  { staticClass: "talentPoolActions" },
                  [
                    _c(
                      "md-field",
                      { staticStyle: { "max-width": "500px" } },
                      [
                        _c("md-input", {
                          staticClass: "mb-3",
                          attrs: {
                            type: "search",
                            clearable: "",
                            placeholder: "Search records",
                          },
                          model: {
                            value: _vm.searchQuery,
                            callback: function ($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "md-table",
                  {
                    attrs: { "md-card": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "md-table-row",
                          fn: function (ref) {
                            var item = ref.item
                            return _c(
                              "md-table-row",
                              {},
                              [
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "Name",
                                      "md-sort-by": "name",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "Description",
                                      "md-sort-by": "description",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.description))]
                                ),
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "Student No.",
                                      "md-sort-by": "email",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.studentsLength))]
                                ),
                                _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-success btn-talent-pool",
                                        staticStyle: { width: "150px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewTalentPool(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" View Talent Pool")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3223581849
                    ),
                    model: {
                      value: _vm.filteredTalentPools,
                      callback: function ($$v) {
                        _vm.filteredTalentPools = $$v
                      },
                      expression: "filteredTalentPools",
                    },
                  },
                  [
                    _c("md-table-toolbar", [
                      _c("h1", { staticClass: "md-title" }, [
                        _vm._v("Talent Pools"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }