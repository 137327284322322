<template>
	<div>
		<h5 class="info-text">Specify the location of work</h5>
		<div class="md-layout">
			<div class="md-layout-item md-small-size-100">
				<icon-checkbox @input="remoteSelection" v-model="remote" icon="fas fa-laptop" title="Remote"></icon-checkbox>
			</div>
			<div class="md-layout-item md-small-size-100">
				<icon-checkbox @input="onsiteSelection" v-model="onsite" icon="fas fa-building" title="On-site"></icon-checkbox>
			</div>
			<div class="md-layout-item md-small-size-100">
				<icon-checkbox @input="hybridSelection" v-model="hybrid" icon="fas fa-home" title="Hybrid"></icon-checkbox>
			</div>
		</div>
		<div class="md-layout-item mt-4 md-small-size-100" v-if="onsite || hybrid">
			<md-field :class="[{ 'md-valid': !v$.address.required.$invalid && v$.address.$dirty }, { 'md-error': v$.address.required.$invalid && v$.address.$dirty }]">
				<md-input @change="addAddress" v-model="v$.address.$model" data-vv-name="address" data-vv-scope="common" type="text" name="address" required id="input"></md-input>
				<slide-y-down-transition>
					<md-icon class="error" v-show="v$.address.required.$invalid && v$.address.$dirty">close</md-icon>
				</slide-y-down-transition>
				<slide-y-down-transition>
					<md-icon class="success" v-show="!v$.address.required.$invalid && v$.address.$dirty">done</md-icon>
				</slide-y-down-transition>
			</md-field>
			<p v-if="v$.address.required.$invalid && v$.address.$dirty" class="error">Please specify a location</p>
		</div>
		<div class="md-layout">
			<br /><br />
			<div v-if="jobType === 'Once-off Project/Task'" class="md-layout-item mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !v$.deadline.required.$invalid && v$.deadline.$dirty }, { 'md-error': v$.deadline.required.$invalid && v$.deadline.$dirty }]">
					<label>Duration</label>
					<md-select
						@input="addDeadline"
						v-model="v$.deadline.$model"
						data-vv-name="deadline"
						data-vv-scope="project"
						type="text"
						name="deadline"
						required
						style="margin-left: 10px"
					>
						<md-option v-for="(deadline, index) in deadlines" :key="index" :value="deadline">{{ deadline }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="v$.deadline.required.$invalid && v$.deadline.$dirty">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!v$.deadline.required.$invalid && v$.deadline.$dirty">done</md-icon>
					</slide-y-down-transition>
				</md-field>
				<p v-if="v$.deadline.required.$invalid && v$.deadline.$dirty" class="error">Please specify a duration</p>
			</div>

			<template v-if="jobType !== 'Once-off Project/Task'">
				<div class="md-layout-item mt-4 md-small-size-100">
					<md-field
						:class="[
							{ 'md-valid': !v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty },
							{ 'md-error': v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty },
						]"
					>
						<label>Working Days</label>
						<md-select
							v-if="jobType !== 'Once-off Project/Task'"
							@input="addDaysOfTheWeek"
							v-model="v$.daysOfTheWeek.$model"
							data-vv-name="daysOfTheWeek"
							data-vv-scope="nonProject"
							type="text"
							name="daysOfTheWeek"
							multiple
							style="margin-left: 10px"
						>
							<md-option v-for="(daysOfTheWeek, index) in DaysOfTheWeek" :key="index" :value="daysOfTheWeek">{{ daysOfTheWeek }}</md-option>
						</md-select>
						<slide-y-down-transition>
							<md-icon class="error" v-show="v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<p v-if="v$.daysOfTheWeek.required.$invalid && v$.daysOfTheWeek.$dirty" class="error">Please specify the working days</p>
				</div>
			</template>

			<div v-if="jobType === 'Internship' || jobType === 'Part-time'" class="md-layout-item mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !v$.hours.required.$invalid && v$.hours.$dirty }, { 'md-error': v$.hours.required.$invalid && v$.hours.$dirty }]">
					<label>Working Hours</label>
					<md-select @input="addHours" v-model="v$.hours.$model" data-vv-scope="partTime" data-vv-name="hours" type="text" name="hours" style="margin-left: 10px">
						<md-option v-for="(hours, index) in workingHours" :key="index" :value="hours">{{ hours }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="v$.hours.required.$invalid && v$.hours.$dirty">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!v$.hours.required.$invalid && v$.hours.$dirty">done</md-icon>
					</slide-y-down-transition>
				</md-field>
				<p v-if="v$.hours.required.$invalid && v$.hours.$dirty" class="error">Please specify the working hours</p>
			</div>

			<div class="md-layout-item mt-4 md-small-size-100">
				<md-datepicker
					@input="addApplicationDeadline"
					v-model="v$.applicationDeadline.$model"
					data-vv-name="applicationDeadline"
					data-vv-scope="common"
					required
					md-immediately
					:class="[
						{ 'md-valid': !v$.applicationDeadline.required.$invalid && v$.applicationDeadline.$dirty },
						{ 'md-error': v$.applicationDeadline.required.$invalid && v$.applicationDeadline.$dirty },
					]"
				>
					<label>Application Deadline</label>
					<slide-y-down-transition>
						<md-icon class="error" v-show="v$.applicationDeadline.required.$invalid && v$.applicationDeadline.$dirty">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!v$.applicationDeadline.required.$invalid && v$.applicationDeadline.$dirty">done</md-icon>
					</slide-y-down-transition>
				</md-datepicker>
				<p v-if="v$.applicationDeadline.required.$invalid && v$.applicationDeadline.$dirty" class="error" style="margin-left: 2.3rem">
					Please specify an application deadline
				</p>
			</div>
		</div>
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ header }}</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">{{ body }}</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import moment from 'moment';
import { IconCheckbox } from '@/components';
import { SlideYDownTransition } from 'vue2-transitions';
import { Modal } from '@/components';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { jobTypes, employmentTypes } from '@/constants/job-types.const';

export default {
	components: {
		IconCheckbox,
		SlideYDownTransition,
		Modal,
	},
	props: {
		jobType: {},
	},
	data() {
		return {
			address: null,
			hybrid: false,
			remote: true,
			onsite: false,
			location: null,
			deadline: null,
			hours: null,
			daysOfTheWeek: [],
			DaysOfTheWeek: null,
			deadlines: null,
			workingHours: null,
			applicationDeadline: null,
			disabledDates: date => {
				const day = date.getDay();
				return day === 6 || day === 0;
			},
			days: 14,
			modal: false,
			header: null,
			body: null,
		};
	},
	validations() {
		const validations = {
			applicationDeadline: { required },
		};

		if (this.remote) {
			if (this.jobType === employmentTypes.ONCE_OFF_PROJECT) {
				validations.deadline = { required };
			} else if (this.jobType === employmentTypes.WIL) {
				validations.daysOfTheWeek = { required };
			} else {
				validations.daysOfTheWeek = { required };
				if (this.jobType === employmentTypes.INTERNSHIP || this.jobType === employmentTypes.PART_TIME) {
					validations.hours = { required };
				}
			}
		}

		if (this.onsite || this.hybrid) {
			validations.address = { required };
			if (this.jobType === employmentTypes.ONCE_OFF_PROJECT) {
				validations.deadline = { required };
			} else if (this.jobType === employmentTypes.WIL) {
				validations.daysOfTheWeek = { required };
			} else {
				validations.daysOfTheWeek = { required };
				if (this.jobType === employmentTypes.INTERNSHIP || this.jobType === employmentTypes.PART_TIME) {
					validations.hours = { required };
				}
			}
		}

		return validations;
	},
	setup: () => ({ v$: useVuelidate() }),
	methods: {
		getError(fieldName) {
			return this.errors.first(fieldName);
		},
		async validate() {
			this.v$.$touch();
			const properties = jobTypes[this.jobType];

			if (!properties) {
				return;
			}

			for (const property of properties) {
				if (this.v$[property].required.$invalid) {
					return;
				}
			}

			if (this.onsite || this.hybrid) {
				if (this.v$.address.required.$invalid) {
					return;
				}
			}

			let common = this.$validator.validate('common.*');
			const promises = [common];
			if (this.jobType == employmentTypes.ONCE_OFF_PROJECT) {
				let projectValidation = this.$validator.validate('project.*');
				promises.push(projectValidation);
			} else {
				let nonProjectValidation = this.$validator.validate('nonProject.*');
				promises.push(nonProjectValidation);
				if (this.jobType === employmentTypes.INTERNSHIP || this.jobType === employmentTypes.PART_TIME) {
					let partTimeValidation = this.$validator.validate('partTime.*');
					promises.push(partTimeValidation);
				}

				if (this.jobType === employmentTypes.WIL) {
					let wilValidation = this.$validator.validate('wil.*');
					promises.push(wilValidation);
				}
			}
			return Promise.all(promises).then(res => {
				const result = res.every(Boolean);
				this.$emit('on-validated', result);
				return result;
			});
		},
		remoteSelection() {
			if (this.remote) {
				this.onsite = false;
				this.hybrid = false;
			}
			if (!this.remote && !this.onsite && !this.hybrid) {
				this.remote = true;
			}
			this.addLocation('Remote');
		},
		onsiteSelection() {
			if (this.onsite) {
				this.remote = false;
				this.hybrid = false;
			}
			if (!this.remote && !this.onsite && !this.hybrid) {
				this.onsite = true;
			}
			this.addLocation('On-Site');
		},
		hybridSelection() {
			if (this.hybrid) {
				this.remote = false;
				this.onsite = false;
			}
			if (!this.remote && !this.onsite && !this.hybrid) {
				this.hybrid = true;
			}
			this.addLocation('Hybrid');
		},
		addLocation: function (location) {
			this.$emit('location', location);
		},
		addAddress: function () {
			this.$emit('address', this.address);
		},
		addDeadline: function () {
			this.$emit('deadline', this.deadline);
		},
		addHours: function () {
			this.$emit('hours', this.hours);
		},
		addDaysOfTheWeek: function () {
			this.deadline = null;
			this.$emit('daysOfTheWeek', this.daysOfTheWeek);
		},
		addApplicationDeadline: function () {
			var date = new Date();
			var daysAfter = new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.days);
			if (this.applicationDeadline <= date) {
				this.applicationDeadline = null;
				this.modal = true;
				this.header = 'Oops! ✋';
				this.body = "You cannot select any days before today's date. Please select a date after " + moment(daysAfter).format('LL');
			} else if (this.applicationDeadline <= daysAfter) {
				this.applicationDeadline = null;
				this.modal = true;
				this.header = 'Oops! ✋';
				this.body = 'You cannot select a date ' + this.days + ' days before the start date. Please select a date after ' + moment(daysAfter).format('LL');
			} else {
				this.$emit('applicationDeadline', this.applicationDeadline);
			}
		},
		modalHide() {
			this.modal = false;
		},
	},
	created() {
		let settings = db.collection('Settings');
		settings
			.doc('Drop-down Lists')
			.get()
			.then(doc => {
				this.deadlines = doc.data().Deadlines;
				this.DaysOfTheWeek = doc.data().DaysOfTheWeek;
				this.workingHours = doc.data().WorkingHours;
			});
		settings
			.doc('Business Model')
			.get()
			.then(doc => {
				this.days = doc.data().applicationDeadline;
			});
		this.remoteSelection();
	},
	updated() {
		this.$nextTick(() => {
			if (window.google && document.getElementById('input')) {
				try {
					const google = window.google;
					const inputElement = document.getElementById('input');

					if (inputElement instanceof HTMLInputElement) {
						const autocomplete = new google.maps.places.Autocomplete(inputElement);
						autocomplete.addListener('place_changed', () => {
							const place = autocomplete.getPlace();
							this.address = place.formatted_address;
							this.$emit('address', this.address);
						});
					}
				} catch (error) {
					// TODO: Log error message
					console.error('Error initializing Google Maps autocomplete:', error);
				}
			}
		});
	},
};
</script>

<style>
.padding {
	padding: 10px;
}
.margin-left {
	margin-left: 10px;
}

.error {
	color: red;
	font-size: 10px;
	margin-top: -10px;
}
</style>
