var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "40px", "padding-top": "0px" } },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "md-card",
        { staticClass: "padding" },
        [
          _c("h2", { staticClass: "centre" }, [
            _vm._v("Successful Job Review"),
          ]),
          _c("p", { staticClass: "centre" }, [
            _vm._v(
              "The job has been successfully completed, please click on the dropdowns below to see more details"
            ),
          ]),
          _c(
            "md-card",
            [
              _c(
                "md-card-content",
                [
                  _c(
                    "collapse",
                    {
                      attrs: {
                        collapse: [
                          "Job Description",
                          "Job Information",
                          "Payment",
                          "Ratings & Reviews",
                        ],
                        icon: "keyboard_arrow_down",
                        "color-collapse": "success",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "md-collapse-pane-1" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c("md-card-content", [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category card-category-social",
                                    staticStyle: { "text-align": "center" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-newspaper",
                                    }),
                                    _vm._v(" Job Description"),
                                  ]
                                ),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Job Name"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.name)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Company Name"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.companyName)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Client Name"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.clientName)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Description"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.description)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Job Category"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.skills.category)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Skills Required"),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.skills.skills,
                                    function (skill, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "card-description",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.skills.skills[index])
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-2" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c("md-card-content", [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category card-category-social centre",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-newspaper",
                                    }),
                                    _vm._v(" Job Information"),
                                  ]
                                ),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Location"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.location)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Estimated Duration"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.duration)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-3" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c("md-card-content", [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category card-category-social centre",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-newspaper",
                                    }),
                                    _vm._v(" Budget"),
                                  ]
                                ),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Budget"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v("R" + _vm._s(_vm.job.budget)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-4" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Ratings and Review"),
                                    ]
                                  ),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _vm._v("Your ratings"),
                                  ]),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _c("b", [_vm._v("Communication")]),
                                  ]),
                                  _c("h5", [_vm._v("Listening")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good listening skills is one that follows instructions, asking clarifying questions, can rephrase what a person says to ensure understanding and they are able to listen to feedback and action on the feedback given. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.listening,
                                      callback: function ($$v) {
                                        _vm.listening = $$v
                                      },
                                      expression: "listening",
                                    },
                                  }),
                                  _c("h5", [
                                    _vm._v("Verbal and written communication"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good verbal and written communication has clear enunciation, responds to messages on time, speaks with a good tone, knows when to speak and they are able to clearly state needs and wants. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.communication,
                                      callback: function ($$v) {
                                        _vm.communication = $$v
                                      },
                                      expression: "communication",
                                    },
                                  }),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _c("b", [_vm._v("Problem Solving")]),
                                  ]),
                                  _c("h5", [_vm._v("Critical Thinking")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good critical thinking acumen displays the ability to think clearly, rationally and they show an understanding of the logical connection between ideas and concepts. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.criticalThinking,
                                      callback: function ($$v) {
                                        _vm.criticalThinking = $$v
                                      },
                                      expression: "criticalThinking",
                                    },
                                  }),
                                  _c("h5", [
                                    _vm._v("Creative Problem Solving"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good creative problem-solving abilities is one that can identify problems, redefine problems and create opportunities out of the problems by developing new and innovative solutions to problems. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.creative,
                                      callback: function ($$v) {
                                        _vm.creative = $$v
                                      },
                                      expression: "creative",
                                    },
                                  }),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _c("b", [_vm._v("Leadership")]),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " The visionary perspective that enables one to know what will work and what will not work and the passionate persistence to stimulate people to strive towards peak performance that will enhance both the organisational and their individual well-being. "
                                    ),
                                  ]),
                                  _c("h5", [_vm._v("Taking Initiative")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who is good at taking the initiative does not wait for all of the tasks to be assigned to them, they ask for more work to do and also volunteer for additional responsibilities outside the scope of their role. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.initiative,
                                      callback: function ($$v) {
                                        _vm.initiative = $$v
                                      },
                                      expression: "initiative",
                                    },
                                  }),
                                  _c("h5", [_vm._v("Accountability")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good accountability is one that takes responsibilities for the outcome of their actions and decisions. They successfully transform their effort and they reliably deliver on their commitments and they can be trusted to do what they say they'll do. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.accountability,
                                      callback: function ($$v) {
                                        _vm.accountability = $$v
                                      },
                                      expression: "accountability",
                                    },
                                  }),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _c("b", [_vm._v("Organisation")]),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " The ability to function effectively in the workplace. Things to consider are preparedness, attention to detail, the ability to handle a large workload or implement a complex project within an established time frame. "
                                    ),
                                  ]),
                                  _c("h5", [_vm._v("Planning")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good planning is one who completes tasks on time and manages expectations by informing you on whether they will not be able to accomplish a task in the time frame. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.planning,
                                      callback: function ($$v) {
                                        _vm.planning = $$v
                                      },
                                      expression: "planning",
                                    },
                                  }),
                                  _c("h5", [_vm._v("Keep track of work")]),
                                  _c("p", [
                                    _vm._v(
                                      "A student who is able to keep track of their work is one who takes notes and makes an effort to ensure that their work is well managed."
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.tracking,
                                      callback: function ($$v) {
                                        _vm.tracking = $$v
                                      },
                                      expression: "tracking",
                                    },
                                  }),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _vm._v("Review"),
                                  ]),
                                  _c("p", { staticClass: "card-description" }, [
                                    _vm._v(_vm._s(_vm.review)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticStyle: { "text-align": "center" } }, [
                            _vm._v("Call us at: "),
                            _c("b", [_vm._v("087 149 4394")]),
                          ]),
                          _c("p", { staticStyle: { "text-align": "center" } }, [
                            _vm._v(" Email us at: "),
                            _c("b", [
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  attrs: { href: "mailto:contact@jobox.co.za" },
                                },
                                [_vm._v("contact@jobox.co.za")]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }