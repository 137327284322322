var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user" }, [
    _c("div", { staticClass: "photo" }, [
      !_vm.user.profile
        ? _c("img", { attrs: { src: _vm.avatar, alt: "avatar" } })
        : _vm._e(),
      _vm.user.profile
        ? _c("img", { attrs: { src: _vm.user.profile, alt: "avatar" } })
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "user-info" },
      [
        _c(
          "a",
          {
            attrs: {
              "data-toggle": "collapse",
              "aria-expanded": !_vm.isClosed,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleMenu.apply(null, arguments)
              },
              "!click": function ($event) {
                return _vm.clicked.apply(null, arguments)
              },
            },
          },
          [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.user.name) +
                  " " +
                  _vm._s(_vm.user.surname) +
                  " "
              ),
              _c("b", { staticClass: "caret" }),
            ]),
          ]
        ),
        _c("collapse-transition", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isClosed,
                  expression: "!isClosed",
                },
              ],
            },
            [
              _c(
                "ul",
                { staticClass: "nav" },
                [
                  _vm.client
                    ? _vm._t("default", function () {
                        return [
                          !_vm.isInvitedClient
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "edit-client-profile",
                                          params: { id: _vm.alias },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "sidebar-mini" },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-user-edit",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "sidebar-normal" },
                                        [_vm._v("Edit Profile")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      })
                    : _vm._e(),
                  _vm.student
                    ? _vm._t("default", function () {
                        return [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "edit-student-profile",
                                      params: { id: _vm.alias },
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "sidebar-mini" }, [
                                    _c("i", {
                                      staticClass: "fas fa-user-edit",
                                    }),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "sidebar-normal" },
                                    [_vm._v("Edit Profile")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }