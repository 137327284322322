var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", { staticClass: "info-text" }, [
        _vm._v("Tell us about your most recent work experience?"),
      ]),
      _c(
        "h5",
        {
          staticClass: "info-text",
          staticStyle: {
            "font-weight": "bold",
            "font-size": "12px",
            "margin-top": "-25px",
          },
        },
        [_vm._v("You can skip this section if you have no experience")]
      ),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("notifications"),
          _vm.experiences
            ? _c(
                "div",
                { staticClass: "md-layout" },
                [
                  _c(
                    "md-table",
                    { attrs: { "md-card": "" } },
                    [
                      _c("md-table-toolbar", [
                        _c("h1", { staticClass: "md-title" }, [
                          _vm._v("Experience"),
                        ]),
                      ]),
                      _vm._l(_vm.experiences, function (experience) {
                        return _c(
                          "md-table-row",
                          { key: experience.experienceNum },
                          [
                            _c(
                              "md-table-cell",
                              {
                                attrs: {
                                  "md-label": "JobTitle",
                                  "md-sort-by": "jobTitle",
                                },
                              },
                              [_vm._v(_vm._s(experience.jobTitle))]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                attrs: {
                                  "md-label": "Employer",
                                  "md-sort-by": "employer",
                                },
                              },
                              [_vm._v(_vm._s(experience.employer))]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                attrs: {
                                  "md-label": "StartDate",
                                  "md-sort-by": "startDate",
                                },
                              },
                              [_vm._v(_vm._s(experience.startDate))]
                            ),
                            experience.work === false
                              ? _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "endDate",
                                      "md-sort-by": "endDate",
                                    },
                                  },
                                  [_vm._v(_vm._s(experience.endDate))]
                                )
                              : _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "EndDate",
                                      "md-sort-by": "status",
                                    },
                                  },
                                  [_vm._v("Currently working")]
                                ),
                            _c(
                              "md-table-cell",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-icon-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewExperience(
                                          experience.experienceNum
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("md-icon", [_vm._v("edit")]),
                                    _c(
                                      "md-tooltip",
                                      { attrs: { "md-direction": "bottom" } },
                                      [_vm._v("View")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c("div", [
                _c("h6", { staticClass: "info-text" }, [
                  _vm._v("You currently have no experiences added"),
                ]),
              ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [
          _c(
            "md-button",
            {
              staticClass: "md-plain md-success md-fab-bottom-right",
              on: { click: _vm.addExperience },
            },
            [_c("md-icon", [_vm._v("add")]), _vm._v("Add Experiences ")],
            1
          ),
        ],
        1
      ),
      _c("experience-modal", {
        attrs: {
          student: _vm.student,
          selectedExp: _vm.selectedExp,
          status: _vm.status,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }