var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        { staticClass: "export-trigger", on: { click: _vm.openModal } },
        [_vm._v("Export CSV")]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showModal
          ? _c(
              "div",
              {
                staticClass: "modal-overlay",
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "modal-container" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c("h2", [_vm._v("Select Columns to Export")]),
                  ]),
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      { staticClass: "checkbox-list" },
                      _vm._l(_vm.availableColumns, function (column, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "checkbox-item" },
                          [
                            _c(
                              "md-checkbox",
                              {
                                attrs: { value: column.value },
                                model: {
                                  value: _vm.selectedColumns,
                                  callback: function ($$v) {
                                    _vm.selectedColumns = $$v
                                  },
                                  expression: "selectedColumns",
                                },
                              },
                              [_vm._v(_vm._s(column.label))]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "modal-actions" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-danger",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("CANCEL")]
                      ),
                      _c(
                        "md-button",
                        { staticClass: "export", on: { click: _vm.exportCsv } },
                        [_vm._v("EXPORT")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }