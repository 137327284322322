var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
      [
        _c(
          "simple-wizard",
          {
            attrs: {
              name: _vm.name,
              description: _vm.description,
              category: _vm.category,
              skills: _vm.skills,
              location: _vm.location,
              deadline: _vm.deadline,
              budget: _vm.budget,
            },
          },
          [
            _c("template", { slot: "header" }, [
              _c("h3", { staticClass: "title" }, [_vm._v("Post a Job")]),
              _c("h5", { staticClass: "category" }, [
                _vm._v(
                  "This information you fill out will allow students to apply for the microjob"
                ),
              ]),
            ]),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step1")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [
                    _vm._v("Description"),
                  ]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-list-ul" }),
                  ]),
                ]),
                _c("first-step", {
                  ref: "step1",
                  on: {
                    "on-validated": _vm.onStepValidated,
                    name: _vm.addName,
                    description: _vm.addDescription,
                    category: _vm.addCategory,
                    skills: _vm.addSkills,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step2")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [_vm._v("Location")]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-location-arrow" }),
                  ]),
                ]),
                _c("second-step", {
                  ref: "step2",
                  on: {
                    "on-validated": _vm.onStepValidated,
                    location: _vm.addLocation,
                    deadline: _vm.addDeadline,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step3")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [_vm._v("Payment")]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-wallet" }),
                  ]),
                ]),
                _c("third-step", {
                  ref: "step3",
                  on: {
                    "on-validated": _vm.onStepValidated,
                    budget: _vm.addBudget,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step4")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [_vm._v("Review")]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-tasks" }),
                  ]),
                ]),
                _c("fourth-step", {
                  ref: "step4",
                  attrs: {
                    name: _vm.name,
                    description: _vm.description,
                    category: _vm.category,
                    skills: _vm.skills,
                    location: _vm.location,
                    deadline: _vm.deadline,
                    budget: _vm.budget,
                  },
                }),
              ],
              2
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }