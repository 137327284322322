var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("notifications"),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "align-item": "center",
                "justify-content": "flex-start",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c("h4", { staticStyle: { "margin-left": "2px" } }, [
                    _vm._v("Extracurricular Activities"),
                  ]),
                  _c("multi-select", {
                    attrs: {
                      openModal: _vm.openActivityModal,
                      items: _vm.activities,
                      heading: _vm.heading,
                    },
                    on: {
                      cancel: function (closeModal) {
                        return (_vm.openActivityModal = closeModal)
                      },
                      confirmSelection: _vm.confirmSelection,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "selected-activities" },
                    _vm._l(_vm.selectedActivities, function (activity, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "activity-tag" },
                        [_vm._v(" " + _vm._s(activity.value) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                      },
                    },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-plain md-success md-fab-bottom-right",
                          on: { click: _vm.addActivity },
                        },
                        [
                          _vm.selectedActivities.length === 0
                            ? _c("md-icon", [_vm._v("add")])
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedActivities.length > 0
                                  ? "Edit"
                                  : " Add Extracurricular Activities "
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "md-layout-item ml-auto mt-4 md-small-size-100",
              staticStyle: { "margin-top": "10rem" },
            },
            [
              _c("h4", [_vm._v("CV/Resume (Optional)")]),
              _c(
                "md-field",
                {
                  class: [
                    { "md-valid": !_vm.errors.has("cv") && _vm.touched.cv },
                    { "md-form-group": true },
                    { "md-error": _vm.cvError },
                  ],
                },
                [
                  _c("label", [_vm._v("CV/Resume (Optional)")]),
                  _c("md-file", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.cv,
                        expression: "modelValidations.cv",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "cv",
                      name: "CV",
                      accept: "application/pdf",
                    },
                    on: { change: _vm.previewPdf },
                    model: {
                      value: _vm.cv,
                      callback: function ($$v) {
                        _vm.cv = $$v
                      },
                      expression: "cv",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cvError,
                              expression: "cvError",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.cvError && _vm.touched.cv,
                              expression: "!cvError && touched.cv",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Whoa there! ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v("You cannot exceed the file limit of 2MB"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.pdfError
        ? _c(
            "modal",
            { on: { close: _vm.PdfModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Whoa there! ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.PdfModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [_vm._v("Please add a PDF")]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.PdfModalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      _c("div", { staticClass: "loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }