var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "40px", "padding-top": "0px" } },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "md-card",
        { staticClass: "padding" },
        [
          _c("h2", { staticClass: "centre" }, [
            _vm._v("Successful Job Review"),
          ]),
          _c("p", { staticClass: "centre" }, [
            _vm._v(
              "The job has been successfully completed, please click on the dropdowns below to see more details"
            ),
          ]),
          _c(
            "md-card",
            [
              _c(
                "md-card-content",
                [
                  _c(
                    "collapse",
                    {
                      attrs: {
                        collapse: [
                          "Description",
                          "Details",
                          "Payment",
                          "Ratings & Reviews",
                        ],
                        icon: "keyboard_arrow_down",
                        "color-collapse": "success",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "md-collapse-pane-1" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c("md-card-content", [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category card-category-social",
                                    staticStyle: { "text-align": "center" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-newspaper",
                                    }),
                                    _vm._v(" Job Description"),
                                  ]
                                ),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Job Name"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.name)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Company Name"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.companyName)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Client Name"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.clientName)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Description"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.description)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Job Category"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.skills.category)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Skills Required"),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.skills.skills,
                                    function (skill, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "card-description",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.skills.skills[index])
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-2" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c("md-card-content", [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category card-category-social centre",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-newspaper",
                                    }),
                                    _vm._v(" Job Information"),
                                  ]
                                ),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Location"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.location)),
                                ]),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Estimated Duration"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(_vm._s(_vm.job.duration)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-3" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c("md-card-content", [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category card-category-social centre",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-newspaper",
                                    }),
                                    _vm._v(" Breakdown"),
                                  ]
                                ),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Total Cost"),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _c("b", [_vm._v("R" + _vm._s(_vm.total()))]),
                                  _vm._v(" = "),
                                  _c("b", [_vm._v("R" + _vm._s(_vm.rate()))]),
                                  _vm._v(" + "),
                                  _c("b", [_vm._v("R" + _vm._s(_vm.fee()))]),
                                  _vm._v(" + "),
                                  _c("b", [
                                    _vm._v(
                                      "R" +
                                        _vm._s(_vm.price.facilitationFee) +
                                        ".00"
                                    ),
                                  ]),
                                ]),
                                _c("hr"),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Cost Breakdown"),
                                ]),
                                _vm.jobType === "Once-off Project/Task"
                                  ? _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [
                                        _vm._v(" Fixed-Term Rate: "),
                                        _c("b", [
                                          _vm._v("R" + _vm._s(_vm.rate())),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.jobType !== "Once-off Project/Task"
                                  ? _c(
                                      "p",
                                      { staticClass: "card-description" },
                                      [
                                        _vm._v(" Salary: "),
                                        _c("b", [
                                          _vm._v("R" + _vm._s(_vm.rate())),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(
                                    " Jobox Service Fee (" +
                                      _vm._s(_vm.percentage()) +
                                      "%): "
                                  ),
                                  _c("b", [_vm._v("R" + _vm._s(_vm.fee()))]),
                                ]),
                                _c("p", { staticClass: "card-description" }, [
                                  _vm._v(" Jobox Facilitation Cost: "),
                                  _c("b", [
                                    _vm._v(
                                      "R" +
                                        _vm._s(_vm.price.facilitationFee) +
                                        ".00"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-4" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Ratings and Review"),
                                    ]
                                  ),
                                  _c("h4", { staticClass: "card-title" }, [
                                    _vm._v("Your rating"),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": true,
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.rating,
                                      callback: function ($$v) {
                                        _vm.rating = $$v
                                      },
                                      expression: "rating",
                                    },
                                  }),
                                  _vm.review
                                    ? _c("h4", { staticClass: "card-title" }, [
                                        _vm._v("Your review"),
                                      ])
                                    : _vm._e(),
                                  _vm.review
                                    ? _c(
                                        "p",
                                        { staticClass: "card-description" },
                                        [_vm._v(_vm._s(_vm.review))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }