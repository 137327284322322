import { render, staticRenderFns } from "./FundedDegree.vue?vue&type=template&id=588da38b&"
import script from "./FundedDegree.vue?vue&type=script&lang=js&"
export * from "./FundedDegree.vue?vue&type=script&lang=js&"
import style0 from "./FundedDegree.vue?vue&type=style&index=0&id=588da38b&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/jobox-platform/jobox-platform/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('588da38b')) {
      api.createRecord('588da38b', component.options)
    } else {
      api.reload('588da38b', component.options)
    }
    module.hot.accept("./FundedDegree.vue?vue&type=template&id=588da38b&", function () {
      api.rerender('588da38b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/student/bursaries/application/wizard/formSteps/FundedDegree.vue"
export default component.exports