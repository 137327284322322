var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "md-card",
        { staticClass: "padding" },
        [
          _c(
            "div",
            { class: _vm.checkStep() === 3 ? "show" : "hide" },
            [_c("applicant-table-select")],
            1
          ),
          _c(
            "md-card-actions",
            { staticClass: "button-container" },
            [
              _c(
                "div",
                { class: _vm.checkStep() === 3 ? "show" : "hide" },
                [
                  _vm.inviteCallback
                    ? _c(
                        "md-button",
                        {
                          staticClass: "btn-next md-success button",
                          staticStyle: { "max-width": "155px" },
                          on: { click: _vm.onInvite },
                        },
                        [_vm._v("Invite for interview")]
                      )
                    : _vm._e(),
                  _vm._v("     "),
                ],
                1
              ),
              _vm.select || _vm.active
                ? _c(
                    "md-button",
                    {
                      staticClass: "btn-next md-info button",
                      staticStyle: { "max-width": "110px" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v("Edit Job")]
                  )
                : _vm._e(),
              _vm._v("     "),
              _vm.select && !_vm.paid
                ? _c(
                    "md-button",
                    {
                      staticClass: "btn-next md-danger button",
                      staticStyle: { "max-width": "110px" },
                      on: { click: _vm.cancelJob },
                    },
                    [_vm._v("Cancel Job")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("notifications"),
                  !_vm.invoice
                    ? _c("p", { staticClass: "black" }, [
                        _vm._v("Your payment is outstanding"),
                      ])
                    : _vm._e(),
                  !_vm.invoice
                    ? _c("p", { staticClass: "black" }, [
                        _vm._v(
                          "Your payment could take up to 5 minutes to reflect depending on your internet speed"
                        ),
                      ])
                    : _vm._e(),
                  _vm.invoice
                    ? _c("iframe", { attrs: { id: "invoice" } })
                    : _vm._e(),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                !_vm.invoice
                  ? _c(
                      "div",
                      { staticClass: "centre" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.makePayment },
                          },
                          [_vm._v("Pay Now")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.invoice
                  ? _c(
                      "div",
                      { staticClass: "centre" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.sendEmail },
                          },
                          [_vm._v("Send")]
                        ),
                        _vm._v("    "),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-info",
                            on: { click: _vm.downloadFile },
                          },
                          [_vm._v("Download")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.interviewModal
        ? _c(
            "modal",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Woah there ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.interviewModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("h4", [
                  _vm._v("Please select an applicant using the checkboxes."),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      _c("div", { staticClass: "loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }