<template>
	<div style="max-width: 1500px; margin: auto">
		<div class="dashboard-header" style="display: flex; align-items: center; justify-content: space-between">
			<h2>Bursary Demographics Dashboard</h2>
			<CsvDownload :data="filteredApplicants" :availableColumns="dynamicCsvColumns" />
		</div>

		<md-card>
			<md-card-content>
				<div class="filter-container">
					<div class="filter-input">
						<SelectField v-model="selectedRace" label="Race" placeholder="Filter by Race" :options="allUniqueRaces" @change="applyFilters" />
					</div>
					<div class="filter-input">
						<SelectField v-model="selectedGender" label="Gender" placeholder="Filter by Gender" :options="allUniqueGenders" @change="applyFilters" />
					</div>
					<div class="filter-input">
						<SelectField v-model="selectedProvince" label="Province" placeholder="Filter by Province" :options="allUniqueProvinces" @change="applyFilters" />
					</div>
					<div class="filter-input">
						<SelectField v-model="selectedUniversity" label="University" placeholder="Filter by University" :options="allUniqueUniversities" @change="applyFilters" />
					</div>
					<div class="filter-input">
						<SelectField v-model="selectedDegree" label="Degree" placeholder="Filter by Degree" :options="allUniqueDegrees" @change="applyFilters" />
					</div>
					<div class="filter-input">
						<SelectField v-model="selectedYear" label="Year" placeholder="Filter by Year" :options="allUniqueYears" @change="applyFilters" />
					</div>
					<div class="filter-input">
						<SelectField v-model="selectedAgeGroup" label="Age" placeholder="Filter by Age" :options="allUniqueAgeGroups" @change="applyFilters" />
					</div>
					<md-button class="md-warning" @click="resetFilters">Clear Filters</md-button>
				</div>
			</md-card-content>
		</md-card>

		<div class="stats-card-container">
			<div class="stats-card">
				<h3>Total Applicants</h3>
				<h2>{{ filteredApplicants.length }}</h2>
			</div>
			<div class="stats-card">
				<h3>Selected Applicants</h3>
				<h2>{{ selectedApplicantsCount }}</h2>
			</div>
		</div>

		<div class="applicants-toggle-container">
			<div class="applicant-toggle" :class="{ active: applicantView === 'all' }" @click="setApplicantView('all')">
				<p>All Applicants</p>
			</div>
			<div class="applicant-toggle" :class="{ active: applicantView === 'selected' }" @click="setApplicantView('selected')">
				<p>Selected Applicants</p>
			</div>
		</div>

		<div class="dashboard-container">
			<div class="chart-row row-1">
				<div class="chart-card half">
					<h3>
						<md-icon>people</md-icon>
						Race Distribution
					</h3>
					<DonutChart title="Race Distribution" :options="raceChartOptions" :series="raceSeries" width="100%" height="100%" />
				</div>
				<div class="chart-card half">
					<h3>
						<md-icon>wc</md-icon>
						Gender Distribution
					</h3>
					<DonutChart title="Gender Distribution" :options="genderChartOptions" :series="genderSeries" width="100%" height="100%" />
				</div>
			</div>

			<div class="chart-row row-2">
				<div class="chart-card half bar">
					<h3>
						<md-icon>accessibility</md-icon>
						Age Distribution
					</h3>
					<BarChart title="Age Distribution" :options="ageChartOptions" :series="ageSeries" width="100%" height="100%" />
				</div>
				<div class="chart-card half bar">
					<h3>
						<md-icon>location_on</md-icon>
						Province Distribution
					</h3>
					<BarChart title="Province Distribution" :options="provinceChartOptions" :series="provinceSeries" width="100%" height="100%" />
				</div>
			</div>
			<div class="chart-row row-3">
				<div class="chart-card half fit">
					<h3>
						<md-icon>school</md-icon>
						University Distribution
					</h3>
					<BarChart title="University Distribution" :options="universityChartOptions" :series="universitySeries" width="100%" height="500" />
				</div>
				<div class="chart-card half fit">
					<h3>
						<md-icon>assignment</md-icon>
						Degree Distribution
					</h3>
					<BarChart title="Degree Distribution" :options="degreeChartOptions" :series="degreeSeries" width="100%" height="500" />
				</div>
			</div>
			<div class="chart-row row-4">
				<div class="chart-card full bar">
					<h3>
						<md-icon>date_range</md-icon>
						Year of Study Distribution
					</h3>
					<BarChart title="Year of Study Distribution" :options="yearOfStudyChartOptions" :series="yearOfStudySeries" width="100%" height="100%" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../../../../../../firebase/init';
import SelectField from '@/components/Inputs/SelectField.vue';
import DonutChart from '@/components/Charts/DonutChart.vue';
import BarChart from '@/components/Charts/BarChart.vue';
import CsvDownload from '@/components/CsvDownload.vue';

import { createChartOptions } from '@/utils/charts.util.js';

export default {
	components: { SelectField, DonutChart, BarChart, CsvDownload },
	computed: {
		dynamicCsvColumns() {
			if (this.filteredApplicants.length > 0) {
				return Object.keys(this.filteredApplicants[0])
					.filter(key => {
						const value = this.filteredApplicants[0][key];
						return key !== 'internalField' && key !== 'selectedDegree' && (typeof value !== 'object' || value === null);
					})
					.sort((a, b) => a.localeCompare(b))
					.map(key => ({
						label: this.formatLabel(key),
						value: key,
					}));
			}
			return [];
		},

		selectedApplicantsCount() {
			return this.filteredApplicants.filter(a => a.status === 'Selected').length;
		},
		displayedApplicants() {
			return this.applicantView === 'selected' ? this.filteredApplicants.filter(a => a.status === 'Selected') : this.filteredApplicants;
		},
	},
	data() {
		return {
			bursaryId: this.$route.params.bursaryId,
			bursaryApplicants: [],
			// chart options
			raceChartOptions: {},
			genderChartOptions: {},
			ageChartOptions: {},
			provinceChartOptions: {},
			universityChartOptions: {},
			degreeChartOptions: {},
			yearOfStudyChartOptions: {},
			// Filters
			selectedRace: '',
			selectedGender: '',
			selectedProvince: '',
			selectedUniversity: '',
			selectedDegree: '',
			selectedYear: '',
			selectedAgeGroup: '',
			filteredApplicants: [],
			// Unique filter options
			allUniqueRaces: [],
			allUniqueGenders: [],
			allUniqueProvinces: [],
			allUniqueUniversities: [],
			allUniqueDegrees: [],
			allUniqueYears: [],
			allUniqueAgeGroups: [],
			// Chart series
			raceSeries: [],
			genderSeries: [],
			provinceSeries: [],
			universitySeries: [],
			degreeSeries: [],
			ageSeries: [],
			yearOfStudySeries: [],
			applicantView: 'all',
		};
	},
	created() {
		this.getBursaryApplicants().then(() => {
			this.filteredApplicants = [...this.bursaryApplicants];
			this.populateChartData();
		});
	},
	methods: {
		formatLabel(key) {
			return key
				.replace(/([A-Z])/g, ' $1')
				.replace(/_/g, ' ')
				.replace(/^./, str => str.toUpperCase())
				.trim();
		},
		resetFilters() {
			['selectedRace', 'selectedGender', 'selectedProvince', 'selectedUniversity', 'selectedDegree', 'selectedYear', 'selectedAgeGroup'].forEach(field => (this[field] = ''));
			this.filteredApplicants = [...this.bursaryApplicants];
			this.populateChartData();
		},
		applyFilters() {
			this.filteredApplicants = this.bursaryApplicants.filter(
				app =>
					(!this.selectedRace || app.race === this.selectedRace) &&
					(!this.selectedGender || app.gender === this.selectedGender) &&
					(!this.selectedProvince || app.province === this.selectedProvince) &&
					(!this.selectedUniversity || app.university === this.selectedUniversity) &&
					(!this.selectedDegree || app.degree === this.selectedDegree) &&
					(!this.selectedYear || app.year === this.selectedYear) &&
					(!this.selectedAgeGroup || app.ageGroup === this.selectedAgeGroup)
			);
			this.populateChartData();
		},
		clearChartData() {
			['raceSeries', 'genderSeries', 'provinceSeries', 'universitySeries', 'degreeSeries', 'ageSeries', 'yearOfStudySeries'].forEach(key => (this[key] = []));
			this.raceChartOptions.labels = [];
			this.genderChartOptions.labels = [];
			this.provinceChartOptions.xaxis.categories = [];
			this.universityChartOptions.labels = [];
			this.degreeChartOptions.labels = [];
			this.ageChartOptions.xaxis.categories = [];
			this.yearOfStudyChartOptions.xaxis.categories = [];
		},
		populateChartData() {
			const apps = this.applicantView === 'selected' ? this.filteredApplicants.filter(a => a.status === 'Selected') : this.filteredApplicants;

			if (!apps.length || apps.length === 0) {
				this.raceChartOptions = { labels: [], noData: { text: 'No data available' } };
				this.genderChartOptions = { labels: [], noData: { text: 'No data available' } };
				this.provinceChartOptions = { xaxis: { categories: [] }, noData: { text: 'No data available' } };
				this.universityChartOptions = { xaxis: { categories: [] }, noData: { text: 'No data available' } };
				this.degreeChartOptions = { xaxis: { categories: [] }, noData: { text: 'No data available' } };
				this.ageChartOptions = { xaxis: { categories: [] }, noData: { text: 'No data available' } };
				this.yearOfStudyChartOptions = { xaxis: { categories: [] }, noData: { text: 'No data available' } };
				this.clearChartData();

				return;
			}

			const yearOrder = ['1st Year', '2nd Year', '3rd Year', '4th Year', '5th Year', 'Honours', 'Masters', 'PhD'];

			const getUniques = prop => {
				let values = [...new Set(apps.map(a => a[prop]))];
				if (prop === 'year') {
					values.sort((a, b) => {
						const idxA = yearOrder.indexOf(a);
						const idxB = yearOrder.indexOf(b);
						return (idxA === -1 ? Infinity : idxA) - (idxB === -1 ? Infinity : idxB);
					});
				}
				return values;
			};
			const updateChart = (prop, optionKey, seriesKey, config) => {
				const uniques = getUniques(prop);
				const counts = uniques.map(value => apps.filter(a => a[prop] === value).length);

				if (config.chartType === 'bar') {
					this[seriesKey] = [{ name: 'Applicants', data: counts }];
					this[optionKey] = createChartOptions({
						chartType: 'bar',
						categories: uniques,
						colors: config.colors || [],
						horizontal: config.horizontal || false,
						legend: config.legend || null,
					});
				} else if (config.chartType === 'donut') {
					this[seriesKey] = counts;
					this[optionKey] = createChartOptions({
						chartType: 'donut',
						labels: uniques,
						colors: config.colors || [],
					});
				}
			};

			const chartConfigs = [
				{ prop: 'race', optionKey: 'raceChartOptions', seriesKey: 'raceSeries', config: { chartType: 'donut', colors: [] } },
				{ prop: 'gender', optionKey: 'genderChartOptions', seriesKey: 'genderSeries', config: { chartType: 'donut', colors: [] } },
				{ prop: 'province', optionKey: 'provinceChartOptions', seriesKey: 'provinceSeries', config: { chartType: 'bar', colors: ['#5ba16f'] } },
				{
					prop: 'university',
					optionKey: 'universityChartOptions',
					seriesKey: 'universitySeries',
					config: { chartType: 'bar', colors: ['#f04a13'], horizontal: true, legend: { position: 'bottom', horizontalAlign: 'left' } },
				},
				{
					prop: 'degree',
					optionKey: 'degreeChartOptions',
					seriesKey: 'degreeSeries',
					config: { chartType: 'bar', colors: ['#22abff'], horizontal: true, legend: { position: 'bottom', horizontalAlign: 'left', width: '100%' } },
				},
				{ prop: 'ageGroup', optionKey: 'ageChartOptions', seriesKey: 'ageSeries', config: { chartType: 'bar', colors: [] } },
				{ prop: 'year', optionKey: 'yearOfStudyChartOptions', seriesKey: 'yearOfStudySeries', config: { chartType: 'bar', colors: ['#e0752a'] } },
			];

			chartConfigs.forEach(({ prop, optionKey, seriesKey, config }) => {
				updateChart(prop, optionKey, seriesKey, config);
			});
		},
		async getBursaryApplicants() {
			try {
				const apps = await this.fetchBursaryApplications();
				if (!apps.length) return this.clearApplicantsData();
				const studentMap = await this.fetchStudentData(apps);
				this.bursaryApplicants = this.mapApplicantsData(apps, studentMap);
				this.extractUniqueValues();
			} catch (e) {
				console.error('Error fetching bursary applicants:', e);
			}
		},
		async fetchBursaryApplications() {
			const q = query(collection(db, 'bursary-applications'), where('bursaryID', '==', this.bursaryId));
			const snap = await getDocs(q);
			return snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
		},
		async fetchStudentData(apps) {
			const userIds = [...new Set(apps.map(app => app.userId))];
			const studentMap = new Map();
			for (let i = 0; i < userIds.length; i += 30) {
				const chunk = userIds.slice(i, i + 30);
				const q = query(collection(db, 'students'), where('userId', 'in', chunk));
				const snap = await getDocs(q);
				snap.docs.forEach(doc => studentMap.set(doc.data().userId, doc.data()));
			}
			return studentMap;
		},
		mapApplicantsData(apps, studentMap) {
			return apps.map(app => {
				const student = studentMap.get(app.userId) || {};
				return {
					...app,
					race: student.race?.trim() || 'Unknown',
					gender: student.gender?.trim() || 'Unknown',
					province: student.location?.province?.trim() || 'Unknown',
					university: app.selectedDegree?.university?.trim() || 'Unknown',
					degree: app.selectedDegree?.degree?.trim() || 'Unknown',
					year: app.selectedDegree?.yearOfStudy?.toString().trim() || 'Unknown',
					ageGroup: this.calculateAgeGroup(student.dateOfBirth),
				};
			});
		},
		extractUniqueValues() {
			this.allUniqueRaces = [...new Set(this.bursaryApplicants.map(a => a.race))].filter(val => val && val !== 'Unknown');
			this.allUniqueGenders = [...new Set(this.bursaryApplicants.map(a => a.gender))].filter(val => val && val !== 'Unknown');
			this.allUniqueProvinces = [...new Set(this.bursaryApplicants.map(a => a.province))].filter(val => val && val !== 'Unknown');
			this.allUniqueUniversities = [...new Set(this.bursaryApplicants.map(a => a.university))].filter(val => val && val !== 'Unknown');
			this.allUniqueDegrees = [...new Set(this.bursaryApplicants.map(a => a.degree))].filter(val => val && val !== 'Unknown');
			this.allUniqueYears = [...new Set(this.bursaryApplicants.map(a => a.year))].filter(val => val && val !== 'Unknown');
			this.allUniqueAgeGroups = [...new Set(this.bursaryApplicants.map(a => a.ageGroup))].filter(val => val && val !== 'Unknown');
		},
		clearApplicantsData() {
			this.bursaryApplicants = [];
			this.filteredApplicants = [];
		},
		calculateAgeGroup(dob) {
			if (!dob) return 'Unknown';
			const age = new Date().getFullYear() - parseInt(dob.split('/')[2]);
			return age >= 18 && age <= 24 ? '18-24' : age >= 25 && age <= 34 ? '25-34' : '35+';
		},
		setApplicantView(view) {
			this.applicantView = view;
			this.populateChartData();
		},
	},
};
</script>

<style lang="scss" scoped>
.dashboard-container {
	max-width: 1500px;
	margin: auto;
}
.dashboard-header {
	margin-bottom: 1rem;
}
.chart-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
	&.row-1 {
		height: 400px;
		margin-top: 0;
	}
	&.row-2 {
		height: 400px;
		margin-bottom: 5rem;
	}
	&.row-3 {
		height: 400px;
		margin-bottom: 8rem;
		margin-top: 8rem;
	}
	&.row-4 {
		height: 400px;
	}
}
.chart-card {
	border: 1px solid #e4e4e9;
	border-radius: 10px;
	padding: 1rem;
	margin: 10px;
	&.half {
		width: 50%;
	}
	&.full {
		width: 100%;
	}
	&.bar {
		padding: 1rem 1rem 5rem 1rem;
		height: 90%;
	}
	&.fit {
		height: fit-content;
	}
}
.md-icon {
	vertical-align: middle;
	margin-right: 0.5rem;
	margin-bottom: 3px !important;
}
select {
	padding: 12px;
	border: 1px solid #e4e4e8;
	border-radius: 10px;
}
.stats-card-container,
.filter-container {
	.filter-input {
		margin-right: 1rem;
		margin-top: 1rem;
	}
	h2 {
		margin-top: -1rem;
		font-weight: bold;
	}
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	.stats-card {
		width: 500px;
		height: 150px;
		border: 1px solid #e4e4e8;
		padding: 1rem;
		border-radius: 10px;
	}
}
.applicants-toggle-container {
	width: fit-content;
	padding: 5px;
	border-radius: 10px;
	height: 45px;
	background-color: #f4f4f6;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	.applicant-toggle {
		margin: 0 10px;
		background-color: white;
		border-radius: 10px;
		padding: 0 10px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			cursor: pointer;
		}
	}
	.applicant-toggle.active {
		font-weight: bold;
	}
}
.clear-filter {
	align-self: center;
}
</style>
