var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        [
          _c(
            "md-card",
            [
              _c(
                "md-button",
                {
                  staticClass:
                    "md-simple md-just-icon md-round modal-default-button",
                  staticStyle: {
                    position: "absolute",
                    top: "0px",
                    left: "3px",
                    "font-size": "30px !imporant",
                  },
                  on: { click: _vm.openUsersHelp },
                },
                [_c("md-icon", [_vm._v("help")])],
                1
              ),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "md-table",
                    {
                      attrs: { "md-card": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "md-table-row",
                            fn: function (ref) {
                              var item = ref.item
                              return _c(
                                "md-table-row",
                                {},
                                [
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Name",
                                        "md-sort-by": "name",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Surname",
                                        "md-sort-by": "surname",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.surname))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Email",
                                        "md-sort-by": "email",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.email))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Status",
                                        "md-sort-by": "status",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.status))]
                                  ),
                                  _vm.mainClient
                                    ? _c("md-table-cell", [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectUserForDelete(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_c("md-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.mainClient
                                    ? _c("md-table-cell", [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectUserForEdit(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_c("md-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2346351116
                      ),
                      model: {
                        value: _vm.users,
                        callback: function ($$v) {
                          _vm.users = $$v
                        },
                        expression: "users",
                      },
                    },
                    [
                      _c("md-table-toolbar", [
                        _c("h1", { staticClass: "md-title" }, [
                          _vm._v("Users"),
                        ]),
                        _vm.mainClient
                          ? _c(
                              "div",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success",
                                    on: { click: _vm.openAddUsersModal },
                                  },
                                  [_vm._v(" Add Users")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.openUserModal
                ? _c(
                    "div",
                    [
                      _c(
                        "modal",
                        [
                          _c("template", { slot: "header" }, [
                            this.isEditUser
                              ? _c("div", [_c("h2", [_vm._v("Edit user")])])
                              : _c("div", [
                                  _c("h2", [
                                    _vm._v("Invite users to workspace"),
                                  ]),
                                ]),
                          ]),
                          _c("template", { slot: "body" }, [
                            _c("form", { staticClass: "labels" }, [
                              _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.v$.firstName.$errors,
                                    function (error) {
                                      return _c("div", { key: error.$uid }, [
                                        _c(
                                          "div",
                                          { staticClass: "labels-error" },
                                          [_vm._v(_vm._s(error.$message))]
                                        ),
                                      ])
                                    }
                                  ),
                                  _c("label", [_vm._v("First Name")]),
                                  _c(
                                    "md-field",
                                    [
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          name: "firstName",
                                        },
                                        model: {
                                          value: _vm.firstName,
                                          callback: function ($$v) {
                                            _vm.firstName = $$v
                                          },
                                          expression: "firstName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.v$.surname.$errors,
                                    function (error) {
                                      return _c("div", { key: error.$uid }, [
                                        _c(
                                          "div",
                                          { staticClass: "labels-error" },
                                          [_vm._v(_vm._s(error.$message))]
                                        ),
                                      ])
                                    }
                                  ),
                                  _c("label", [_vm._v("Surname")]),
                                  _c(
                                    "md-field",
                                    [
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          name: "surname",
                                        },
                                        model: {
                                          value: _vm.surname,
                                          callback: function ($$v) {
                                            _vm.surname = $$v
                                          },
                                          expression: "surname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              !this.isEditUser
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(
                                        _vm.v$.email.$errors,
                                        function (error) {
                                          return _c(
                                            "div",
                                            { key: error.$uid },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "labels-error" },
                                                [_vm._v(_vm._s(error.$message))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("label", [_vm._v("Email")]),
                                      _c(
                                        "md-field",
                                        [
                                          _c("md-input", {
                                            attrs: {
                                              type: "text",
                                              name: "email",
                                            },
                                            model: {
                                              value: _vm.email,
                                              callback: function ($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("template", { slot: "footer" }, [
                            _c(
                              "div",
                              { staticClass: "modal-footer" },
                              [
                                this.isEditUser
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass: "md-success",
                                        on: { click: _vm.editUsers },
                                      },
                                      [_vm._v(" Edit User")]
                                    )
                                  : _c(
                                      "md-button",
                                      {
                                        staticClass: "md-success",
                                        on: { click: _vm.addUsers },
                                      },
                                      [_vm._v(" Add User")]
                                    ),
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-danger",
                                    on: { click: _vm.cancel },
                                  },
                                  [_vm._v(" Cancel")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isConfirmation
                ? _c(
                    "div",
                    [
                      _c(
                        "modal",
                        [
                          _c("template", { slot: "header" }, [
                            _c("h3", [_vm._v(_vm._s(this.header))]),
                          ]),
                          _c("template", { slot: "body" }, [
                            _c("p", [_vm._v(_vm._s(this.message))]),
                          ]),
                          _c(
                            "template",
                            { slot: "footer" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-success",
                                  on: {
                                    click: function () {
                                      this$1.isConfirmation = false
                                    },
                                  },
                                },
                                [_vm._v("OK")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isDeleteUser
                ? _c(
                    "div",
                    [
                      _c(
                        "modal",
                        [
                          _c("template", { slot: "header" }, [
                            _c("h3", [_vm._v("Delete User!")]),
                          ]),
                          _c("template", { slot: "body" }, [
                            _c("p", [
                              _vm._v(
                                "This user will no longer have access to this workspace."
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Are you sure you want to delete " +
                                  _vm._s(this.deleteUser.name) +
                                  " " +
                                  _vm._s(this.deleteUser.surname) +
                                  "?"
                              ),
                            ]),
                          ]),
                          _c("template", { slot: "footer" }, [
                            _c(
                              "div",
                              { staticClass: "modal-footer" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success",
                                    on: { click: _vm.deleteSelectedUser },
                                  },
                                  [_vm._v(" Yes")]
                                ),
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-danger",
                                    on: {
                                      click: function () {
                                        this$1.isDeleteUser = false
                                      },
                                    },
                                  },
                                  [_vm._v(" No")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }