var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    {
      staticClass: "md-card-login",
      class: { "md-card-hidden": _vm.cardHidden },
    },
    [
      _c(
        "md-card-header",
        { class: _vm.getClass(_vm.headerColor) },
        [_vm._t("title")],
        2
      ),
      _c(
        "md-card-content",
        [_vm._t("description"), _vm._t("inputs"), _vm._t("text-field")],
        2
      ),
      _c("md-card-actions", [_vm._t("footer")], 2),
      _c("md-card-content", [_vm._t("hyperlink")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }