var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    {
      staticClass: "md-card-testimonial",
      class: { "md-card-plain": _vm.cardPlain },
    },
    [
      _c(
        "md-card-header",
        [
          _vm.$slots.icon
            ? _c("div", { staticClass: "icon icon-black" }, [_vm._t("icon")], 2)
            : _vm._e(),
          _vm._t("cardAvatarTop"),
        ],
        2
      ),
      _vm.$slots.cardContent
        ? _c("md-card-content", [_vm._t("cardContent")], 2)
        : _vm._e(),
      _vm.$slots.cardAction
        ? _c(
            "md-card-actions",
            { staticClass: "justify-content-center" },
            [_vm._t("cardAction")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }