var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.writtenSubmission || this.isVideoSubmission
    ? _c(
        "modal",
        { staticClass: "modal-frame" },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _c("div", { staticClass: "top-left-text" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.currentProfile.profile.name) +
                      " " +
                      _vm._s(_vm.currentProfile.profile.surname)
                  ),
                ]),
              ]),
              _c(
                "md-button",
                {
                  staticClass:
                    "md-simple md-just-icon md-round modal-default-button",
                  on: { click: _vm.closeViewWrittenSubmissionModal },
                },
                [
                  _c("md-icon", { staticClass: "md-size-3x" }, [
                    _vm._v("clear"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "body" }, [
            _c(
              "div",
              [
                _c("iframe", { attrs: { src: this.submissionUrl } }),
                _vm.profileModalVisible
                  ? _c(
                      "modal",
                      { on: { close: _vm.profileModalHide } },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-simple md-just-icon md-round modal-default-button",
                                on: { click: _vm.profileModalHide },
                              },
                              [
                                _c(
                                  "md-icon",
                                  {
                                    staticStyle: {
                                      "font-size": "2rem !important",
                                      color: "black !important",
                                    },
                                  },
                                  [_vm._v("clear")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "bottom-right-button" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: { click: _vm.viewProfile },
                      },
                      [_vm._v(" View Profile ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }