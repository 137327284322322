var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("h5", { staticClass: "info-text" }, [
        _vm._v("Tell us a little about yourself"),
      ]),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("notifications"),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-40 md-small-size-100" },
            [
              _c("div", { staticClass: "picture-container" }, [
                _c("div", { staticClass: "picture" }, [
                  !_vm.image
                    ? _c("div", [
                        _c("img", {
                          staticClass: "picture-src",
                          attrs: { src: _vm.avatar, title: "" },
                        }),
                      ])
                    : _c("div", [_c("img", { attrs: { src: _vm.image } })]),
                  _c("input", {
                    attrs: {
                      type: "file",
                      title: "Profile Picture",
                      accept: "image/*",
                    },
                    on: { change: _vm.previewImage },
                  }),
                ]),
                _c("h6", { staticClass: "description" }, [
                  _vm._v("Profile Picture"),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-60 mt-4 md-small-size-100" },
            [
              _c(
                "md-datepicker",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.modelValidations.dob,
                      expression: "modelValidations.dob",
                    },
                  ],
                  class: [
                    { "md-valid": !_vm.errors.has("dob") && _vm.touched.dob },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("dob") },
                  ],
                  attrs: {
                    "data-vv-name": "dob",
                    required: "",
                    "md-immediately": "",
                  },
                  on: { input: _vm.addDob },
                  model: {
                    value: _vm.dob,
                    callback: function ($$v) {
                      _vm.dob = $$v
                    },
                    expression: "dob",
                  },
                },
                [
                  _c("label", [_vm._v("Date of birth")]),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("dob"),
                              expression: "errors.has('dob')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.errors.has("dob") && _vm.touched.dob,
                              expression: "!errors.has('dob') && touched.dob",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("gender") && _vm.touched.gender,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("gender") },
                  ],
                },
                [
                  _vm.gender == "Female"
                    ? _c("md-icon", [_c("i", { staticClass: "fas fa-female" })])
                    : _vm.gender == "Male"
                    ? _c("md-icon", [_c("i", { staticClass: "fas fa-male" })])
                    : _vm.gender == "Other"
                    ? _c("md-icon", [
                        _c("i", { staticClass: "fas fa-transgender" }),
                      ])
                    : _c("md-icon", [
                        _c("i", { staticClass: "fas fa-genderless" }),
                      ]),
                  _c("label", { attrs: { for: "gender" } }, [_vm._v("Gender")]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.gender,
                          expression: "modelValidations.gender",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "gender",
                        name: "gender",
                        required: "",
                      },
                      on: { input: _vm.addGender },
                      model: {
                        value: _vm.gender,
                        callback: function ($$v) {
                          _vm.gender = $$v
                        },
                        expression: "gender",
                      },
                    },
                    _vm._l(_vm.genders, function (gender, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: gender } },
                        [_vm._v(_vm._s(gender))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("gender"),
                              expression: "errors.has('gender')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("gender") && _vm.touched.gender,
                              expression:
                                "!errors.has('gender') && touched.gender",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    { "md-valid": !_vm.errors.has("race") && _vm.touched.race },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("race") },
                  ],
                },
                [
                  _c("md-icon", [_vm._v("face")]),
                  _c("label", { attrs: { for: "race" } }, [_vm._v("Race")]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.race,
                          expression: "modelValidations.race",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "race",
                        name: "race",
                        required: "",
                      },
                      on: { input: _vm.addRace },
                      model: {
                        value: _vm.race,
                        callback: function ($$v) {
                          _vm.race = $$v
                        },
                        expression: "race",
                      },
                    },
                    _vm._l(_vm.races, function (race, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: race } },
                        [_vm._v(_vm._s(race))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("race"),
                              expression: "errors.has('race')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("race") && _vm.touched.race,
                              expression: "!errors.has('race') && touched.race",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("location") && _vm.touched.location,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("location") },
                  ],
                },
                [
                  _c("md-icon", [
                    _c("i", { staticClass: "fas fa-map-marker-alt" }),
                  ]),
                  _c("label", { attrs: { for: "location" } }, [
                    _vm._v("Where do you stay (i.e Town or Suburb?)"),
                  ]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      id: "autocomplete-location",
                      type: "text",
                      "data-vv-name": "location",
                      name: "location",
                    },
                    on: {
                      input: _vm.addLocation,
                      blur: _vm.handleLocationBlur,
                    },
                    model: {
                      value: _vm.formattedLocationValue,
                      callback: function ($$v) {
                        _vm.formattedLocationValue = $$v
                      },
                      expression: "formattedLocationValue",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("location"),
                              expression: "errors.has('location')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("location") &&
                                _vm.touched.location,
                              expression:
                                "!errors.has('location') && touched.location",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("relocation") && _vm.touched.relocation,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("relocation") },
                  ],
                },
                [
                  _c("md-icon", [_vm._v("commute")]),
                  _c("label", { attrs: { for: "relocation" } }, [
                    _vm._v("Are you willing to relocate?"),
                  ]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.relocation,
                          expression: "modelValidations.relocation",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "relocation",
                        name: "relocation",
                        required: "",
                      },
                      on: { input: _vm.addRelocation },
                      model: {
                        value: _vm.relocation,
                        callback: function ($$v) {
                          _vm.relocation = $$v
                        },
                        expression: "relocation",
                      },
                    },
                    _vm._l(_vm.yes_no, function (answer, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: answer } },
                        [_vm._v(_vm._s(answer))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("relocation"),
                              expression: "errors.has('relocation')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("relocation") &&
                                _vm.touched.relocation,
                              expression:
                                "!errors.has('relocation') && touched.relocation",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("disability") && _vm.touched.disability,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("disability") },
                  ],
                },
                [
                  _c("md-icon", [
                    _c("i", {
                      staticClass: "fas fa-american-sign-language-interpreting",
                    }),
                  ]),
                  _c("label", { attrs: { for: "disability" } }, [
                    _vm._v("Do you have a disability?"),
                  ]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.disability,
                          expression: "modelValidations.disability",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "disability",
                        name: "disability",
                        required: "",
                      },
                      on: { input: _vm.addDisability },
                      model: {
                        value: _vm.disability,
                        callback: function ($$v) {
                          _vm.disability = $$v
                        },
                        expression: "disability",
                      },
                    },
                    _vm._l(_vm.yes_no, function (yes_no, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: yes_no } },
                        [_vm._v(_vm._s(yes_no))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("disability"),
                              expression: "errors.has('disability')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("disability") &&
                                _vm.touched.disability,
                              expression:
                                "!errors.has('disability') && touched.disability",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.disability === "Yes"
                ? _c(
                    "md-field",
                    {
                      class: [
                        {
                          "md-valid":
                            !_vm.errors.has("disabilityDescription") &&
                            _vm.touched.disabilityDescription,
                        },
                        { "md-form-group": true },
                        { "md-error": _vm.errors.has("disabilityDescription") },
                      ],
                    },
                    [
                      _c("md-icon", [_c("i", { staticClass: "fas fa-blind" })]),
                      _c("label", [_vm._v("Description of your disability.")]),
                      _c("md-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.disabilityDescription,
                            expression:
                              "modelValidations.disabilityDescription",
                          },
                        ],
                        attrs: {
                          "data-vv-name": "disabilityDescription",
                          type: "text",
                          name: "disabilityDescription",
                        },
                        on: { change: _vm.addDisabilityDescription },
                        model: {
                          value: _vm.disabilityDescription,
                          callback: function ($$v) {
                            _vm.disabilityDescription = $$v
                          },
                          expression: "disabilityDescription",
                        },
                      }),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has(
                                    "disabilityDescription"
                                  ),
                                  expression:
                                    "errors.has('disabilityDescription')",
                                },
                              ],
                              staticClass: "error",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.errors.has("disabilityDescription") &&
                                    _vm.touched.disabilityDescription,
                                  expression:
                                    "!errors.has('disabilityDescription') && touched.disabilityDescription",
                                },
                              ],
                              staticClass: "success",
                            },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("license") && _vm.touched.license,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("license") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "far fa-id-badge" })]),
                  _c("label", { attrs: { for: "license" } }, [
                    _vm._v("Driver's license?"),
                  ]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.license,
                          expression: "modelValidations.license",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "license",
                        name: "license",
                        required: "",
                      },
                      on: { input: _vm.addLicense },
                      model: {
                        value: _vm.license,
                        callback: function ($$v) {
                          _vm.license = $$v
                        },
                        expression: "license",
                      },
                    },
                    _vm._l(_vm.yes_no, function (yes_no, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: yes_no } },
                        [_vm._v(_vm._s(yes_no))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("license"),
                              expression: "errors.has('license')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("license") &&
                                _vm.touched.license,
                              expression:
                                "!errors.has('license') && touched.license",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.license == "Yes"
                ? _c(
                    "md-field",
                    {
                      class: [
                        {
                          "md-valid":
                            !_vm.errors.has("vehicle") && _vm.touched.vehicle,
                        },
                        { "md-form-group": true },
                        { "md-error": _vm.errors.has("vehicle") },
                      ],
                    },
                    [
                      _c("md-icon", [_c("i", { staticClass: "fas fa-car" })]),
                      _c("label", { attrs: { for: "vehicle" } }, [
                        _vm._v("Do you have a vehicle?"),
                      ]),
                      _c(
                        "md-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.modelValidations.vehicle,
                              expression: "modelValidations.vehicle",
                            },
                          ],
                          staticClass: "pad",
                          attrs: {
                            "data-vv-name": "vehicle",
                            name: "vehicle",
                            required: "",
                          },
                          on: { input: _vm.addVehicle },
                          model: {
                            value: _vm.vehicle,
                            callback: function ($$v) {
                              _vm.vehicle = $$v
                            },
                            expression: "vehicle",
                          },
                        },
                        _vm._l(_vm.yes_no, function (yes_no, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: yes_no } },
                            [_vm._v(_vm._s(yes_no))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("vehicle"),
                                  expression: "errors.has('vehicle')",
                                },
                              ],
                              staticClass: "error",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.errors.has("vehicle") &&
                                    _vm.touched.vehicle,
                                  expression:
                                    "!errors.has('vehicle') && touched.vehicle",
                                },
                              ],
                              staticClass: "success",
                            },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("programme") && _vm.touched.programme,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("programme") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fas fa-briefcase" })]),
                  _c("label", { attrs: { for: "programme" } }, [
                    _vm._v("Which programme are you a part of?"),
                  ]),
                  _c(
                    "md-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.modelValidations.programme,
                          expression: "modelValidations.programme",
                        },
                      ],
                      staticClass: "pad",
                      attrs: {
                        "data-vv-name": "programme",
                        name: "programme",
                        required: "",
                      },
                      on: { input: _vm.addProgramme },
                      model: {
                        value: _vm.programme,
                        callback: function ($$v) {
                          _vm.programme = $$v
                        },
                        expression: "programme",
                      },
                    },
                    _vm._l(_vm.programmes, function (programme, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: programme } },
                        [_vm._v(_vm._s(programme))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("programme"),
                              expression: "errors.has('programme')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("programme") &&
                                _vm.touched.programme,
                              expression:
                                "!errors.has('programme') && touched.programme",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    { "md-valid": !_vm.errors.has("bio") && _vm.touched.bio },
                    { "md-error": _vm.errors.has("bio") },
                  ],
                },
                [
                  _c("label", [
                    _vm._v("Professional Summary ("),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(" You can skip this and fill it in later😅"),
                    ]),
                    _vm._v(" )"),
                  ]),
                  _c("md-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.bio,
                        expression: "modelValidations.bio",
                      },
                    ],
                    attrs: { "data-vv-name": "bio", type: "text", name: "bio" },
                    on: { change: _vm.addBio },
                    model: {
                      value: _vm.bio,
                      callback: function ($$v) {
                        _vm.bio = $$v
                      },
                      expression: "bio",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("bio"),
                              expression: "errors.has('bio')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.errors.has("bio") && _vm.touched.bio,
                              expression: "!errors.has('bio') && touched.bio",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [_vm._v(_vm._s(_vm.body))]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      _c("div", { staticClass: "loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }