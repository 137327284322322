var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.containerClass },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [_vm._m(0)])
        : _vm._e(),
      _c("div", [
        _c("h1", [_vm._v("Comments")]),
        _vm.errorMessage
          ? _c(
              "p",
              {
                staticStyle: {
                  color: "red !important",
                  "margin-top": "-1.8rem",
                  "font-weight": "bold",
                },
              },
              [_vm._v(_vm._s(_vm.errorMessage))]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "comment-box-container" },
          [
            _vm._l(_vm.comments, function (comment, index) {
              return _c(
                "md-card",
                { key: index, staticClass: "comment-card" },
                [
                  _c("div", { staticClass: "comment-header" }, [
                    _c("p", { staticClass: "bold-text" }, [
                      _vm._v(
                        _vm._s(comment.clientName) +
                          " - " +
                          _vm._s(comment.companyName)
                      ),
                    ]),
                    _c("p", [_vm._v("Posted: " + _vm._s(comment.timestamp))]),
                  ]),
                  !_vm.isEditingComment(index)
                    ? _c("p", [_vm._v(_vm._s(comment.commentBody))])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "crud-container" },
                    [
                      _c("star-rating", {
                        attrs: {
                          value: _vm.commentRating(comment),
                          disabled: !_vm.isEditingComment(index),
                        },
                        on: { setRatingValue: _vm.setEditedRating },
                      }),
                      !_vm.isEditingComment(index) &&
                      comment.commentorId === _vm.currentUserUid
                        ? _c("div", { staticClass: "crud-actions" }, [
                            _c(
                              "div",
                              {
                                staticClass: "crud-btn-container",
                                on: {
                                  click: function ($event) {
                                    return _vm.editComment(comment, index)
                                  },
                                },
                              },
                              [
                                _c("md-icon", { staticClass: "crud-btn" }, [
                                  _vm._v(" edit "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "crud-btn-container",
                                on: {
                                  click: function ($event) {
                                    return _vm.showDeleteModal(comment.id)
                                  },
                                },
                              },
                              [
                                _c("md-icon", { staticClass: "crud-btn" }, [
                                  _vm._v(" delete "),
                                ]),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isEditingComment(index)
                    ? _c("div", { staticClass: "comment-edit-section" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedComment.commentBody,
                              expression: "selectedComment.commentBody",
                            },
                          ],
                          staticClass: "comment-textarea",
                          attrs: { placeholder: "Update Your Comment" },
                          domProps: { value: _vm.selectedComment.commentBody },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.selectedComment,
                                "commentBody",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-success",
                                staticStyle: { "margin-right": "1rem" },
                                on: { click: _vm.updateComment },
                              },
                              [_vm._v("Save")]
                            ),
                            _c(
                              "md-button",
                              {
                                staticClass: "md-danger",
                                on: { click: _vm.cancelEdit },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _c(
              "md-button",
              {
                staticClass: "close-button md-danger",
                on: { click: _vm.handleClose },
              },
              [_c("md-icon", [_vm._v("chevron_left ")])],
              1
            ),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "leave-comment" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.commentBody,
              expression: "commentBody",
            },
          ],
          staticClass: "comment-textarea",
          attrs: { placeholder: "Leave a Comment" },
          domProps: { value: _vm.commentBody },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.commentBody = $event.target.value
            },
          },
        }),
        _c(
          "div",
          { staticClass: "leave-comment-button-container" },
          [
            _c(
              "div",
              { staticClass: "star-rating" },
              [
                _c("star-rating", {
                  on: { setRatingValue: _vm.setApplicantRating },
                  model: {
                    value: _vm.applicantRating,
                    callback: function ($$v) {
                      _vm.applicantRating = $$v
                    },
                    expression: "applicantRating",
                  },
                }),
              ],
              1
            ),
            _c(
              "md-button",
              { staticClass: "md-success", on: { click: _vm.postComment } },
              [_vm._v("Leave Comment")]
            ),
          ],
          1
        ),
      ]),
      _vm.showDeleteConfirmationModal
        ? _c(
            "modal",
            { on: { close: _vm.closeModal } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Whoa there! 🤚"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.closeModal },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(
                    "You are about to delete your comment. Are you sure you want to continue with this?"
                  ),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-danger",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v("No")]
                    ),
                    _vm._v("     "),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: {
                          click: function ($event) {
                            return _vm.deleteComment(_vm.commentToDeleteId)
                          },
                        },
                      },
                      [_vm._v("Yes")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-circle" }, [
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
        _c("div", { staticClass: "loading" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }