var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
        : _vm._e(),
      _c("h5", { staticClass: "info-text" }, [
        _vm._v("Social Media Accounts and Additional Documents"),
      ]),
      _c("p", { staticClass: "info-text" }, [
        _vm._v("Please do not include inappropriate handles 🤪"),
      ]),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c("notifications"),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("linkedIn") && _vm.touched.linkedIn,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("linkedIn") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fab fa-linkedin" })]),
                  _c("label", [_vm._v("LinkedIn")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.linkedIn,
                        expression: "modelValidations.linkedIn",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "linkedIn",
                      type: "url",
                      name: "linkedIn",
                      required: "",
                    },
                    on: {
                      change: _vm.addLinkedIn,
                      blur: function ($event) {
                        return _vm.setTouched("linkedIn")
                      },
                    },
                    model: {
                      value: _vm.linkedIn,
                      callback: function ($$v) {
                        _vm.linkedIn = $$v
                      },
                      expression: "linkedIn",
                    },
                  }),
                  _c(
                    "md-tooltip",
                    {
                      style: { maxWidth: "800px" },
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Please add https:// to the beginning of the URL")]
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("linkedIn"),
                              expression: "errors.has('linkedIn')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("linkedIn") &&
                                _vm.touched.linkedIn,
                              expression:
                                "!errors.has('linkedIn') && touched.linkedIn",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("facebook") && _vm.touched.facebook,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("facebook") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fab fa-facebook" })]),
                  _c("label", [_vm._v("Facebook")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.facebook,
                        expression: "modelValidations.facebook",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "facebook",
                      type: "url",
                      name: "facebook",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.setTouched("facebook")
                      },
                      change: _vm.addFacebook,
                    },
                    model: {
                      value: _vm.facebook,
                      callback: function ($$v) {
                        _vm.facebook = $$v
                      },
                      expression: "facebook",
                    },
                  }),
                  _c(
                    "md-tooltip",
                    {
                      style: { maxWidth: "800px" },
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Please add https:// to the beginning of the URL")]
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("facebook"),
                              expression: "errors.has('facebook')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("facebook") &&
                                _vm.touched.facebook,
                              expression:
                                "!errors.has('facebook') && touched.facebook",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("twitter") && _vm.touched.twitter,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("twitter") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fab fa-twitter" })]),
                  _c("label", [_vm._v("Twitter")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.twitter,
                        expression: "modelValidations.twitter",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "twitter",
                      type: "url",
                      name: "twitter",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.setTouched("twitter")
                      },
                      change: _vm.addTwitter,
                    },
                    model: {
                      value: _vm.twitter,
                      callback: function ($$v) {
                        _vm.twitter = $$v
                      },
                      expression: "twitter",
                    },
                  }),
                  _c(
                    "md-tooltip",
                    {
                      style: { maxWidth: "800px" },
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Please add https:// to the beginning of the URL")]
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("twitter"),
                              expression: "errors.has('twitter')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("twitter") &&
                                _vm.touched.twitter,
                              expression:
                                "!errors.has('twitter') && touched.twitter",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("instagram") && _vm.touched.instagram,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("instagram") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fab fa-instagram" })]),
                  _c("label", [_vm._v("Instagram")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.instagram,
                        expression: "modelValidations.instagram",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "instagram",
                      type: "url",
                      name: "instagram",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.setTouched("instagram")
                      },
                      change: _vm.addInstagram,
                    },
                    model: {
                      value: _vm.instagram,
                      callback: function ($$v) {
                        _vm.instagram = $$v
                      },
                      expression: "instagram",
                    },
                  }),
                  _c(
                    "md-tooltip",
                    {
                      style: { maxWidth: "800px" },
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Please add https:// to the beginning of the URL")]
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("instagram"),
                              expression: "errors.has('instagram')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("instagram") &&
                                _vm.touched.instagram,
                              expression:
                                "!errors.has('instagram') && touched.instagram",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("github") && _vm.touched.github,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("github") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fab fa-github" })]),
                  _c("label", [_vm._v("Github")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.github,
                        expression: "modelValidations.github",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "github",
                      type: "url",
                      name: "github",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.setTouched("github")
                      },
                      change: _vm.addGithub,
                    },
                    model: {
                      value: _vm.github,
                      callback: function ($$v) {
                        _vm.github = $$v
                      },
                      expression: "github",
                    },
                  }),
                  _c(
                    "md-tooltip",
                    {
                      style: { maxWidth: "800px" },
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Please add https:// to the beginning of the URL")]
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("github"),
                              expression: "errors.has('github')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("github") && _vm.touched.github,
                              expression:
                                "!errors.has('github') && touched.github",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("personalWebsite") &&
                        _vm.touched.personalWebsite,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("personalWebsite") },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fas fa-globe" })]),
                  _c("label", [_vm._v("Personal Website")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.personalWebsite,
                        expression: "modelValidations.personalWebsite",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "personalWebsite",
                      type: "url",
                      name: "personalWebsite",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.setTouched("personalWebsite")
                      },
                      change: _vm.addPersonalWebsite,
                    },
                    model: {
                      value: _vm.personalWebsite,
                      callback: function ($$v) {
                        _vm.personalWebsite = $$v
                      },
                      expression: "personalWebsite",
                    },
                  }),
                  _c(
                    "md-tooltip",
                    {
                      style: { maxWidth: "800px" },
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Please add https:// to the beginning of the URL")]
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("personalWebsite"),
                              expression: "errors.has('personalWebsite')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("personalWebsite") &&
                                _vm.touched.personalWebsite,
                              expression:
                                "!errors.has('personalWebsite') && touched.personalWebsite",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    { "md-valid": !_vm.errors.has("id") && _vm.touched.id },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("id") },
                  ],
                },
                [
                  _c("label", [_vm._v("ID/Passport")]),
                  _c("md-file", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.id,
                        expression: "modelValidations.id",
                      },
                    ],
                    attrs: { "data-vv-name": "id", name: "id" },
                    on: { change: _vm.previewID },
                    model: {
                      value: _vm.id,
                      callback: function ($$v) {
                        _vm.id = $$v
                      },
                      expression: "id",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("id"),
                              expression: "errors.has('id')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.errors.has("id") && _vm.touched.id,
                              expression: "!errors.has('id') && touched.id",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    { "md-valid": !_vm.errors.has("cv") && _vm.touched.cv },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("cv") },
                  ],
                },
                [
                  _c("label", [_vm._v("CV")]),
                  _c("md-file", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.cv,
                        expression: "modelValidations.cv",
                      },
                    ],
                    attrs: { "data-vv-name": "cv", name: "cv" },
                    on: { change: _vm.previewCV },
                    model: {
                      value: _vm.cv,
                      callback: function ($$v) {
                        _vm.cv = $$v
                      },
                      expression: "cv",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("cv"),
                              expression: "errors.has('cv')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.errors.has("cv") && _vm.touched.cv,
                              expression: "!errors.has('cv') && touched.cv",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("portfolio") && _vm.touched.portfolio,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("portfolio") },
                  ],
                },
                [
                  _c("label", [_vm._v("Portfolio")]),
                  _c("md-file", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.portfolio,
                        expression: "modelValidations.portfolio",
                      },
                    ],
                    attrs: { "data-vv-name": "portfolio", name: "portfolio" },
                    on: { change: _vm.previewPortfolio },
                    model: {
                      value: _vm.portfolio,
                      callback: function ($$v) {
                        _vm.portfolio = $$v
                      },
                      expression: "portfolio",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("portfolio"),
                              expression: "errors.has('portfolio')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("portfolio") &&
                                _vm.touched.portfolio,
                              expression:
                                "!errors.has('portfolio') && touched.portfolio",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item ml-auto mt-4 md-small-size-100" },
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("certificate1") &&
                        _vm.touched.certificate1,
                    },
                    { "md-form-group": true },
                    { "md-error": _vm.errors.has("certificate1") },
                  ],
                },
                [
                  _c("label", [_vm._v("Certificate #1")]),
                  _c("md-file", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.certificate1,
                        expression: "modelValidations.certificate1",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "certificate1",
                      name: "certificate1",
                    },
                    on: { change: _vm.previewCertificate1 },
                    model: {
                      value: _vm.certificate1,
                      callback: function ($$v) {
                        _vm.certificate1 = $$v
                      },
                      expression: "certificate1",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("certificate1"),
                              expression: "errors.has('certificate1')",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("certificate1") &&
                                _vm.touched.certificate1,
                              expression:
                                "!errors.has('certificate1') && touched.certificate1",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.certificate1
            ? _c(
                "div",
                {
                  staticClass: "md-layout-item ml-auto mt-4 md-small-size-100",
                },
                [
                  _c(
                    "md-field",
                    {
                      class: [
                        {
                          "md-valid":
                            !_vm.errors.has("certificate2") &&
                            _vm.touched.certificate2,
                        },
                        { "md-form-group": true },
                        { "md-error": _vm.errors.has("certificate2") },
                      ],
                    },
                    [
                      _c("label", [_vm._v("Certificate #2")]),
                      _c("md-file", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.certificate2,
                            expression: "modelValidations.certificate2",
                          },
                        ],
                        attrs: {
                          "data-vv-name": "certificate2",
                          name: "certificate2",
                        },
                        on: { change: _vm.previewCertificate2 },
                        model: {
                          value: _vm.certificate2,
                          callback: function ($$v) {
                            _vm.certificate2 = $$v
                          },
                          expression: "certificate2",
                        },
                      }),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("certificate2"),
                                  expression: "errors.has('certificate2')",
                                },
                              ],
                              staticClass: "error",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.errors.has("certificate2") &&
                                    _vm.touched.certificate2,
                                  expression:
                                    "!errors.has('certificate2') && touched.certificate2",
                                },
                              ],
                              staticClass: "success",
                            },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.certificate2
            ? _c(
                "div",
                {
                  staticClass: "md-layout-item ml-auto mt-4 md-small-size-100",
                },
                [
                  _c(
                    "md-field",
                    {
                      class: [
                        {
                          "md-valid":
                            !_vm.errors.has("certificate3") &&
                            _vm.touched.certificate3,
                        },
                        { "md-form-group": true },
                        { "md-error": _vm.errors.has("certificate3") },
                      ],
                    },
                    [
                      _c("label", [_vm._v("Certificate #3")]),
                      _c("md-file", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.certificate3,
                            expression: "modelValidations.certificate3",
                          },
                        ],
                        attrs: {
                          "data-vv-name": "certificate3",
                          name: "certificate3",
                        },
                        on: { change: _vm.previewCertificate3 },
                        model: {
                          value: _vm.certificate3,
                          callback: function ($$v) {
                            _vm.certificate3 = $$v
                          },
                          expression: "certificate3",
                        },
                      }),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("certificate3"),
                                  expression: "errors.has('certificate3')",
                                },
                              ],
                              staticClass: "error",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "slide-y-down-transition",
                        [
                          _c(
                            "md-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.errors.has("certificate3") &&
                                    _vm.touched.certificate3,
                                  expression:
                                    "!errors.has('certificate3') && touched.certificate3",
                                },
                              ],
                              staticClass: "success",
                            },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h4", [_vm._v("Extracurricular Activities")]),
          _c(
            "md-button",
            {
              staticClass: "md-plain jb-standard md-fab-bottom-right",
              on: { click: _vm.addActivity },
            },
            [
              _c("md-icon", [_vm._v("add")]),
              _vm._v("Add Extracurricular Activities"),
            ],
            1
          ),
          _c("multi-select", {
            attrs: {
              openModal: _vm.openActivityModal,
              items: _vm.activities,
              heading: this.heading,
            },
            on: {
              cancel: function (closeModal) {
                return (_vm.openActivityModal = closeModal)
              },
              confirmSelection: function (selectedValue) {
                return _vm.confirmSelection(selectedValue)
              },
            },
          }),
          _c(
            "div",
            [
              _c("md-table", {
                attrs: { "md-card": "" },
                scopedSlots: _vm._u([
                  {
                    key: "md-table-row",
                    fn: function (ref) {
                      var item = ref.item
                      return _c(
                        "md-table-row",
                        {},
                        [_c("md-table-cell", [_vm._v(_vm._s(item.value))])],
                        1
                      )
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedActivities,
                  callback: function ($$v) {
                    _vm.selectedActivities = $$v
                  },
                  expression: "selectedActivities",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Whoa there! ✋"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v("You cannot exceed the file limit of 2MB"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      _c("div", { staticClass: "loading" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }