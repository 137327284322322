<template>
	<div>
		<button class="export-trigger" @click="openModal">Export CSV</button>
		<transition name="fade">
			<div v-if="showModal" class="modal-overlay" @click.self="closeModal">
				<div class="modal-container">
					<div class="modal-header"><h2>Select Columns to Export</h2></div>
					<div class="modal-content">
						<div class="checkbox-list">
							<div v-for="(column, index) in availableColumns" :key="index" class="checkbox-item">
								<md-checkbox v-model="selectedColumns" :value="column.value">{{ column.label }}</md-checkbox>
							</div>
						</div>
					</div>
					<div class="modal-actions">
						<md-button class="md-danger" @click="closeModal">CANCEL</md-button>
						<md-button class="export" @click="exportCsv">EXPORT</md-button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'CsvDownload',
	props: {
		data: { type: Array, default: () => [] },
		availableColumns: { type: Array, default: () => [] },
	},
	data() {
		return { showModal: false, selectedColumns: [] };
	},
	methods: {
		openModal() {
			this.showModal = true;
		},
		closeModal() {
			this.showModal = false;
		},
		exportCsv() {
			if (!this.selectedColumns.length) {
				alert('Please select at least one column to export.');
				return;
			}
			const rows = this.data.map(item => {
				return this.selectedColumns.map(col => {
					return item[col] !== undefined && item[col] !== null ? String(item[col]).replace(/"/g, '""') : '';
				});
			});
			const header = this.selectedColumns.map(colValue => {
				const colObj = this.availableColumns.find(col => col.value === colValue);
				return colObj ? colObj.label : colValue;
			});
			const csvArray = [header, ...rows].map(row => row.map(val => `"${val}"`).join(','));
			const csvContent = csvArray.join('\r\n');
			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'ExportedData.csv';
			link.click();
			URL.revokeObjectURL(url);
			this.closeModal();
		},
	},
};
</script>

<style scoped>
.export-trigger {
	padding: 0.75rem 1.25rem;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 6px;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
}
.export-trigger:hover {
	background-color: #005bb5;
}

.export {
	background: linear-gradient(90deg, rgb(0, 119, 255) 0%, rgb(34, 144, 254) 100%) !important;
	color: #fff !important;
	border: none !important;
	margin-left: 1rem !important;
	font-weight: 500 !important;
	cursor: pointer !important;
	transition: background-color 0.2s !important;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}
.modal-container {
	background: #fff;
	width: 600px;
	max-width: 90%;
	border-radius: 12px;
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
	overflow: auto;
	animation: fadeInUp 0.3s ease-out;
	display: flex;
	flex-direction: column;
}
.modal-header {
	padding: 1.5rem;
	border-bottom: 1px solid #eee;
	background: #f9f9f9;
}
.modal-header h2 {
	margin: 0;
	font-size: 1.5rem;
	font-weight: 600;
}
.modal-content {
	padding: 1.5rem;
}
.checkbox-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem 2rem;
}
.checkbox-item label {
	cursor: pointer;
	font-size: 1rem;
	display: flex;
	align-items: center;
}
.checkbox-item md-checkbox {
	margin-right: 8px;
}
.modal-actions {
	display: flex;
	justify-content: flex-end;
	padding: 1rem 1.5rem;
	border-top: 1px solid #eee;
	background: #f9f9f9;
}

e-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(40px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
</style>
