var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("center", [
        _c("img", {
          attrs: {
            src: require("@/assets/img/Unsuccessful Payment.png"),
            alt: "",
            height: "200",
            width: "200",
          },
        }),
      ]),
      _c(
        "h1",
        { staticClass: "black", staticStyle: { "text-align": "center" } },
        [_vm._v("Your payment was unsuccessful.")]
      ),
      _c(
        "h2",
        { staticClass: "black", staticStyle: { "text-align": "center" } },
        [_vm._v("Click below to view the job status")]
      ),
      _c(
        "center",
        [
          _c(
            "md-button",
            { staticClass: "md-success", on: { click: _vm.status } },
            [_vm._v("back to status")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Call us at: "),
      _c("b", [_vm._v("087 149 4394")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v(" Email us at: "),
      _c("b", [
        _c(
          "a",
          {
            staticStyle: { color: "blue" },
            attrs: { href: "mailto:contact@jobox.co.za" },
          },
          [_vm._v("contact@jobox.co.za")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }