var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "text-center lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("h1", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Post a Bursary"),
      ]),
      _c(
        "md-steppers",
        {
          ref: "stepper",
          staticStyle: { padding: "2rem" },
          attrs: { "md-active-step": _vm.active, "md-linear": "" },
          on: {
            "update:mdActiveStep": function ($event) {
              _vm.active = $event
            },
            "update:md-active-step": function ($event) {
              _vm.active = $event
            },
          },
        },
        [
          _c(
            "md-step",
            {
              attrs: {
                id: "description",
                "md-label": "Description",
                "md-description": "Bursary details",
                "md-done": _vm.steps.description,
              },
              on: {
                "update:mdDone": function ($event) {
                  return _vm.$set(_vm.steps, "description", $event)
                },
                "update:md-done": function ($event) {
                  return _vm.$set(_vm.steps, "description", $event)
                },
              },
            },
            [
              _c("first-step", {
                on: {
                  next: function ($event) {
                    return _vm.nextStep("description", "requirements")
                  },
                  title: _vm.addTitle,
                  description: _vm.addDescription,
                  workPlacement: _vm.addWorkPlacement,
                  workPlacementInfo: _vm.addWorkPlacementInfo,
                  deadline: _vm.addDeadline,
                },
              }),
            ],
            1
          ),
          _c(
            "md-step",
            {
              attrs: {
                id: "requirements",
                "md-label": "Requirements",
                "md-description": "Bursary requirements",
                "md-done": _vm.steps.requirements,
              },
              on: {
                "update:mdDone": function ($event) {
                  return _vm.$set(_vm.steps, "requirements", $event)
                },
                "update:md-done": function ($event) {
                  return _vm.$set(_vm.steps, "requirements", $event)
                },
              },
            },
            [
              _c("second-step", {
                on: {
                  next: function ($event) {
                    return _vm.nextStep("requirements", "funding")
                  },
                  previous: function ($event) {
                    return _vm.previousStep("requirements", "description")
                  },
                  requirements: _vm.addRequirements,
                  cv: _vm.addCv,
                  id: _vm.addId,
                  proofOfIncome: _vm.addProofOfIncome,
                  matricResults: _vm.addMatricResults,
                  academicTranscript: _vm.addAcademicTranscript,
                  tuitionQuotation: _vm.addTuitionQuotation,
                  other: _vm.addOther,
                  newDocument: _vm.addNewDocument,
                  addAdditionalDocuments: _vm.addAdditionalDocuments,
                  documentInstructions: _vm.addDocumentInstructions,
                },
              }),
            ],
            1
          ),
          _c(
            "md-step",
            {
              attrs: {
                id: "funding",
                "md-label": "Funding Details",
                "md-description": "Bursary funding details",
                "md-done": _vm.steps.funding,
              },
              on: {
                "update:mdDone": function ($event) {
                  return _vm.$set(_vm.steps, "funding", $event)
                },
                "update:md-done": function ($event) {
                  return _vm.$set(_vm.steps, "funding", $event)
                },
              },
            },
            [
              _c("third-step", {
                on: {
                  next: function ($event) {
                    return _vm.nextStep("funding", "additional")
                  },
                  previous: function ($event) {
                    return _vm.previousStep("funding", "requirements")
                  },
                  tuition: _vm.addTuition,
                  accommodation: _vm.addAccommodation,
                  meals: _vm.addMeals,
                  books: _vm.addBooks,
                  stipend: _vm.addStipend,
                  additionalCoverDetails: _vm.addAdditionalCoverDetails,
                },
              }),
            ],
            1
          ),
          _c(
            "md-step",
            {
              attrs: {
                id: "additional",
                "md-label": "Additional Submissions",
                "md-description": "Optional submissions",
                "md-done": _vm.steps.additional,
              },
              on: {
                "update:mdDone": function ($event) {
                  return _vm.$set(_vm.steps, "additional", $event)
                },
                "update:md-done": function ($event) {
                  return _vm.$set(_vm.steps, "additional", $event)
                },
              },
            },
            [
              _c("fourth-bursary-step", {
                on: {
                  next: function ($event) {
                    return _vm.nextStep("additional", "review")
                  },
                  previous: function ($event) {
                    return _vm.previousStep("additional", "funding")
                  },
                  videoSubmission: _vm.addVideoSubmission,
                  videoSubmissionDetails: _vm.addVideoSubmissionDetails,
                  writtenSubmission: _vm.addWrittenSubmission,
                  writtenSubmissionDetails: _vm.addWrittenSubmissionDetails,
                },
              }),
            ],
            1
          ),
          _c(
            "md-step",
            {
              attrs: {
                id: "review",
                "md-label": "Review",
                "md-description": "Review your information",
                "md-done": _vm.steps.review,
              },
              on: {
                "update:mdDone": function ($event) {
                  return _vm.$set(_vm.steps, "review", $event)
                },
                "update:md-done": function ($event) {
                  return _vm.$set(_vm.steps, "review", $event)
                },
              },
            },
            [
              _c("fifth-step", {
                attrs: {
                  title: _vm.title,
                  description: _vm.description,
                  workPlacement: _vm.workPlacement,
                  workPlacementInfo: _vm.workPlacementInfo,
                  applicationDeadline: _vm.applicationDeadline,
                  deadline: _vm.deadline,
                  requirements: _vm.requirements,
                  cv: _vm.cv,
                  id: _vm.id,
                  proofOfIncome: _vm.proofOfIncome,
                  matricResults: _vm.matricResults,
                  academicTranscript: _vm.academicTranscript,
                  tuitionQuotation: _vm.tuitionQuotation,
                  other: _vm.other,
                  newDocument: _vm.newDocument,
                  additionalDocuments: _vm.additionalDocuments,
                  documentInstructions: _vm.documentInstructions,
                  tuition: _vm.tuition,
                  accommodation: _vm.accommodation,
                  meals: _vm.meals,
                  books: _vm.books,
                  stipend: _vm.stipend,
                  additionalCoverDetails: _vm.additionalCoverDetails,
                  videoSubmission: _vm.videoSubmission,
                  videoSubmissionDetails: _vm.videoSubmissionDetails,
                  writtenSubmission: _vm.writtenSubmission,
                  writtenSubmissionDetails: _vm.writtenSubmissionDetails,
                  resetSteps: true,
                },
                on: {
                  postBursary: _vm.postBursary,
                  previous: function ($event) {
                    return _vm.previousStep("review", "additional")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.success
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "font-size": "2rem" } }, [
                  _vm._v("✅"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", [
                  _vm._v(
                    "Thank you for the bursary post. Our Customer Success Team will be in touch once your post has been verified"
                  ),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-button md-success",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(" Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }