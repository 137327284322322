var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rotating-card-container",
      class: { "manual-flip": _vm.manualRotate, hover: _vm.manualRotateState },
    },
    [
      _c(
        "md-card",
        {
          staticClass: "md-card-rotate",
          class: [_vm.cardClass, _vm.cardColor],
        },
        [
          _c(
            "div",
            {
              staticClass: "front",
              class: { "front-background": _vm.layerBackground },
              style: _vm.bgFrontFullCards(_vm.frontCardImage),
            },
            [
              _vm.$slots.frontRotateCardContent
                ? _c("md-card-content", [_vm._t("frontRotateCardContent")], 2)
                : _vm._e(),
              _vm.$slots.frontCardAction
                ? _c("md-card-actions", [_vm._t("frontCardAction")], 2)
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "back",
              class: { "back-background": _vm.layerBackground },
              style: _vm.bgBackFullCards(_vm.backCardImage),
            },
            [
              _vm.$slots.backRotateCardContent
                ? _c("md-card-content", [_vm._t("backRotateCardContent")], 2)
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }