var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("apexchart", {
    attrs: {
      width: _vm.width,
      height: _vm.height,
      type: "donut",
      options: _vm.options,
      series: _vm.series,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }