<template>
	<div v-if="loading">
		<md-card>
			<div class="container talentPool">
				<div class="talentPoolActions">
					<md-field style="max-width: 500px">
						<md-input type="search" class="mb-3" clearable placeholder="Search records" v-model="searchQuery"> </md-input>
					</md-field>
				</div>

				<md-table v-model="filteredTalentPools" md-card>
					<md-table-toolbar>
						<h1 class="md-title">Special Program Talent Pools</h1>
					</md-table-toolbar>

					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
						<md-table-cell md-label="Description" md-sort-by="description">{{ item.description }}</md-table-cell>
						<md-table-cell md-label="Student No." md-sort-by="email">{{ item.studentsLength }}</md-table-cell>

						<md-table-cell>
							<md-button @click="viewTalentPool(item)" class="md-success btn-talent-pool" style="width: 150px"> View Talent Pool</md-button>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</div>
		</md-card>
	</div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export default {
	name: 'TableTemplate',
	setup() {
		return { v$: useVuelidate() };
	},
	data: () => ({
		clientId: null,
		invitedUserList: [],
		talentPools: [],
		loading: false,
		availableTalentPool: [],
		searchQuery: '',
	}),
	computed: {
		filteredTalentPools() {
			return this.availableTalentPool.filter(
				item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || item.description.toLowerCase().includes(this.searchQuery.toLowerCase())
			);
		},
	},
	methods: {
		viewTalentPool(item) {
			this.$router.push({
				name: 'special-programs-students',
				params: { id: item.id },
				query: { talentPoolId: item.id, name: item.name },
			});
		},
		async getTalentPoolsWithStudents(clientId) {
			const talentPoolSubcollectionSnapshot = await db.collection('clients').doc(clientId).collection('specialProgramTalentPools').get();
			const talentPoolIds = talentPoolSubcollectionSnapshot.docs.map(doc => doc.data().talentPoolId);
			if (talentPoolIds.length === 0) {
				return [];
			}

			const talentPoolPromises = talentPoolIds.map(async talentPoolId => {
				const talentPoolDoc = await db.collection('specialProgramTalentPools').doc(talentPoolId).get();

				if (!talentPoolDoc.exists) {
					return null;
				}

				const studentsSnapshot = await db.collection('specialProgramTalentPools').doc(talentPoolId).collection('specialProgramTalentPoolStudents').get();

				const students = studentsSnapshot.docs.map(studentDoc => ({
					id: studentDoc.id,
					...studentDoc.data(),
				}));

				return {
					id: talentPoolId,
					...talentPoolDoc.data(),
					studentsLength: students.length,
				};
			});

			const talentPoolsWithStudents = (await Promise.all(talentPoolPromises)).filter(pool => pool !== null);
			return talentPoolsWithStudents;
		},
	},
	async created() {
		let user = firebase.auth().currentUser;
		const users = await db.collection('users').where('userId', '==', user.uid).get();
		users.docs.forEach(async doc => {
			if (doc.exists) {
				const clientData = doc.data();
				if (clientData.accessType) {
					this.getInvitedClientData(doc.data().clientAlias);
					this.clientId = doc.data().clientAlias;
					this.availableTalentPool = await this.getTalentPoolsWithStudents(this.clientId);
					this.invitedClient = true;
					this.loading = true;
				} else {
					this.clientId = doc.data().alias;
					this.availableTalentPool = await this.getTalentPoolsWithStudents(this.clientId);
					this.mainClient = true;
					this.loading = true;
				}
			}
		});
	},
};
</script>

<style scoped>
.md-button .md-button-content i {
	font-size: 30px !important;
}
.modal-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
.radio-selection {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.md-radio .md-radio-label {
	color: #3c4858 !important;
}
label.md-radio-label {
	color: #3c4858 !important;
}
.labels {
	text-align: left;
}
.labels-error {
	text-align: left;
	font-size: 12px;
	color: red;
}
.md-table >>> .md-table-head {
	/* background-color: #64a151 !important; */
	background-color: #ff5722 !important;
}
.md-table >>> .md-table-head-label {
	color: rgb(255, 255, 255) !important;
}
.container {
	margin: 0px 20px;
}
.talentPool {
	display: flex;
	flex-direction: column;
}
.talentPoolActions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
}
.modal-header h3 {
	color: black !important;
}
</style>
