var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      staticClass: "fixed-plugin",
    },
    [
      _c(
        "div",
        { staticClass: "dropdown show-dropdown", class: { show: _vm.isOpen } },
        [
          _c("a", { attrs: { "data-toggle": "dropdown" } }, [
            _c(
              "i",
              { staticClass: "fa fa-2x", on: { click: _vm.toggleDropDown } },
              [
                _c(
                  "md-icon",
                  {
                    staticClass: "fa md-size-2x",
                    staticStyle: { color: "#fff" },
                  },
                  [_vm._v("leaderboard")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "ul",
            { staticClass: "dropdown-menu", class: { show: _vm.isOpen } },
            [
              _c("li", { staticClass: "header-title" }, [_vm._v("Reports")]),
              _c(
                "li",
                { staticClass: "adjustments-line text-center" },
                [_vm._t("reports")],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }