var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item" },
      [
        _c("md-card", [
          _c(
            "div",
            { staticClass: "mobile-filter-container" },
            [
              _c("job-filter-modal", {
                attrs: { filterClicked: _vm.filterClicked, data: _vm.data },
                on: {
                  closeFilterModal: _vm.closeFilterModal,
                  selectedCategory: _vm.handleCategoryChange,
                  selectedEmploymentType: _vm.handleEmploymentTypeChange,
                  selectedLocations: _vm.handleLocationChange,
                },
              }),
              _c("h1", { staticClass: "data-table-heading" }, [
                _vm._v("Open Jobs"),
              ]),
              _c("i", { staticClass: "fa-solid fa-user-doctor" }),
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("assignment")])],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-row" },
                    [
                      _c(
                        "md-button",
                        {
                          staticStyle: {
                            "background-color": "#448aff !important",
                          },
                          on: { click: _vm.openFilter },
                        },
                        [_vm._v("Filter")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c(
                    "md-table-toolbar",
                    [
                      _c(
                        "md-field",
                        [
                          _c("md-input", {
                            staticClass: "mb-3",
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "search",
                              clearable: "",
                              placeholder: "Search records",
                            },
                            model: {
                              value: _vm.searchQuery,
                              callback: function ($$v) {
                                _vm.searchQuery = $$v
                              },
                              expression: "searchQuery",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.queriedData, function (job, index) {
                    return _c(
                      "md-card",
                      { key: index },
                      [
                        _c("md-card-header", [
                          _c("div", { staticClass: "md-title" }, [
                            _vm._v(_vm._s(job.name)),
                          ]),
                        ]),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "md-icon",
                                {
                                  staticClass: "md-size-1x",
                                  staticStyle: { color: "darkorange" },
                                },
                                [_vm._v("business")]
                              ),
                              _vm._v(" " + _vm._s(job.companyName) + " "),
                            ],
                            1
                          ),
                        ]),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "md-icon",
                                {
                                  staticClass: "md-size-1x",
                                  staticStyle: { color: "green" },
                                },
                                [_vm._v("monetization_on")]
                              ),
                              _vm._v(" " + _vm._s(_vm.formatBudget(job)) + " "),
                            ],
                            1
                          ),
                        ]),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "md-icon",
                                {
                                  staticClass: "md-size-1x",
                                  staticStyle: { color: "red" },
                                },
                                [_vm._v("place")]
                              ),
                              _vm._v(" " + _vm._s(job.location) + " "),
                            ],
                            1
                          ),
                        ]),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "md-icon",
                                {
                                  staticClass: "md-size-1x",
                                  staticStyle: { color: "orange" },
                                },
                                [_vm._v("work")]
                              ),
                              _vm._v(" " + _vm._s(job.category) + " "),
                            ],
                            1
                          ),
                        ]),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c(
                                "md-icon",
                                {
                                  staticClass: "md-size-1x",
                                  staticStyle: { color: "navyblue" },
                                },
                                [_vm._v("school")]
                              ),
                              _vm._v(" " + _vm._s(job.type) + " "),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "md-card-actions",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "micro-application",
                                    params: { id: job.id },
                                  },
                                },
                              },
                              [
                                _c(
                                  "md-button",
                                  { staticClass: "jb-standard" },
                                  [_vm._v("View")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }