<template>
	<div>
		<h4 class="title center">Additional Submissions</h4>
		<select-field
			:label="'Do you want a written submission included in the application?'"
			:options="['Yes', 'No']"
			:name="'writtenSubmissions'"
			:type="'String'"
			:iconName="'description'"
			v-model="writtenSubmission"
			:required="true"
			@input="addWrittenSubmission"
		>
		</select-field>
		<br />
		<br />
		<label :class="error ? 'md-error' : 'md-reset'" v-if="writtenSubmission === 'Yes'"> <h5>Please enter your written submission questions:</h5></label>

		<vue-editor v-if="writtenSubmission === 'Yes'" v-model="writtenSubmissionDescription" @text-change="addwrittenSubmissionDescription" />
		<br />
		<br />
		<select-field
			:label="'Do you want a video submission included in the application?'"
			:options="['Yes', 'No']"
			:name="'videoSubmissions'"
			:type="'String'"
			:iconName="'description'"
			v-model="videoSubmission"
			:required="true"
			@input="addVideoSubmission"
		>
		</select-field>
		<br />
		<br />
		<div>
			<div>
				<label :class="error ? 'md-error' : 'md-reset'" v-if="videoSubmission === 'Yes'"> <h5>Please enter your video submission questions:</h5></label>
				<vue-editor v-if="videoSubmission === 'Yes'" v-model="videoSubmissionDescription" @text-change="addVideoQuestion" />
			</div>
		</div>
	</div>
</template>

<script>
import SelectField from '@/components/Inputs/SelectField.vue';
import { VueEditor } from 'vue2-editor';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';

export default {
	components: {
		SelectField,
		VueEditor,
	},
	data() {
		return {
			error: false,
			writtenSubmission: null,
			writtenSubmissionDescription: null,
			videoSubmission: null,
			videoSubmissionDescription: null,
			jobsDoc: null,
			vidoeSubmissionSelection: null,
		};
	},
	methods: {
		validate() {
			const hasNoWrittenSubmission =
				this.writtenSubmission === 'Yes' && (this.writtenSubmissionDescription === null || this.writtenSubmissionDescription.replace(/<[^>]+>/g, '').trim() === '');
			const hasNoVideoSubmission =
				this.videoSubmission === 'Yes' && (this.videoSubmissionDescription === null || this.videoSubmissionDescription.replace(/<[^>]+>/g, '').trim() === '');

			if (hasNoWrittenSubmission || hasNoVideoSubmission) {
				this.error = true;
				return false;
			} else {
				return true;
			}
		},
		addWrittenSubmission: function () {
			this.$emit('writtenSubmission', this.writtenSubmission);
		},
		addwrittenSubmissionDescription: function () {
			this.$emit('writtenSubmissionDescription', this.writtenSubmissionDescription.replace(/<[^>]+>/g, ''));
		},
		addVideoSubmission: function () {
			this.$emit('videoSubmission', this.videoSubmission);
		},
		addVideoQuestion() {
			this.$emit('videoQuestion', this.videoSubmissionDescription.replace(/<[^>]+>/g, ''));
		},
	},
	async created() {
		this.user = firebase.auth().currentUser;
		let jobsRef = db.collection('jobs');
		let microsRef = db.collection('micros').doc(this.$route.params.id);

		let snapshot = await jobsRef.where('clientId', '==', this.user.uid).get();

		snapshot.forEach(async doc => {
			if (doc.exists) {
				let microsDoc = await microsRef.get();
				if (microsDoc.exists) {
					let data = microsDoc.data()?.additionalSubmissions;
					this.writtenSubmission = data?.writtenSubmission;
					this.writtenSubmissionDescription = data?.writtenSubmissionDescription;
					this.videoSubmission = data?.videoSubmission ? data.videoSubmission : null;
					this.videoSubmissionDescription = data?.videoSubmissionDescription;
				}
			}
		});
	},
};
</script>

<style scoped>
.center {
	text-align: center;
}
.md-error {
	color: red;
}
.md-reset {
	color: black;
}
</style>
