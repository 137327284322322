var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("md-card", [
        _c(
          "div",
          { staticClass: "container talentPool" },
          [
            _c(
              "div",
              { staticClass: "talentPoolActions" },
              [
                _c(
                  "md-field",
                  { staticStyle: { "max-width": "500px" } },
                  [
                    _c("md-input", {
                      staticClass: "mb-3",
                      attrs: {
                        type: "search",
                        clearable: "",
                        placeholder: "Search records",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-success btn-talent-pool",
                    staticStyle: { width: "150px" },
                    on: { click: _vm.toggleFilterMenu },
                  },
                  [_vm._v(" Filter")]
                ),
              ],
              1
            ),
            _c(
              "md-table",
              {
                attrs: { "md-card": "" },
                scopedSlots: _vm._u([
                  {
                    key: "md-table-row",
                    fn: function (ref) {
                      var item = ref.item
                      return _c(
                        "md-table-row",
                        { key: item.userId },
                        [
                          _c(
                            "md-table-cell",
                            {
                              attrs: {
                                "md-label": "Name",
                                "md-sort-by": "name",
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c(
                            "md-table-cell",
                            {
                              attrs: {
                                "md-label": "Surname",
                                "md-sort-by": "surname",
                              },
                            },
                            [_vm._v(_vm._s(item.surname))]
                          ),
                          _c(
                            "md-table-cell",
                            {
                              attrs: {
                                "md-label": "Email",
                                "md-sort-by": "email",
                              },
                            },
                            [_vm._v(_vm._s(item.email))]
                          ),
                          _c(
                            "md-table-cell",
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-success btn-talent-pool",
                                  staticStyle: { width: "100px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewStudentinTalentPool(item)
                                    },
                                  },
                                },
                                [_vm._v(" View Student")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
                model: {
                  value: _vm.reactiveList,
                  callback: function ($$v) {
                    _vm.reactiveList = $$v
                  },
                  expression: "reactiveList",
                },
              },
              [
                _c("md-table-toolbar", [
                  _c("h1", { staticClass: "md-title" }, [
                    _vm._v(
                      "Students in " +
                        _vm._s(_vm.currentTalentPool.name) +
                        " Talent Pool"
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.profileModalVisible
        ? _c(
            "modal",
            { on: { close: _vm.profileModalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.profileModalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("CandidateProfile", {
                    attrs: {
                      userProfile: _vm.currentProfile,
                      showFavoriteButton: true,
                      isTalentPool: _vm.isTalentPool,
                      currentTalentPool: _vm.currentTalentPool,
                    },
                    on: { setProfileFavourite: _vm.setProfileFavourite },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.isFilterClicked
        ? _c("StudentFilter", {
            attrs: {
              isFilterClicked: _vm.isFilterClicked,
              uniqueInstitutions: _vm.uniqueInstitutions,
              uniqueQualificationTypes: _vm.uniqueQualificationTypes,
              uniqueYearOfStudy: _vm.uniqueYearOfStudy,
              uniqueDegrees: _vm.uniqueDegrees,
              uniqueInterestedDepartments: _vm.uniqueInterestedDepartments,
              uniquePreferredEmployers: _vm.uniquePreferredEmployers,
              uniqueProgrammes: _vm.uniqueProgrammes,
              uniqueRaces: _vm.uniqueRaces,
              uniqueGenders: _vm.uniqueGenders,
              uniqueDriversLicense: _vm.uniqueDriversLicense,
              uniqueVehicles: _vm.uniqueVehicles,
              uniqueDisability: _vm.uniqueDisability,
              uniqueCitizenships: _vm.uniqueCitizenships,
              uniqueLocations: _vm.uniqueLocations,
              students: _vm.currentTalentPoolStudentTalentPool || [],
            },
            on: {
              "update:isClosed": _vm.handleFilterClose,
              filterChanged: _vm.handleFilterChange,
              clearFilters: _vm.clearFilters,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }