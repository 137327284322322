<template>
	<div>
		<md-table md-card v-if="selectedApplicants.length > 0">
			<md-table-toolbar>
				<div class="w-full flex items-center justify-between">
					<h1 class="md-title">Selected Applicants</h1>
					<md-button class="remove-btn" @click="unselectBulk"> <md-icon>delete</md-icon> Remove Candidates </md-button>
				</div>
			</md-table-toolbar>

			<md-table-row>
				<md-table-head>
					<md-checkbox v-model="selectAll" @change="toggleSelectAll"></md-checkbox>
				</md-table-head>
				<md-table-head>Name</md-table-head>
				<md-table-head>Surname</md-table-head>
				<md-table-head>Year of Study</md-table-head>
				<md-table-head>Degree</md-table-head>
				<md-table-head>Status</md-table-head>
				<md-table-head></md-table-head>
				<md-table-head></md-table-head>
			</md-table-row>

			<md-table-row v-for="(applicant, index) in selectedApplicants" :key="index">
				<md-table-cell>
					<md-checkbox v-model="selectedToRemove" :value="applicant"></md-checkbox>
				</md-table-cell>
				<md-table-cell>{{ applicant.name }}</md-table-cell>
				<md-table-cell>{{ applicant.surname }}</md-table-cell>
				<md-table-cell>{{ applicant.selectedDegree.yearOfStudy }}</md-table-cell>
				<md-table-cell>{{ applicant.selectedDegree.degree }}</md-table-cell>
				<md-table-cell>{{ applicant.status }}</md-table-cell>
				<md-table-cell>
					<md-button class="view-profile-btn" @click="openUserProfile(applicant)"> <md-icon>person</md-icon> View Profile </md-button>
				</md-table-cell>
				<md-table-cell>
					<md-button class="unselect-btn" @click="unselectApplicant(applicant)"> <md-icon>close</md-icon> Unselect </md-button>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<div v-else class="no-applicants-message">
			<h3>No selected applicants found.</h3>
			<p>Please select candidates from the shortlist.</p>
		</div>

		<modal v-if="profileModalVisible" @close="profileModalVisible = false">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalVisible = false">
					<md-icon style="font-size: 2rem !important; color: black !important">clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<CandidateProfile :userProfile="selectedApplicant" :showFavoriteButton="true" :jobId="this.$route.params.id" />
			</template>
		</modal>

		<modal v-if="error">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>{{ errorMessage }}</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { doc, getDoc, updateDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import db from '@/firebase/init';
import { BursaryStatuses } from '../../../../../../constants/bursary-statuses.const';
import CandidateProfile from '@/components/Profile/CandidateProfile.vue';
import { Modal } from '@/components';

export default {
	components: { CandidateProfile, Modal },
	data() {
		return {
			selectedApplicants: [],
			selectedToRemove: [],
			selectAll: false,
			unsubscribe: null,
			profileModalVisible: false,
			selectedApplicant: null,
			error: false,
			errorMessage: '',
		};
	},
	created() {
		this.subscribeToSelectedApplicants();
	},
	beforeUnmount() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	},
	methods: {
		subscribeToSelectedApplicants() {
			const bursaryId = this.$route.params.bursaryId;
			const selectedQuery = query(collection(db, 'bursary-applications'), where('bursaryID', '==', bursaryId), where('status', '==', BursaryStatuses.SELECTED));

			this.unsubscribe = onSnapshot(selectedQuery, snapshot => {
				this.selectedApplicants = snapshot.docs.map(doc => ({
					...doc.data(),
					id: doc.id,
					alias: doc.data().studentAlias,
				}));
			});
		},

		async openUserProfile(applicant) {
			try {
				const docRef = doc(db, 'bursary-applications', applicant.id);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					this.selectedApplicant = { id: applicant.id, ...docSnap.data(), alias: docSnap.data().studentAlias };
				} else {
					this.selectedApplicant = { ...applicant };
				}

				this.profileModalVisible = true;
			} catch (error) {
				this.displayError('Error fetching applicant details.');
			}
		},

		async unselectApplicant(applicant) {
			if (!applicant.id) {
				this.displayError('Missing document ID for the applicant.');
				return;
			}

			const docRef = doc(db, 'bursary-applications', applicant.id);

			try {
				await updateDoc(docRef, { status: BursaryStatuses.UNSELECTED });
			} catch (error) {
				this.displayError('An error occurred while updating the applicant status.');
			}
		},

		async unselectBulk() {
			if (this.selectedToRemove.length === 0) {
				this.displayError('Please select at least one applicant to unselect.');
				return;
			}

			try {
				const updatePromises = this.selectedToRemove.map(applicant => {
					if (!applicant.id) {
						this.displayError('One or more applicants are missing document IDs.');
						return Promise.resolve();
					}
					const docRef = doc(db, 'bursary-applications', applicant.id);
					return updateDoc(docRef, { status: BursaryStatuses.UNSELECTED });
				});

				await Promise.all(updatePromises);
				this.selectedToRemove = [];
				this.selectAll = false;
			} catch (error) {
				this.displayError('An error occurred while bulk unselecting applicants.');
			}
		},

		toggleSelectAll() {
			if (this.selectAll) {
				this.selectedToRemove = [...this.selectedApplicants];
			} else {
				this.selectedToRemove = [];
			}
		},

		displayError(message) {
			this.errorMessage = message;
			this.error = true;
		},

		closeErrorModal() {
			this.error = false;
			this.errorMessage = '';
		},
	},
};
</script>

<style scoped>
.md-title {
	font-size: 1.5rem !important;
}
.md-table {
	overflow: auto;
}
.md-warn {
	background-color: #e74c3c !important;
	color: white !important;
}
.md-warn:hover {
	background-color: #c0392b !important;
}
.md-checkbox {
	margin: 0 !important;
}
.no-applicants-message {
	text-align: center;
	padding: 2rem;
	color: #666;
}
.no-applicants-message h3 {
	font-size: 1.5rem;
	margin-bottom: 0.5rem;
}
.unselect-btn {
	width: fit-content !important;
}
.view-profile-btn {
	background-color: #4caf50 !important;
	color: white !important;
	width: fit-content !important;
}
.view-profile-btn:hover {
	background-color: #388e3c !important;
}

.md-table-cell-container .md-button {
	padding-bottom: 30px !important;
}

.remove-btn {
	background-color: #f39c12 !important;
	color: white !important;
}
.remove-btn:hover {
	background-color: #e67e22 !important;
}

.unselect-btn {
	background-color: #e23927 !important;

	border: 1px solid #e74c3c !important;
}
.unselect-btn:hover {
	background-color: #e74c3c !important;
	color: white !important;
}

.view-profile-btn {
	background-color: #4caf50 !important;
	color: white !important;
}
.view-profile-btn:hover {
	background-color: #388e3c !important;
}
</style>
