var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "title center" }, [
        _vm._v("Additional Submissions"),
      ]),
      _c("select-field", {
        attrs: {
          label:
            "Do you want a written submission included in the application?",
          options: ["Yes", "No"],
          name: "writtenSubmissions",
          type: "String",
          iconName: "description",
          required: true,
        },
        on: { input: _vm.addWrittenSubmission },
        model: {
          value: _vm.writtenSubmission,
          callback: function ($$v) {
            _vm.writtenSubmission = $$v
          },
          expression: "writtenSubmission",
        },
      }),
      _c("br"),
      _c("br"),
      _vm.writtenSubmission === "Yes"
        ? _c("label", { class: _vm.error ? "md-error" : "md-reset" }, [
            _c("h5", [
              _vm._v("Please enter your written submission questions:"),
            ]),
          ])
        : _vm._e(),
      _vm.writtenSubmission === "Yes"
        ? _c("vue-editor", {
            on: { "text-change": _vm.addwrittenSubmissionDescription },
            model: {
              value: _vm.writtenSubmissionDescription,
              callback: function ($$v) {
                _vm.writtenSubmissionDescription = $$v
              },
              expression: "writtenSubmissionDescription",
            },
          })
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c("select-field", {
        attrs: {
          label: "Do you want a video submission included in the application?",
          options: ["Yes", "No"],
          name: "videoSubmissions",
          type: "String",
          iconName: "description",
          required: true,
        },
        on: { input: _vm.addVideoSubmission },
        model: {
          value: _vm.videoSubmission,
          callback: function ($$v) {
            _vm.videoSubmission = $$v
          },
          expression: "videoSubmission",
        },
      }),
      _c("br"),
      _c("br"),
      _c("div", [
        _c(
          "div",
          [
            _vm.videoSubmission === "Yes"
              ? _c("label", { class: _vm.error ? "md-error" : "md-reset" }, [
                  _c("h5", [
                    _vm._v("Please enter your video submission questions:"),
                  ]),
                ])
              : _vm._e(),
            _vm.videoSubmission === "Yes"
              ? _c("vue-editor", {
                  on: { "text-change": _vm.addVideoQuestion },
                  model: {
                    value: _vm.videoSubmissionDescription,
                    callback: function ($$v) {
                      _vm.videoSubmissionDescription = $$v
                    },
                    expression: "videoSubmissionDescription",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }