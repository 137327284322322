var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
        [
          _c(
            "simple-wizard",
            {
              attrs: {
                file: _vm.file,
                image: _vm.image,
                firstName: _vm.firstName,
                lastName: _vm.lastName,
                companyName: _vm.companyName,
                phoneNumber: _vm.phoneNumber,
                companyWebsite: _vm.companyWebsite,
                vat: _vm.vat,
                companySize: _vm.companySize,
                companyCategory: _vm.companyCategory,
                industry: _vm.industry,
                aboutMe: _vm.aboutMe,
                addressLine1: _vm.addressLine1,
                city: _vm.city,
                province: _vm.province,
                postalCode: _vm.postalCode,
                email: _vm.email,
                country: _vm.country,
              },
              on: {
                emailVerified: _vm.addEmailVerified,
                feedback: _vm.addFeedback,
              },
            },
            [
              _c("template", { slot: "header" }, [
                _vm.emailVerified
                  ? _c(
                      "h3",
                      { staticClass: "title", staticStyle: { color: "#000" } },
                      [_vm._v("Build your profile")]
                    )
                  : _vm._e(),
                _vm.emailVerified
                  ? _c("h5", { staticClass: "category" }, [
                      _vm._v(
                        "This information will let us know more about you."
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: { color: "red" },
                            attrs: { slot: "inputs" },
                            slot: "inputs",
                          },
                          [
                            _vm._v(" Please check your inbox to verify that "),
                            _c("b", [_vm._v(_vm._s(_vm.email))]),
                            _vm._v(
                              " is the email address that you are currently using "
                            ),
                          ]
                        ),
                        _c(
                          "md-button",
                          {
                            staticClass: "jb-standard",
                            on: { click: _vm.verification },
                          },
                          [_vm._v(" Resend verification ")]
                        ),
                        _c("br"),
                        _c("br"),
                      ],
                      1
                    ),
              ]),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": function () {
                      return _vm.validateStep("step1")
                    },
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [_vm._v("About")]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-user" }),
                    ]),
                  ]),
                  _c("first-step", {
                    ref: "step1",
                    on: {
                      "on-validated": _vm.onStepValidated,
                      file: _vm.addFile,
                      image: _vm.addImage,
                      firstName: _vm.addFirstName,
                      lastName: _vm.addLastName,
                      phoneNumber: _vm.addPhoneNumber,
                      companyName: _vm.addCompanyName,
                      companyWebsite: _vm.addCompanyWebsite,
                      vat: _vm.addVat,
                      companySize: _vm.addCompanySize,
                      companyCategory: _vm.addCompanyCategory,
                      industry: _vm.addIndustry,
                      aboutMe: _vm.addAboutMe,
                    },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": function () {
                      return _vm.validateStep("step2")
                    },
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [_vm._v("Location")]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-location-arrow" }),
                    ]),
                  ]),
                  _c("second-step", {
                    ref: "step2",
                    on: {
                      "on-validated": _vm.onStepValidated,
                      addressLine1: _vm.addAddressLineOne,
                      city: _vm.addCity,
                      province: _vm.addProvince,
                      postalCode: _vm.addPostalCode,
                      country: _vm.addCountry,
                    },
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "black" }, [
                  _vm._v(_vm._s(_vm.feedback)),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-success",
                        on: { click: _vm.modalHide },
                      },
                      [_vm._v("Got it")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }