var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("nav"),
      _c("div", { staticClass: "copyright text-center" }, [
        _vm._v(
          " © " +
            _vm._s(new Date().getFullYear()) +
            " Jobox (Pty) Ltd All rights reserved "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }