var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c("div", { staticClass: "profile-banner" }, [
      _c("div", { staticClass: "profile-banner-content" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "margin-bottom": "2rem",
            },
          },
          [
            _vm.edit
              ? _c(
                  "md-button",
                  {
                    staticClass: "jb-standard",
                    on: { click: _vm.editProfile },
                  },
                  [_vm._v("Edit Profile")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "profile-avatar" }, [
          _vm.profile.profile
            ? _c("img", {
                staticClass: "img",
                attrs: { src: _vm.profile.profile },
              })
            : _vm._e(),
          !_vm.profile.profile
            ? _c("img", {
                staticClass: "img",
                attrs: { src: _vm.cardUserImage },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "profile-banner-row" }, [
          _c("div", [
            _c("span", { staticClass: "profile-full-name-header" }, [
              _c("h3", { staticClass: "profile-top" }, [
                _vm._v("Hi there 👋, welcome"),
              ]),
              _c(
                "h1",
                {
                  staticClass: "profile-full-name",
                  staticStyle: { "margin-top": "-4px" },
                },
                [_vm._v(_vm._s(_vm.user.name) + " " + _vm._s(_vm.user.surname))]
              ),
            ]),
            _c("p", { staticClass: "profile-bio" }, [
              _vm._v(_vm._s(_vm.profile.bio)),
            ]),
            _vm.hasSocialMedia
              ? _c("div", [
                  _c("div", { staticClass: "profile-social-media-icons" }, [
                    _vm.profile.linkedIn
                      ? _c("i", {
                          staticClass: "fab fa-linkedin",
                          staticStyle: { color: "#0e76a8", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.profile.linkedIn)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.profile.github
                      ? _c("i", {
                          staticClass: "fab fa-github",
                          staticStyle: { color: "#000", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.profile.github)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.profile.facebook
                      ? _c("i", {
                          staticClass: "fab fa-facebook",
                          staticStyle: { color: "#3b5998", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.profile.facebook)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.profile.twitter
                      ? _c("i", {
                          staticClass: "fab fa-twitter",
                          staticStyle: { color: "#00acee", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.profile.twitter)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.profile.instagram
                      ? _c("i", {
                          staticClass: "fab fa-instagram",
                          staticStyle: { color: "#d6249f", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.profile.instagram)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.profile.personalWebsite
                      ? _c("i", {
                          staticClass: "fa fa-globe",
                          staticStyle: { color: "black", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.profile.personalWebsite)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm.profile &&
        _vm.profile.qualifications &&
        _vm.profile.qualifications.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "profile-heading" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("md-icon", { staticClass: "profile" }, [
                        _vm._v(" school "),
                      ]),
                      _c("h4", { staticStyle: { "margin-left": "1rem" } }, [
                        _vm._v("Education"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._l(_vm.profile.qualifications, function (degree) {
                  return _c(
                    "div",
                    {
                      key: degree.qualificationNum,
                      staticClass: "profile-education-card",
                    },
                    [
                      _c("div", { staticClass: "profile-row" }, [
                        _c("div", [
                          _c("h4", [_vm._v(_vm._s(degree.degree))]),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "font-style": "italic",
                                color: "grey !important",
                                "margin-top": "-16px",
                              },
                            },
                            [_vm._v(_vm._s(degree.institution))]
                          ),
                        ]),
                        _c("h4", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v(
                            _vm._s(degree.startDate) +
                              " - " +
                              _vm._s(
                                degree.endDate === "Invalid date"
                                  ? "Present"
                                  : degree.endDate
                              )
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.experience && _vm.experience.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "profile-heading" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("md-icon", { staticClass: "profile" }, [
                        _vm._v(" work "),
                      ]),
                      _c("h4", { staticStyle: { "margin-left": "1rem" } }, [
                        _vm._v("Experience"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._l(_vm.experience, function (experience, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "profile-education-card" },
                    [
                      _c("div", { staticClass: "profile-row" }, [
                        _c("div", [
                          _c("h4", [_vm._v(_vm._s(experience.jobTitle))]),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "10px",
                                "font-style": "italic",
                                color: "lightgrey !important",
                                "margin-top": "-16px",
                              },
                            },
                            [_vm._v(_vm._s(experience.institution))]
                          ),
                        ]),
                        _c("h4", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v(
                            _vm._s(experience.startDate) +
                              " - " +
                              _vm._s(
                                experience.endDate === "Invalid date"
                                  ? "Present"
                                  : experience.endDate
                              )
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.profile &&
        _vm.profile.extraCirricularActivities &&
        _vm.profile.extraCirricularActivities.length
          ? _c("div", [
              _c("div", { staticClass: "profile-heading" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("md-icon", { staticClass: "profile" }, [
                      _vm._v(" sports "),
                    ]),
                    _c("h4", { staticStyle: { "margin-left": "1rem" } }, [
                      _vm._v("Extracurricular Activities"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "profile-row" },
                _vm._l(
                  _vm.profile.extraCirricularActivities,
                  function (activity, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "extra-cirricular-card" },
                      [_c("ul", [_c("li", [_vm._v(_vm._s(activity))])])]
                    )
                  }
                ),
                0
              ),
            ])
          : _vm._e(),
        _vm.profile &&
        _vm.profile.preferredCompanies &&
        _vm.profile.preferredCompanies.choices &&
        _vm.profile.preferredCompanies.choices.length
          ? _c("div", [
              _c("div", { staticClass: "profile-heading" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("md-icon", { staticClass: "profile" }, [
                      _vm._v(" business "),
                    ]),
                    _c("h4", { staticStyle: { "margin-left": "1rem" } }, [
                      _vm._v("Preferred Companies"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "profile-row" },
                _vm._l(
                  _vm.profile.preferredCompanies.choices,
                  function (activity, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "extra-cirricular-card" },
                      [_c("ul", [_c("li", [_vm._v(_vm._s(activity))])])]
                    )
                  }
                ),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }