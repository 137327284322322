<template>
	<div class="error_container mt-4 md-small-size-100">
		<md-field :class="fieldClass" style="padding-bottom: 10px">
			<md-icon v-if="iconName">{{ iconName }}</md-icon>
			<label class="label">{{ label }}</label>
			<md-select
				class="pad"
				:value="value"
				:required="required"
				:name="name"
				:type="type"
				:multiple="multiple"
				@input="$emit('input', $event)"
				@md-selected="$emit('change', $event)"
				@md-opened="$emit('opened', $event)"
			>
				<md-option v-if="placeholder" :value="''">{{ placeholder }}</md-option>
				<md-option v-for="(option, index) in options" :key="index" :value="option">
					{{ option }}
				</md-option>
			</md-select>
		</md-field>
		<!-- <p v-if="error" class="error_message">{{ label }} {{ error }}</p> -->
	</div>
</template>

<script>
export default {
	name: 'SelectField',
	props: {
		label: String,
		options: [Array, String],
		name: String,
		type: String,
		iconName: String,
		error: [String, Boolean],
		required: Boolean,
		multiple: Boolean,
		value: [String, Number, Array],
		placeholder: {
			type: String,
			default: '',
		},
	},
	computed: {
		fieldClass() {
			return {
				'md-error': !!this.error,
			};
		},
	},
};
</script>
<style scoped>
.error_message {
	color: red;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
}

@media (max-width: 400px) {
	.label {
		font-size: 10px;
	}
}
</style>
