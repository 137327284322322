var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bursary && _vm.bursary.additionalSubmissions
    ? _c(
        "div",
        { staticClass: "video-submission-content-container" },
        [
          _vm.loading
            ? _c("div", { staticClass: "background" }, [
                _vm.loading
                  ? _c("div", { staticClass: "logo-position lds-circle" }, [
                      _vm._m(0),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "content-container" }, [
            _c("div", { staticClass: "instructions-container" }, [
              _c("h3", {}, [_vm._v("Video Submission Instructions")]),
              _vm.bursary.additionalSubmissions.videoSubmission === "No" ||
              _vm.bursary.additionalSubmissions.videoSubmission === ""
                ? _c("p", [
                    _vm._v(" There are no video submissions required. "),
                  ])
                : _vm._e(),
              _vm.bursary.additionalSubmissions.videoSubmission === "Yes"
                ? _c("p", [
                    _vm._v(
                      "Instructions: Please record and upload a video or provide a link to a pre-recorded video."
                    ),
                  ])
                : _vm._e(),
              _vm.bursary.additionalSubmissions.videoSubmission === "Yes"
                ? _c("h3", { staticClass: "question-title" }, [
                    _vm._v("Question"),
                  ])
                : _vm._e(),
              _vm.bursary.additionalSubmissions.videoSubmission === "Yes"
                ? _c("h4", {
                    staticClass: "question-text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.bursary.additionalSubmissions.videoSubmissionDetails
                      ),
                    },
                  })
                : _vm._e(),
            ]),
            _vm.bursary.additionalSubmissions.videoSubmission === "Yes"
              ? _c(
                  "div",
                  { staticClass: "upload-container" },
                  [
                    _c(
                      "button",
                      { ref: "loomButton", staticClass: "loom-record-button" },
                      [_vm._v("Record with Loom")]
                    ),
                    _c("p", { staticClass: "or-text" }, [_vm._v("Or")]),
                    _c(
                      "md-field",
                      {
                        class: { "md-valid": _vm.videoLink.trim().length > 0 },
                      },
                      [
                        _c("label", [_vm._v("Provide Link")]),
                        _c("md-input", {
                          model: {
                            value: _vm.videoLink,
                            callback: function ($$v) {
                              _vm.videoLink = $$v
                            },
                            expression: "videoLink",
                          },
                        }),
                        _c("md-icon", [_vm._v("add_link")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.showSuccessModal
            ? _c(
                "modal",
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticStyle: { "font-size": "2rem" } }, [
                      _vm._v("Success! 🎊"),
                    ]),
                  ]),
                  _c("template", { slot: "body" }, [
                    _c("p", [_vm._v("Video Submitted Successfully! ✅")]),
                  ]),
                  _c(
                    "template",
                    { slot: "footer" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-button md-success",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(" Okay")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.showErrorModal
            ? _c("modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("h4", { staticClass: "modal-title black" }, [
                            _vm._v("Whoa there! 🤚"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-success",
                              on: { click: _vm.closeErrorModal },
                            },
                            [_vm._v(" Ok ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3042895156
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }