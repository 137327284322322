var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { class: !this.filterSelected ? "alertMessage" : "" }, [
          _vm._v("Please select at least one filter type"),
        ]),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c("select-field", {
            attrs: {
              options: _vm.genderType,
              "name:": "genderTypeList",
              iconName: "account_circle",
              label: "Gender",
            },
            on: { input: _vm.filterGender },
            model: {
              value: _vm.gender,
              callback: function ($$v) {
                _vm.gender = $$v
              },
              expression: "gender",
            },
          }),
          _vm._v(" "),
          _c("select-field", {
            attrs: {
              options: _vm.favouriteTypeList,
              "name:": "favouriteTypelist",
              iconName: "star",
              label: "Favourite Applicant",
            },
            on: { input: _vm.filterFavourites },
            model: {
              value: _vm.favourite,
              callback: function ($$v) {
                _vm.favourite = $$v
              },
              expression: "favourite",
            },
          }),
          _vm._v(" "),
          _c("select-field", {
            attrs: {
              options: _vm.qualificationType,
              "name:": "qualificationType",
              iconName: "workspace_premium",
              label: "Qualification Type",
            },
            on: { input: _vm.filterQualification },
            model: {
              value: _vm.qualification,
              callback: function ($$v) {
                _vm.qualification = $$v
              },
              expression: "qualification",
            },
          }),
          _c("select-field", {
            attrs: {
              options: _vm.ageRangeType,
              "name:": "ageRangeType",
              iconName: "directions_run",
              label: "Age Range",
            },
            on: { input: _vm.filterAge },
            model: {
              value: _vm.ageRange,
              callback: function ($$v) {
                _vm.ageRange = $$v
              },
              expression: "ageRange",
            },
          }),
          _vm._v(" "),
          _c("select-field", {
            attrs: {
              options: _vm.ethinicityType,
              "name:": "ethnicityTypeList",
              iconName: "diversity_3",
              label: "Ethnicity",
              multiple: true,
            },
            on: { input: _vm.filterEthnicity },
            model: {
              value: _vm.ethnicity,
              callback: function ($$v) {
                _vm.ethnicity = $$v
              },
              expression: "ethnicity",
            },
          }),
          _c("select-field", {
            attrs: {
              options: this.institution,
              "name:": "institution",
              iconName: "school",
              label: "University",
              multiple: true,
            },
            on: { input: _vm.filterUniversities },
            model: {
              value: _vm.university,
              callback: function ($$v) {
                _vm.university = $$v
              },
              expression: "university",
            },
          }),
          _c("select-field", {
            attrs: {
              options: this.status,
              "name:": "applicationStatus",
              iconName: "fact_check",
              label: "Application Status",
              multiple: true,
            },
            on: { input: _vm.filterApplicationStatus },
            model: {
              value: _vm.applicationStatus,
              callback: function ($$v) {
                _vm.applicationStatus = $$v
              },
              expression: "applicationStatus",
            },
          }),
          _vm._v(" "),
          _c("select-field", {
            attrs: {
              options: this.activities,
              "name:": "ethnicityTypeList",
              iconName: "groups",
              label: "Extracurricular Activities",
              multiple: true,
            },
            on: { input: _vm.filterExtracirricularActivities },
            model: {
              value: _vm.extracirricularActivities,
              callback: function ($$v) {
                _vm.extracirricularActivities = $$v
              },
              expression: "extracirricularActivities",
            },
          }),
          _c("select-field", {
            attrs: {
              options: _vm.townsList,
              name: "locationTypeList",
              iconName: "place",
              label: "Location",
            },
            on: { input: _vm.filterLocation },
            model: {
              value: _vm.town,
              callback: function ($$v) {
                _vm.town = $$v
              },
              expression: "town",
            },
          }),
          _c("select-field", {
            attrs: {
              options: _vm.studentRelocationType,
              name: "studentRelocationTypeList",
              iconName: "commute",
              label: "Willing to relocate?",
            },
            on: { input: _vm.filterStudentRelocation },
            model: {
              value: _vm.studentRelocation,
              callback: function ($$v) {
                _vm.studentRelocation = $$v
              },
              expression: "studentRelocation",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "md-button",
              {
                staticClass: "md-success",
                on: {
                  click: function ($event) {
                    return _vm.resetFilters()
                  },
                },
              },
              [_vm._v("Clear Filters")]
            ),
            _vm._v("         "),
            _c(
              "md-button",
              {
                staticClass: "md-danger",
                on: {
                  click: function ($event) {
                    return _vm.closeModal()
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }