var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-content",
            [
              _c(
                "collapse",
                {
                  attrs: {
                    collapse: [
                      "Profile",
                      "Email Notifications",
                      "Account Settings",
                    ],
                    icon: "keyboard_arrow_down",
                    "color-collapse": "success",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "md-collapse-pane-1" },
                    [
                      _c("md-card-content", [
                        _c(
                          "h3",
                          {
                            staticClass: "card-category card-category-social",
                            staticStyle: { "text-align": "center" },
                          },
                          [
                            _c("i", { staticClass: "fas fa-user" }),
                            _vm._v(" Profile"),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "md-collapse-pane-2" },
                    [
                      _c("md-card-content", [
                        _c(
                          "h3",
                          {
                            staticClass: "card-category card-category-social",
                            staticStyle: { "text-align": "center" },
                          },
                          [
                            _c("i", { staticClass: "fas fa-envelope" }),
                            _vm._v(" Email Notifications"),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "md-collapse-pane-3" },
                    [
                      _c(
                        "md-card-content",
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "card-category card-category-social",
                              staticStyle: { "text-align": "center" },
                            },
                            [
                              _c("i", { staticClass: "fas fa-cog" }),
                              _vm._v(" Account Settings"),
                            ]
                          ),
                          _c(
                            "md-button",
                            { staticClass: "md-button md-success" },
                            [
                              _vm._v("I would like to change my password "),
                              _c("i", { staticClass: "fas fa-chevron-right" }),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "md-button",
                            { staticClass: "md-button md-success" },
                            [
                              _vm._v("I would like to change my email "),
                              _c("i", { staticClass: "fas fa-chevron-right" }),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-button md-success",
                              on: { click: _vm.deleteModal },
                            },
                            [
                              _vm._v("I would like to delete my account "),
                              _c("i", { staticClass: "fas fa-chevron-right" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(
            "modal",
            { on: { close: _vm.modalHide } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("h4", { staticClass: "modal-title black" }, [
                    _vm._v("Delete Account"),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: { click: _vm.modalHide },
                    },
                    [_c("md-icon", [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", [
                  _vm._v("Are you sure you want to delete your account?"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "centre" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-danger",
                        on: { click: _vm.cancel },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v("    "),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-button md-warning",
                        on: { click: _vm.deleteAccount },
                      },
                      [_vm._v("Delete Account")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }