var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.jobType === "Once-off Project/Task"
      ? _c("h5", { staticClass: "info-text" }, [
          _vm._v(" Let us know what your budget is. Minimum amount is "),
          _vm._m(0),
        ])
      : _vm.jobType === "Full-time"
      ? _c("h5", { staticClass: "info-text" }, [
          _vm._v(
            " Let us know what the employee salary is. Minimum amount is "
          ),
          _vm._m(1),
          _vm._v(" per month. "),
        ])
      : _c("h5", { staticClass: "info-text" }, [
          _vm._v(
            " Let us know what the employee salary is. Minimum amount is "
          ),
          _vm._m(2),
          _vm._v(" per month. "),
        ]),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item mt-4 md-size-100" },
        [
          _vm.jobType === "Once-off Project/Task"
            ? _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("budget") &&
                        _vm.touched.budget &&
                        typeof _vm.budget !== "object",
                    },
                    { "md-form-group": true },
                    {
                      "md-error":
                        _vm.errors.has("budget") && _vm.touched.budget,
                    },
                  ],
                },
                [
                  _c("md-icon", [_c("i", { staticClass: "fas fa-wallet" })]),
                  _vm.jobType === "Once-off Project/Task"
                    ? _c("label", [_vm._v("Budget")])
                    : _c("label", [_vm._v("Salary")]),
                  _vm.jobType === "Once-off Project/Task"
                    ? _c("md-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.budget,
                            expression: "modelValidations.budget",
                          },
                        ],
                        attrs: {
                          id: "once-off-budget",
                          "data-vv-name": "budget",
                          type: "number",
                          min: "10",
                          step: ".01",
                          name: "budget",
                          required: "",
                        },
                        on: {
                          change: _vm.addBudget,
                          blur: function ($event) {
                            return _vm.setTouched("budget")
                          },
                        },
                        model: {
                          value: _vm.budget,
                          callback: function ($$v) {
                            _vm.budget = $$v
                          },
                          expression: "budget",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.errors.has("budget") && _vm.touched.budget,
                              expression:
                                "errors.has('budget') && touched.budget",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("budget") &&
                                _vm.touched.budget &&
                                _vm.budget.length > 0,
                              expression:
                                "!errors.has('budget') && touched.budget && budget.length > 0",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.jobType === "Once-off Project/Task" &&
          (_vm.budget < 400 || !_vm.budget || _vm.budget === 0)
            ? _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "1rem",
                    "text-align": "left",
                    "font-size": "10px",
                    color: "red",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.budgetError) + " ")]
              )
            : _vm._e(),
          _vm.jobType !== "Once-off Project/Task"
            ? _c(
                "div",
                { staticClass: "ranges" },
                [
                  _c(
                    "md-field",
                    {
                      class: {
                        "md-valid": _vm.isSalaryMinValid,
                        "md-error":
                          !_vm.isSalaryMinValid && _vm.touched.salaryMin,
                      },
                    },
                    [
                      _c("label", [_vm._v("Minimum (ZAR) ")]),
                      _c("md-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.minSalary,
                            expression: "modelValidations.minSalary",
                          },
                        ],
                        staticStyle: {
                          outline: "none",
                          border: "none",
                          width: "auto",
                          "text-align": "left",
                        },
                        attrs: {
                          "data-vv-name": "salaryMin",
                          type: "number",
                          step: "10",
                          required: "",
                        },
                        on: {
                          change: _vm.addBudget,
                          blur: function ($event) {
                            return _vm.setTouched("salaryMin")
                          },
                        },
                        model: {
                          value: _vm.salaryMin,
                          callback: function ($$v) {
                            _vm.salaryMin = $$v
                          },
                          expression: "salaryMin",
                        },
                      }),
                      _c("span", { staticClass: "md-helper-text" }, [
                        _vm._v("Please enter a minimum salary"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "md-field",
                    {
                      class: {
                        "md-valid": _vm.isSalaryMaxValid,
                        "md-error":
                          !_vm.isSalaryMaxValid && _vm.touched.salaryMax,
                      },
                      staticStyle: { "margin-top": "3rem" },
                    },
                    [
                      _c("label", [_vm._v("Maximum (ZAR) ")]),
                      _c("md-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.maxSalary,
                            expression: "modelValidations.maxSalary",
                          },
                        ],
                        staticStyle: {
                          outline: "none",
                          border: "none",
                          width: "auto",
                          "text-align": "left",
                        },
                        attrs: {
                          "data-vv-name": "salaryMax",
                          type: "number",
                          min: _vm.modelValidations.salary.min_value,
                          max: _vm.modelValidations.salary.min_value + 50000,
                          step: "10",
                          required: "",
                        },
                        on: {
                          change: _vm.addBudget,
                          blur: function ($event) {
                            return _vm.setTouched("salaryMax")
                          },
                        },
                        model: {
                          value: _vm.salaryMax,
                          callback: function ($$v) {
                            _vm.salaryMax = $$v
                          },
                          expression: "salaryMax",
                        },
                      }),
                      _c("span", { staticClass: "md-helper-text" }, [
                        _vm._v("Please enter a maximum salary"),
                      ]),
                    ],
                    1
                  ),
                  _vm.minAngleError
                    ? _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-top": "1rem",
                            "text-align": "left",
                            "font-size": "10px",
                            color: "red",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.minAngleError) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "salary_range" }, [
        _vm.jobType === "Once-off Project/Task"
          ? _c("p", [
              _vm._v(
                " Jobox service fee (" + _vm._s(_vm.percentage()) + "%): "
              ),
              _c("b", [_vm._v("R" + _vm._s(_vm.fee()))]),
              _c("br"),
              _vm._v(" Admin fee: "),
              _c("b", [
                _vm._v("R" + _vm._s(_vm.price.facilitationFee) + ".00"),
              ]),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("Total: "),
                _c("b", [_c("u", [_vm._v("R" + _vm._s(_vm.total()))])]),
              ]),
            ])
          : _c("p", [
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("Salary Range: "),
                _c("b", [
                  _c("u", [
                    _vm._v(
                      "R " +
                        _vm._s(_vm.salaryMin) +
                        " - " +
                        _vm._s(_vm.salaryMax)
                    ),
                  ]),
                ]),
              ]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("u", [_vm._v("R400.00")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("u", [_vm._v("R3500.00")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("u", [_vm._v("R2000.00")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }