var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout", attrs: { id: "top" } }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
      [
        _c(
          "simple-wizard",
          {
            attrs: {
              name: _vm.name,
              description: _vm.description,
              jobType: _vm.jobType,
              education: _vm.education,
              experience: _vm.experience,
              programme: _vm.programme,
              industryCategory: _vm.industryCategory,
              jobCategory: _vm.jobCategory,
              skills: _vm.skills,
              location: _vm.location,
              deadline: _vm.deadline,
              daysOfTheWeek: _vm.daysOfTheWeek,
              hours: _vm.hours,
              applicationDeadline: _vm.applicationDeadline,
              budget: _vm.budget,
              benefit: _vm.benefit,
              address: _vm.address || "",
              writtenSubmission: _vm.writtenSubmission,
              writtenSubmissionDescription: _vm.writtenSubmissionDescription,
              videoSubmission: _vm.videoSubmission,
              videoSubmissionDescription: _vm.videoSubmissionDescription,
            },
          },
          [
            _c(
              "template",
              { slot: "header" },
              [
                _c("h3", { staticClass: "title" }, [_vm._v("Post a Job")]),
                _c("h5", { staticClass: "category" }, [
                  _vm._v(
                    "This information you fill out will allow students to apply for the job"
                  ),
                ]),
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-simple md-just-icon md-round modal-default-button",
                    staticStyle: {
                      position: "absolute",
                      top: "3px",
                      left: "10px",
                      "font-size": "30px !important",
                    },
                    on: { click: _vm.openPostJobHelp },
                  },
                  [_c("md-icon", [_vm._v("help")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step1")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [
                    _vm._v("Description"),
                  ]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-list-ul" }),
                  ]),
                ]),
                _c("first-step", {
                  ref: "step1",
                  on: {
                    "on-validated": _vm.onStepValidated,
                    name: _vm.addName,
                    description: _vm.addDescription,
                    education: _vm.addEducation,
                    experience: _vm.addExperience,
                    jobType: _vm.addJobType,
                    industryCategory: _vm.addIndustryCategory,
                    jobCategory: _vm.addJobCategory,
                    skills: _vm.addSkills,
                    programme: _vm.addProgramme,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step2")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [_vm._v("Location")]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-location-arrow" }),
                  ]),
                ]),
                _c("second-step", {
                  ref: "step2",
                  attrs: { jobType: _vm.jobType },
                  on: {
                    "on-validated": _vm.onStepValidated,
                    location: _vm.addLocation,
                    deadline: _vm.addDeadline,
                    daysOfTheWeek: _vm.addDaysOfTheWeek,
                    hours: _vm.addHours,
                    applicationDeadline: _vm.addApplicationDeadline,
                    address: _vm.addAddress,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step3")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [_vm._v("Salary")]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-wallet" }),
                  ]),
                ]),
                _c("third-step", {
                  ref: "step3",
                  attrs: { jobType: _vm.jobType },
                  on: {
                    "on-validated": _vm.onStepValidated,
                    budget: _vm.addBudget,
                    benefit: _vm.addBenefit,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step4")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [
                    _vm._v("Additional Submissions"),
                  ]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-tasks" }),
                  ]),
                ]),
                _c("fourth-step", {
                  ref: "step4",
                  on: {
                    writtenSubmission: _vm.addWrittenSubmission,
                    writtenSubmissionDescription:
                      _vm.addWrittenSubmissionDescription,
                    videoSubmission: _vm.addVideoSubmission,
                    videoQuestion: _vm.addVideoQuestion,
                    "on-validated": _vm.onStepValidated,
                  },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": function () {
                    return _vm.validateStep("step4")
                  },
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _c("div", { staticClass: "pc-view" }, [_vm._v("Review")]),
                  _c("div", { staticClass: "mobi-view" }, [
                    _c("i", { staticClass: "fas fa-tasks" }),
                  ]),
                ]),
                _c("fifth-step", {
                  ref: "step5",
                  attrs: {
                    name: _vm.name,
                    description: _vm.description,
                    jobType: _vm.jobType,
                    education: _vm.education,
                    experience: _vm.experience,
                    industryCategory: _vm.industryCategory,
                    jobCategory: _vm.jobCategory,
                    skills: _vm.skills,
                    location: _vm.location,
                    deadline: _vm.deadline,
                    daysOfTheWeek: _vm.daysOfTheWeek,
                    hours: _vm.hours,
                    applicationDeadline: _vm.applicationDeadline,
                    budget: _vm.budget,
                    benefit: _vm.benefit,
                  },
                }),
              ],
              2
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }