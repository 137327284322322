var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    { staticClass: "modal-frame" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("div", { staticClass: "top-left-text" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.currentProfile.profile.name) +
                  " " +
                  _vm._s(_vm.currentProfile.profile.surname)
              ),
            ]),
          ]),
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: { click: _vm.closeViewTranscriptModal },
            },
            [_c("md-icon", { staticClass: "md-size-3x" }, [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("div", [
          _c("iframe", { attrs: { src: this.academicTranscript.url } }),
          _c(
            "div",
            { staticClass: "bottom-right-button" },
            [
              _c(
                "md-button",
                { staticClass: "md-success", on: { click: _vm.viewProfile } },
                [_vm._v(" View Profile ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }