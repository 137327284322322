var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carousel-container" }, [
    _c(
      "div",
      { staticClass: "close-btn" },
      [
        _c(
          "md-button",
          { staticClass: "md-danger", on: { click: _vm.closeViewer } },
          [_vm._v("Close")]
        ),
      ],
      1
    ),
    _vm.videoLink
      ? _c("div", { staticClass: "section video-submission" }, [
          _c(
            "h4",
            { staticClass: "submission-title" },
            [
              _c("md-icon", { staticClass: "icon" }, [_vm._v("videocam")]),
              _vm._v(" Video Submission"),
            ],
            1
          ),
          _vm.videoLink.includes("loom")
            ? _c("iframe", {
                staticStyle: { margin: "auto" },
                attrs: {
                  src: _vm.videoLink + "?autoplay=0",
                  width: "640",
                  height: "360",
                  frameborder: "0",
                  allowfullscreen: "",
                },
              })
            : _c(
                "a",
                {
                  staticClass: "document-link",
                  attrs: { href: _vm.videoLink, target: "_blank" },
                },
                [_vm._v("View Video")]
              ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }