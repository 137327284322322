var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.openModal
    ? _c(
        "modal",
        [
          _c("template", { slot: "header" }, [
            _c("div", { staticClass: "modalHeader" }, [
              _c("h3", [_vm._v(_vm._s(_vm.heading))]),
            ]),
          ]),
          _c(
            "template",
            { slot: "body" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "form-group form-check" },
                [
                  _c("div", { staticClass: "itemCheckBox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedValues,
                          expression: "selectedValues",
                        },
                      ],
                      attrs: { type: "checkbox", id: item.id },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.selectedValues)
                          ? _vm._i(_vm.selectedValues, item) > -1
                          : _vm.selectedValues,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.selectedValues,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = item,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectedValues = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedValues = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedValues = $$c
                          }
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "itemValue" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: item.id },
                      },
                      [_vm._v(_vm._s(item.value))]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass: "centre",
                staticStyle: { "text-align": "center" },
              },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-button md-danger",
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" Cancel")]
                ),
                _vm._v("        "),
                _c(
                  "md-button",
                  {
                    staticClass: "md-button md-success",
                    on: { click: _vm.confirmSelection },
                  },
                  [_vm._v(" Confirm")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }