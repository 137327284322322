var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questions_taker_container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "text-center lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "timer_display" },
        [
          _c("md-icon", [_vm._v(" schedule ")]),
          _c("p", [_vm._v("Time Left: " + _vm._s(_vm.formattedTime))]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "md-icon",
            {
              staticStyle: {
                margin: "0 !important",
                "font-size": "2rem !important",
                "margin-right": "1rem",
              },
            },
            [_vm._v("edit")]
          ),
          _c("h1", [_vm._v(_vm._s(_vm.quizTitle))]),
        ],
        1
      ),
      _c(
        "form",
        {
          staticClass: "questions_taker_form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitQuiz(_vm.ApplicationStatus.COMPLETED)
            },
          },
        },
        [
          _vm._l(_vm.quizItems, function (item, index) {
            return _c(
              "div",
              { key: "item-" + item.id + "-" + index, staticClass: "question" },
              [
                _c(
                  "md-card",
                  { staticClass: "md-layout-item md-size-100" },
                  [
                    _c(
                      "md-card-content",
                      [
                        _c(
                          "h2",
                          {
                            class: {
                              unanswered: _vm.unansweredQuestionsIds.includes(
                                item.questionId
                              ),
                            },
                          },
                          [_vm._v(_vm._s(item.question))]
                        ),
                        item.image.contentUri
                          ? _c("div", { staticClass: "image_container" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "auto",
                                  height: "auto",
                                  "object-fit": "contain",
                                },
                                attrs: {
                                  src: item.image.contentUri,
                                  alt: item.title,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModal(
                                      item.image.contentUri,
                                      item.title
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm.showModal
                          ? _c("ZoomModal", {
                              attrs: {
                                imageSrc: _vm.currentImageSrc,
                                imageAlt: _vm.currentImageAlt,
                              },
                              on: { close: _vm.closeModal },
                            })
                          : _vm._e(),
                        item.question
                          ? _c(
                              "div",
                              [
                                item.type === null
                                  ? _c(
                                      "md-field",
                                      [
                                        _c("md-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: item.description,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.updateResponse(
                                                item.id,
                                                $event.target.value
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.responses[item.id],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.responses,
                                                item.id,
                                                $$v
                                              )
                                            },
                                            expression: "responses[item.id]",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.options
                                  ? _vm._l(
                                      item.options,
                                      function (option, optionIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: optionIndex,
                                            staticClass: "answers",
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: item.type,
                                                id:
                                                  "choice-" +
                                                  index +
                                                  "-" +
                                                  optionIndex,
                                                name: "choice-" + index,
                                              },
                                              domProps: { value: option.value },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.updateResponse(
                                                    index,
                                                    option.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for:
                                                    "choice-" +
                                                    index +
                                                    "-" +
                                                    optionIndex,
                                                },
                                              },
                                              [_vm._v(_vm._s(option.value))]
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "md-button",
            { staticClass: "md-success", attrs: { type: "submit" } },
            [_vm._v("Submit")]
          ),
        ],
        2
      ),
      _vm.error
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! 🤚"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeErrorModal },
                        },
                        [_vm._v(" Ok ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3042895156
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }