var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-overlay" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "image-wrapper" }, [
          _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.imageAlt } }),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "md-button",
            {
              staticClass: "close-btn md-danger",
              on: { click: _vm.closeModal },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }