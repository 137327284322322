var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "logo-position lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "md-card",
            {
              staticClass: "md-card-wizard active",
              attrs: { "data-color": "orange" },
            },
            [
              _c(
                "md-card-header",
                [
                  _vm._t("header", function () {
                    return [
                      _c("h3", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _c("h3", { staticClass: "description" }, [
                        _vm._v(_vm._s(_vm.subTitle)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "wizard-navigation" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-pills", attrs: { role: "tablist" } },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "li",
                      {
                        key: tab.title,
                        ref: "tab-" + index,
                        refInFor: true,
                        staticClass: "nav-item wizard-tab-link",
                        style: _vm.linkWidth,
                        attrs: {
                          role: "tab",
                          tabindex: tab.checked ? 0 : "",
                          id: "step-" + tab.tabId,
                          "aria-controls": tab.tabId,
                          "aria-disabled": tab.active,
                          "aria-selected": tab.active,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: [
                              { "disabled-wizard-link": !tab.checked },
                              { active: tab.active },
                              { checked: tab.checked },
                            ],
                            attrs: { "data-toggle": "tab" },
                            on: {
                              click: function ($event) {
                                return _vm.navigateToTab(index)
                              },
                            },
                          },
                          [_c("tab-item-content", { attrs: { tab: tab } })],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm.activeTab
                  ? _c(
                      "div",
                      {
                        staticClass: "moving-tab",
                        class: { "error-link": _vm.activeTab.hasError },
                        staticStyle: {
                          transition:
                            "transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
                          width: "100%",
                        },
                        style: _vm.movingTabStyles,
                      },
                      [
                        _c("tab-item-content", {
                          attrs: { tab: _vm.activeTab, "moving-tab": true },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("md-card-content", [
                _c(
                  "div",
                  { staticClass: "tab-content" },
                  [
                    _vm._t("default", null, {
                      activeIndex: _vm.activeTabIndex,
                      activeTab: _vm.activeTab,
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "md-card-actions",
                { attrs: { "md-alignment": "space-between" } },
                [
                  _vm._t(
                    "footer",
                    function () {
                      return [
                        _c(
                          "div",
                          [
                            _vm.activeTabIndex > 0
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass: "btn-previous",
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.prevTab.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "pc-view" }, [
                                      _vm._v("Previous"),
                                    ]),
                                    _c("div", { staticClass: "mobi-view" }, [
                                      _c("i", {
                                        staticClass: "fas fa-arrow-left",
                                      }),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _vm.activeTabIndex < _vm.tabCount - 1
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass: "jb-standard",
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.nextTab.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "pc-view" }, [
                                      _vm._v(_vm._s(_vm.btnText)),
                                    ]),
                                    _c("div", { staticClass: "mobi-view" }, [
                                      _c("i", {
                                        staticClass: "fas fa-arrow-right",
                                      }),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "md-button md-success md-theme-default",
                                    on: { click: _vm.finishBursaryApplication },
                                  },
                                  [
                                    _c("div", { staticClass: "md-ripple" }, [
                                      _c(
                                        "div",
                                        { staticClass: "md-button-content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pc-view" },
                                            [_vm._v("Complete Application")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "mobi-view" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-check",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    },
                    { nextTab: _vm.nextTab, prevTab: _vm.prevTab }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.submissionSuccessful
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "font-size": "2rem" } }, [
                  _vm._v("Success! 🎊"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", [
                  _vm._v(
                    "Thank you for your application. The team will be in touch once your application has been evaluated."
                  ),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-button md-success",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(" Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }