var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-40 md-small-size-100" },
        [
          _c("h2", { staticClass: "title" }, [_vm._v("Pop us a message!")]),
          _c("h5", { staticClass: "description" }, [
            _vm._v(
              "Got questions? Want to tell us something? Looking for Advice?"
            ),
          ]),
          _c(
            "h3",
            { staticClass: "title" },
            [
              _c("md-icon", { staticStyle: { color: "white" } }, [
                _vm._v("email"),
              ]),
              _vm._v(" Get in contact"),
            ],
            1
          ),
          _vm._m(0),
          _c("p", [_vm._v("contact@jobox.co.za")]),
        ]
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-40 md-small-size-100 ml-auto" },
        [
          _c(
            "md-card",
            { staticClass: "md-card-contact" },
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-primary text-center" },
                [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Contact Us"),
                  ]),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c("div", { staticClass: "md-layout" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-size-50 md-small-size-100",
                      },
                      [
                        _c(
                          "md-field",
                          [
                            _c("label", [_vm._v("First Name")]),
                            _c("md-input", {
                              model: {
                                value: _vm.firstName,
                                callback: function ($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-size-50 md-small-size-100",
                      },
                      [
                        _c(
                          "md-field",
                          [
                            _c("label", [_vm._v("Last Name")]),
                            _c("md-input", {
                              model: {
                                value: _vm.lastName,
                                callback: function ($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Email Adress")]),
                      _c("md-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Your Message")]),
                      _c("md-textarea", {
                        model: {
                          value: _vm.textarea,
                          callback: function ($$v) {
                            _vm.textarea = $$v
                          },
                          expression: "textarea",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-card-actions",
                { staticClass: "d-flex justify-content-between" },
                [
                  _c(
                    "md-checkbox",
                    {
                      model: {
                        value: _vm.boolean,
                        callback: function ($$v) {
                          _vm.boolean = $$v
                        },
                        expression: "boolean",
                      },
                    },
                    [_vm._v(" I'm not a robot ")]
                  ),
                  _c("md-button", { staticClass: "md-primary" }, [
                    _vm._v(" Send Message "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "description" }, [
      _vm._v(" Having problems or just wanna say hi? "),
      _c("br"),
      _vm._v(" Send us an email or contact us directly using the form! "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }