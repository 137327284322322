var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h2", { staticClass: "test-information-heading" }, [
      _vm._v(_vm._s(this.$route.params.skillName) + " Test Information"),
    ]),
    _vm._m(0),
    _c("ul", [_c("li", [_vm._v(_vm._s(this.$route.params.skillName))])]),
    _c("p", [_vm._v("Test Duration: 10 minutes")]),
    _vm._m(1),
    _c("p", { staticClass: "sub-heading" }, [
      _vm._v("Take note of the following:"),
    ]),
    _vm._m(2),
    _c(
      "div",
      { staticClass: "start-test-button" },
      [
        !_vm.error
          ? _c(
              "md-button",
              {
                staticClass: "md-success",
                staticStyle: { width: "fit-content" },
                on: { click: _vm.navigateToResults },
              },
              [_vm._v("Start Test")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Here you'll be given a vetting test to complete, to see if you are the right fit for the role. "
      ),
      _c("br"),
      _vm._v(
        "This assessment measures the ability to problem solve, use new information, and think critically. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " It tells employers how easily a new hire will internalize training for a new role. Research also shows that this is one of the best predictors of job performance. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" The topics tested in this vetting test are as follows: "),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Want to be better prepared for Jobox’s vetting test? Follow this link: "
      ),
      _c("br"),
      _c(
        "a",
        {
          attrs: {
            href: "https://intercom.help/jobox-64fe20407ade/en/articles/7018895-prepare-for-success-jobox-s-vetting-tests-and-screening-assessments",
          },
        },
        [
          _vm._v(
            " Prepare for Success: Jobox's Vetting Tests and Screening Assessments 📝 | Jobox Help Center "
          ),
        ]
      ),
      _c("br"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [_vm._v("Remember to thoroughly read the questions.")]),
      _c("li", [_vm._v("No take backs!")]),
      _c("li", [
        _vm._v(
          "If you have any questions, please send an email to contact@joinjobox.com"
        ),
      ]),
      _c("li", [
        _vm._v("The Start Test button should open and start the test"),
      ]),
      _c("li", { staticClass: "warning-text" }, [
        _vm._v(
          "Once you start the test, the timer will continue even when you close or leave the screen."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }