var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.job.clientRatingComplete
    ? _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(0)])
            : _vm._e(),
          _c("hr"),
          _c("h2", { staticClass: "centre" }, [
            _vm._v("Please Rate & Review the Student"),
          ]),
          _c("hr"),
          _c("p", [
            _vm._v(
              "Thank you so much for using Jobox as your service provider."
            ),
          ]),
          _c("p", [
            _vm._v(
              "In order to ensure that we keep on providing you with high-quality students, we ask that you please rate and review the students that you worked with."
            ),
          ]),
          _c("p", [
            _vm._v(
              "This not only helps the students improve but also assists us in knowing where students need support"
            ),
          ]),
          _c("p", [
            _vm._v(
              "Your continuous support and collaboration is highly appreciated!"
            ),
          ]),
          _c(
            "md-card",
            [
              _c(
                "md-card-content",
                [
                  _c("p", [
                    _vm._v(
                      "Collapse the headings below to start rating the student"
                    ),
                  ]),
                  _c(
                    "collapse",
                    {
                      attrs: {
                        collapse: [
                          "1. Quality of Skills",
                          "2. Soft Skills - Communication",
                          "3. Soft Skills - Problem Solving",
                          "4. Soft Skills - Leadership",
                          "5. Soft Skills - Organisation",
                        ],
                        icon: "keyboard_arrow_down",
                        "color-collapse": "success",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "md-collapse-pane-1" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Quality of Skills"),
                                    ]
                                  ),
                                  _vm._v(
                                    " Could you please rate the students based on the work they gave you "
                                  ),
                                  _c(
                                    "i",
                                    { staticClass: "fas fa-question-circle" },
                                    [
                                      _c(
                                        "md-tooltip",
                                        { attrs: { "md-direction": "right" } },
                                        [
                                          _vm._v(
                                            "This is similar to an Uber rating where you rate the quality of the work they did."
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.hard,
                                      callback: function ($$v) {
                                        _vm.hard = $$v
                                      },
                                      expression: "hard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-2" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Communication"),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " Communication soft skills are the tools that are used to clearly and effectively converse with others, set expectations, and work with others on projects. "
                                    ),
                                  ]),
                                  _c("h5", [_vm._v("Listening")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good listening skills is one that follows instructions, asking clarifying questions, can rephrase what a person says to ensure understanding and they are able to listen to feedback and action on the feedback given. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.listening,
                                      callback: function ($$v) {
                                        _vm.listening = $$v
                                      },
                                      expression: "listening",
                                    },
                                  }),
                                  _c("h5", [
                                    _vm._v("Verbal and written communication"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good verbal and written communication has clear enunciation, responds to messages on time, speaks with a good tone, knows when to speak and they are able to clearly state needs and wants. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.communication,
                                      callback: function ($$v) {
                                        _vm.communication = $$v
                                      },
                                      expression: "communication",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-3" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Problem Solving"),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " It is the ability to recognize difficulties or complications, identify possible solutions, implement them, and finally follow up to make sure they were successful. "
                                    ),
                                  ]),
                                  _c("h5", [_vm._v("Critical Thinking")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good critical thinking acumen displays the ability to think clearly, rationally and they show an understanding of the logical connection between ideas and concepts. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.criticalThinking,
                                      callback: function ($$v) {
                                        _vm.criticalThinking = $$v
                                      },
                                      expression: "criticalThinking",
                                    },
                                  }),
                                  _c("h5", [
                                    _vm._v("Creative Problem Solving"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good creative problem-solving abilities is one that can identify problems, redefine problems and create opportunities out of the problems by developing new and innovative solutions to problems. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.creative,
                                      callback: function ($$v) {
                                        _vm.creative = $$v
                                      },
                                      expression: "creative",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-4" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Leadership"),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " The visionary perspective that enables one to know what will work and what will not work and the passionate persistence to stimulate people to strive towards peak performance that will enhance both the organisational and their individual well-being. "
                                    ),
                                  ]),
                                  _c("h5", [_vm._v("Taking Initiative")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who is good at taking the initiative does not wait for all of the tasks to be assigned to them, they ask for more work to do and also volunteer for additional responsibilities outside the scope of their role. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.initiative,
                                      callback: function ($$v) {
                                        _vm.initiative = $$v
                                      },
                                      expression: "initiative",
                                    },
                                  }),
                                  _c("h5", [_vm._v("Accountability")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good accountability is one that takes responsibilities for the outcome of their actions and decisions. They successfully transform their effort and they reliably deliver on their commitments and they can be trusted to do what they say they'll do. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.accountability,
                                      callback: function ($$v) {
                                        _vm.accountability = $$v
                                      },
                                      expression: "accountability",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "md-collapse-pane-5" },
                        [
                          _c(
                            "md-card",
                            { staticClass: "bg-success" },
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-category card-category-social centre",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-newspaper",
                                      }),
                                      _vm._v(" Organisation"),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " The ability to function effectively in the workplace. Things to consider are preparedness, attention to detail, the ability to handle a large workload or implement a complex project within an established time frame. "
                                    ),
                                  ]),
                                  _c("h5", [_vm._v("Planning")]),
                                  _c("p", [
                                    _vm._v(
                                      " A student who demonstrates good planning is one who completes tasks on time and manages expectations by informing you on whether they will not be able to accomplish a task in the time frame. "
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.planning,
                                      callback: function ($$v) {
                                        _vm.planning = $$v
                                      },
                                      expression: "planning",
                                    },
                                  }),
                                  _c("h5", [_vm._v("Keep track of work")]),
                                  _c("p", [
                                    _vm._v(
                                      "A student who is able to keep track of their work is one who takes notes and makes an effort to ensure that their work is well managed."
                                    ),
                                  ]),
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": true,
                                      glow: 10,
                                      "round-start-rating": false,
                                    },
                                    model: {
                                      value: _vm.tracking,
                                      callback: function ($$v) {
                                        _vm.tracking = $$v
                                      },
                                      expression: "tracking",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "md-field",
            {
              class: [
                { "md-valid": !_vm.errors.has("review") && _vm.touched.review },
                { "md-error": _vm.errors.has("review") },
              ],
            },
            [
              _c("label", [
                _vm._v(
                  "Tell us about your experience working with the student"
                ),
              ]),
              _c("md-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.modelValidations.review,
                    expression: "modelValidations.review",
                  },
                ],
                attrs: {
                  "data-vv-name": "review",
                  type: "text",
                  name: "review",
                },
                model: {
                  value: _vm.review,
                  callback: function ($$v) {
                    _vm.review = $$v
                  },
                  expression: "review",
                },
              }),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("review"),
                          expression: "errors.has('review')",
                        },
                      ],
                      staticClass: "error",
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "slide-y-down-transition",
                [
                  _c(
                    "md-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.errors.has("review") && _vm.touched.review,
                          expression: "!errors.has('review') && touched.review",
                        },
                      ],
                      staticClass: "success",
                    },
                    [_vm._v("done")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "button",
              {
                staticClass: "md-button md-success md-theme-default",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [_vm._m(1)]
            ),
          ]),
          _vm.modal
            ? _c(
                "modal",
                { on: { close: _vm.modalHide } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! ✋"),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.modalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("p", { staticClass: "black" }, [
                      _vm._v(_vm._s(_vm.feedback)),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.modalHide },
                          },
                          [_vm._v("Got it")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        [
          _vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center lds-circle" }, [_vm._m(2)])
            : _vm._e(),
          _c("hr"),
          _c("h2", { staticClass: "centre" }, [
            _vm._v("Waiting for the Student to Give You a Rating"),
          ]),
          _c("hr"),
          _c("h6", { staticClass: "centre" }, [_vm._v("Student Information")]),
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              { staticClass: "md-layout-item md-small-size-100" },
              [
                _c("br"),
                _c(
                  "md-card",
                  { staticClass: "md-card-profile" },
                  [
                    _c("div", { staticClass: "md-card-avatar" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.cardUserImage },
                      }),
                    ]),
                    _c("br"),
                    _c(
                      "md-card-content",
                      [
                        _c("h6", { staticClass: "category text-gray" }, [
                          _vm._v(_vm._s(_vm.applicant.degree[0].degree)),
                        ]),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-round md-info md-sm",
                            on: {
                              click: function ($event) {
                                return _vm.viewProfile(_vm.applicant.alias)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.applicant.applicant))]
                        ),
                        _c("p", { staticClass: "card-description" }, [
                          _vm._v(" " + _vm._s(_vm.applicant.bio) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.profileModal
            ? _c(
                "modal",
                { on: { close: _vm.profileModalHide } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("div", { staticClass: "md-card-avatar avatar" }, [
                        _c("img", {
                          staticClass: "img radius",
                          attrs: { src: _vm.cardUserImage },
                        }),
                      ]),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-simple md-just-icon md-round modal-default-button",
                          on: { click: _vm.profileModalHide },
                        },
                        [_c("md-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "body" }, [
                    _c("h2", { staticClass: "modal-title black" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.student.name + " " + _vm.student.surname)
                        ),
                      ]),
                    ]),
                    _c("p", { staticClass: "black left" }, [
                      _vm._v(" " + _vm._s(_vm.student.bio) + " "),
                    ]),
                    _c("b", { staticClass: "large-font" }, [
                      _vm._v("Qualifications"),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("p", { staticClass: "black left" }, [
                      _vm.student.studying === "Yes"
                        ? _c("span", [
                            _vm._v(
                              "The candidate is currently studying the following:"
                            ),
                          ])
                        : _vm._e(),
                      _vm.student.studying === "No"
                        ? _c("span", [
                            _vm._v(
                              "The candidate most recently completed the following:"
                            ),
                          ])
                        : _vm._e(),
                      _c("br"),
                      _c("br"),
                      _c("b", [_vm._v("Institution:")]),
                      _vm._v(
                        " " + _vm._s(_vm.student.degree[0].institution) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Degree:")]),
                      _vm._v(" " + _vm._s(_vm.student.degree[0].degree) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Graduate Status:")]),
                      _vm._v(
                        " " + _vm._s(_vm.student.degree[0].graduateStatus) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Year of Study:")]),
                      _vm._v(" " + _vm._s(_vm.student.degree[0].year) + " "),
                      _c("br"),
                    ]),
                    _c("b", { staticClass: "large-font" }, [
                      _vm._v("Contact Information"),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("p", { staticClass: "black left" }, [
                      _c("b", [_vm._v("Email Address:")]),
                      _vm._v(
                        " " + _vm._s(_vm.student.email || "**********") + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Phone Number:")]),
                      _vm._v(
                        " " + _vm._s(_vm.student.phone || "**********") + " "
                      ),
                      _vm.student.personalWebsite
                        ? _c("span", [
                            _c("br"),
                            _c("b", [_vm._v("Website: ")]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.student.personalWebsite,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(_vm.student.personalWebsite))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("b", { staticClass: "large-font" }, [
                      _vm._v("Certificates"),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm.student.certificate1 ||
                    _vm.student.certificate2 ||
                    _vm.student.certificate3
                      ? _c(
                          "p",
                          { staticClass: "black" },
                          [
                            _vm.student.certificate1
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass: "md-round md-info md-sm",
                                    on: { click: _vm.certificate1 },
                                  },
                                  [_vm._v("# 1")]
                                )
                              : _vm._e(),
                            _vm._v("     "),
                            _vm.student.certificate2
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass: "md-round md-info md-sm",
                                    on: { click: _vm.certificate2 },
                                  },
                                  [_vm._v("# 2")]
                                )
                              : _vm._e(),
                            _vm._v("     "),
                            _vm.student.certificate3
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass: "md-round md-info md-sm",
                                    on: { click: _vm.certificate3 },
                                  },
                                  [_vm._v("# 3")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.student.certificate1 &&
                    !_vm.student.certificate2 &&
                    !_vm.student.certificate3
                      ? _c("p", { staticClass: "red left" }, [
                          _vm._v(
                            "The candidate has not uploaded any certificates"
                          ),
                        ])
                      : _vm._e(),
                    _c("b", { staticClass: "large-font" }, [_vm._v("Resume")]),
                    _vm._v(" "),
                    _c("br"),
                    _c(
                      "p",
                      { staticClass: "black" },
                      [
                        _vm.student.cv && _vm.student.cv !== ""
                          ? _c(
                              "md-button",
                              {
                                staticClass: "md-round md-info md-sm",
                                on: { click: _vm.cv },
                              },
                              [_vm._v("CV")]
                            )
                          : _vm._e(),
                        _vm._v("     "),
                        _vm.student.portfolio && _vm.student.portfolio !== ""
                          ? _c(
                              "md-button",
                              {
                                staticClass: "md-round md-info md-sm",
                                on: { click: _vm.portfolio },
                              },
                              [_vm._v("Portfolio")]
                            )
                          : _vm._e(),
                        _vm._v("     "),
                      ],
                      1
                    ),
                    _c("b", { staticClass: "large-font" }, [
                      _vm._v("Social Media Handles"),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c(
                      "p",
                      { staticClass: "black" },
                      [
                        _vm.student.linkedIn && _vm.student.linkedIn !== ""
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-linkedin",
                                staticStyle: {
                                  color: "#0e76a8",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.linkedin },
                              }),
                            ])
                          : _vm._e(),
                        _vm.student.linkedIn && _vm.student.linkedIn !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.linkedIn && _vm.student.linkedIn !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.linkedIn && _vm.student.linkedIn !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.linkedIn && _vm.student.linkedIn !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.github && _vm.student.github !== ""
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-github",
                                staticStyle: {
                                  color: "#000",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.github },
                              }),
                            ])
                          : _vm._e(),
                        _vm.student.github && _vm.student.github !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.github && _vm.student.github !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.github && _vm.student.github !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.github && _vm.student.github !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.facebook && _vm.student.facebook !== ""
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-facebook",
                                staticStyle: {
                                  color: "#3b5998",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.facebook },
                              }),
                            ])
                          : _vm._e(),
                        _vm.student.facebook && _vm.student.facebook !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.facebook && _vm.student.facebook !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.facebook && _vm.student.facebook !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.facebook && _vm.student.facebook !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.twitter && _vm.student.twitter !== ""
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-twitter",
                                staticStyle: {
                                  color: "#00acee",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.twitter },
                              }),
                            ])
                          : _vm._e(),
                        _vm.student.twitter && _vm.student.twitter !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.twitter && _vm.student.twitter !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.twitter && _vm.student.twitter !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.twitter && _vm.student.twitter !== ""
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm.student.instagram && _vm.student.instagram !== ""
                          ? _c("md-icon", [
                              _c("i", {
                                staticClass: "fab fa-instagram",
                                staticStyle: {
                                  color: "#d6249f",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.instagram },
                              }),
                            ])
                          : _vm._e(),
                        !_vm.socialMedia
                          ? _c("span", { staticClass: "red left" }, [
                              _vm._v(
                                "The candidate has no social media handles present"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticClass: "centre" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-button md-success",
                            on: { click: _vm.profileModalHide },
                          },
                          [_vm._v("Done")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-ripple" }, [
      _c("div", { staticClass: "md-button-content" }, [_vm._v("Submit")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }